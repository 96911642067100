import _get from 'lodash/get';

// Models
import { Status, States } from '@sift/resift/models/Status';
import {
  FETCH_TEAM,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_ERROR,
  FETCH_RECENT_SEARCHES,
  FETCH_RECENT_SEARCHES_SUCCESS,
  FETCH_RECENT_SEARCHES_ERROR,
  UPDATE_RECENT_SEARCHES,
  UPDATE_RECENT_SEARCHES_SUCCESS,
  UPDATE_RECENT_SEARCHES_ERROR,
  DELETE_RECENT_SEARCHES,
  DELETE_RECENT_SEARCHES_SUCCESS,
  DELETE_RECENT_SEARCHES_ERROR,
  DELETE_RECENT_SEARCH,
  DELETE_RECENT_SEARCH_SUCCESS,
  DELETE_RECENT_SEARCH_ERROR,
} from 'store/search';

import { RequestResolve } from '@sift/resift/redux/DataService';
import createReducer from '@sift/resift/createReducer';

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_AUTOCOMPLETE = 'FETCH_AUTOCOMPLETE';
export const FETCH_AUTOCOMPLETE_ERROR = 'FETCH_AUTOCOMPLETE_ERROR';
export const FETCH_AUTOCOMPLETE_SUCCESS = 'FETCH_AUTOCOMPLETE_SUCCESS';

export const UPDATE_QUERY = 'UPDATE_QUERY';
export const CLEAR_QUERY = 'CLEAR_QUERY';
export const TOGGLE_BIG_SEARCH = 'TOGGLE_BIG_SEARCH';
export const BIG_SEARCH_CLOSE = 'BIG_SEARCH_CLOSE';

// ------------------------------------
// Actions
// ------------------------------------
/**
 * Fetch a search query
 *
 * @param {object} params The params of the search query.
 *  {
 *    q: string,
 *    ...filters,
 *    ...pagination
 *  }
 *
 */
export const fetchAutocomplete = query => {
  return {
    type: FETCH_AUTOCOMPLETE,
    identifier: 'search',
    payload: {
      query: query,
      requestResolve: RequestResolve.CANCEL,
    },
  };
};

/**
 * Update Query
 *
 * @param {string} [query] updates query
 *
 */
export const updateQuery = query => {
  return {
    type: UPDATE_QUERY,
    identifier: 'search',
    payload: {
      query: query,
    },
  };
};

/**
 * Clear Query
 *
 * @param {string} [query] clears query
 *
 */
export const clearQuery = () => {
  return {
    type: CLEAR_QUERY,
    identifier: 'search',
    payload: {},
  };
};

/**
 * Toggle Open Status of Big Search
 *
 * @param {bool} [query] clears query
 *
 */
export const toggleBigSearch = forceSetState => {
  return {
    type: TOGGLE_BIG_SEARCH,
    identifier: 'bigSearch',
    payload: {
      forceSetState: forceSetState === true || forceSetState === false ? forceSetState : undefined,
    },
  };
};

export const closeBigSearch = () => ({
  type: BIG_SEARCH_CLOSE,
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FETCH_AUTOCOMPLETE]: (state, action) => {
    return {
      ...state,
      searchStatus: new Status(States.NORMAL | States.LOADING),
    };
  },
  [FETCH_AUTOCOMPLETE_SUCCESS]: (state, action) => {
    let results = action.payload.data ? action.payload.data.results : [];
    let status = new Status(States.NORMAL);
    if (results && results[0] && results.length === 1 && results[0].totalLength === 0) {
      status = new Status(States.EMPTY);
    }

    return {
      ...state,
      results: results,
      suggestions: action.payload.data ? action.payload.data.suggestions : state.suggestions,
      searchStatus: status,
      firstLoad: false,
    };
  },
  [FETCH_AUTOCOMPLETE_ERROR]: (state, action) => {
    return {
      ...state,
      searchStatus: new Status(States.NORMAL),
      firstLoad: false,
    };
  },
  [FETCH_TEAM]: (state, action) => {
    return {
      ...state,
      fetchTeamStatus: new Status(States.LOADING),
    };
  },
  [FETCH_TEAM_SUCCESS]: (state, action) => {
    let teamBucket = {};
    if (action.payload.data) {
      teamBucket = {
        items: action.payload.data.results,
        totalLength: action.payload.data.results.length,
        type: 'people',
        display: {
          header: 'My Team',
        },
      };
    }

    return {
      ...state,
      team: teamBucket,
      fetchTeamStatus: new Status(States.NORMAL),
    };
  },
  [FETCH_TEAM_ERROR]: (state, action) => {
    return {
      ...state,
      team: {},
      fetchTeamStatus: new Status(States.NORMAL),
    };
  },
  [UPDATE_QUERY]: (state, action) => {
    return {
      ...state,
      query: action.payload.query,
    };
  },
  [CLEAR_QUERY]: (state, action) => {
    return {
      ...state,
      query: '',
      suggestions: [],
      searchStatus: new Status(States.NORMAL),
      results: [],
    };
  },
  [TOGGLE_BIG_SEARCH]: (state, action) => {
    let open = action.payload.forceSetState;
    if (action.payload.forceSetState === undefined) {
      open = !state.open;
    }

    return {
      ...state,
      open: open,
    };
  },
  [FETCH_RECENT_SEARCHES]: (state, action) => {
    return {
      ...state,
      fetchRecentSearchesStatus: new Status(States.LOADING),
    };
  },
  [FETCH_RECENT_SEARCHES_SUCCESS]: (state, action) => {
    let recentSearchBucket = {};
    if (_get(action, 'payload.data', false)) {
      recentSearchBucket = {
        items: action.payload.data.results,
        totalLength: _get(action, 'payload.data.results.length', 0),
        type: 'recentSearches',
        display: {
          header: 'Recent Searches',
        },
      };

      recentSearchBucket = _get(action, 'payload.data.results.length') ? recentSearchBucket : {};
    }

    return {
      ...state,
      recentSearches: recentSearchBucket,
      fetchRecentSearchesStatus: new Status(States.NORMAL),
    };
  },
  [FETCH_RECENT_SEARCHES_ERROR]: (state, action) => {
    return {
      ...state,
      recentSearches: {},
      fetchRecentSearchesStatus: new Status(States.NORMAL),
    };
  },
  [UPDATE_RECENT_SEARCHES]: (state, action) => {
    return {
      ...state,
    };
  },
  [UPDATE_RECENT_SEARCHES_SUCCESS]: (state, action) => {
    let recentSearchBucket = {};

    if (action.payload.data) {
      recentSearchBucket = {
        items: action.payload.data.results,
        totalLength: action.payload.data.results.length,
        type: 'recentSearches',
        display: {
          header: 'Recent Searches',
        },
      };

      recentSearchBucket = action.payload.data.results.length ? recentSearchBucket : {};
    }

    return {
      ...state,
      recentSearches: recentSearchBucket,
    };
  },
  [UPDATE_RECENT_SEARCHES_ERROR]: (state, action) => {
    return {
      ...state,
    };
  },
  [DELETE_RECENT_SEARCHES]: (state, action) => {
    return {
      ...state,
    };
  },
  [DELETE_RECENT_SEARCHES_SUCCESS]: (state, action) => {
    return {
      ...state,
      recentSearches: {},
    };
  },
  [DELETE_RECENT_SEARCHES_ERROR]: (state, action) => {
    return {
      ...state,
    };
  },
  [DELETE_RECENT_SEARCH]: (state, action) => {
    return {
      ...state,
    };
  },
  [DELETE_RECENT_SEARCH_SUCCESS]: (state, action) => {
    let recentSearchBucket = {};
    if (action.payload.data) {
      recentSearchBucket = {
        items: action.payload.data.results,
        totalLength: action.payload.data.results.length,
        type: 'recentSearches',
        display: {
          header: 'Recent Searches',
        },
      };
      recentSearchBucket = action.payload.data.results.length ? recentSearchBucket : {};
    }

    return {
      ...state,
      recentSearches: recentSearchBucket,
    };
  },
  [DELETE_RECENT_SEARCH_ERROR]: (state, action) => {
    return {
      ...state,
    };
  },
  [BIG_SEARCH_CLOSE]: state => ({
    ...state,
    open: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  open: false,
  results: [],
  team: undefined,
  suggestions: [],
  recentSearches: undefined,
  query: '',
  fetchRecentSearchesStatus: new Status(States.LOADING),
  fetchTeamStatus: new Status(States.NORMAL),
  searchStatus: new Status(States.NORMAL),
  firstLoad: false,
};

export default createReducer(initialState, ACTION_HANDLERS);
