import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import classnames from 'classnames';
import { PROFILE_URL } from 'components/Router/consts';
import { ENTITIES_PATH } from 'components/search/consts';
import { OPEN_ROLE_ENTITY_KEY } from 'pages/OrgChart/consts';

import { Link } from 'react-router-dom';

/**
 * Need to link to a Person/User/Profile thing? Use m3!
 *
 * This component is wrapping `react-router-dom`s `Link` component. Checkout
 * its API for additional props that are available.
 */
function PersonLink(props) {
  let {
    to,
    person,
    classes: classesFromProps,
    className,
    children,
    staticContext,
    ...otherProps
  } = props;
  const { classes } = useStyles(undefined, { props: { classes: classesFromProps } });

  if (!to) {
    const parentUrl = person.entityTypeKey === OPEN_ROLE_ENTITY_KEY ? ENTITIES_PATH : PROFILE_URL;

    to = `${parentUrl}/${person.id}`;
  }

  return (
    <Link to={to} className={classnames(classes.root, className)} {...otherProps}>
      {children}
    </Link>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      color: theme.palette.textColor,
      cursor: 'pointer',
      textDecoration: 'none !important',
      '&:hover': {
        color: theme.palette.accent1Color,
      },
    },
  };
});

PersonLink.propTypes = {
  person: PropTypes.object.isRequired,
  classes: PropTypes.object,
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  // from react-router
  staticContext: PropTypes.any,
};

export default PersonLink;
