import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

function Checked(props: SvgIconProps) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm3 0l5 5 9-9-1.41-1.42L10 14.17l-3.59-3.58L5 12z"
        fill="#70D44B"
      />
      <mask id="prefix__a" maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm3 0l5 5 9-9-1.41-1.42L10 14.17l-3.59-3.58L5 12z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path fill="#70D44B" d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default Checked;
