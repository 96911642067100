import React, { useState } from 'react';
import classnames from 'classnames';
import { AppBarRoute } from 'components/skipper/appBar/models';
import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';
import _get from 'lodash/get';

// Components
import Avatar from 'components/atoms/Avatar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { alpha } from '@mui/material/styles';

import AppLink from 'components/skipper/appBar/AppLink';
import MenuItems from 'components/skipper/appBar/MenuItems';
import SkipTranslate from 'components/atoms/SkipTranslate';

// Styles
import { makeStyles } from 'tss-react/mui';
import { useTheme, Theme } from '@mui/material/styles';
import brandColors from 'styles/skipper/brandColors';
import getBackgroundColor from 'styles/getBackgroundColor';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      textDecoration: 'none',
      position: 'relative',
      marginLeft: theme.spacing(0.75),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      minWidth: '150px',
    },
    link: {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      borderRadius: 999,
      color: theme.palette.originalPrimary.contrastText,
      padding: theme.spacing(0.5, 0.75),
      '&:hover': {
        backgroundColor: getBackgroundColor(theme),
        '& #person-menu-display-name': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    linkActive: {
      backgroundColor: getBackgroundColor(theme),
    },
    linkPrimary: {
      color: theme.palette.originalPrimary.contrastText,
      '&:hover': {
        backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    linkSecondary: {
      color: theme.palette.originalSecondary.contrastText,
      '&:hover': {
        backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    icon: {
      color: theme.palette.primary.contrastText,
      flex: '0 0 auto',
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    iconPrimary: {
      color: theme.palette.originalPrimary.contrastText,
    },
    iconSecondary: {
      color: theme.palette.originalSecondary.contrastText,
    },
    avatarContainer: {},
    avatarNew: {
      marginRight: theme.spacing(1),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(),
    },
    name: {
      minWidth: '100px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '180px',
      padding: 2,
    },
    menuPaper: {
      minWidth: 200,
      top: '65px !important',
    },
    menuItemLink: {
      color: theme.palette.text.primary,
    },
  };
});

export interface Props {
  className?: string;
  avatar?: React.ReactNode;
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  navBarRoutes?: AppBarRoute[];
  accountRoutes?: AppBarRoute[];
  additionalRoutes?: AppBarRoute[];
  msTeamsRoutes?: AppBarRoute[];
  person: {
    id: string;
    displayName: string;
    accountOnly: boolean;
    products: { [key: string]: boolean | undefined };
  };
  pictureUrl?: string;
  onProfileClick?: () => void;
}

/**
 * A Person/Profile dropdown to easily give user access to a list of
 * available routes (applications, settings, admin, etc.).
 *
 * Overrides for styling are provided via `color` and `classes`.
 */
function PersonMenu({
  avatar: avatarFromProps,
  pictureUrl,
  person,
  navBarRoutes,
  accountRoutes,
  additionalRoutes,
  msTeamsRoutes,
  color,
  className,
  onProfileClick,
}: Props) {
  const theme = useTheme();
  let { classes } = useStyles();
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const { displayName, accountOnly, _alternateFieldValues } = person;
  const altDisplayName = _get(_alternateFieldValues, 'displayName');
  const primaryDark = _get(theme, ['palette', 'primary', 'dark'], brandColors.common.black);
  const defaultPictureUrl = createPictureUrlFromColor(primaryDark);

  const avatar = avatarFromProps || (
    <Avatar
      className={classes.avatarNew}
      pictureUrl={pictureUrl || defaultPictureUrl}
      alt={displayName}
    />
  );

  const personClasses = classnames(classes.link, {
    [classes.linkPrimary]: color === 'primary',
    [classes.linkSecondary]: color === 'secondary',
  });
  const iconClasses = classnames(classes.icon, {
    [classes.iconPrimary]: color === 'primary',
    [classes.iconSecondary]: color === 'secondary',
  });
  const rootClasses = classnames(classes.root, className);

  const hasProfile = person.products.directory && !accountOnly;

  const menu = (
    <Menu
      classes={{ paper: classes.menuPaper }}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <MenuItems
        navBarRoutes={navBarRoutes}
        accountRoutes={accountRoutes}
        additionalRoutes={additionalRoutes}
        msTeamsRoutes={msTeamsRoutes}
        person={person}
        classes={{
          menuItemLink: classes.menuItemLink,
        }}
      />
    </Menu>
  );

  const renderDisplayName = () => {
    return (
      <SkipTranslate className={classes.container}>
        <div className={classes.name} id="person-menu-display-name">
          {displayName}
        </div>
        {altDisplayName && <Typography variant="body2">{`(${altDisplayName})`}</Typography>}
      </SkipTranslate>
    );
  };

  if (!hasProfile) {
    return (
      <div className={rootClasses}>
        <div className={personClasses} onClick={handleOpen}>
          {avatar}
          {renderDisplayName()}
          <MoreVertIcon className={iconClasses} />
        </div>
        {menu}
      </div>
    );
  }

  return (
    <div className={rootClasses}>
      <AppLink
        className={personClasses}
        to={`/profile/${person.id}`}
        onClick={onProfileClick}
        activeClassName={classes.linkActive}
      >
        {avatar}
        {renderDisplayName()}
      </AppLink>
      <IconButton onClick={handleOpen} className={iconClasses} aria-label="more actions">
        <MoreVertIcon />
      </IconButton>
      {menu}
    </div>
  );
}

export default PersonMenu;
