import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const WarningIcon = props => (
  <SvgIcon width={24} height={24} viewBox="0 -3 20 22" fill="none" {...props}>
    <path
      d="M9.298 1.243a1.489 1.489 0 0 0-2.577 0L.247 12.991c-.66 1.078.082 2.509 1.278 2.509h12.947c1.216 0 1.938-1.431 1.278-2.51L9.298 1.244z"
      fill="#F5A800"
    />
    <path
      d="M8.87 12.528c0 .357-.209.537-.537.537h-.537c-.357 0-.536-.18-.536-.537v-.447c0-.358.179-.537.536-.537h.537c.358 0 .537.208.537.537v.447zm-.09-2.683c0 .357-.208.537-.537.537h-.387c-.329 0-.537-.18-.537-.537L7.2 4.331c0-.358.18-.566.537-.566h.626c.358 0 .537.208.537.566l-.12 5.514z"
      fill="#fff"
    />
  </SvgIcon>
);

export default WarningIcon;
