import React from 'react';

export type ValueOf<T> = T[keyof T];
export type TypeIn<T, U extends keyof T> = ValueOf<Pick<T, U>>;
export type PropsOf<T> = T extends React.ComponentClass<infer P> ? P : never;
export type RemoveProps<T, U extends keyof T> = Pick<T, Exclude<keyof T, U>>;
export type MakeOptional<T, U extends keyof T> = Pick<T, Exclude<keyof T, U>> & Partial<Pick<T, U>>;

export type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export function isDefined<T>(arg: T | undefined): arg is T {
  return arg !== undefined;
}

export function isTruthy<T>(arg: T | undefined | null): arg is T {
  return !!arg;
}
