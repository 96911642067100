import React from 'react';
import typography from 'helpers/typography';
import { DivProps } from 'helpers/typings';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  displayName: {
    ...typography(theme, 'h4'),
  },
  description: {
    ...typography(theme, 'body1'),
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  badge: {
    alignSelf: 'center',
  },
}));

interface Props extends DivProps {
  displayName: React.ReactNode;
  description: React.ReactNode;
  fromLinkedIn: boolean;
}

function Header({ className, displayName, description, fromLinkedIn, ...restOfProps }: Props) {
  const { classes } = useStyles();

  return (
    <div className={classNames(classes.root, className)} {...restOfProps}>
      <div className={classes.topContainer}>
        <div className={classes.displayName}>{displayName}</div>
        {fromLinkedIn && (
          <img
            className={classes.badge}
            src="https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/onboarding_5.svg"
            alt="Badge"
          />
        )}
      </div>
      <div className={classes.description}>{description}</div>
    </div>
  );
}

export default Header;
