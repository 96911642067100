import React from 'react';
import _get from 'lodash/get';
import typography from 'helpers/typography';

// Components
import Avatar from 'components/atoms/Avatar';
import SkipTranslate from 'components/atoms/SkipTranslate';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      transition: theme.transitions.create('all'),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.infoHover,
      },
      '&:active': {
        backgroundColor: theme.palette.action.infoActive,
      },
    },
    rootHighlight: {
      backgroundColor: theme.palette.action.infoHover,
    },
    avatar: {
      flex: '0 0 auto',
      width: 40,
      height: 40,
      borderRadius: 99999,
      marginRight: theme.spacing(1),
    },
    name: {
      ...typography(theme, 'body1'),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 'initial',
      flex: '0 0 auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 200,
      transition: theme.transitions.create('all'),
    },
    title: {
      ...typography(theme, 'body2'),
      flex: '0 0 auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 200,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

interface Props {
  className?: string;
  pictureUrl: string;
  officialPictureUrl?: string;
  displayName: string;
  title: string;
  isHighlighted: boolean;
  _alternateFieldValues: any;
}

function PersonSuggestion(props: Props) {
  const { className, title, displayName, isHighlighted, _alternateFieldValues } = props;
  const altDisplayName = _get(_alternateFieldValues, 'displayName');
  const { classes } = useStyles();

  return (
    <div
      className={classNames(classes.root, className, {
        [classes.rootHighlight]: isHighlighted,
      })}
    >
      <Avatar className={classes.avatar} person={props} />
      <div className={classes.info}>
        <SkipTranslate className={classes.name}>{displayName}</SkipTranslate>
        {altDisplayName && (
          <SkipTranslate className={classes.title}>({altDisplayName})</SkipTranslate>
        )}
        <div className={classes.title}>{title}</div>
      </div>
    </div>
  );
}

export default PersonSuggestion;
