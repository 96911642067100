import moment from 'moment';

export default function hashDate(value) {
  const from = value.from
    ? moment(value.from)
        .utc()
        .format('MM/DD/YYYY')
    : '';
  const to = value.to
    ? moment(value.to)
        .utc()
        .format('MM/DD/YYYY')
    : '';

  return `${from}-${to}`;
}
