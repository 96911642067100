import moment from 'moment';
import pluralize from 'pluralize';
import memoizeLast from '@sift/resift/memoizeLast';

function getAnniversaryDateContent(anniversaryDate: string) {
  if (!anniversaryDate) {
    return null;
  }

  const parsedDate = moment(anniversaryDate, 'MM-DD-YYYY');
  const now = moment();

  const years = now.diff(parsedDate, 'years');
  const monthsTotal = now.diff(parsedDate, 'months');

  const months = monthsTotal - years * 12;

  const yearsPart = years > 0 ? `${years} ${pluralize('years', years)}` : '';
  const _monthsPart = `${months} ${pluralize('months', months)}`;

  const monthsPart = (() => {
    if (months > 0) {
      return _monthsPart;
    }

    if (yearsPart) {
      return '';
    }

    return 'Less than one month';
  })();

  const tenure = `${[yearsPart, monthsPart].filter(x => !!x).join(' ')}`;
  const startedDate = parsedDate.format('MMM D, YYYY');
  const tenureWithStartDate = `${tenure}, started on ${startedDate}`;

  return {
    tenure,
    tenureWithStartDate,
  };
}

export default memoizeLast(getAnniversaryDateContent);
