import React, { useState } from 'react';
import typography from 'helpers/typography';
import { NORMAL, isLoading } from 'resift';
import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';
import bowser from 'bowser';
import getBase64 from 'helpers/getBase64';
import { useDropzone } from 'react-dropzone';

// Components
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from 'components/skipper/Loader';
import AvatarCropper from 'components/profile/AvatarCropper';
import AppBarPortal from 'pages/Onboarding/components/AppBarPortal';
import SkipDialog from 'pages/Onboarding/components/SkipDialog';
import Header from 'pages/Onboarding/components/Header';
import ContrastButton from 'pages/Onboarding/components/ContrastButton';
import { Prompt } from 'react-router-dom';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { transparentize } from 'polished';

function replacePlaceholderPhoto(pictureUrl: string, theme: Theme) {
  if (!pictureUrl) return undefined;

  if (pictureUrl.includes('placeholders')) {
    return theme.pictureUrl || createPictureUrlFromColor(theme.palette.primary.dark);
  }

  return pictureUrl;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  loader: {
    position: 'relative',
  },
  columns: {
    flex: '1 1 auto',
    display: 'flex',
    width: 640,
    maxWidth: '100%',
    '& > *:not(:last-child)': {
      paddingRight: theme.spacing(2),
    },
  },
  column: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  officialPhoto: {
    flex: '0 0 auto',
    width: 196,
    height: 196,
    borderRadius: 99999,
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
  },
  label: {
    ...typography(theme, 'body2'),
    marginTop: theme.spacing(2),
  },
  customPictureContainer: {
    flex: '0 0 auto',
    width: 196,
    height: 196,
    position: 'relative',
    borderRadius: 99999,
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    ...(bowser.safari
      ? {
          WebkitMaskImage: '-webkit-radial-gradient(white, black)',
          overflow: 'hidden',
        }
      : undefined),
  },
  customPictureContainerEmpty: {
    backgroundColor: transparentize(0.3, 'black'),
    color: transparentize(0.4, theme.palette.common.white),
    '&:hover': {
      color: transparentize(0.2, theme.palette.common.white),
    },
    '&:active': {
      color: theme.palette.common.white,
    },
  },
  editingPhotosOverlayCenter: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    maxWidth: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  changeYourProfilePhoto: {
    ...typography(theme, 'body2'),
    color: 'inherit',
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  customPicture: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    ...(bowser.safari
      ? {
          WebkitMaskImage: '-webkit-radial-gradient(white, black)',
          overflow: 'hidden',
        }
      : undefined),
  },
  controls: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  fileInputButton: {
    ...typography(theme, 'h3'),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.surface,
    overflow: 'hidden',
    paddingTop: 48,
  },
  skipButton: {
    marginRight: theme.spacing(2),
  },
  buttonDisabled: {
    color: `${theme.palette.grey['300']} !important`,
    backgroundColor: `${theme.palette.grey['600']} !important`,
  },
}));

interface Props {
  clientDisplayName: string;
  officialPictureUrl: string;
  customPictureUrl: string | undefined;
  /**
   * when the user presses the continue button, this event will fire
   *
   * the container will do the fetch
   */
  onContinue: (dataUri: string) => void;
  onSkip: () => void;
  status: number;
}

function PhotoUploader({
  onContinue,
  clientDisplayName,
  officialPictureUrl,
  customPictureUrl,
  status,
  onSkip,
}: Props) {
  const { classes, theme } = useStyles();
  const initValue = customPictureUrl || '';
  const [editValueDataUri, setEditValueDataUri] = useState(initValue);
  const [skipDialogOpen, setSkipDialogOpen] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);

  const handleDeleteClick = () => {
    setAreYouSureOpen(true);
  };

  const handleCancelAreYouSure = () => {
    setAreYouSureOpen(false);
  };

  const handleDeleteConfirm = () => {
    setEditValueDataUri('');
    setAreYouSureOpen(false);
  };

  const handleImage = (e: any) => {
    const file = e.target.files[0];

    if (!file) {
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const { result } = reader;
      if (!result) {
        return;
      }
      setEditValueDataUri(result as string);
      setCropping(true);
    };
  };
  const handleDrop = async (acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0];
    if (!acceptedFile) {
      return;
    }

    const base64 = await getBase64(acceptedFile);
    setEditValueDataUri(base64);
    setCropping(true);
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/jpeg, image/png',
    preventDropOnDocument: true,
  });

  const showSkipConfirmation = initValue !== editValueDataUri;

  const handleCropperClose = () => {
    setCropping(false);
  };

  const handleCrop = (croppedDataUri: string) => {
    setEditValueDataUri(croppedDataUri);
    setCropping(false);
  };

  const handleContinue = () => {
    onContinue(editValueDataUri);
    setShowPrompt(false);
  };

  const handleSkip = () => {
    if (showSkipConfirmation) {
      setSkipDialogOpen(true);
    } else {
      onSkip();
    }
  };

  const handleSkipDialogClose = () => {
    setSkipDialogOpen(false);
  };

  return (
    <>
      <AppBarPortal>
        <ContrastButton className={classes.skipButton} onClick={handleSkip}>
          Skip
        </ContrastButton>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleContinue}
          disabled={isLoading(status) || !editValueDataUri}
          classes={{
            disabled: classes.buttonDisabled,
          }}
        >
          Continue
        </Button>
      </AppBarPortal>

      <SkipDialog open={skipDialogOpen} onClose={handleSkipDialogClose} onSkip={onSkip} />
      <Prompt
        when={showPrompt && !skipDialogOpen && showSkipConfirmation}
        message="Are you sure you'd like to leave? Your changes have not been saved yet."
      />

      <AvatarCropper
        open={cropping}
        onClose={handleCropperClose}
        onCrop={handleCrop}
        pictureUrl={editValueDataUri}
      />

      <Dialog open={areYouSureOpen} onClose={handleCancelAreYouSure}>
        <DialogTitle>Are you sure you want to delete your profile photo?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelAreYouSure}>Cancel</Button>
          <Button color="error" variant="contained" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.root}>
        <Header
          displayName="Profile Photo"
          description={
            <>
              {clientDisplayName} encourages you to add a personalized photo. Do you want to do that
              now?
            </>
          }
          fromLinkedIn={false}
        />
        <Loader status={status | NORMAL} isLoadingView={null} className={classes.loader}>
          <div className={classes.columns}>
            <div className={classes.column}>
              <div
                className={classes.officialPhoto}
                aria-label="Company provided photo"
                key={officialPictureUrl}
                style={{
                  background: `url(${replacePlaceholderPhoto(officialPictureUrl, theme)})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
              <div className={classes.label}>Official photo</div>
            </div>
            <div className={classes.column}>
              <div
                className={classNames(classes.customPictureContainer, {
                  [classes.customPictureContainerEmpty]: !editValueDataUri,
                })}
                {...getRootProps()}
              >
                {!editValueDataUri && (
                  <div className={classes.editingPhotosOverlayCenter}>
                    <InsertPhotoIcon fontSize="large" />
                    <div className={classes.changeYourProfilePhoto}>
                      {isDragActive ? 'Incoming!' : 'Add profile photo.'}
                    </div>
                  </div>
                )}
                <div
                  className={classes.customPicture}
                  aria-label="Custom picture"
                  key={editValueDataUri}
                  style={{
                    background: `url(${editValueDataUri})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />
                <input {...getInputProps()} />
              </div>
              <div className={classes.label}>Custom photo</div>
              {!!editValueDataUri && (
                <div className={classes.controls}>
                  <Tooltip title="Delete image" placement="bottom">
                    <IconButton onClick={handleDeleteClick} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Upload Image" placement="bottom">
                    <IconButton size="large">
                      <input
                        className={classes.fileInputButton}
                        size={60}
                        type="file"
                        onChange={handleImage}
                        accept="image/jpeg, image/png"
                      />
                      <CloudUploadIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </Loader>
      </div>
    </>
  );
}

export default PhotoUploader;
