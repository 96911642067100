import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Apple2 = props => (
  <SvgIcon viewBox="-5 0 61 61" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="currentcolor">
        <path d="M47.65,41.19 C46.351,44.069 45.728,45.355 44.055,47.9 C41.721,51.454 38.43,55.879 34.352,55.917 C30.728,55.951 29.798,53.559 24.88,53.586 C19.962,53.613 18.936,55.959 15.311,55.926 C11.234,55.888 8.115,51.892 5.779,48.339 C-0.75,38.402 -1.433,26.74 2.594,20.541 C5.457,16.135 9.971,13.556 14.216,13.556 C18.538,13.556 21.255,15.926 24.829,15.926 C28.296,15.926 30.408,13.552 35.405,13.552 C39.182,13.552 43.183,15.608 46.036,19.163 C36.692,24.283 38.21,37.623 47.65,41.19" />
        <path d="M31.614,9.582 C33.429,7.252 34.808,3.963 34.308,0.6 C31.342,0.804 27.873,2.691 25.848,5.15 C24.009,7.383 22.492,10.693 23.082,13.911 C26.32,14.012 29.67,12.078 31.614,9.582" />
      </g>
    </g>
  </SvgIcon>
);

export default Apple2;
