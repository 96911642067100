import { defineFetch, typedFetchFactory } from 'resift';
import _get from 'lodash/get';
import * as Sift from '@sift/types';

const makeOrgChartSettingsFetch = defineFetch({
  displayName: 'Get Org Chart Settings',
  share: { namespace: 'orgChartSettings' },
  make: () => ({
    request: () => async ({ http, getState }) => {
      const state = getState();
      const personId = _get(state, ['authentication', 'person', 'id']);

      if (!personId) {
        throw new Error('[orgChartSettingsFetch] could not get logged in user id');
      }

      const settings = await http({
        method: 'GET',
        route: `/people/${personId}/settings`,
      });

      const { orgChart } = settings.directory;

      return orgChart;
    },
  }),
});

const typedMakeOrgChartSettingsFetch = typedFetchFactory<Sift.OrgChartSettings>()(
  makeOrgChartSettingsFetch,
);
const orgChartSettingsFetch = typedMakeOrgChartSettingsFetch();
export default orgChartSettingsFetch;
