// Models
import Fetch from '@sift/resift/models/Fetch';
import { mergeLoading, mergeError } from '@sift/resift/redux/FetchUtils';
import { Status, States } from '@sift/resift/models/Status';

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_CONFIG = 'FETCH_CONFIG';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR';

// ------------------------------------
// Actions
// ------------------------------------

/**
 * Get a Config
 *
 * @param {string} configType
 */
export const fetchConfig = configType => {
  return {
    type: FETCH_CONFIG,
    identifier: configType,
    payload: {
      configType: configType,
    },
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FETCH_CONFIG]: mergeLoading,
  [FETCH_CONFIG_SUCCESS]: (state, action) => {
    let identifier = action.identifier;

    return {
      ...state,
      [identifier]: action.payload.data,
      fetches: {
        ...state.fetches,
        [identifier]: new Fetch(
          identifier,
          [identifier],
          [],
          action.payload.data,
          new Status(States.NORMAL),
        ),
      },
    };
  },
  [FETCH_CONFIG_ERROR]: mergeError,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  fetches: {},
};

export default function ConfigurationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
