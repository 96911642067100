import _get from 'lodash/get';
import { useData } from 'resift';
import makeSettingsFetch from 'fetches/settingsFetch';

export default function useLinkedIn() {
  const settingsFetch = makeSettingsFetch();
  const settings = useData(settingsFetch);
  const linkedInData = _get(settings, ['directory', 'userOnboarding', 'linkedInData'], null);
  return linkedInData;
}
