import React from 'react';
import PropTypes from 'prop-types';

// UI
import { default as DefaultHighlighter } from 'components/Highlighter';

import { makeStyles } from 'tss-react/mui';

function Highlighter(props) {
  const { children, className, ...otherProps } = props;
  const { classes } = useStyles();

  return (
    <DefaultHighlighter
      className={className}
      highlightClassName={classes.highlight}
      {...otherProps}
    >
      {children}
    </DefaultHighlighter>
  );
}
const useStyles = makeStyles()(theme => {
  return {
    highlight: {
      fontWeight: theme.typography.fontWeightBlack,
      letterSpacing: '1px',
      color: theme.palette.matched.main,
    },
  };
});

Highlighter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Highlighter;
