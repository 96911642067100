import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SmartPhone = props => (
  <SvgIcon {...props} viewBox="0 0 16 25">
    <g fillRule="nonzero">
      <path d="M11.896.883H4.104A3.251 3.251 0 0 0 .857 4.13v16.884a3.251 3.251 0 0 0 3.247 3.247h7.792a3.251 3.251 0 0 0 3.247-3.247V4.13A3.25 3.25 0 0 0 11.896.883zm1.949 20.13a1.951 1.951 0 0 1-1.949 1.948H4.104a1.951 1.951 0 0 1-1.949-1.948V4.13c0-1.074.875-1.948 1.949-1.948h7.792c1.074 0 1.949.874 1.949 1.948v16.883z" />
      <circle cx="8" cy="21.013" r="1.298" />
      <path d="M9.298 2.83H6.701a.649.649 0 1 0 0 1.3h2.597a.649.649 0 1 0 0-1.3z" />
    </g>
  </SvgIcon>
);

export default SmartPhone;
