import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { AppBarRoute } from 'components/skipper/appBar/models';

// Components
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ExternalMenuLink from 'components/skipper/appBar/ExternalMenuLink';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';

const useStyles = makeStyles({ name: 'SkpAppBarMenuItem' })((theme: any) => {
  return {
    root: {},
    menuItem: {},
    sectionTitle: {
      ...theme.typography.body2,
      fontWeight: theme.typography.fontWeightBold as CSSObject,
      padding: theme.spacing(1.25, 2, 1, 2),
      textTransform: 'capitalize',
    },
    menuItemLink: {
      ...theme.typography.body1,
      textDecoration: 'none',
    },
  };
});

interface Props {
  className?: string;
  classes?: any;
  onClose?: () => void;
  adminRoute?: AppBarRoute;
  navBarRoutes?: AppBarRoute[];
  accountRoutes?: AppBarRoute[];
  additionalRoutes?: AppBarRoute[];
  msTeamsRoutes?: AppBarRoute[];
  person: {
    products: any;
  };
  onMsTeamsLogout?: () => void;
}

function MenuItems({
  className,
  classes: _classes,
  onClose,
  adminRoute,
  navBarRoutes,
  accountRoutes,
  additionalRoutes,
  msTeamsRoutes,
  onMsTeamsLogout,
}: Props) {
  const { classes } = useStyles();
  const allClasses = { ...classes, _classes };
  async function handleLogout() {
    (window as any).Intercom && (window as any).Intercom('shutdown');
    if (!!onMsTeamsLogout) {
      onMsTeamsLogout();
    } else {
      window.location.assign(
        `${window.location.origin}/api/auth/logout?redirect=true&setCookies=true`,
      );
    }
  }

  return (
    <div className={classNames(allClasses.root, className)}>
      {adminRoute && (
        <ExternalMenuLink
          href={adminRoute.externalUrl}
          key={adminRoute.key}
          name={adminRoute.name}
          className={allClasses.menuItemLink}
          onClick={onClose}
        />
      )}
      {adminRoute && <Divider />}

      {navBarRoutes &&
        navBarRoutes.map(route => {
          if (route.onClick) {
            return (
              <div onClick={route.onClick} className={allClasses.menuItemLink} key={route.key}>
                <MenuItem onClick={onClose}>{route.name}</MenuItem>
              </div>
            );
          }

          if (route.url) {
            return (
              <Link to={route.url} className={allClasses.menuItemLink} key={route.key}>
                <MenuItem onClick={onClose}>{route.name}</MenuItem>
              </Link>
            );
          }

          if (route.externalUrl) {
            return (
              <ExternalMenuLink
                href={route.externalUrl}
                key={route.key}
                name={route.name}
                className={allClasses.menuItemLink}
                onClick={onClose}
              />
            );
          }

          return null;
        })}

      {navBarRoutes && <Divider />}

      {accountRoutes &&
        accountRoutes.map(route => {
          if (route.onClick) {
            return (
              <div onClick={route.onClick} className={allClasses.menuItemLink} key={route.key}>
                <MenuItem onClick={onClose}>{route.name}</MenuItem>
              </div>
            );
          }

          if (route.url) {
            return (
              <Link to={route.url} className={allClasses.menuItemLink} key={route.key}>
                <MenuItem onClick={onClose}>{route.name}</MenuItem>
              </Link>
            );
          }

          return null;
        })}

      {!_isEmpty(accountRoutes) && <Divider />}

      {additionalRoutes &&
        additionalRoutes.map(route => {
          const { name, key, externalUrl, url } = route;

          return (
            <a
              href={externalUrl || url}
              className={allClasses.menuItemLink}
              key={key}
              target={externalUrl && '_blank'}
              rel="noopener noreferrer"
            >
              <MenuItem onClick={onClose}>{name}</MenuItem>
            </a>
          );
        })}

      {additionalRoutes && <Divider />}

      {msTeamsRoutes &&
        msTeamsRoutes.map(route => {
          if (route.onClick) {
            return (
              <div onClick={route.onClick} className={allClasses.menuItemLink} key={route.key}>
                <MenuItem onClick={onClose}>{route.name}</MenuItem>
              </div>
            );
          }

          if (route.url) {
            return (
              <Link to={route.url} className={allClasses.menuItemLink} key={route.key}>
                <MenuItem onClick={onClose}>{route.name}</MenuItem>
              </Link>
            );
          }

          if (route.externalUrl) {
            return (
              <ExternalMenuLink
                href={route.externalUrl}
                key={route.key}
                name={route.name}
                className={allClasses.menuItemLink}
                onClick={onClose}
              />
            );
          }

          return null;
        })}

      {msTeamsRoutes && <Divider />}

      <MenuItem onClick={handleLogout} className={allClasses.menuItemLink}>
        Log Out
      </MenuItem>
    </div>
  );
}

export default MenuItems;
