import React from 'react';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  href?: string;
  name: string;
  className: string;
  onClick?: () => void;
}

function ExternalMenuLink({ href, name, className, onClick }: Props) {
  return (
    <a href={href} className={className} target="_blank" rel="noopener noreferrer">
      <MenuItem onClick={onClick}>{name}</MenuItem>
    </a>
  );
}

export default ExternalMenuLink;
