import React from 'react';
import _keyBy from 'lodash/keyBy';
import { SpeakerPersonIcon } from '@fluentui/react-icons-northstar';
import ProfileHeaderItem from 'components/msTeams/embed/molecules/ProfileHeaderItem';

interface Props {
  value: {
    code?: 'FEMALE' | 'MALE' | 'NEUTRAL' | 'OPTOUT';
    value?: string;
    isOther: boolean;
  };
  field: any;
}

function Pronouns({ value, field }: Props) {
  const pronounsDisplay = value.isOther
    ? value.value
    : _keyBy(field.validation.postChildren._selectOptions, 'code')[value.code!].label;

  return <ProfileHeaderItem icon={<SpeakerPersonIcon />} content={pronounsDisplay} />;
}

export default Pronouns;
