import getIsInMsTeams from './getIsInMsTeams';
import { MS_TEAMS_MEETING_MAIN_URL } from 'components/msTeams/consts';

/**
 * (pearl) Notes: isInMsTeams implies our app is being opened inside of Teams,
 * whether it be a tab inside the Teams app, or in the browser.
 *
 * isInMsTeamsEmbed on the other hand, specifically means our app is being opened inside of Teams
 * meeting integration panel or tabs.
 */
export default function getIsInMsTeamsEmbed() {
  const isInMsTeams = getIsInMsTeams();

  if (!isInMsTeams) {
    return false;
  }

  return window.location.href.includes(MS_TEAMS_MEETING_MAIN_URL);
}
