import Component from './CoreSnackbar';

// Libs
import { connect } from 'react-redux';

// Actions
import { expireSnackbar } from 'store/core';

/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

const mapDispatchToProps = {
  expireSnackbar,
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentSnackbar: state.core.currentSnackbar,
    currentSnackbarOpen: !!state.core.currentSnackbar,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
