import React from 'react';
import { makeStyles } from 'tss-react/mui';
import getIsInMicrosoft from 'helpers/getIsInMicrosoft';

import LaunchIcon from '@mui/icons-material/Launch';

interface Props {
  text: string;
}

function NewTabDiv({ text }: Props) {
  const isInMicrosoft = getIsInMicrosoft();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {text}
      {isInMicrosoft && <LaunchIcon fontSize="inherit" />}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
  },
}));

export default NewTabDiv;
