import React, { useEffect } from 'react';
import typography from 'helpers/typography';
import * as microsoftTeams from '@microsoft/teams-js';
import config from 'config';
import queryString from 'query-string';
import classNames from 'classnames';

// Components
import SiftLogo from 'components/skipper/SiftLogo';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';

// Styles
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import MsTeamsThemeWrapper from '../MsTeamsThemeWrapper';

import { SIFT_MARKETING_SITE } from 'helpers/consts';
import { MS_TEAMS_LOGIN_DONE_URL } from 'components/msTeams/consts';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    display: 'flex',
    minHeight: '100%',
    maxWidth: '100%',
  },
  aside: {
    flex: '0 0 auto',
    width: '40%',
    background: 'linear-gradient(180deg, rgba(61, 0, 145, 0) 0%, #3D0091 100%), #7512FF',
    overflow: 'hidden',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  img: {
    display: 'block',
    margin: 'auto',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    maxWidth: 640,
    padding: theme.spacing(4),
  },
  body: {
    flex: '1 1 auto',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 6),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  logo: {
    marginBottom: theme.spacing(3),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    color: '#7512FF',
    marginBottom: theme.spacing(2),
  },
  workBetter: {
    ...typography(theme, 'h4'),
    lineHeight: 1,
  },
  together: {
    ...typography(theme, 'h2'),
    fontWeight: theme.typography.fontWeightBold,
  },
  main: {
    margin: 'auto 0',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
  },
  paragraph: {
    marginBottom: theme.spacing(2),
    maxWidth: 330,
    [theme.breakpoints.down('md')]: {
      maxWidth: 400,
    },
  },
  buttons: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    marginBottom: theme.spacing(2),
  },
  button: {
    width: 150,
    marginTop: theme.spacing(2),
  },
  signupInfo: {
    ...typography(theme, 'caption'),
  },
}));

function MsTeamsLogin() {
  const { classes } = useStyles();

  useEffect(() => {
    microsoftTeams.initialize();
  }, []);

  const handleSignIn = () => {
    microsoftTeams.authentication.authenticate({
      successCallback: () => {
        // (pearl) Note: Console log showed that this never fires, but putting it here in case it is needed.
        const parsed = queryString.parse(window.location.search);
        const path = (parsed.redirectTo as string) || '/';
        window.location.href = path;
      },
      failureCallback: () => {
        /** This failure callback fires when the login window is closed.
         * We don't have a way of calling the successCallback because we're not following the
         * microsoft teams login flow. Instead, we have our own cookie authentication system.
         * Therefore, we always redirect people to where they came from, and auth checking is always
         * handled in `dataServiceActionHandlers`*/
        const parsed = queryString.parse(window.location.search);
        const path = (parsed.redirectTo as string) || '/';
        window.location.href = path;
      },
      height: 700,
      width: 400,
      url: `${config.LOGIN}?inTeams=true&redirectTo=${encodeURIComponent(MS_TEAMS_LOGIN_DONE_URL)}`,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <aside className={classes.aside}>
          <img
            className={classes.img}
            src="https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/sift-group.png"
            alt="Sift Illustration: People and Cards"
          />
        </aside>
        <div className={classes.body}>
          <SiftLogo height={24} className={classes.logo} />
          <main className={classes.main}>
            <h1 className={classes.title}>
              <Typography variant="h4" component="span" className={classes.workBetter}>
                Work Better,
              </Typography>
              <Typography variant="h2" component="span" className={classes.together}>
                Together
              </Typography>
            </h1>
            <Typography className={classes.paragraph}>
              Sift is the fastest way to find and connect with anyone in your company.
            </Typography>
            <Typography className={classes.paragraph}>
              Empowering everyone with the ability to discover and leverage the expertise of all
              your colleagues.
            </Typography>
            <div className={classes.buttons}>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={handleSignIn}
              >
                Sign In
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                component="a"
                href={SIFT_MARKETING_SITE}
                startIcon={<LaunchIcon />}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Button>
            </div>
            <Typography className={classNames(classes.paragraph, classes.signupInfo)}>
              <strong>Note:</strong> A Sift account is required to sign in. To get your company
              started with Sift, please click “Learn More” or try our 30-day trial through the store
              page.
            </Typography>
          </main>
        </div>
      </div>
    </div>
  );
}

function WrappedComponent() {
  return (
    <MsTeamsThemeWrapper>
      <MsTeamsLogin />
    </MsTeamsThemeWrapper>
  );
}

export default WrappedComponent;
