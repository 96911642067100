import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MagnifyingGlass = props => (
  <SvgIcon {...props} viewBox="0 0 52 52">
    <g fillRule="evenodd">
      <path d="M31.28 39.54a.502.502 0 0 1-.354-.146l-5.763-5.763a.503.503 0 0 1 .049-.751 14.565 14.565 0 0 0 2.734-2.734.502.502 0 0 1 .751-.049l5.763 5.763a.5.5 0 0 1 0 .707l-2.828 2.828a.497.497 0 0 1-.352.145zm-5.012-6.22l5.013 5.013 2.121-2.121-5.013-5.013a15.516 15.516 0 0 1-2.121 2.121zM37 3.501h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z" />
      <path d="M35 5.501a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v4a.5.5 0 0 1-.5.5zM43 8.501h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z" />
      <path d="M41 10.501a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v4a.5.5 0 0 1-.5.5zM34.462 35.858l-3.536 3.536a.999.999 0 0 0 0 1.414l9.899 9.899a.999.999 0 0 0 1.414 0l3.536-3.536a.999.999 0 0 0 0-1.414l-9.899-9.899a1 1 0 0 0-1.414 0z" />
      <path d="M41.533 51.499c-.384 0-.768-.146-1.061-.438l-9.899-9.9a1.485 1.485 0 0 1-.439-1.06c0-.4.156-.778.439-1.062l3.535-3.535a1.5 1.5 0 0 1 2.122 0l9.899 9.899a1.501 1.501 0 0 1 0 2.121l-3.536 3.536a1.487 1.487 0 0 1-1.06.439zm-6.364-15.434a.501.501 0 0 0-.354.146l-3.535 3.535a.497.497 0 0 0 0 .707l9.899 9.9a.504.504 0 0 0 .707 0l3.536-3.536a.5.5 0 0 0 0-.708l-9.899-9.899a.5.5 0 0 0-.354-.145zM16.431 36.862c-8.547 0-15.5-6.953-15.5-15.5s6.953-15.5 15.5-15.5c8.546 0 15.5 6.953 15.5 15.5s-6.953 15.5-15.5 15.5zm0-30c-7.995 0-14.5 6.505-14.5 14.5s6.505 14.5 14.5 14.5 14.5-6.505 14.5-14.5-6.505-14.5-14.5-14.5z" />
      <path d="M16.431 33.862c-6.893 0-12.5-5.607-12.5-12.5s5.607-12.5 12.5-12.5c6.892 0 12.5 5.607 12.5 12.5s-5.608 12.5-12.5 12.5zm0-24c-6.341 0-11.5 5.159-11.5 11.5s5.159 11.5 11.5 11.5 11.5-5.159 11.5-11.5-5.159-11.5-11.5-11.5z" />
      <path d="M25.431 21.862a.5.5 0 0 1-.5-.5c0-4.687-3.813-8.5-8.5-8.5a.5.5 0 0 1 0-1c5.238 0 9.5 4.262 9.5 9.5 0 .277-.223.5-.5.5zM3 42.807H1a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1zM16 42.807H5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1zM51 27.807h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1zM47 27.807H36a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1z" />
    </g>
  </SvgIcon>
);

export default MagnifyingGlass;
