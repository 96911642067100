import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import shortId from 'shortid';
import _get from 'lodash/get';
import _has from 'lodash/has';
import useRouter from '@sift/resift/useRouter';
import useInterval from 'helpers/useInterval';
import useFetchSiftBotEffect from './helpers/useFetchSiftBotEffect';
import useConfigureSentryEffect from './helpers/useConfigureSentryEffect';
import useSendThemeBridgeEffect from './helpers/useSendThemeBridgeEffect';
import useRemoveLoaderEffect from './helpers/useRemoveLoaderEffect';
import useRedirectEffect from './helpers/useRedirectEffect';
import useUpdateApp from './helpers/useUpdateApp';
import getTasks from 'pages/Notifications/fetches/getTasks';
import useWrap from 'helpers/useWrap';

// Stores / Actions
import getAppVersionStatus from 'fetches/getAppVersionStatus';
// @ts-ignore
import { fetchPerson as fetchMe } from '@sift/resift/redux/reducers/authentication';
import { fetchConfig } from 'store/configurations';
import { fetchEntityTypes } from 'store/entities';
// @ts-ignore
import { fetchClient } from '@sift/resift/redux/reducers/client';
import { getThemeToUse } from 'store/theme';
import getClientOrgChart from 'fetches/getClientOrgChart';

const second = 1000;
const minute = 60 * second;
const fiveMinutes = 5 * minute;

interface Props {
  children: () => JSX.Element;
}

function AppFetcher({ children }: Props) {
  const dispatch = useDispatch();
  const { pathname } = useRouter().location;

  const [intervalKey, setIntervalKey] = useState(shortId());
  const [updateKey, setUpdateKey] = useState(intervalKey);

  // this `useInterval` will set the `intervalKey` with a brand new id every 5 minutes
  useInterval(() => {
    setIntervalKey(shortId());
    dispatch(getAppVersionStatus());
  }, fiveMinutes);

  const getIntervalKey = useWrap(intervalKey);

  // that `intervalKey` will be used in combination with the pathname
  // this effect is purposefully only watching the `pathname`
  // when the `pathname` changes, this will cause the `updateKey` to change if the `intervalKey` changed
  useEffect(() => {
    const intervalKey = getIntervalKey();
    setUpdateKey(intervalKey);
  }, [pathname, getIntervalKey]);

  const person = useSelector(state => _get(state, ['authentication', 'person']));
  const themeState = useSelector((state: any) => state.theme);
  const clientSettings = useSelector(state => _get(state, ['client', 'settings']));
  const hasUserConfig = useSelector(state => _has(state, ['configurations', 'user']));
  const hasEntityTypes = useSelector(state => _has(state, ['entities', 'types']));
  const hasSiftBotFieldsNotCompleted = useSelector(state =>
    _has(state, ['siftBot', 'fieldsNotCompleted']),
  );
  const products = useSelector(state => _get(state, ['client', 'products']));

  const themeToUse = useMemo(() => getThemeToUse(themeState), [themeState]);

  /**
   * determines whether or not `siftBot` is required to be loaded in order for `loaded` to be true
   */
  const shouldFetchSiftBot = useMemo(() => {
    if (!person) {
      // return true until `person` finishes loading
      return true;
    }

    if (!clientSettings) {
      // return true until `clientSettings` finishes loading
      return true;
    }

    if (_get(person, ['accountOnly'])) {
      return false;
    }

    if (!_get(clientSettings, ['siftBot', 'enabled'])) {
      return false;
    }

    return true;
  }, [person, clientSettings]);

  const siftBotLoaded = useMemo(() => {
    // if we shouldn't fetch siftbot, then siftbot is loaded as far as the app is concerned
    if (!shouldFetchSiftBot) {
      return true;
    }

    return hasSiftBotFieldsNotCompleted;
  }, [shouldFetchSiftBot, hasSiftBotFieldsNotCompleted]);

  const loaded = !!person && hasUserConfig && hasEntityTypes && !!clientSettings && siftBotLoaded;

  useEffect(() => {
    // (notes - Pearl) these fetches happen on app load
    dispatch(getAppVersionStatus());
    dispatch(getClientOrgChart());
  }, [dispatch]);

  // these fetches occur only on route change
  useEffect(() => {
    dispatch(fetchMe());
    dispatch(fetchConfig('user'));
    dispatch(fetchEntityTypes());
    dispatch(fetchClient({ expand: ['theme'] }));
    dispatch(getTasks());
  }, [dispatch, updateKey]);

  // other effects
  useFetchSiftBotEffect({ updateKey, shouldFetchSiftBot, person, clientSettings });
  useConfigureSentryEffect({ person });
  useSendThemeBridgeEffect({ themeToUse, loaded });
  useRemoveLoaderEffect({ loaded });
  useRedirectEffect({ products });
  useUpdateApp();

  if (!loaded) {
    return null;
  }

  return children();
}

export default AppFetcher;
