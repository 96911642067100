import readableColor from 'styles/skipper/readableColor';
import { oneLine } from 'common-tags';

export default function createPictureUrlFromColor(primaryColor) {
  const lineColor = readableColor(primaryColor);

  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(oneLine`
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="200px" height="200x" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero">
          <rect fill="${primaryColor}" x="0" y="0" width="200" height="200"></rect>
          <path
            d="M100,99 C85.0883118,99 73,86.9116882 73,72 C73,57.0883118 85.0883118,45 100,45 C114.911688,45 127,57.0883118 127,72 C127,86.9116882 114.911688,99 100,99 Z M99.6102701,144.479734 C77.2427378,144.479734 59.0000315,144.479734 59.0000315,144.479734 C59.1745144,117.308661 77.2427378,100 99.6102701,100 C107.363234,100 114.642726,101.923057 120.838754,105.620711 C132.518454,112.590901 140.348241,125.866834 140.242298,144.454107 C140.242298,144.454107 121.977803,144.479734 99.6102701,144.479734 Z"
            stroke="${lineColor}"
            stroke-width="6"
          ></path>
        </g>
      </g>
    </svg>
  `)}`;
}
