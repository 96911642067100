import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function Org(props: SvgIconProps) {
  return (
    <SvgIcon width={18} height={18} fill="none" viewBox="0 0 18 18" {...props}>
      <path d="M4.176 10.008h9.791v.36h.72v-.72a.36.36 0 0 0-.36-.36H9.36V8.245h-.72V9.29H3.816a.36.36 0 0 0-.36.36v.72h.72v-.36zM6.66 7.884h4.68c.792 0 1.44-.648 1.44-1.44V1.8c0-.792-.648-1.44-1.44-1.44H6.66c-.792 0-1.44.648-1.44 1.44v4.68c0 .792.648 1.404 1.44 1.404zm1.332-4.932a2.927 2.927 0 0 1 0-.792c.071-.324.54-.612 1.08-.576.54 0 .576.289.576.289.072-.073.216-.109.324.071.108.18.108.54.036 1.008.18.072.072.648-.108.612h-.036c-.108.468-.468.828-.9.828s-.756-.36-.9-.828h-.036c-.108 0-.216-.54-.036-.612zM6.912 5.76c0-.648.54-1.188 1.188-1.188h1.8c.648 0 1.188.54 1.188 1.188v.936H6.912V5.76zM5.976 10.729h-4.32A1.29 1.29 0 0 0 .36 12.024v4.285c0 .72.576 1.295 1.296 1.295H5.94a1.29 1.29 0 0 0 1.296-1.295v-4.285c.036-.72-.54-1.295-1.26-1.295zM2.88 13.104a2.423 2.423 0 0 1 0-.72c.072-.288.504-.54.972-.54.468 0 .504.252.504.252.072-.036.216-.072.288.072.108.18.108.504.036.936.18.071.072.576-.108.576-.109.431-.432.755-.829.755-.395 0-.684-.324-.828-.755-.107 0-.215-.504-.035-.576zm2.844 3.42H1.908v-.864c0-.611.469-1.08 1.08-1.08h1.656c.612 0 1.08.469 1.08 1.08v.864zM16.344 10.729h-4.32a1.29 1.29 0 0 0-1.296 1.295v4.285c0 .72.576 1.295 1.296 1.295h4.284a1.29 1.29 0 0 0 1.296-1.295v-4.285c.036-.72-.54-1.295-1.26-1.295zm-3.096 2.375a2.42 2.42 0 0 1 0-.72c.072-.288.505-.54.972-.54.504 0 .504.252.504.252.072-.036.216-.072.288.072.109.18.109.504.036.936.18.071.072.576-.108.576-.108.431-.432.755-.828.755-.396 0-.684-.324-.829-.755-.107 0-.215-.504-.035-.576zm2.844 3.42h-3.816v-.864c0-.611.468-1.08 1.08-1.08h1.656c.611 0 1.08.469 1.08 1.08v.864z" />
    </SvgIcon>
  );
}

export default Org;
