import { useMemo, useCallback } from 'react';
import _get from 'lodash/get';
import usePassiveRedux from '@sift/resift/usePassiveRedux';

const defaultTerminology = {
  teamMember: 'team member',
  teamMember_plural: 'team members',
};

export default function useTerminology() {
  const terminology = usePassiveRedux((state: any) =>
    _get(state, ['client', 'terminology'], defaultTerminology),
  );

  const singular = terminology.teamMember as string;
  const plural = terminology.teamMember_plural as string;

  const pluralize = useCallback((count: number) => (count === 1 ? singular : plural), [
    singular,
    plural,
  ]);

  return useMemo(
    () => ({
      singular,
      plural,
      pluralize,
    }),
    [singular, plural, pluralize],
  );
}
