import React from 'react';
import _get from 'lodash/get';
import { useHistory } from 'react-router';
import { List, Image, Text } from '@fluentui/react-northstar';
import { ChevronEndIcon } from '@fluentui/react-icons-northstar';
import { Theme } from 'components/msTeams/MsTeamsMainTheme';
import { MS_TEAMS_MEETING_MAIN_URL, MS_TEAMS_PARTICIPANTS_URL } from 'components/msTeams/consts';
import { Person } from '@sift/types';
import SkipTranslate from 'components/atoms/SkipTranslate';

interface Props {
  attendeeList: Person[];
  theme: Theme;
}

function ParticipantsList({ attendeeList, theme }: Props) {
  const styles = useStyles(theme);
  const history = useHistory();

  const personListItems = attendeeList.map(item => {
    const { displayName, pictureUrl, title, id, _alternateFieldValues } = item;
    const altDisplayName = _get(_alternateFieldValues, 'displayName');

    const handleClick = () => {
      history.push(`${MS_TEAMS_MEETING_MAIN_URL}${MS_TEAMS_PARTICIPANTS_URL}/${id}`);
    };

    return {
      key: displayName,
      media: <Image src={pictureUrl} avatar alt={`avatar of ${displayName}`} />,
      header: (
        <SkipTranslate>
          {displayName}
          {altDisplayName && <div>({altDisplayName})</div>}
        </SkipTranslate>
      ),
      content: title ? <span style={styles.listItem}>{title}</span> : null,
      endMedia: <ChevronEndIcon />,
      onClick: handleClick,
    };
  });

  return (
    <>
      <Text>Learn more about participants in this meeting by viewing their profile.</Text>
      <List navigable items={personListItems} style={styles.list} />
    </>
  );
}

const useStyles = (theme: Theme) => ({
  root: {},
  list: {
    marginTop: theme.siteVariables.spacing.unit * 3,
  },
  listItem: {
    color: theme.siteVariables.colorScheme.sift.labelGrey,
  },
});

export default ParticipantsList;
