import { defineFetch } from 'resift';
import config from '../config';

const makeGetAppVersionStatus = defineFetch({
  displayName:
    'Gets the current status on how the application should update if the client is on an old version.',
  make: () => ({
    request: () => ({ http }) => {
      return http({
        method: 'POST',
        route: `/products/directory-web/compatible`,
        data: { platform: 'browser', version: config.APP_VERSION },
        req: (req: any) => {
          req.set({ Authorization: `Bearer ${config.STATIC_AUTH_TOKEN}` });
        },
        ok: () => true,
      });
    },
  }),
});

export default makeGetAppVersionStatus();
