// React
import React from 'react';
import typography from 'helpers/typography';
import { makeStyles } from 'tss-react/mui';

// UI Components
import Item from './Item';
import ResultItemPropTypes from './ResultItemPropTypes';
import SearchLink from 'components/Link/SearchLink';
import Highlighter from './Highlighter';
import Terminology from 'components/Terminology';
import SkipTranslate from 'components/atoms/SkipTranslate';

import { SKIP_TRANSLATE_FIELDS } from 'helpers/consts';

// Component
function AggregateItem({ value, fieldKey, query }) {
  const count = value.count;
  const { classes } = useStyles();
  const ValueComponent = SKIP_TRANSLATE_FIELDS.includes(fieldKey) ? SkipTranslate : 'div';

  return (
    <SearchLink fieldKey={fieldKey} value={value.value} classes={{ root: classes.linkRoot }}>
      <Item
        rows={2}
        className={classes.root}
        recentSearchObject={{
          query: {
            [fieldKey]: value.value,
            page: 1,
          },
          type: 'search',
        }}
        analyticsCardObject={{
          event: 'Big Search Aggregate Result Clicked',
          properties: {
            category: 'Big Search',
            label: fieldKey,
          },
        }}
      >
        <ValueComponent className={classes.text}>
          <Highlighter className={classes.highlighter} find={[query]}>
            {value.value}
          </Highlighter>
        </ValueComponent>
        <div className={classes.bottomRow}>
          <div className={classes.count}>{`${count} matching\u00A0`}</div>
          <Terminology
            className={classes.count}
            term={count > 1 ? 'teamMember_plural' : 'teamMember'}
            casing="capitalize"
          />
        </div>
      </Item>
    </SearchLink>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
    },
    linkRoot: {
      width: '100%',
    },
    text: {
      ...typography(theme, 'h6'),
      fontWeight: theme.typography.fontWeightBold,
      color: 'inherit',
      lineHeight: 1.65,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      maxWidth: '100%',
      height: '50%',
    },
    count: {
      ...typography(theme, 'body2'),
      lineHeight: 1.35,
      color: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    highlighter: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    bottomRow: {
      display: 'flex',
      marginLeft: theme.spacing(),
    },
  };
});

AggregateItem.propTypes = ResultItemPropTypes;

export default AggregateItem;
