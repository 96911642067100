import React from 'react';
import { Theme } from '@mui/material';
import createTheme from './createSkipperTheme';
import memoizeLast from '@sift/resift/memoizeLast';
import { deepmerge } from '@mui/utils';
import { ThemeProvider } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
  theme?: Theme;
}

export default function ThemeOverrideComponent({ children, theme }: Props) {
  const getOrCalculateOverrideTheme = memoizeLast((previousTheme?: Theme) => {
    return createTheme(deepmerge(previousTheme || {}, theme || {}));
  });

  const overrideTheme = getOrCalculateOverrideTheme(theme);

  return <ThemeProvider theme={overrideTheme}>{children}</ThemeProvider>;
}
