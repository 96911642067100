import React from 'react';
import typography from 'helpers/typography';

// Components
import ErrorFaceIcon from 'components/Icons/ErrorFace';
import Random from 'components/skipper/Random';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      ...typography(theme, 'h6'),
      margin: theme.spacing(0, 4),
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    subtitle: {
      textAlign: 'center',
      fontSize: 14,
      margin: theme.spacing(0, 4),
      marginTop: theme.spacing(1),
    },
    icon: {
      marginRight: 14,
    },
  };
});

interface Props {
  className?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
}

const defaultTitle = <Random oneOf={['Yikes!', 'Oh no!', 'Sift happens!']} />;
const defaultSubtitle = (
  <>
    Something went wrong. Try refreshing the page and if this error persists, contact support.
    <br />
    <a href="mailto:support@justsift.com">Support@justsift.com</a>
  </>
);

function ErrorView({
  className,
  title = defaultTitle,
  subtitle = defaultSubtitle,
  children,
}: Props) {
  const { classes } = useStyles();

  return (
    <div className={classNames(classes.root, className)} data-testid="loader-error">
      <ErrorFaceIcon className={classes.icon} />
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
      {children}
    </div>
  );
}

export default ErrorView;
