// NOTICE: this file is deprecated in favor of `/src/helpers/stringifyLocationProps`
import queryString from 'query-string';
import removeFalsyKeys from 'helpers/removeFalsyKeys';
import { pageSizeOptions } from 'store/searchResults';

/**
 * creates a location from the given params
 */
export default function getDerivedLocationFromProps({
  q,
  page,
  pageSize,
  selectedAttributeValues,
  sortBy,
  sortDirection = undefined,
  advancedSearch,
  location,
}) {
  const _location = location && location[0] === '/' ? location.substr(1) : location;

  return `/${_location || 'search'}?${queryString.stringify(
    removeFalsyKeys({
      q,
      page: page === 1 ? undefined : page,
      pageSize: pageSize && pageSize === pageSizeOptions[0] ? undefined : pageSize,
      sortBy,
      sortDirection,
      advancedSearch: advancedSearch ? 'true' : undefined,
      ...selectedAttributeValues.reduce((acc, { value, attributeKey }) => {
        const values = acc[attributeKey] || [];
        values.push(value);
        acc[attributeKey] = values;
        return acc;
      }, {}),
    }),
  )}`;
}
