import { transparentize, getLuminance } from 'polished';
import readableColor from 'styles/skipper/readableColor';

export default function styles(theme) {
  const { primary, common } = theme.palette;

  return {
    components: {
      card: {
        transition: `background-color ${theme.transitions.duration.standard}ms`,
        backgroundColor:
          getLuminance(primary.main) > 0.2
            ? transparentize(0.9, '#000')
            : transparentize(0.9, common.white),
        color: primary.contrastText,
        '&:hover': {
          backgroundColor: primary.dark,
          color: readableColor(primary.dark),
        },
        '&:focus': {
          backgroundColor: primary.dark,
          color: readableColor(primary.dark),
        },
        '&:active': {
          backgroundColor:
            getLuminance(primary.main) > 0.2
              ? transparentize(0.8, '#000')
              : transparentize(0.8, common.white),
          color: primary.contrastText,
        },
      },
    },
  };
}
