import { connect } from 'react-redux';
import _get from 'lodash/get';
import AnalyticsProvider from './AnalyticsProvider';

interface Props {
  userId?: string;
  client?: any;
  clientId?: string;
  clientName?: string;
  clientSubdomain?: string;
  children?: React.ReactNode;
  config: any;
  customIntegrations: any;
}

const mapStateToProps = (state: any, ownProps: Props) => {
  const user = _get(state, 'authentication.person');
  const userId = ownProps.userId || _get(user, 'id');
  const client = ownProps.client || _get(state, 'client');
  const clientId = ownProps.clientId || _get(client, 'clientId');
  const clientSubdomain = ownProps.clientId || _get(client, 'subdomain');
  const clientName = ownProps.clientName || _get(client, 'displayName');

  return { userId, user, clientId, clientName, clientSubdomain };
};

export default connect(
  mapStateToProps,
  null,
)(AnalyticsProvider) as any;
