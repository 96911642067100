import { NORMAL } from 'resift';
import { LegacyStatus } from '@sift/resift/models/Status';

function getNormal(status: number | LegacyStatus) {
  if (typeof status === 'number') return (status & NORMAL) !== 0;
  return status.isNormal();
}

export default function isNormal(...statuses: (number | LegacyStatus)[]) {
  return statuses.every(getNormal);
}
