import React, { useContext } from 'react';
import ParticipantsList from './ParticipantsList';
import { TeamsThemeContext } from 'components/msTeams/MsTeamsMainTheme';
import { AttendeesDataContext } from 'components/msTeams/embed/MsTeamsMain';

function Container() {
  const themeContext = useContext(TeamsThemeContext);
  const attendeeList = useContext(AttendeesDataContext);

  return <ParticipantsList attendeeList={attendeeList} theme={themeContext} />;
}

export default Container;
