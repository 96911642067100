import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SeparatedDetailedCopy from './DetailedCopy';

class SeparatedDetailDialog extends React.Component {
  static propTypes = {
    person: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { person, children } = this.props;

    return (
      <React.Fragment>
        <div onClick={this.handleClickOpen}>{children}</div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="separated-explanation"
          aria-describedby=""
        >
          <DialogTitle>
            {person.separationImplicit ? 'User Removed from Sift' : 'User Separated'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <SeparatedDetailedCopy person={person} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Okay</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default SeparatedDetailDialog;
