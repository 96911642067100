import React, { useContext } from 'react';
import { useData, useStatus } from 'resift';
import { useDispatch } from 'react-redux';
import useRouter from '@sift/resift/useRouter';
import usePassiveRedux from '@sift/resift/usePassiveRedux';
import RunnerContext from '@sift/runner/Context';
import _get from 'lodash/get';
import useSlideInfo from 'pages/Onboarding/helpers/useSlideInfo';
import useIsLastSlide from 'pages/Onboarding/helpers/useIsLastSlide';

import makeAssistantsFetch from 'pages/Profile/fetches/assistantsFetch';
import makeAssistantOfFetch from 'pages/Profile/fetches/assistantOfFetch';
import makeProfileFetch from 'pages/Profile/fetches/profileFetch';
import makeUpdateCompletedSlidesFetch from 'pages/Onboarding/fetches/updateCompletedSlidesFetch';
import updateOnboardingStateFetch from 'pages/Onboarding/fetches/updateOnboardingStateFetch';

import { Redirect } from 'react-router';
import Assistants from './Assistants';

interface Person {
  id: string;
  displayName: string;
  title: string;
  pictureUrl: string;
}

function Container() {
  const { history } = useRouter();
  const dispatch = useDispatch();
  const contextValue = useContext(RunnerContext);
  const slideInfo = useSlideInfo();
  const { nextSlideLink, currentSlide } = slideInfo;

  if (!nextSlideLink) {
    throw new Error('[Assistants Container] expected nextSlideLink to bt truthy');
  }

  if (!contextValue) {
    throw new Error('[Assistants Container] could not find runner context');
  }

  if (!currentSlide) {
    throw new Error('[Assistants Container] expected currentSlide to be truthy');
  }

  const { root, config } = contextValue;
  const personId = _get(root, ['id']) as string | undefined;

  if (!personId) {
    throw new Error('[Assistants Container] did not find person id');
  }

  const assistantsFetch = makeAssistantsFetch(personId);
  const assistantOfFetch = makeAssistantOfFetch(personId);
  const profileFetch = makeProfileFetch(personId);
  const updateCompletedSlidesFetch = makeUpdateCompletedSlidesFetch();

  const displayName = _get(
    config,
    ['categories', 'user_assistants', 'displayName'],
    'assistant',
  ) as string;

  const isLastSlide = useIsLastSlide();

  const handleContinue = async (newData: { assistants: Person[]; assistantOf: Person[] }) => {
    const { assistants, assistantOf } = newData;

    await Promise.all([
      dispatch(assistantsFetch(assistants.map(a => a.id))),
      dispatch(assistantOfFetch(assistantOf.map(a => a.id))),
    ]);

    await dispatch(updateCompletedSlidesFetch(currentSlide));

    if (isLastSlide) {
      await dispatch(updateOnboardingStateFetch('completed'));
    }

    if (!nextSlideLink) {
      return;
    }

    history.push(nextSlideLink);
  };

  const person = useData(profileFetch);
  const status = useStatus(profileFetch);

  const assistants = _get(person, ['_entityRelationships', 'assistants'], []).map(
    (n: any) => n.person,
  );
  const assistantOf = _get(person, ['_entityRelationships', 'assistantOf'], []).map(
    (n: any) => n.person,
  );

  const assistantsEnabled = usePassiveRedux((state: any) =>
    _get(state, ['client', 'features', 'connect', 'assistants'], false),
  ) as boolean;

  if (!assistantsEnabled) {
    return <Redirect to={nextSlideLink} />;
  }

  return (
    <Assistants
      displayName={displayName}
      assistantOf={assistantOf}
      assistants={assistants}
      status={status}
      onContinue={handleContinue}
      currentPersonId={personId}
      isLastSlide={isLastSlide}
    />
  );
}

export default Container;
