// React
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import typography from 'helpers/typography';
import { makeStyles } from 'tss-react/mui';

// UI
import Highlighter from './Highlighter';

function ItemInfo({ title, subtitle, query, topRightIcon }) {
  const { classes } = useStyles();

  let highlighterProps = useMemo(
    () => ({
      find: [query],
    }),
    [query],
  );
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Highlighter className={classes.subOverflow} {...highlighterProps}>
          {title}
        </Highlighter>
        {topRightIcon || null}
      </div>
      <div className={classes.subtitle}>
        <Highlighter {...highlighterProps}>{subtitle}</Highlighter>
      </div>
    </div>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: `0 ${theme.spacing(2)}`,
    },
    title: {
      ...typography(theme, 'h6'),
      fontWeight: theme.typography.fontWeightBold,
      color: 'inherit',
      lineHeight: 1.35,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
    },
    subOverflow: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    subtitle: {
      ...typography(theme, 'body2'),
      lineHeight: 1.35,
      color: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});

ItemInfo.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  query: PropTypes.string,
  topRightIcon: PropTypes.node,
};

export default ItemInfo;
