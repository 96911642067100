export const DOTTED_LINE_RELATIONSHIP = 'Dotted Line Relationship';
export const DOTTED_LINE_RELATIONSHIPS = 'Dotted Line Relationships';
export const DLR_DIAGRAM = 'diagram';
export const DLR_LIST = 'list';
export const DLR_SEARCH = 'search';
export const DLR_ADD = 'add';
export const DLR_EDIT = 'edit';
export const DOTTED_LINE_MANAGER = 'Dotted Line Manager';
export const DOTTED_LINE_REPORT = 'Dotted Line Report';
export const DOTTED_LINE_TEAMMATE = 'Dotted Line Teammate';
export const DOTTED_LINE_MANAGER_KEY = 'dottedLineManager';
export const DOTTED_LINE_REPORT_KEY = 'dottedLineReport';
export const DOTTED_LINE_TEAMMATE_KEY = 'dottedLineTeammate';
export const DOTTED_LINE_MANAGER_API_KEY = 'dotted_line_manager';
export const DOTTED_LINE_REPORT_API_KEY = 'dotted_line_report';
export const DOTTED_LINE_TEAMMATE_API_KEY = 'dotted_line_teammate';

export const ORGCHART_URL = '/orgchart';
export const DLR_MAIN_URL = '/dotted-line-relationships';
export const DLR_LIST_URL = `/${DLR_LIST}`;
export const DLR_DIAGRAM_URL = `/${DLR_DIAGRAM}`;
export const DLR_SEARCH_URL = `/${DLR_SEARCH}`;
export const DLR_ADD_URL = `/${DLR_ADD}`;
export const DLR_EDIT_URL = `/${DLR_EDIT}`;

export const RELATIONSHIP_NAME_ATTR_KEY = 'entityRelationshipTypeName';
export const RELATIONSHIP_LOOKUP = {
  [DOTTED_LINE_MANAGER_API_KEY]: DOTTED_LINE_MANAGER,
  [DOTTED_LINE_TEAMMATE_API_KEY]: DOTTED_LINE_TEAMMATE,
  [DOTTED_LINE_REPORT_API_KEY]: DOTTED_LINE_REPORT,
};

export const DLR_ENTITY_RELATIONSHIPS_KEYS = [
  DOTTED_LINE_MANAGER_KEY,
  DOTTED_LINE_REPORT_KEY,
  DOTTED_LINE_TEAMMATE_KEY,
];

export const ORG_CHART_DIALOG_MIN_HEIGHT = 600;

export const ENTITY_RELATIONSHIP_KEY = '_entityRelationships';
export const RELATED_ENTITY_KEY = '_relatedEntity';

export const OPEN_ROLE_ENTITY_KEY = 'open-role';
