import React from 'react';

// Components
import ContrastButton from './ContrastButton';
import { ButtonProps } from '@mui/material/Button';

// Styles
import ThemeOverrideComponent from 'styles/skipper/ThemeOverrideComponent';
import createTheme from 'styles/skipper/createSkipperTheme';
import { useTheme } from '@mui/material/styles';

function Container(props: ButtonProps) {
  const theme = useTheme();
  const contrastButtonTheme = createTheme({
    palette: { primary: { main: theme.palette.originalPrimary.contrastText } },
  });

  return (
    <ThemeOverrideComponent theme={contrastButtonTheme}>
      <ContrastButton color="primary" {...props} />
    </ThemeOverrideComponent>
  );
}

export default Container;
