import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import { oneLine } from 'common-tags';
import bowser from 'bowser';

// Component
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { transparentize, darken } from 'polished';
import { Theme } from '@mui/material/styles';
import typography from 'helpers/typography';

const threshold = 10;

interface Props {
  className?: string;
  scrollToTop?: boolean;
  onScrolledToTop?: () => void;
  showMore?: boolean;
  color?: string; // primary, secondary, white, black,
  iosScroll?: boolean;
  children: React.ReactNode;
}

const ScrollHinter = forwardRef(
  (
    {
      className,
      scrollToTop,
      showMore,
      color: _color,
      iosScroll,
      children,
      onScrolledToTop,
    }: Props,
    ref: React.Ref<any>,
  ) => {
    const { classes } = useStyles();
    const [interval, setInterval] = useState<number | undefined>();
    const [top, setTop] = useState(true);
    const [bottom, setBottom] = useState(true);

    const setScrollHinters = useCallback(
      (element: HTMLDivElement) => {
        const { scrollTop, scrollHeight, clientHeight } = element;

        const newTop = scrollTop - threshold < 0;
        const newBottom = clientHeight + scrollTop + threshold > scrollHeight;

        if (newTop !== top || newBottom !== bottom) {
          setTop(newTop);
          setBottom(newBottom);
        }
      },
      [bottom, top],
    );

    useEffect(() => {
      // @ts-ignore
      const rootEl = ref.current;
      if (!rootEl) return;

      setInterval(
        window.setInterval(() => {
          setScrollHinters(rootEl);
        }, 250),
      );

      return () => (interval !== undefined ? window.clearInterval(interval) : null);
    }, [interval, ref, setScrollHinters]);

    useEffect(() => {
      onScrolledToTop && onScrolledToTop();
      // @ts-ignore
      const rootEl = ref.current;
      if (!rootEl) return;

      const startAnchor = rootEl.querySelector('.start-anchor');
      if (!startAnchor) return;

      if (rootEl.scrollTop < 30) {
        return;
      }

      if (bowser.msie || bowser.safari) {
        rootEl.scrollTop = 0;
        return;
      }

      startAnchor.scrollIntoView({ behavior: 'smooth' });
    }, [onScrolledToTop, ref, scrollToTop]);

    const handleMoreClick = () => {
      // @ts-ignore
      const scrollEl = ref.current;
      if (!scrollEl) return;
      const endAnchorEl = scrollEl.querySelector('.end-anchor');
      if (!endAnchorEl) return;
      endAnchorEl.scrollIntoView({ behavior: 'smooth' });
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
      setScrollHinters(e.currentTarget);
    };

    const color = _color || 'white';
    const textShadow = `0px 0px 24px ${transparentize(0, color)}`;

    return (
      <div className={classNames(classes.root, className)}>
        <div
          className={classes.scrollTop}
          style={
            !top
              ? {
                  background: `linear-gradient(${color}, ${transparentize(1, color)})`,
                  opacity: 1,
                }
              : undefined
          }
        />
        <div
          className={classNames(classes.scroll, 'scroll', {
            [classes.iosScroll]: iosScroll,
          })}
          ref={ref}
          onScroll={handleScroll}
        >
          <div className="start-anchor" />
          {children}
          <div className="end-anchor" />
        </div>
        <div
          className={classes.scrollBottom}
          style={
            !bottom
              ? {
                  opacity: 1,
                  background: `linear-gradient(${transparentize(1, color)}, ${color})`,
                }
              : undefined
          }
        >
          {showMore && (
            <div
              className={classes.text}
              onClick={handleMoreClick}
              style={{
                textShadow: oneLine`
                  ${textShadow}, ${textShadow}, ${textShadow}, ${textShadow}, ${textShadow}, ${textShadow},
                  ${textShadow}, ${textShadow}, ${textShadow}, ${textShadow}, ${textShadow}, ${textShadow}
                `,
              }}
            >
              {!bottom && (
                <>
                  More&nbsp;
                  <ExpandMoreIcon />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    scroll: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden',
      flex: '1 1 auto',
      '& > *': {
        flex: '0 0 auto',
      },
    },
    iosScroll: {
      WebkitOverflowScrolling: 'touch',
      overflowY: 'scroll',
    },
    scrollTop: {
      position: 'absolute',
      top: 0,
      width: '100%',
      opacity: 0,
      height: 100,
      transition: `all ${theme.transitions.duration.enteringScreen}ms`,
      zIndex: theme.zIndex.surface,
      pointerEvents: 'none',
    },
    scrollBottom: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      opacity: 0,
      height: 100,
      transition: `all ${theme.transitions.duration.enteringScreen}ms`,
      zIndex: theme.zIndex.surface,
      pointerEvents: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    text: {
      ...typography(theme, 'caption'),
      pointerEvents: 'auto',
      cursor: 'pointer',
      color: theme.palette.grey[900],
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
      '&:active': {
        color: darken(0.1, theme.palette.secondary.main),
      },
    },
  };
});

export default ScrollHinter;
