import { useEffect } from 'react';
import _get from 'lodash/get';

interface Params {
  loaded: boolean;
  themeToUse: any;
}

export default function useSendThemeBridgeEffect({ loaded, themeToUse }: Params) {
  useEffect(() => {
    if (!loaded) {
      return;
    }

    const bridgeGetTheme = _get(window, 'bridge.getTheme');
    if (!bridgeGetTheme) {
      return;
    }

    bridgeGetTheme(themeToUse);
  }, [themeToUse, loaded]);
}
