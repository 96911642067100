import React from 'react';

import ErrorBoundary from 'components/skipper/ErrorBoundary';
import AppProvider from 'components/AppProvider';
import AppFetcher from 'components/AppFetcher';
import Router from 'components/Router';

function App() {
  return (
    <ErrorBoundary fullScreen>
      <AppProvider>
        <AppFetcher>{() => <Router />}</AppFetcher>
      </AppProvider>
    </ErrorBoundary>
  );
}

export default App;
