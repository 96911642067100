import React, { forwardRef, useMemo } from 'react';
import { DivProps } from 'helpers/typings';
import { CSSTransition } from 'react-transition-group';

// these are different because they support the legacy status
import isLoading from './helpers/isLoading';
import isNormal from './helpers/isNormal';
import isError from './helpers/isError';

// Components
import CircularProgress from '@mui/material/CircularProgress';
import ErrorView from '../views/Error';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const TRANSITION_TIMING = 250;

function isEmpty(status: any) {
  if (typeof status === 'number') return false;
  return status.isEmpty();
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {},
    overlay: {
      backgroundColor: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: `opacity ${TRANSITION_TIMING}ms`,
      zIndex: theme.zIndex.loader,
    },
    overlayTransparent: {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    appear: {
      opacity: 0,
    },
    appearActive: {
      opacity: 1,
    },
    enter: {
      opacity: 0,
    },
    enterActive: {
      opacity: 1,
    },
    exit: {
      opacity: 1,
    },
    exitActive: {
      opacity: 0,
    },
  };
});

interface Props extends DivProps {
  /**
   * Classnames for each of the three components.
   */
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  className?: string;
  /**
   * Transition timeouts.
   */
  transitionTimeout?: {
    enter?: number;
    exit?: number;
  };
  /**
   * The content to be rendered when normal.
   */
  children?: React.ReactNode;
  /**
   * Loading State Model
   */
  status: any;
  /**
   * Loading indicator view
   */
  isLoadingView?: React.ReactNode;
  /**
   * Empty View
   */
  isEmptyView?: React.ReactNode;
  /**
   * Error View
   */
  isErrorView?: React.ReactNode;
  /**
   * pass in a `ref` to the inner `div` element
   */
}

export default forwardRef((props: Props, ref: React.Ref<any>) => {
  const {
    classes: classesFromProps,
    className,
    transitionTimeout,
    children,
    status,
    isLoadingView,
    isEmptyView,
    isErrorView,
    ...rest
  } = props;
  const { classes } = useStyles(undefined, { props: { classes: classesFromProps } });

  const transitionClasses = {
    appear: classes.appear,
    appearActive: classes.appearActive,
    enter: classes.enter,
    enterActive: classes.enterActive,
    exit: classes.exit,
    exitActive: classes.exitActive,
  };

  const getTransitionTimeout = () => {
    return (
      transitionTimeout || {
        enter: TRANSITION_TIMING,
        exit: TRANSITION_TIMING,
        active: TRANSITION_TIMING,
      }
    );
  };

  /**
   * Renders the proper state view from the current status. See contents of function to
   * determine priority order of each state.
   */

  const stateView = useMemo(() => {
    if (isLoading(status)) return isLoadingView || <CircularProgress />;
    if (isEmpty(status)) return isEmptyView || null;
    if (isError(status)) return isErrorView || <ErrorView />;

    return null;
  }, [isEmptyView, isErrorView, isLoadingView, status]);

  const visible = !!stateView;

  return (
    <div className={classNames(classes.root, className)} data-testid="loader" ref={ref} {...rest}>
      <CSSTransition
        classNames={transitionClasses}
        timeout={getTransitionTimeout()}
        appear
        unmountOnExit
        in={visible}
      >
        <div
          className={classNames(classes.overlay, {
            [classes.overlayTransparent]: isNormal(status),
          })}
        >
          {stateView}
        </div>
      </CSSTransition>
      {isNormal(status) && <>{children}</>}
    </div>
  );
});
