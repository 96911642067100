import { parseToRgb } from 'polished';

export default function isValidColor(color: any) {
  if (!color) return false;
  try {
    parseToRgb(color);
    return true;
  } catch (e) {
    return false;
  }
}
