import * as React from 'react';

const SiftLogo = (props: any) => (
  <svg
    width={138}
    height={39}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Sift Logo"
    {...props}
  >
    <path
      d="M6.3 22.428c3.478 0 6.298-2.644 6.298-5.906 0-3.263-2.82-5.907-6.299-5.907C2.82 10.615 0 13.259 0 16.522c0 3.262 2.82 5.907 6.3 5.907Z"
      fill="#EF5296"
    />
    <path
      d="M23.758 22.428c3.48 0 6.3-2.644 6.3-5.906 0-3.263-2.82-5.907-6.3-5.907-3.479 0-6.299 2.644-6.299 5.907 0 3.262 2.82 5.907 6.3 5.907Z"
      fill="#F27176"
    />
    <path
      d="M41.186 22.428c3.479 0 6.299-2.644 6.299-5.906 0-3.263-2.82-5.907-6.3-5.907-3.478 0-6.298 2.644-6.298 5.907 0 3.262 2.82 5.907 6.299 5.907Z"
      fill="#F78D2C"
    />
    <path
      d="M23.758 38.409c3.48 0 6.3-2.645 6.3-5.907s-2.82-5.907-6.3-5.907c-3.479 0-6.299 2.645-6.299 5.907s2.82 5.907 6.3 5.907Z"
      fill="#F4A81D"
    />
    <path
      d="m53.508 29.598 6.075-3.33a.408.408 0 0 1 .327-.037c.11.035.197.113.238.214 1.473 3.259 4.243 5.343 9.025 5.343 4.781 0 6.393-1.874 6.393-3.962 0-2.783-2.694-3.852-8.62-5.473C60.84 20.693 54.9 18.286 54.9 11.166 54.9 4.046 61.185.03 68.032.03c6.434 0 11.39 3.036 14.112 8.1a.354.354 0 0 1-.153.476l-5.958 3.225a.414.414 0 0 1-.302.028.385.385 0 0 1-.232-.183c-1.428-2.649-3.592-4.472-7.467-4.472-3.313 0-5.253 1.609-5.253 3.747 0 2.303 1.54 3.533 7.592 5.246 6.336 1.874 13.07 3.857 13.07 11.511 0 7.01-5.99 11.292-14.497 11.292-8.023 0-13.344-3.566-15.584-8.972a.354.354 0 0 1 .148-.43Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.619 1.483a.53.53 0 0 0-.081.064c-3.336 3.389-.597 7.523 3.017 7.515 2.491-.002 4.522-1.875 4.566-4.21 0-3.18-3.888-5.735-7.502-3.369ZM93.88 12.28H87.262a.373.373 0 0 0-.363.363v25.26c0 .096.04.187.111.254a.395.395 0 0 0 .27.105h6.6c.21 0 .382-.16.382-.358V12.642c0-.198-.17-.36-.382-.362Z"
      fill="#fff"
    />
    <path
      d="M109.674 11.233v1.044h5.666c.212.003.384.163.386.362v5.082c-.002.198-.174.358-.386.358h-5.284c-.212.002-.382.164-.382.362v19.464c0 .197-.171.358-.382.358h-6.599c-.211 0-.382-.16-.382-.358v-19.46a.352.352 0 0 0-.113-.256.401.401 0 0 0-.273-.106h-3.34a.396.396 0 0 1-.273-.103.348.348 0 0 1-.114-.255V12.64c0-.2.173-.362.386-.362h3.727v-1.044c0-7.288 4.22-11.607 13.047-11.207.205.01.366.169.368.362v5.894a.344.344 0 0 1-.121.262.395.395 0 0 1-.287.096c-3.574-.14-5.644 1.082-5.644 4.593ZM131.429 18.08c-.212.002-.382.163-.382.362V29.27c0 2.8 2.084 2.947 6.043 2.754a.398.398 0 0 1 .285.097.35.35 0 0 1 .12.26v5.558a.376.376 0 0 1-.346.362c-10.062.968-13.469-1.886-13.469-9.03V18.445c-.003-.2-.174-.36-.387-.362H119.1c-.212 0-.384-.16-.386-.358V12.64c.002-.199.174-.36.386-.362h4.198c.211 0 .384-.16.386-.358V7.29a.364.364 0 0 1 .251-.341l6.596-2.244a.409.409 0 0 1 .351.043.356.356 0 0 1 .165.294v6.88c0 .197.171.357.382.357h5.684c.211.002.382.164.382.362v5.082c0 .197-.171.358-.382.358h-5.684Z"
      fill="#fff"
    />
  </svg>
);

export default SiftLogo;
