import React from 'react';
import { useSelector } from 'react-redux';
import AggregatesColumn from './AggregatesColumn';

function Container(props) {
  const config = useSelector(state => state.configurations);

  return <AggregatesColumn config={config} {...props} />;
}

export default Container;
