import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import bowser from 'bowser';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Android from '@mui/icons-material/Android';
import Apple from 'components/Icons/Apple2';
import getIsInMicrosoft from 'helpers/getIsInMicrosoft';

import { makeStyles } from 'tss-react/mui';

const mobilePlatforms = {
  android: {
    displayText: 'Android',
    icon: <Android />,
    href:
      'https://play.google.com/store/apps/details?id=com.justsift.connect&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  },
  ios: {
    displayName: 'iOS',
    icon: <Apple />,
    href:
      'https://itunes.apple.com/app/apple-store/id1213640928?pt=118583916&ct=Sift-Connect-Nav&mt=8',
  },
};

const useStyles = makeStyles()(theme => {
  return {
    banner: {
      display: 'flex',
      height: 50,
      alignItems: 'center',
      padding: '0 18px',
      flex: '0 0 auto',
    },
    spacer: {
      flex: '1 1 auto',
    },
    logo: {
      width: 27,
      height: 27,
      marginRight: theme.spacing(2),
    },
    deviceIcon: {
      marginLeft: 10,
      display: 'inline-flex',
      alignItems: 'center',
    },
    text: {
      flex: '1 1 auto',
      textAlign: 'center',
      display: 'inline-flex',
      alignItems: 'center',
    },
  };
});

function MobileAppGetter() {
  const { classes } = useStyles();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const isMobileView = !!queryParams['mobileView'];
  const isInMicrosoft = getIsInMicrosoft();

  if (isMobileView || isInMicrosoft) {
    return null;
  }

  if (!bowser.mobile && !bowser.tablet) {
    return null;
  }

  let mobDevice = null;

  if (bowser.android) {
    mobDevice = 'android';
  } else {
    return null;
  }

  const deviceDisplay = mobilePlatforms[mobDevice];

  return (
    <Paper elevation={2} className={classes.banner}>
      <img
        className={classes.logo}
        src="https://emailimages.justsift.com/colored_dots_transparent.png"
        alt=""
      />
      <span className={classes.text}>
        Get Sift for <span>&nbsp;{deviceDisplay.displayText}</span>
        <span className={classes.deviceIcon}>{deviceDisplay.icon}</span>
      </span>
      <div className={classes.spacer} />
      <Button href={deviceDisplay.href}>View</Button>
    </Paper>
  );
}
export default MobileAppGetter;
