import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { toggleSupportDrawer } from 'store/support';
import { toggleBigSearch } from 'store/bigSearch';
import { openFilterDrawer } from 'store/search';
import { getThemeToUse } from 'store/theme';
import Navigation from './Navigation';
import { withRouter } from 'react-router';
import queryString from 'query-string';

const mapDispatchToProps = {
  toggleBigSearch,
  openFilterDrawer,
  toggleSupportDrawer,
};

const mapStateToProps = (state, ownProps) => {
  const person = state.authentication.person;
  const currentTheme = getThemeToUse(state.theme);
  const preferOfficialPhotos =
    state.settings && state.settings.directory
      ? state.settings.directory.preferOfficialPhotos
      : false;

  const { q } = queryString.parse(ownProps.location.search);

  return {
    logoUrl: currentTheme.logoUrl,
    person,
    pictureUrl: preferOfficialPhotos ? person.officialPictureUrl : person.pictureUrl,
    pathname: ownProps.location.pathname,
    client: state.client,
    query: q,
    config: state.configurations,
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Navigation);
