import { defineFetch } from 'resift';

export default defineFetch({
  displayName: 'meeting attendees fetch',
  make: () => ({
    request: (chatId: string) => ({ http }: any) =>
      http({
        method: 'get',
        route: `/ms-teams/meetings/${chatId}/attendees`,
      }),
  }),
});
