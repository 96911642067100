import React, { forwardRef } from 'react';
// import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

interface Props {
  loop?: boolean;
  autoplay?: boolean;
  animationData: any;
  stop?: boolean;
  pause?: boolean;
  height?: number;
  width?: number;
  options?: any;
  eventListeners?: any[];
}

const LottieAnimation = forwardRef(
  (
    {
      loop = true,
      autoplay = true,
      animationData,
      stop,
      pause,
      height = 40,
      width = 40,
      options,
      ...restOfProps
    }: Props,
    ref: any,
  ) => {
    const defaultOptions = {
      loop,
      autoplay: autoplay,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
      ...options,
    };

    return (
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={stop}
        isPaused={pause}
        ref={ref}
        {...restOfProps}
      />
    );
  },
);

// LottieAnimation.propTypes = {
//   animationData: PropTypes.any,
//   stop: PropTypes.bool,
//   pause: PropTypes.bool,
//   loop: PropTypes.bool,
//   autoplay: PropTypes.bool,
//   height: PropTypes.number,
//   width: PropTypes.number,
// };

export default LottieAnimation;
