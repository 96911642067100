import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export default function renderDefaultSuggestion(
  suggestion: string,
  { query, isHighlighted }: { query: string; isHighlighted: boolean },
) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      {parts.map((part, index) => {
        return part.highlight ? (
          <strong key={String(index)} style={{ whiteSpace: 'pre' }}>
            {part.text}
          </strong>
        ) : (
          <span key={String(index)} style={{ whiteSpace: 'pre' }}>
            {part.text}
          </span>
        );
      })}
    </MenuItem>
  );
}
