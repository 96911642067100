import { useContext } from 'react';
import * as Sift from '@sift/types';
import RunnerContext from '@sift/runner/Context';
import _get from 'lodash/get';
import useRouter from '@sift/resift/useRouter';
import slides from './slides';
import fieldMap from './fieldMap';

export default function useSlideInfo() {
  const contextValue = useContext(RunnerContext);
  const { match } = useRouter({ path: '/onboarding/:page?' });

  if (!match) {
    throw new Error('[useSlideInfo] could not find "/onboarding" path match');
  }

  if (!contextValue) {
    throw new Error('[useSlideInfo] could not find runner context');
  }

  const page = _get(match, ['params', 'page']);
  const { config } = contextValue;

  const currentSlideIndex = slides.findIndex(slide => slide.path === `/${page}`.toLowerCase());
  if (currentSlideIndex === -1) {
    const firstSlide = slides[0];
    return {
      displayName: undefined,
      fieldKey: undefined,
      field: undefined,
      nextSlideLink: `/onboarding${firstSlide.path}`,
      currentSlide: undefined,
    };
  }
  const _nextSlideLink = _get(slides, [currentSlideIndex + 1, 'path']);
  const nextSlideLink = _nextSlideLink ? `/onboarding${_nextSlideLink}` : null;

  const fieldKey = fieldMap[page];
  const field = _get(config, ['fields', fieldKey || '']) as Sift.Field | undefined;

  const displayNameFromSlides = _get(slides, [currentSlideIndex, 'displayName']) as
    | string
    | undefined;
  const displayNameFromField = _get(field, ['displayName']) as string | undefined;
  const displayName = displayNameFromSlides || displayNameFromField;

  return {
    displayName,
    fieldKey,
    field,
    nextSlideLink,
    currentSlide: `/${page}`,
  };
}
