import { HttpParams, defineFetch } from 'resift';

interface FetcherParams {
  http: (httpParams: HttpParams) => Promise<any>;
}

export default defineFetch({
  displayName: 'autocomplete fetch',
  make: (instanceId: string) => ({
    key: [instanceId],
    request: (httpParams: HttpParams) => ({ http }: FetcherParams) => http(httpParams),
  }),
});
