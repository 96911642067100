import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Status } from '@sift/resift/models/Status';
import _get from 'lodash/get';
import useAnalytics from 'helpers/useAnalytics';
import typography from 'helpers/typography';

// Components
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import Dropzone from 'react-dropzone';
import Loader from 'components/Loader';

import { makeStyles } from 'tss-react/mui';

// Component
function SupportDrawerForm({
  supportTicket,
  userEmail,
  status,
  addAttachments,
  onSubmit,
  createSupportMessage,
  updateSupportMessage,
  toggleSupportDrawer,
}) {
  const analytics = useAnalytics();
  const { classes } = useStyles();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState('');
  const [showFileError, setShowFileError] = useState(false);

  const onDrop = files => {
    if (!supportTicket) {
      createSupportMessage({
        subject: subject,
        message: message,
      });
    }

    let addedFiles = [];
    let showFileError = false;
    for (let i = 0; files.length > i; i++) {
      let file = files[i];
      if (file.type === 'image/gif' || file.type === 'image/jpeg' || file.type === 'image/png') {
        if (addedFiles.length < 5) {
          let fileHasSameName = false;
          if (_get(supportTicket, 'attachments.length')) {
            for (let count = 0; supportTicket.attachments.length > count; count++) {
              if (supportTicket.attachments[count].name === file.name) {
                fileHasSameName = true;
              }
            }
          }

          if (!fileHasSameName) {
            addedFiles.push(file);
          }
        } else {
          showFileError =
            'Only five files can be uploaded at once. You may now add more files to be uploaded.';
        }
      } else {
        showFileError = 'An unsupported file was uploaded. Please only upload PNG, JPEG or GIFs.';
      }
    }

    if (supportTicket && addedFiles.length) {
      addAttachments(addedFiles, supportTicket.id);
    }

    setAttachments([...attachments, ...addedFiles]);
    setShowFileError(showFileError);
  };

  const handleChangeSubject = e => {
    setSubject(e.target.value);
  };

  const handleChangeMessage = e => {
    setMessage(e.target.value);
  };

  const onClearFilesUploaded = () => {
    updateSupportMessage(
      {
        subject: subject,
        message: message,
        attachments: [],
      },
      supportTicket.id,
    );
  };

  const removeAttachmentByIndex = index => {
    const supportTicketFiles = supportTicket.attachments.slice();
    supportTicketFiles.splice(index, 1);
    updateSupportMessage(
      {
        subject: subject,
        message: message,
        attachments: supportTicketFiles,
      },
      supportTicket.id,
    );
  };

  const onClose = () => {
    toggleSupportDrawer();
  };

  const handleSubmit = () => {
    if (!supportTicket) {
      createSupportMessage({ subject: subject, message: message }, '?send=true');
    } else {
      updateSupportMessage(
        {
          subject: subject,
          message: message,
          attachments: supportTicket.attachments,
        },
        supportTicket.id,
        '?send=true',
      );
    }

    analytics.track('Submited Support Ticket', {
      category: 'Support',
    });

    setSubject('');
    setMessage('');
    setShowFileError(false);

    onSubmit();
  };

  let submitButtonCopy = 'SEND';
  if (status.isLoading()) {
    submitButtonCopy = 'LOADING';
  }

  return (
    <Dropzone noClick style={{ position: 'relative', height: '100%' }} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <Loader
          status={new Status(1 | status.value)}
          isErrorView={<div>There was an error. Please refresh the page.</div>}
        >
          <div {...getRootProps()} style={{ outline: 'none' }}>
            <input {...getInputProps()} />
            {isDragActive && <div className={classes.dropzoneOverlay}>Drop files...</div>}
            <div className={classes.headerRow + ' ' + classes.drawerSection}>
              <div>
                <div className={classes.title}> Support </div>
                <div className={classes.headerSubtitle}>
                  Complete the form below to send your system administrator a message.
                </div>
              </div>
              <CloseIcon className={classes.headerIcon} onClick={onClose} />
            </div>
            <div className={classes.drawerSection}>
              <TextField
                label="Add Subject"
                fullWidth
                className={classes.inputSpacing}
                value={subject}
                onChange={handleChangeSubject}
                maxLength={100}
              />
              <TextField
                label="Describe the issue or idea in detail"
                fullWidth
                className={classes.inputSpacing}
                value={message}
                onChange={handleChangeMessage}
                multiline
                maxRows={4}
                rows={4}
                maxLength={800}
              />
              <TextField
                label="Email"
                value={userEmail}
                fullWidth
                className={classes.inputSpacing}
                disabled
              />
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps} className={classes.dragAndDropMessage}>
                    <input {...getInputProps()} />
                    <CloudUploadIcon className="upload-icon" style={{ width: 84, height: 40 }} />
                    <div> Drag and drop files on this drawer. </div>
                  </div>
                )}
              </Dropzone>
              {supportTicket && supportTicket.attachments && supportTicket.attachments.length ? (
                <div className={classes.filesContainer}>
                  <div style={{ marginBottom: 8 }}>
                    {supportTicket.attachments.length} Uploaded:{' '}
                  </div>
                  <div className={classes.attachments}>
                    {supportTicket.attachments.map((file, index) => (
                      <div className={classes.attachmentRow} key={file}>
                        <strong className={classes.fileName}>{file}</strong>
                        <IconButton
                          aria-label="Delete"
                          onClick={() => removeAttachmentByIndex(index)}
                          size="large"
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <Button className={classes.clearFiles} onClick={onClearFilesUploaded}>
                    Clear Files Uploaded
                  </Button>
                </div>
              ) : null}
              {showFileError ? <div className={classes.error}>{showFileError}</div> : null}
              <div className={classes.buttonsRow}>
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  CLOSE
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  color="secondary"
                  disabled={subject.length === 0 || message.length === 0 || status.isLoading()}
                >
                  {submitButtonCopy}
                </Button>
              </div>
            </div>
          </div>
        </Loader>
      )}
    </Dropzone>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    drawerSection: {
      padding: 16,
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    title: {
      ...typography(theme, 'h6'),
    },
    headerRow: {
      borderBottom: '1px solid ' + theme.palette.borderColor,
      marginBottom: 16,
    },
    headerSubtitle: {
      ...typography(theme, 'subtitle1'),
      marginTop: 8,
      color: '#8a8a8a',
      width: 350,
    },
    headerIcon: {
      cursor: 'pointer',
      borderRadius: '50%',
      position: 'absolute',
      minWidth: 24,
      right: 8,
      top: 8,
      transition: 'background-color 200ms',

      '&:hover': {
        backgroundColor: theme.palette.borderColor,
      },
    },
    inputSpacing: {
      marginBottom: theme.spacing(2),
    },
    buttonsRow: {
      marginTop: 32,
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      position: 'relative',
    },
    dropzoneOverlay: {
      ...typography(theme, 'h6'),
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: '2.5em 0',
      background: '#84B1FF',
      opacity: '.8',
      textAlign: 'center',
      color: theme.palette.common.white,
      zIndex: theme.zIndex.surface,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    dragAndDropMessage: {
      ...typography(theme, 'subtitle1'),
      boxSizing: 'border-box',
      margin: '8px auto',
      color: '#8a8a8a',
      border: '1px dashed',
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
    },
    attachmentRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(0.5),
    },
    filesContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    clearFiles: {
      ...typography(theme, 'caption'),
      marginTop: theme.spacing(1),
      alignSelf: 'flex-end',
      cursor: 'pointer',
    },
    error: {
      ...typography(theme, 'caption'),
      color: theme.palette.error.main,
      marginBottom: theme.spacing(1),
    },
    fileName: {
      width: 335,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    attachments: {
      ...typography(theme, 'body2'),
    },
  };
});

SupportDrawerForm.propTypes = {
  status: PropTypes.object.isRequired,
  supportTicket: PropTypes.object,
  userEmail: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleSupportDrawer: PropTypes.func.isRequired,
  createSupportMessage: PropTypes.func.isRequired,
  updateSupportMessage: PropTypes.func.isRequired,
  addAttachments: PropTypes.func.isRequired,
};

export default SupportDrawerForm;
