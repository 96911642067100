import React, { useContext } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useDispatch } from 'resift';
import usePrevious from '@sift/resift/usePrevious';
import getPathDepth from 'helpers/getPathDepth';
import getClearAuthToken from 'fetches/getClearAuthToken';

import { Layout, Button } from '@fluentui/react-northstar';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';
import { MS_TEAMS_MEETING_MAIN_URL, MS_TEAMS_PARTICIPANTS_URL } from 'components/msTeams/consts';
import ParticipantsList from 'components/msTeams/embed/ParticipantsList';
import PersonProfile from 'components/msTeams/embed/profile/PersonProfile';

import 'styles/msTeams.css';

function MsTeamsMain() {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);
  const timeout = { enter: 1200 };
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const dispatch = useDispatch();
  const flipToChildPage =
    getPathDepth(location.pathname) - getPathDepth(prevLocation.pathname) >= 0;

  const handleSignOut = () => {
    dispatch(getClearAuthToken());
    window.location.reload();
  };

  return (
    <TransitionGroup component={null} style={styles.root}>
      <CSSTransition
        key={location.key}
        classNames={flipToChildPage ? 'msTeamsPage-slide-left' : 'msTeamsPage-slide-right'}
        timeout={timeout}
        mountOnEnter={false}
        unmountOnExit
      >
        <Layout vertical style={styles.root} data-testid="root-div">
          <Button
            content="Sign Out"
            size="small"
            text
            style={styles.button}
            onClick={handleSignOut}
          />
          <Switch>
            <Route
              path={`${MS_TEAMS_MEETING_MAIN_URL}${MS_TEAMS_PARTICIPANTS_URL}/:personId`}
              component={PersonProfile}
            />
            <Route
              path={`${MS_TEAMS_MEETING_MAIN_URL}${MS_TEAMS_PARTICIPANTS_URL}`}
              component={ParticipantsList}
            />
            <Route path={MS_TEAMS_MEETING_MAIN_URL} component={ParticipantsList} />
            <Redirect to={`${MS_TEAMS_MEETING_MAIN_URL}${MS_TEAMS_PARTICIPANTS_URL}`} />
          </Switch>
        </Layout>
      </CSSTransition>
    </TransitionGroup>
  );
}

const useStyles = (theme: Theme) => ({
  root: {
    paddingTop: theme.siteVariables.spacing.unit,
    maxWidth: 500,
  },
  button: {
    marginLeft: 'auto',
    marginBottom: theme.siteVariables.spacing.unit,
    textDecoration: 'underline',
  },
});

export default MsTeamsMain;
