import React, { useContext } from 'react';
import { Flex, Text } from '@fluentui/react-northstar';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';

interface Props extends React.ComponentProps<'div'> {
  icon: React.ReactNode;
  content: string;
}

function ProfileHeaderItem({ icon, content, style, ref, ...restOfProps }: Props) {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);

  return (
    <Flex gap="gap.smaller" vAlign="center" style={styles.root} {...restOfProps}>
      {icon}
      <Text>{content}</Text>
    </Flex>
  );
}

const useStyles = (theme: Theme) => ({
  root: {
    marginBottom: theme.siteVariables.spacing.unit * 0.5,
  },
});

export default ProfileHeaderItem;
