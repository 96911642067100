import React, { lazy } from 'react';
import _get from 'lodash/get';
// Pages
const EntityPage = lazy(() => import('pages/Entity'));
const LandingPage = lazy(() => import('pages/Landing'));
const SettingsPage = lazy(() => import('pages/Settings'));
const ListsPage = lazy(() => import('pages/Lists'));
const ListPage = lazy(() => import('pages/List'));
const ProfilePage = lazy(() => import('pages/Profile'));
const PersonPage = lazy(() => import('pages/Person'));
const OrgChartPage = lazy(() => import('pages/OrgChart'));
const NotificationsPage = lazy(() => import('pages/Notifications'));
const NoRoutePage = lazy(() => import('pages/NoRoute'));
const OnboardingPage = lazy(() => import('pages/Onboarding'));
const SmsFormPage = lazy(() => import('pages/SmsForm'));
const ThemeLoaderPage = lazy(() => import('pages/ThemeLoader'));
const SearchPage = lazy(() => import('pages/Search'));
const AttributePage = lazy(() => import('pages/Attribute'));

const Landing = {
  path: '/',
  component: props => <LandingPage {...props} />,
  exact: true,
};

const Search = {
  title: 'Search',
  path: '/search',
  component: props => <SearchPage {...props} />,
};

const Attribute = {
  title: 'Attribute',
  path: '/attribute/:entityTypeTypeKey/:field/:fieldValue',
  component: props => <AttributePage {...props} />,
};

const Lists = [
  {
    title: 'Lists',
    path: ['/lists', '/lists/shared'],
    component: props => <ListsPage {...props} />,
    exact: true,
  },
  {
    title: 'List',
    path: '/lists/:listId',
    component: props => <ListPage {...props} />,
  },
];

const Entity = {
  title: 'Entity',
  path: '/entities/:entityId',
  component: props => <EntityPage {...props} />,
};

const EntityMembers = {
  title: 'Members',
  path: '/entities/:entityId/members',
  component: props => <EntityPage {...props} />,
};

const SmsForm = {
  title: 'Get the App',
  path: '/get-the-app',
  component: props => <SmsFormPage {...props} />,
  exact: true,
};

const Settings = {
  title: 'Settings',
  path: '/settings',
  component: props => <SettingsPage {...props} />,
  exact: true,
};

const Profile = {
  title: 'Profile',
  path: '/profile/:id?',
  component: props => <ProfilePage {...props} />,
};

const Person = {
  title: 'Person',
  path: '/person/:objectKey/:value',
  component: props => <PersonPage {...props} />,
};

const OrgChart = {
  title: 'OrgChart',
  path: '/orgchart',
  component: OrgChartPage,
};

const Notifications = {
  title: 'Notifications',
  path: '/notifications',
  component: props => <NotificationsPage {...props} />,
  exact: true,
};

const ThemeLoader = {
  title: 'Preview Theme',
  path: '/preview-theme/:themeId',
  component: props => <ThemeLoaderPage {...props} />,
};

const Onboarding = {
  title: 'Social Onboarding',
  path: '/onboarding',
  component: OnboardingPage,
};

const NoRoute = {
  title: 'Not found',
  path: '/404',
  component: NoRoutePage,
};

export default (client, user) => {
  const routes = [
    Landing,
    SmsForm,
    Entity,
    EntityMembers,
    Settings,
    Profile,
    Person,
    OrgChart,
    Notifications,
    ...Lists,
    ThemeLoader,
    Onboarding,
    NoRoute,
    Search,
  ];

  if (_get(client, 'settings.explore.enabled', false) || _get(user, 'account.isAdmin', false)) {
    routes.push(Attribute);
  }

  return routes;
};
