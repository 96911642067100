import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AdminIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={20} height={20} viewBox="0 0 20 20" {...props}>
      <path d="M8.15 7.944a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666zM9.133 17.678a1.194 1.194 0 01-.211-1.417l.294-.555-.605-.184a1.19 1.19 0 01-.834-1.166v-1.14a1.2 1.2 0 01.85-1.15l.606-.183-.289-.555a1.205 1.205 0 01.194-1.4 10.51 10.51 0 00-1.288-.09 8.656 8.656 0 00-6.74 2.979v4.305a.556.556 0 00.556.556h7.467z" />
      <path d="M18.722 13.033L17.61 12.7a3.74 3.74 0 00-.323-.789l.556-1.033a.195.195 0 00-.039-.24L17 9.829a.211.211 0 00-.24-.04l-1.027.556a4.3 4.3 0 00-.795-.333L14.6 8.9a.211.211 0 00-.2-.139h-1.156a.211.211 0 00-.194.145l-.334 1.11c-.28.082-.55.196-.805.34L10.905 9.8a.211.211 0 00-.244.033l-.817.8a.206.206 0 00-.039.245l.556 1.01c-.15.252-.27.518-.361.795l-1.112.34a.2.2 0 00-.144.194v1.139a.2.2 0 00.144.194l1.112.339c.083.272.194.535.333.783l-.556 1.056a.205.205 0 00.04.244l.827.806a.211.211 0 00.244.033l1.04-.555c.246.133.507.239.777.316l.333 1.14a.211.211 0 00.2.144h1.14a.212.212 0 00.194-.145l.333-1.139a3.71 3.71 0 00.767-.316l1.05.555a.21.21 0 00.244-.033l.811-.806a.211.211 0 00.034-.244l-.556-1.045c.133-.243.24-.5.317-.766l1.11-.34a.217.217 0 00.15-.194v-1.15a.223.223 0 00-.11-.2zm-4.906 2.623a1.855 1.855 0 11-.01-3.71 1.855 1.855 0 01.01 3.71z" />
    </SvgIcon>
  );
}

export default AdminIcon;
