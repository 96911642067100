import { useSelector } from 'react-redux';
import _get from 'lodash/get';

export default function useSettingsEnabled() {
  const settingsEnabled = useSelector(state =>
    _get(state, 'client.settings.settingsNavigationItem.enabled', true),
  );

  return settingsEnabled;
}
