import { useSelector } from 'react-redux';
import _get from 'lodash/get';

export default function useSupportEnabled() {
  const supportIsEnabled = useSelector(state =>
    _get(state, 'client.settings.support.enabled', false),
  );

  return supportIsEnabled;
}
