import { defineFetch } from 'resift';
import removeKeys from 'helpers/removeKeys';

export default defineFetch({
  displayName: 'upload picture fetch',
  share: {
    namespace: 'person',
    merge: (previous, next) => ({
      ...removeKeys(previous, ['customPictureUrl']),
      ...next,
    }),
  },
  make: (personId: string) => ({
    request: (dataUrl: string) => ({ http }: any) => {
      if (!dataUrl) {
        // delete
        return http({
          method: 'DELETE',
          route: `/people/${personId}/upload?imageType=pictureUrl`,
        });
      } else {
        // post
        const formData = new FormData();

        formData.append('file[pictureUrl]', dataUrl);

        return http({
          method: 'POST',
          route: `/people/${personId}/upload`,
          data: formData,
        });
      }
    },
  }),
});
