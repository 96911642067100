import React, { memo } from 'react';
import _get from 'lodash/get';
import bowser from 'bowser';

// Components
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import IconButton from '@mui/material/IconButton';
import ReorderIcon from '@mui/icons-material/Reorder';
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalScrollHinter from 'components/skipper/VerticalScrollHinter';
import Avatar from 'components/atoms/Avatar';
import SkipTranslate from 'components/atoms/SkipTranslate';
import InputInstructionWhenEmpty from 'components/molecules/InputInstructionWhenEmpty';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import typography from 'helpers/typography';

interface Person {
  id: string;
  pictureUrl: string;
  displayName: string;
  title: string;
  _alternateFieldValues: {
    displayName: string;
  };
}

interface Props {
  className?: string;
  people: Person[];
  onChange: (newPeople: Person[]) => void;
}

function PersonList({ people, className, onChange }: Props) {
  const { classes } = useStyles();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const oldIndex = result.source.index;
    const newIndex = result.destination.index;

    if (newIndex === oldIndex) {
      return;
    }

    const currentItem = people[oldIndex];

    const withoutOldIndex = [
      ...people.slice(0, oldIndex),
      ...people.slice(oldIndex + 1, people.length),
    ];

    const withNewIndex = [
      ...withoutOldIndex.slice(0, newIndex),
      currentItem,
      ...withoutOldIndex.slice(newIndex, withoutOldIndex.length),
    ];

    return onChange(withNewIndex);
  };

  const handleDelete = (personIdToDelete: string) => {
    onChange(people.filter(person => person.id !== personIdToDelete));
  };

  return (
    <div className={classNames(classes.root, className)}>
      {people.length > 0 ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="sortable-person-list">
            {(provided: any) => (
              <VerticalScrollHinter
                className={classes.sortableList}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {people.map((person, index) => {
                  const altDisplayName = _get(person, ['_alternateFieldValues', 'displayName']);
                  return (
                    <Draggable
                      draggableId={`${person.id}-${index}`}
                      index={index}
                      key={`${person.id}-${index}`}
                    >
                      {provided => (
                        <div
                          className={classes.personItem}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Avatar className={classes.avatar} person={person} />
                          <div className={classes.info}>
                            <SkipTranslate className={classes.name}>
                              {person.displayName}
                            </SkipTranslate>
                            {altDisplayName && (
                              <SkipTranslate
                                className={classes.title}
                              >{`(${altDisplayName})`}</SkipTranslate>
                            )}
                            <div className={classes.title}>{person.title}</div>
                          </div>
                          <div className={classes.controls}>
                            <IconButton className={classes.reorderButton} size="large">
                              <ReorderIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleDelete(person.id)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </VerticalScrollHinter>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <InputInstructionWhenEmpty />
      )}
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
    },
    personItem: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      cursor: bowser.msie ? 'ns-resize' : 'grab',
      '&:hover': {
        backgroundColor: theme.palette.action.infoHover,
      },
      '&:active': {
        backgroundColor: theme.palette.action.infoActive,
        cursor: bowser.msie ? 'ns-resize' : 'grabbing',
      },
    },
    controls: {
      display: 'flex',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    sortableHelperClass: {
      zIndex: `${theme.zIndex.max} !important`,
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.action.infoActive,
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    name: {
      ...typography(theme, 'body1'),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 'initial',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 200,
      marginRight: theme.spacing(2),
    },
    title: {
      ...typography(theme, 'body2'),
      flex: '0 0 auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 200,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    reorderButton: {
      pointerEvents: 'none',
    },
    sortableList: {},

    sorting: {
      '& *': {
        userSelect: 'none',
      },
    },
  };
});

export default memo(PersonList);
