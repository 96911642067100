// This screen shows up after the user successfully logged in to Sift
// in ms teams mobile app
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import SiftLogo from 'components/skipper/SiftLogo';
import Typography from '@mui/material/Typography';
import MsTeamsThemeWrapper from '../MsTeamsThemeWrapper';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  body: {
    margin: '0 auto',
    maxWidth: 600,
  },
  logo: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  text: {
    margin: '0 auto',
  },
}));

function MsTeamsLoginSuccess() {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <SiftLogo height={24} className={classes.logo} />
        <Typography className={classes.text}>
          You have been signed in. You may close this window.
        </Typography>
      </div>
    </div>
  );
}

function WrappedComponent() {
  return (
    <MsTeamsThemeWrapper>
      <MsTeamsLoginSuccess />
    </MsTeamsThemeWrapper>
  );
}

export default WrappedComponent;
