import { deepmerge } from '@mui/utils';
import brandColors from 'styles/skipper/brandColors';
import ensureColors from './skipper/ensureColors';
import skipperDefaultPalette from './skipper/skipperDefaultPalette';

const palette = {
  brandColors,
  siftBot: {
    main: brandColors.secondaryPalette.cobalt,
  },
  action: {
    infoHover: '#f3f5f9',
  },
  separated: {
    main: '#ed6000',
  },
  border: {
    main: '#e0e6ef',
  },
  divider: '#e5e5e5',
  info: {
    main: brandColors.secondaryPalette.cobalt,
  },
  success: {
    main: brandColors.indicatorColors.parakeet,
  },
  error: {
    main: brandColors.indicatorColors.fire,
    contrastText: '#fff',
  },
  warning: {
    main: brandColors.indicatorColors.gold,
  },
  outline: (opacity = 1) => {
    return `rgba(185, 192, 226, ${opacity})`;
  },
  transparentizeAmount: {
    navItem: 0.49,
  },
  opacity: {
    fourPercent: 0.04,
    eightPercent: 0.08,
    twelvePercent: 0.12,
    thirtyEightPercent: 0.38,
    seventyEightPercent: 0.78,
    eightySevenPercent: 0.87,
  },
};

export default ensureColors(deepmerge(skipperDefaultPalette, palette));
