import { applyMiddleware, compose, createStore } from 'redux';
import config from '../config';

// Reducers
import rootReducer from './reducers';

// Middleware
import thunk from 'redux-thunk';
import DataService from '@sift/resift/redux/DataService';
import DataServiceActionHandlers, { unauthorizedHandler } from './dataServiceActionHandlers';
import dataServiceMiddleware from './dataServiceMiddleware';

import withSideEffects from './sideEffects';

export default () => {
  // ======================================================
  // Initial State setup
  // ======================================================
  let initialState = rootReducer({}, {});

  const windowAppState = window.appState ? window.appState : {};

  initialState = {
    ...initialState,
    ...windowAppState,
    authentication: {
      ...initialState.authentication,
      ...windowAppState.authentication,
      api: {
        ...initialState.authentication.api,
        path: '',
        authentication: config.LOGIN,
      },
    },
  };

  // ======================================================
  // Desktop Hook
  // ======================================================
  if (window.bridge) {
    initialState = window.bridge.getInitialState(initialState);
  }

  // ======================================================
  // Middleware Configuration
  // ======================================================
  let dataService = new DataService(DataServiceActionHandlers, unauthorizedHandler);
  const middleware = [thunk, dataService.middleware, dataServiceMiddleware];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];

  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development') {
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension;
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = withSideEffects(
    createStore(
      rootReducer,
      initialState,
      composeEnhancers(applyMiddleware(...middleware), ...enhancers),
    ),
  );
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  if (window.bridge) {
    window.bridge.setStore(store);
  }

  return store;
};
