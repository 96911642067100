import * as microsoftTeams from '@microsoft/teams-js';

export default function getIsInMsTeams() {
  const microsoftTeamsLib = microsoftTeams || window['microsoftTeams'];

  if (!microsoftTeamsLib) {
    // The Microsoft Teams library is not loaded
    return false;
  }

  if (
    (window.parent === window.self && (window as any).nativeInterface) ||
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  ) {
    return true;
  }

  return false;
}
