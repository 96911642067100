import React, { useContext } from 'react';
import { Flex } from '@fluentui/react-northstar';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';

interface Props {
  level: 100 | 200 | 300 | 400;
}

function SkillLevel({ level }: Props) {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);
  const { levelSquare, filledLevelSquare } = styles;

  return (
    <Flex aria-hidden>
      <div style={{ ...levelSquare, ...filledLevelSquare }} data-testid="square-one">
        &nbsp;
      </div>
      <div
        style={level !== 100 ? { ...levelSquare, ...filledLevelSquare } : levelSquare}
        data-testid="square-two"
      >
        &nbsp;
      </div>
      <div
        style={
          level === 300 || level === 400 ? { ...levelSquare, ...filledLevelSquare } : levelSquare
        }
        data-testid="square-three"
      >
        &nbsp;
      </div>
      <div
        style={level === 400 ? { ...levelSquare, ...filledLevelSquare } : levelSquare}
        data-testid="square-four"
      >
        &nbsp;
      </div>
    </Flex>
  );
}

const useStyles = (theme: Theme) => ({
  root: {},
  levelSquare: {
    backgroundColor: theme.siteVariables.colors.white,
    height: 14,
    width: 14,
    marginRight: 2,
    borderRadius: 2,
  },
  filledLevelSquare: {
    backgroundColor: theme.siteVariables.colorScheme.brand.foregroundHover,
  },
});

export default SkillLevel;
