import PropTypes from 'prop-types';

export default {
  value: PropTypes.object,
  fieldKey: PropTypes.string,
  field: PropTypes.string,
  analyticsLabel: PropTypes.string,
  query: PropTypes.string,
  classes: PropTypes.object,
};
