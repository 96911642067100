import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

export default function createPaletteColor(paletteColorName: string, mainColor: string) {
  return defaultTheme.palette.augmentColor({
    color: { main: mainColor },
    name: paletteColorName,
  });
}
