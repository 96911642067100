import createReducer from '@sift/resift/createReducer';
import moment from 'moment-timezone';
import getTimezoneDisplayNames from 'components/TimezoneAndWorkHours/helpers/getTimezoneDisplayNames';

// ------------------------------------
// Constants
// ------------------------------------
export const TOGGLE_TIMEZONE_AND_WORK_HOURS_MODAL = 'TOGGLE_TIMEZONE_AND_WORK_HOURS_MODAL';

// ------------------------------------
// Actions
// ------------------------------------
export const toggleOpenTimezoneAndWorkHoursModal = (open: boolean) => {
  return {
    type: TOGGLE_TIMEZONE_AND_WORK_HOURS_MODAL,
    identifier: 'timezoneAndWorkHours',
    payload: {
      open,
    },
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOGGLE_TIMEZONE_AND_WORK_HOURS_MODAL]: (state: any, action: any) => {
    return {
      ...state,
      open: !action.payload.open,
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  open: false,
  timezoneDisplayNames: getTimezoneDisplayNames(moment.tz.guess()),
};

export default createReducer(initialState, ACTION_HANDLERS);
