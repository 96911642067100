import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import typography from 'helpers/typography';

interface Props {
  title?: string;
  content?: string;
}

function InputInstructionWhenEmpty({
  title = 'Nothing here yet.',
  content = 'Start typing above to search for people to add.',
}: Props) {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{content}</div>
      </div>
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    ...typography(theme, 'h6'),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  description: {
    ...typography(theme, 'body2'),
  },
}));

export default InputInstructionWhenEmpty;
