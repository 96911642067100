import config from 'config';

export default (entityId: string, siftAppPath: string) => {
  const context = encodeURIComponent(
    JSON.stringify({
      subEntityId: siftAppPath,
    }),
  );

  return `https://teams.microsoft.com/l/entity/${config.MICROSOFT_APP_ID}/${entityId}?context=${context}`;
};
