import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AddressBook(props: SvgIconProps) {
  return (
    <SvgIcon width={30} height={34} viewBox="0 0 30 34" {...props}>
      <path d="M28.25 11c.413 0 .75-.338.75-.75v-2.5a.752.752 0 00-.75-.75H27V4a3 3 0 00-3-3H4a3 3 0 00-3 3v26a3 3 0 003 3h20a3 3 0 003-3v-3h1.25c.413 0 .75-.337.75-.75v-2.5a.752.752 0 00-.75-.75H27v-4h1.25c.413 0 .75-.337.75-.75v-2.5a.752.752 0 00-.75-.75H27v-4h1.25zM14 9c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4zm7 14.8c0 .662-.625 1.2-1.4 1.2H8.4c-.775 0-1.4-.538-1.4-1.2v-1.2c0-1.988 1.881-3.6 4.2-3.6h.313a6.449 6.449 0 004.975 0h.312c2.319 0 4.2 1.613 4.2 3.6v1.2z" />
    </SvgIcon>
  );
}

export default AddressBook;
