import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/material/styles';
import _get from 'lodash/get';

export default function typography(
  theme: Theme,
  key: keyof Theme['typography'],
  fontKey?: string,
): CSSObject {
  if (fontKey) {
    return _get(theme, ['typography', key, fontKey]) as CSSObject;
  } else {
    return theme.typography[key] as CSSObject;
  }
}
