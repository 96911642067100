import React, { useState, useCallback } from 'react';
import PortalContext from './context';

interface Props {
  children: React.ReactNode;
}

function PortalProvider({ children }: Props) {
  const [contentEls, setContentEls] = useState<{ [key: string]: HTMLElement }>({});

  const setContentEl = useCallback((portalId: string, contentEl: HTMLElement) => {
    setContentEls(previousContentEls => ({
      ...previousContentEls,
      [portalId]: contentEl,
    }));
  }, []);

  const removeContentEl = useCallback((portalId: string) => {
    setContentEls(previousContentEls => {
      const entries = Object.entries(previousContentEls);

      const newContentEls = entries
        .filter(([key]) => key !== portalId)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {} as typeof previousContentEls);

      return newContentEls;
    });
  }, []);

  return (
    <PortalContext.Provider
      value={{
        contentEls,
        setContentEl,
        removeContentEl,
      }}
    >
      {children}
    </PortalContext.Provider>
  );
}

export default PortalProvider;
