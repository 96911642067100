import _get from 'lodash/get';

import { UPDATE_ENTITY_SUCCESS, UPDATE_ENTITY_RELATIONSHIP_SUCCESS } from 'store/entities';

import { UPDATE_SETTINGS_SUCCESS } from 'store/settings';
import { THEME_FETCH_ERROR } from 'store/theme';
import { SIFT_BOT_FETCH_ERROR, SIFT_BOT_OPT_OUT_ERROR } from 'store/siftBot';

// ------------------------------------
// Constants
// ------------------------------------
export const PROFILE_SAVED_SUCCESS = 'PROFILE_SAVED_SUCCESS';
export const SNACKBAR_EXPIRED = 'SNACKBAR_EXPIRED';
export const SNACKBAR_ACTIVATE = 'SNACKBAR_ACTIVATE';

export const expireSnackbar = () => {
  return {
    type: SNACKBAR_EXPIRED,
  };
};

export const activateSnackbar = snackbarText => {
  return {
    type: SNACKBAR_ACTIVATE,
    payload: {
      snackbarText,
    },
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SNACKBAR_ACTIVATE]: (state, action) => {
    return {
      ...state,
      currentSnackbar: action.payload.snackbarText,
    };
  },
  [PROFILE_SAVED_SUCCESS]: (state, action) => {
    return {
      ...state,
      currentSnackbar: 'Profile Saved!',
    };
  },
  [SNACKBAR_EXPIRED]: (state, action) => {
    return {
      ...state,
      currentSnackbar: null,
    };
  },
  [UPDATE_SETTINGS_SUCCESS]: (state, action) => {
    // Ignore snackbar update
    if (_get(action, 'originalPayload.data.directory.personLists')) {
      return {
        ...state,
      };
    }

    return {
      ...state,
      currentSnackbar: 'Your settings have been updated!',
    };
  },
  [UPDATE_ENTITY_SUCCESS]: (state, action) => {
    return {
      ...state,
      currentSnackbar: 'This page has been updated!',
    };
  },
  [UPDATE_ENTITY_RELATIONSHIP_SUCCESS]: (state, action) => {
    return {
      ...state,
      currentSnackbar: 'This page has been updated!',
    };
  },
  [THEME_FETCH_ERROR]: state => {
    return {
      ...state,
      currentSnackbar:
        'There was an error fetching your preview theme. The default theme has been restored.',
    };
  },
  [SIFT_BOT_FETCH_ERROR]: state => {
    return {
      ...state,
      currentSnackbar: 'There was an error fetching SiftBot data.',
    };
  },
  [SIFT_BOT_OPT_OUT_ERROR]: state => {
    return {
      ...state,
      currentSnackbar: 'There was an error opting out of this SiftBot field.',
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  currentSnackbar: null,
};

export default function coreReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
