import React from 'react';

// Components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  dialogContent: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  open: boolean;
  onSkip: () => void;
  onClose: () => void;
}

function SkipDialog({ open, onClose, onSkip }: Props) {
  const { classes } = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure you want to skip?</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContent}>
          Your information has not been saved to your profile yet.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Go back</Button>
        <Button color="error" onClick={onSkip} variant="contained">
          Skip
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SkipDialog;
