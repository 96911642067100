import React from 'react';
import createTheme from 'styles/skipper/createSkipperTheme';
import { ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#F88D2B',
      contrastText: '#fff',
    },
  },
});

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

interface Props {
  children: React.ReactNode;
}

function MsTeamsThemeWrapper({ children }: Props) {
  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
}

export default MsTeamsThemeWrapper;
