import React from 'react';
import currentApplication from '../helpers/getCurrentApplication';
import getAppFromLinkPathname from '../helpers/getAppFromLinkPathname';

// Components
import { NavLink, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  to: string;
  activeClassName?: string;
}

/**
 * simple component that determines whether or not to use `react-router` or
 * a normal `<a />`
 */
function AppLink({ className, to, children, activeClassName, ...restOfProps }: Props) {
  if (getAppFromLinkPathname(to) === currentApplication()) {
    return (
      <NavLink className={className} to={to} activeClassName={activeClassName} {...restOfProps}>
        {children}
      </NavLink>
    );
  }

  return (
    <a className={className} href={`${window.location.origin}${to}`} {...restOfProps}>
      {children}
    </a>
  );
}

export default AppLink;
