import { useMemo, useEffect } from 'react';
import { useData, useStatus, isNormal } from 'resift';
import { useSelector, useDispatch } from 'react-redux';
import usePassiveRedux from '@sift/resift/usePassiveRedux';
import fieldMap from 'pages/Onboarding/helpers/fieldMap';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { isTruthy } from 'helpers/typings';
import useRouter from '@sift/resift/useRouter';
import slides from 'pages/Onboarding/helpers/slides';
import subdomainIs from 'helpers/subdomainIs';
import usePreserveReference from 'use-preserve-reference';

import makeSettingsFetch from 'fetches/settingsFetch';

const empty = [] as string[];

export default function useOnboardingRedirectEffect() {
  const dispatch = useDispatch();
  const { match, history } = useRouter({ path: '/onboarding' });
  const config = useSelector(state => _get(state, ['configurations', 'user']));
  const accountOnly = usePassiveRedux(state =>
    _get(state, ['authentication', 'person', 'accountOnly'], false),
  ) as boolean;
  const settingsFetch = makeSettingsFetch();
  const settings = useData(settingsFetch);
  const status = useStatus(settingsFetch);

  const hasSomeFields = useMemo(() => {
    const fieldKeys = Object.values(fieldMap).filter(isTruthy);
    return fieldKeys.some(key => _has(config, ['fields', key]));
  }, [config]);

  const state = _get(settings, ['directory', 'userOnboarding', 'state']);

  // TODO: this will cause two settings fetches to happen on app start
  useEffect(() => {
    dispatch(settingsFetch());
  }, [dispatch, settingsFetch]);

  const onboardingMatch = !!match;

  const completedSlides = usePreserveReference(
    _get(settings, ['directory', 'userOnboarding', 'completedSlides'], empty) as string[],
  );

  const lastSlide = useMemo(() => {
    const { max } = completedSlides.reduce(
      ({ max, lastSlide }, next) => {
        const index = slides.findIndex(slide => slide.path === next);
        if (index > max) {
          return { max: index, lastSlide: next };
        }

        return { max, lastSlide };
      },
      { max: 0, lastSlide: '' },
    );

    const nextSlide = slides[max + 1];

    if (!nextSlide) {
      return '';
    }

    return nextSlide.path;
  }, [completedSlides]);

  useEffect(() => {
    if (subdomainIs('rainforestqa')) return;
    if (accountOnly) return;
    if (!isNormal(status)) return;
    if (!hasSomeFields) return;
    if (state !== 'to-do') return;
    if (onboardingMatch) return;
    if (window.location.href.includes('mobileView=true')) return;

    if (!completedSlides.length) {
      history.replace('/onboarding');
    } else {
      history.replace(`/onboarding${lastSlide}`);
    }
  }, [
    hasSomeFields,
    status,
    state,
    onboardingMatch,
    accountOnly,
    completedSlides.length,
    history,
    lastSlide,
  ]);
}
