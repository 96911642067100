import { darken } from 'polished';
import hasBadContrast from './hasBadContrast';

export default function readableColorForWhite(color: string) {
  let compareColor = color;

  while (hasBadContrast('#fff', compareColor)) {
    compareColor = darken(0.05, compareColor);
  }

  return compareColor;
}
