import React, { useState } from 'react';
import _partition from 'lodash/partition';
import ProfileSection from 'components/msTeams/embed/molecules/ProfileSection';
import SkillCard from 'components/msTeams/embed/profile/Skills/SkillCard';
import { Skill } from 'components/msTeams/embed/typing';
import { MAX_LEN_SKILLS } from 'components/msTeams/consts';

interface Props {
  value: Skill[];
  field: any;
}

function Skills({ value: skillItems, field }: Props) {
  const needTruncation = skillItems.length > MAX_LEN_SKILLS;
  const [truncated, setTruncated] = useState(needTruncation);

  if (!skillItems.length) {
    return null;
  }

  const handleToggleMore = () => {
    setTruncated(!truncated);
  };

  const [featuredSkills, restOfSkills] = _partition(skillItems, { isFeatured: true });
  const orderedSkills = [...featuredSkills, ...restOfSkills];

  const skillsToDisplay = !needTruncation
    ? orderedSkills
    : truncated
    ? orderedSkills.slice(0, MAX_LEN_SKILLS)
    : orderedSkills;

  return (
    <ProfileSection
      sectionHeader={field?.displayName}
      content={skillsToDisplay.map((skill, i) => (
        <SkillCard skill={skill} key={`skill-${i}`} />
      ))}
      onToggleMore={needTruncation ? handleToggleMore : undefined}
      truncated={truncated}
    />
  );
}

export default Skills;
