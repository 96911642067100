import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';

class CoreSnackbar extends React.PureComponent {
  static propTypes = {
    currentSnackbarOpen: PropTypes.bool.isRequired,
    currentSnackbar: PropTypes.string,
    expireSnackbar: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.props.currentSnackbarOpen}
        message={this.props.currentSnackbar || ''}
        onClose={this.props.expireSnackbar}
        autoHideDuration={4000}
        style={{
          minWidth: '50%',
          marginTop: 64, // 64 is the size of the app bar
        }}
      />
    );
  }
}

export default CoreSnackbar;
