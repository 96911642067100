// React
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import typography from 'helpers/typography';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';

// UI
import Highlighter from './Highlighter';
import SkipTranslate from 'components/atoms/SkipTranslate';

function ItemInfo({ displayName, altDisplayName, title, query, topRightIcon }) {
  const { classes } = useStyles();

  let highlighterProps = useMemo(
    () => ({
      find: [query],
    }),
    [query],
  );

  return (
    <div className={classes.root}>
      <SkipTranslate
        className={classNames(classes.displayName, {
          [classes.withAltName]: Boolean(altDisplayName),
        })}
      >
        <div>
          <Highlighter className={classes.subOverflow} {...highlighterProps}>
            {displayName}
          </Highlighter>
          {altDisplayName && (
            <div>
              <Highlighter
                className={classNames(classes.subOverflow, classes.altName)}
                {...highlighterProps}
              >
                {altDisplayName}
              </Highlighter>
            </div>
          )}
        </div>
        {topRightIcon || null}
      </SkipTranslate>

      <div className={classes.title}>
        <Highlighter {...highlighterProps}>{title}</Highlighter>
      </div>
    </div>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: `0 ${theme.spacing(2)}`,
    },
    displayName: {
      ...typography(theme, 'h6'),
      fontWeight: theme.typography.fontWeightBold,
      color: 'inherit',
      lineHeight: 1.35,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
    },
    altName: {
      ...typography(theme, 'body2'),
    },
    withAltName: {
      marginBottom: theme.spacing(0.4),
    },
    subOverflow: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    title: {
      ...typography(theme, 'body2'),
      lineHeight: 1.35,
      color: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});

ItemInfo.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  query: PropTypes.string,
  topRightIcon: PropTypes.node,
};

export default ItemInfo;
