export default function removeKeys(obj: any, keysToRemove: string[]): any {
  return Object.entries(obj || {}).reduce(
    (next, [key, value]) => {
      if (keysToRemove.includes(key)) {
        return next;
      }
      next[key] = value;
      return next;
    },
    {} as any,
  );
}
