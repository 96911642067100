// React
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useHistory } from 'react-router';
import useAnalytics from 'helpers/useAnalytics';
import typography from 'helpers/typography';

// Components
import Item from '../Item';
import PersonLink from 'components/Link/PersonLink';
import FieldLink from 'components/Link/FieldLink';
import Avatar from 'components/People/Avatar';
import SeparatedChip from 'components/People/Separated/Chip';
import SkipTranslate from 'components/atoms/SkipTranslate';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Highlighter from 'components/Highlighter';

// Icons
import Org from 'components/Icons/Org';
import Email from 'components/Icons/Email';
import OfficePhone from 'components/Icons/OfficePhone';
import SmartPhone from 'components/Icons/SmartPhone';

import { makeStyles } from 'tss-react/mui';
import { lighten, transparentize } from 'polished';
import readableColor from 'styles/skipper/readableColor';
import bigSearch from '../../../styles';

// Component
function ProfileCard({ value, config, query }) {
  const history = useHistory();
  const analytics = useAnalytics();
  const { classes } = useStyles();

  const track = label => {
    analytics.track('Profile Opened in Top Match', {
      category: 'Big Search',
      label: label,
    });
  };

  const contactItemClicked = label => {
    analytics.track('Top Match Contact Item Clicked', {
      category: 'Big Search',
      label: label,
    });
  };

  const contactIconClicked = label => {
    analytics.track('Top Match Contact Info Copied', {
      category: 'Big Search',
      event: label,
    });
  };

  const routeToOrgChart = e => {
    e.preventDefault();
    history.push({
      pathname: `/orgchart/${value.id}`,
    });
    analytics.track('Org Chart Opened in Top Match', {
      category: 'Big Search',
      label: query,
    });
  };

  const routeToProfile = (e, matchedFields) => {
    e.preventDefault();
    history.push({
      pathname: `/profile/${value.id}`,
    });
    analytics.track('Profile Opened in Top Match', {
      category: 'Big Search',
      label: matchedFields,
    });
  };

  const flattenFields = (config, categoryKey = 'user_contact') => {
    let fields = [];
    config.user.categories[categoryKey].children.forEach(child => {
      // Field
      if (child.type === 'field') {
        fields.push(config.user.fields[child.name]);
      }
      // Category
      else if (child.type === 'category' && !isCategoryBlackListed(child.name)) {
        fields = fields.concat(flattenFields(config, child.name));
      }
    });

    return fields;
  };

  const iconType = (type, displayType, style) => {
    if (type !== 'phone' && type !== 'email') return null;

    if (type === 'phone' && displayType === 'cellPhone') {
      return <SmartPhone className={style} />;
    }

    if (type === 'phone' && displayType !== 'cellPhone') {
      return <OfficePhone className={style} />;
    }

    if (type === 'email') {
      return <Email className={style} />;
    }
  };

  const matched = _get(value, '_matchedOn', []);
  const matchedFields = matched.map(match => match.field).join(', ');
  const directReports = _get(value, 'directReportCount', 0);

  let cardContent = flattenFields(config).reduce((acc, field) => {
    let fieldValue = value[field.objectKey];

    if (!fieldValue) {
      return acc;
    }
    acc.push(
      <Fragment key={`${field.objectKey}-fragment`}>
        <Divider className={classes.contentDivider} />
        <div className={classes.contentItem}>
          <div className={classes.contentItemIcon}>
            {iconType(field.type, field.display.type, classes.icon)}
          </div>
          <div className={classes.contentItemLabels}>
            <div className={classes.contentItemTitle}>{field.displayName}</div>
            <div className={classes.contentItemValue}>
              <FieldLink
                fieldType={config.user.fields[field.objectKey].type}
                value={fieldValue}
                themed
                handleClickChildren={() => contactItemClicked(field.displayName)}
                handleClickCopyIcon={() => contactIconClicked(field.displayName)}
              >
                {fieldValue}
              </FieldLink>
            </div>
          </div>
        </div>
      </Fragment>,
    );

    return acc;
  }, []);

  cardContent.unshift(
    <Fragment key={'direct-reports'}>
      <div className={classes.contentItem}>
        <div className={classes.contentItemIcon}>
          <Org className={classes.orgIcon} />
        </div>
        <div className={classes.contentItemLabels}>
          <div className={classes.contentItemTitle}>Direct Reports</div>
          <div className={classes.contentItemValue}>{directReports}</div>
        </div>
        <Button variant="outlined" color="inherit" onClick={routeToOrgChart}>
          Org Chart
        </Button>
      </div>
    </Fragment>,
  );

  if (value.separationDate) {
    cardContent = (
      <>
        <div className={classes.separatedArea}>
          <SeparatedChip person={value} date={value.separationDate} />
        </div>
        <div style={{ flex: '1 1 auto' }} />
      </>
    );
  }

  const altDisplayName = _get(value, ['_alternateFieldValues', 'displayName']);

  return (
    <div className={classes.cardContainer}>
      <PersonLink person={value} className={classes.avatarContainer}>
        <Avatar size={114} person={value} showBorder />
      </PersonLink>
      <Item
        rows={0}
        recentSearchObject={{
          id: value.id,
          displayName: value.displayName,
          type: 'person',
        }}
        className={classes.root}
      >
        <PersonLink person={value} className={classes.header}>
          <div className={classes.headerContent} onClick={() => track(matchedFields)}>
            <SkipTranslate>
              <Highlighter
                find={[query]}
                children={value.displayName}
                className={classes.headerContentName}
              />
            </SkipTranslate>
            <SkipTranslate>
              {altDisplayName && (
                <Highlighter
                  find={[query]}
                  children={`(${altDisplayName})`}
                  className={classes.headerContentAltName}
                />
              )}
            </SkipTranslate>
            <div className={classes.headerContentTitle}>{value.title}</div>
            <div className={classes.headerContentSubtitle}>{value.division}</div>
          </div>
        </PersonLink>
        <div className={classes.content}>{cardContent}</div>
        <div className={classes.footer}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={e => routeToProfile(e, matchedFields)}
          >
            Profile
          </Button>
        </div>
      </Item>
    </div>
  );
}

function isCategoryBlackListed(blackListKey) {
  const blackList = {
    user_messengers: true, // TODO: This needs to be removed when messenger categories are rethought. This is a small fix for preventing a sip address from displaying on the exact match card
  };

  return blackList[blackListKey];
}

const useStyles = makeStyles()((theme, _params, classes) => {
  const bigSearchStyles = bigSearch(theme);

  return {
    root: {
      ...bigSearchStyles.components.card,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      padding: theme.spacing(2),
      position: 'relative',
      '&:active, &:focus': {
        backgroundColor: theme.palette.primary.dark,
        color: readableColor(theme.palette.primary.dark),
      },
      '& a': {
        color: readableColor(theme.palette.primary.main),
      },
      '&:hover a': {
        color: readableColor(theme.palette.primary.dark),
      },
      [`&:hover .${classes.contentDivider}`]: {
        borderColor: lighten(0.1, theme.palette.primary.dark),
      },
      transition: 'background-color 200ms',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      '&:hover': {
        background: 'none !important',
      },
      minHeight: 80,
    },
    headerContent: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column',
      marginLeft: '15px',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingLeft: theme.spacing(10),
    },
    headerContentName: {
      ...typography(theme, 'h6'),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.35,
      color: 'inherit',
      marginBottom: theme.spacing(0.5),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    headerContentAltName: {
      ...typography(theme, 'subtitle2'),
      marginBottom: theme.spacing(0.5),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    headerContentTitle: {
      ...typography(theme, 'body2'),
      lineHeight: 1.35,
      color: 'inherit',
      marginBottom: theme.spacing(0.5),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    headerContentSubtitle: {
      ...typography(theme, 'body2'),
      lineHeight: 1.35,
      color: 'inherit',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    separatedArea: {
      padding: '20px 0px',
    },
    content: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      overflowY: 'auto',
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    contentDivider: {
      borderColor: theme.palette.primary.main,
      marginLeft: theme.spacing(5),
    },
    contentItem: {
      display: 'flex',
      flex: '1 1 auto',
      minHeight: 44,
      alignItems: 'flex-start',
      margin: theme.spacing(1, 0),
      overflow: 'hidden',
    },
    contentItemLabels: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    contentItemIcon: {
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
    },
    directReportcontentItem: {
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
      alignItems: 'space-between',
    },
    contentItemTitle: {
      ...typography(theme, 'caption'),
      fontWeight: theme.typography.fontWeightBold,
      color: 'inherit',
    },
    contentItemValue: {
      ...typography(theme, 'body1'),
      color: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '& > div': {
        ...typography(theme, 'body1'),
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'inherit',
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    avatarContainer: {
      top: -20,
      left: -20,
      position: 'absolute',
      zIndex: theme.zIndex.surface,
    },
    cardContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    iconAndLabelContainer: {},
    orgIcon: {
      height: 24,
      width: 24,
      fill: transparentize(0.4, theme.palette.primary.contrastText),
    },
    icon: {
      fill: transparentize(0.4, theme.palette.primary.contrastText),
    },
    goToOrgIcon: {
      fill: theme.palette.primary.contrastText,
    },
  };
});

ProfileCard.propTypes = {
  value: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
};

export default ProfileCard;
