export const MS_TEAMS_START_URL = '/integrations/ms-teams-start';

export const MS_TEAMS_LOGIN_URL = '/integrations/ms-teams-login';

export const MS_TEAMS_LOGIN_DONE_URL = '/integrations/ms-teams-done';

export const MS_TEAMS_MEETING_MAIN_URL = '/integrations/ms-teams';

export const MS_TEAMS_MEETING_LOGIN_URL = '/integrations/ms-teams/login';

export const MS_TEAMS_PARTICIPANTS_URL = '/meetings-panel';

export const PROFILE_HEADER_LAYOUT_KEYS = [
  'anniversaryDate',
  'inclusivePronouns',
  'timezoneAndWorkHours',
];

export const PROFILE_CONTENT_LAYOUT_KEYS = ['bio', 'interests', 'skills'];

export const MAX_LEN_BIO = 175;
export const MAX_LEN_INTERESTS = 5;
export const MAX_LEN_SKILLS = 5;
