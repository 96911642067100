import React from 'react';
import { useSelector } from 'react-redux';

// Helpers
import _get from 'lodash/get';

// Component
import Start from './Start';

const Container = () => {
  const companyName = useSelector(state => _get(state, ['client', 'displayName']));

  return <Start companyName={companyName} />;
};

export default Container;
