import { defineFetch } from 'resift';

const makeGetConfiguration = defineFetch({
  displayName: 'Get entity type configuration',
  // @ts-ignore
  make: (entityTypeTypeKey: string) => ({
    request: () => ({ http }) =>
      http({
        method: 'GET',
        route: `/configurations/${entityTypeTypeKey}`,
      }),
  }),
});

export default makeGetConfiguration;
