/**
 * Notes (Pearl): This unfortunately has to exist because svg clip path is not working in material
 * ui drawer in appBar/Drawer component in chrome.
 */

import React from 'react';
import ensurePictureUrl from 'components/Avatar/helpers/ensurePictureUrl';
import usePictureUrlFromPerson from 'components/Avatar/helpers/usePictureUrlFromPerson';
import _get from 'lodash/get';
import { useTheme } from '@mui/material/styles';

import AvatarOld from './AvatarOld';

interface Person {
  displayName: string;
  pictureUrl: string;
  officialPictureUrl?: string;
}

// prettier-ignore
type Props = {
   className?: string;
 } & (
   | { person: Person; }
   | { pictureUrl: string; alt: string; }
 );

function Container({ className, person, pictureUrl: pictureUrlFromProps, alt }: any) {
  const theme = useTheme();
  const displayName = _get(person, ['displayName']) as string | undefined;

  const pictureUrlFromPerson = usePictureUrlFromPerson(person);

  const _pictureUrl = pictureUrlFromProps || pictureUrlFromPerson;
  const pictureUrl = ensurePictureUrl(_pictureUrl, theme);

  return <AvatarOld className={className} src={pictureUrl} alt={alt || displayName || 'Person'} />;
}

// @ts-ignore
export default Container as React.ComponentType<Props>;
