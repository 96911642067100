import React from 'react';
import PropTypes from 'prop-types';

class SeparatedDetailedCopy extends React.PureComponent {
  static propTypes = {
    person: PropTypes.object.isRequired,
  };

  render() {
    const { person } = this.props;

    if (person.separationImplicit) {
      return 'This is an approximated date based on removal from Sift rather than official data';
    } else {
      return 'This is an exact separation date provided by official data';
    }
  }
}

export default SeparatedDetailedCopy;
