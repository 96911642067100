import { combineReducers } from 'redux';
import { dataServiceReducer as dataService } from 'resift';
import core from './core';
import app from './app';

// @ts-ignore
import authentication from '@sift/resift/redux/reducers/authentication.web';
// @ts-ignore
import client from '@sift/resift/redux/reducers/client';
import entities from './entities';
import search from './search';
import configurations from './configurations';
import settings from './settings';
import bigSearch from './bigSearch';
import advancedSearch from './advancedSearch';
import support from './support';
import page_smsapp from './smsApp';
import siftBot from './siftBot';
import theme from './theme';
import searchResults from './searchResults';
import profile from './profile';
import listSearch from './listSearch';
import timezoneAndWorkHours from './timezoneAndWorkHours';
import orgChart from './orgChart';

const rootReducer = combineReducers({
  advancedSearch,
  app,
  authentication,
  bigSearch,
  client,
  configurations,
  core,
  entities,
  search,
  settings,
  support,
  page_smsapp,
  siftBot,
  theme,
  searchResults,
  dataService,
  profile,
  listSearch,
  timezoneAndWorkHours,
  orgChart,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
