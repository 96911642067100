import { useCallback, useEffect, useState } from 'react';
import _get from 'lodash/get';
import useRouter from '@sift/resift/useRouter';
import useWrap from 'helpers/useWrap';
import useAnalytics from 'helpers/useAnalytics';

function useFullscreen() {
  const analytics = useAnalytics();
  const { match } = useRouter({ path: '/:page' });
  const page = _get(match, ['params', 'page'], '') as string;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const getIsFullscreen = useWrap(isFullscreen);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!isFullscreen);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFullscreen]);

  const toggleFullscreen = useCallback(() => {
    analytics.track('Fullscreen Clicked', { category: 'OrgChart' });

    if (!isFullscreen) {
      document.body.requestFullscreen();
      return;
    }

    document.exitFullscreen();
  }, [isFullscreen, analytics]);

  useEffect(() => {
    const isFullscreen = getIsFullscreen();

    if (isFullscreen) {
      document.exitFullscreen();
    }
  }, [page, getIsFullscreen]);

  return {
    toggleFullscreen,
    isFullscreen,
  };
}

export default useFullscreen;
