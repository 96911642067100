import React, { useContext } from 'react';
import { Flex, Text } from '@fluentui/react-northstar';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';
import LEVEL_DESCRIPTIONS from 'components/Skills/skillLevelDescriptions';
import SkillLevel from 'components/msTeams/embed/profile/Skills/SkillLevel';
import { Skill } from 'components/msTeams/embed/typing';

interface Props {
  skill: Skill;
}

function SkillCard({ skill }: Props) {
  const { name, level } = skill;
  const levelDisplayName = LEVEL_DESCRIPTIONS[level].name;
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);

  return (
    <div style={styles.root} data-testid="skill-card">
      <div
        // Have to ignore typing due to incorrect tying interpretation on lib side
        // @ts-ignore
        style={styles.name}
      >
        {name}
      </div>
      <Flex space="between" vAlign="center" style={styles.levelRow}>
        <Text size="small" style={styles.levelName}>
          {levelDisplayName}
        </Text>
        <SkillLevel level={level} />
      </Flex>
    </div>
  );
}

const useStyles = (theme: Theme) => ({
  root: {
    width: 180,
    height: 56,
    borderWidth: 1,
    borderColor: theme.siteVariables.colorScheme.brand.border,
    borderStyle: 'solid',
    borderRadius: 4,
    padding: theme.siteVariables.spacing.unit,
    marginBottom: theme.siteVariables.spacing.unit,
    backgroundColor: theme.siteVariables.colors.grey[550],
  },
  name: {
    maxWidth: 160,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  levelName: {
    color: theme.siteVariables.colors.grey[270],
  },
  levelRow: {
    marginTop: theme.siteVariables.spacing.unit * 0.25,
  },
});

export default SkillCard;
