import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import typography from 'helpers/typography';

// UI
import Typography from '@mui/material/Typography';
import ErrorFaceIcon from 'components/Icons/ErrorFace';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    icon: {
      width: 300,
      height: 200,
      marginBottom: theme.spacing(),
    },
    title: {
      ...typography(theme, 'h4'),
      marginBottom: theme.spacing(),
    },
    body: {},
  };
});

function ErrorView({ title, body, className }) {
  const { classes } = useStyles();
  const defaultCopy = {
    title: 'Yikes, an error occurred.',
    body: (
      <>
        Try reloading the page, if the
        <br />
        issue persists then please contact us.
      </>
    ),
  };

  return (
    <div data-testid="loader-error" className={classnames(classes.root, className)}>
      <ErrorFaceIcon className={classes.icon} />
      <Typography className={classes.title} variant="h5">
        {title || defaultCopy.title}
      </Typography>
      <Typography className={classes.body} variant="body1">
        {body || defaultCopy.body}
      </Typography>
    </div>
  );
}

ErrorView.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
  className: PropTypes.object,
};

export default ErrorView;
