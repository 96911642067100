// Libs
import React from 'react';
import PropTypes from 'prop-types';

// UI
import SKPAutosuggest from 'components/skipper/Autosuggest';
import AutoSuggestTextField from './AutoSuggestTextField';

import { withStyles } from 'tss-react/mui';

function Autosuggest({
  inputProps,
  fieldKey,
  onSuggestionSelected,
  onSuggestionsFetchRequested,
  ...otherProps
}) {
  const handleChange = e => {
    inputProps.onChange(fieldKey, e.target.value);
  };

  const handleSuggestionSelected = (e, { suggestionValue }) => {
    onSuggestionSelected(fieldKey, suggestionValue);
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    onSuggestionsFetchRequested(fieldKey, value);
  };

  const renderInputComponent = inputProps => {
    const { value, autoFocus, textFieldProps, ...otherInputProps } = inputProps;

    return (
      <AutoSuggestTextField
        value={value}
        inputProps={{
          ...otherInputProps,
        }}
        {...textFieldProps}
        onChange={handleChange}
      />
    );
  };

  return (
    <SKPAutosuggest
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={handleSuggestionSelected}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      {...otherProps}
    />
  );
}

const styles = theme => {
  return {
    suggestionsPaper: {
      marginTop: theme.spacing(1),

      // NOTE: This is copied from the textField class
      // in the AdvancedSearchForm component. Figure out
      // a good way to share this logic.
      width: '90%',

      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  };
};

Autosuggest.propTypes = {
  inputProps: PropTypes.object,
  fieldKey: PropTypes.string.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
};

export default withStyles(Autosuggest, styles);
