import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import typography from 'helpers/typography';

// Components
import SiftLogo from 'components/skipper/SiftLogo';

import { makeStyles } from 'tss-react/mui';

import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import readableColor from 'styles/skipper/readableColor';

function PoweredBySift(props) {
  const {
    className,
    classes: classesFromProps,
    backgroundColor,
    isEasterEgg,
    ...restOfProps
  } = props;
  const theme = useTheme();
  const { classes } = useStyles(undefined, { props: { classes: classesFromProps } });

  const onClickContainer = () => {
    window.open('https://www.justsift.com/');
  };

  const textColor = useMemo(() => {
    const { primary, secondary } = theme.palette;
    if (backgroundColor === 'primary') return primary.contrastText;
    if (backgroundColor === 'secondary') return secondary.contrastText;
    return readableColor(backgroundColor);
  }, [theme.palette, backgroundColor]);

  return (
    <div className={classNames(classes.root, className)}>
      <div
        className={classes.container}
        onClick={onClickContainer}
        {...restOfProps}
        style={isEasterEgg ? {} : { cursor: 'pointer' }}
      >
        <span className={classes.poweredBy} style={{ color: textColor }}>
          Powered by
        </span>
        <SiftLogo height={16} backgroundColor={backgroundColor} />
      </div>
    </div>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  poweredBy: {
    ...typography(theme, 'caption'),
    marginRight: theme.spacing(1),
  },
}));

PoweredBySift.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  classes: PropTypes.object,
  isEasterEgg: PropTypes.bool,
};

export default PoweredBySift;
