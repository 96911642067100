import React, { useContext } from 'react';
import * as Sift from '@sift/types';
import { isTruthy } from 'helpers/typings';
import pluralize from 'pluralize';
import _get from 'lodash/get';
import getValidation from '@sift/runner/getValidation';
import useRouter from '@sift/resift/useRouter';
import RunnerContext from '@sift/runner/Context';
import useSlideInfo from 'pages/Onboarding/helpers/useSlideInfo';
import useLinkedIn from 'pages/Onboarding/helpers/useLinkedIn';
import useValidSlides from 'pages/Onboarding/helpers/useValidSlides';
import fieldMap from 'pages/Onboarding/helpers/fieldMap';

import { Redirect } from 'react-router';
import LinkedInResults from './LinkedInResults';

function Container() {
  const contextValue = useContext(RunnerContext);
  const { history } = useRouter();

  if (!contextValue) {
    throw new Error('[LinkedInResults Container] could not find runner context');
  }
  const { nextSlideLink } = useSlideInfo();

  const { config } = contextValue;

  const linkedInData = useLinkedIn();

  const slides = useValidSlides();

  const info = slides
    .map((slide: any) => {
      const fieldKey = fieldMap[slide.path.substring(1)];
      if (!fieldKey) {
        return null;
      }

      const field = _get(config, ['fields', fieldKey]) as Sift.Field | undefined;
      if (!field) {
        return null;
      }

      const value = linkedInData[fieldKey];
      if (!value) {
        return null;
      }

      const validation = getValidation(value, field, config);

      if (Array.isArray(value) && value.length <= 0) {
        return null;
      }

      const displayName = Array.isArray(value)
        ? `${value.length.toLocaleString()} ${pluralize(field.displayName, value.length)}`
        : field.displayName;

      const status = validation.isValid ? 'success' : 'warning';

      return { displayName, status: status as typeof status };
    })
    .filter(isTruthy);

  const handleContinue = () => {
    if (!nextSlideLink) {
      return;
    }

    history.push(nextSlideLink);
  };

  // the data should be normal at this state
  // otherwise take them to the beginning of the onboarding flow
  if (!linkedInData) {
    if (nextSlideLink) {
      return <Redirect to={nextSlideLink} />;
    }
    return <Redirect to="/onboarding" />;
  }

  return <LinkedInResults onContinue={handleContinue} info={info} />;
}

export default Container;
