import React, { useState, useEffect, useRef } from 'react';
import typography from 'helpers/typography';
import { isLoading } from 'resift';
import delay from 'delay';
import indefinite from 'indefinite';
import pluralize from 'pluralize';
import _isEqual from 'lodash/isEqual';

// Components
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import AppBarPortal from 'pages/Onboarding/components/AppBarPortal';
import Header from 'pages/Onboarding/components/Header';
import PeopleListEditor from './PeopleListEditor';
import { Prompt } from 'react-router-dom';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

function capitalize(str: string) {
  return str.substring(0, 1) + str.substring(1);
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  formControl: {
    marginBottom: theme.spacing(2),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(6),
    },
  },
  label: {
    ...typography(theme, 'body1'),
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: theme.spacing(1),
  },
  peopleEditor: {
    width: 550,
    transition: theme.transitions.create('all', { duration: theme.transitions.duration.complex }),
    maxHeight: 1000,
  },
  peopleEditorHidden: {
    opacity: 0,
    maxHeight: 0,
    pointerEvents: 'none',
  },
  spacer: {
    height: 32,
  },
  buttonDisabled: {
    color: `${theme.palette.grey['300']} !important`,
    backgroundColor: `${theme.palette.grey['600']} !important`,
  },
}));

interface Person {
  id: string;
  displayName: string;
  title: string;
  pictureUrl: string;
}

interface Props {
  assistants: Person[];
  assistantOf: Person[];
  onContinue: (newData: { assistants: Person[]; assistantOf: Person[] }) => void;
  status: number;
  currentPersonId: string;
  displayName: string;
  isLastSlide: boolean;
}

function Assistants({
  onContinue,
  status,
  assistants: assistantsFromProps,
  assistantOf: assistantOfFromProps,
  currentPersonId,
  displayName,
  isLastSlide,
}: Props) {
  const { classes } = useStyles();
  const [assistants, setAssistants] = useState(assistantsFromProps);
  const [assistantOf, setAssistantOf] = useState(assistantOfFromProps);

  const [hasAssistants, setHasAssistants] = useState(!!assistants.length);
  const [hasAssistantOf, setHasAssistantOf] = useState(!!assistantOf.length);

  const [showPrompt, setShowPrompt] = useState(true);

  const assistantsInputRef = useRef<HTMLInputElement | null>(null);
  const assistantOfInputRef = useRef<HTMLInputElement | null>(null);

  const assistantsUpdated =
    !_isEqual(assistants, assistantsFromProps) || !_isEqual(assistantOf, assistantOfFromProps);

  const handleContinue = () => {
    setShowPrompt(false);
    onContinue({
      assistants,
      assistantOf,
    });
  };

  const handleHasAssistantsChange = () => {
    setHasAssistants(!hasAssistants);
  };

  const handleHasAssistantOfChange = () => {
    setHasAssistantOf(!hasAssistantOf);
  };

  const handleAssistantsChange = (assistants: Person[]) => {
    setAssistants(assistants);
  };

  const handleAssistantOfChange = (assistantOf: Person[]) => {
    setAssistantOf(assistantOf);
  };

  useEffect(() => {
    if (hasAssistants) {
      delay(300).then(() => {
        // focus the input element when they select "yes'"
        const assistantsInputEl = assistantsInputRef.current;
        if (!assistantsInputEl) {
          return;
        }
        assistantsInputEl.focus();
      });
    } else {
      // clear the state when they select "no"
      delay(500).then(() => {
        setAssistants([]);
      });
    }
  }, [hasAssistants]);

  useEffect(() => {
    if (hasAssistantOf) {
      // focus the input element when they select "yes'"
      delay(300).then(() => {
        const assistantOfInputEl = assistantOfInputRef.current;
        if (!assistantOfInputEl) {
          return;
        }
        assistantOfInputEl.focus();
      });
    } else {
      // clear the state when they select "no"
      delay(500).then(() => {
        setAssistantOf([]);
      });
    }
  }, [hasAssistantOf]);

  return (
    <>
      <AppBarPortal>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleContinue}
          disabled={isLoading(status)}
          classes={{
            disabled: classes.buttonDisabled,
          }}
        >
          {isLastSlide ? 'Finish' : 'Continue'}
        </Button>
      </AppBarPortal>

      <Prompt
        when={assistantsUpdated && showPrompt}
        message="Are you sure you'd like to leave? Your changes have not been saved yet."
      />
      <div className={classes.root}>
        <Header
          displayName={capitalize(pluralize(displayName))}
          description={null}
          fromLinkedIn={false}
        />

        <FormControl
          // @ts-ignore
          component="fieldset"
          className={classes.formControl}
        >
          <FormLabel
            // @ts-ignore
            component="legend"
            className={classes.label}
          >
            {/*
            // @ts-ignore */}
            Do you have {pluralize(indefinite(displayName), 1).toLowerCase()}?
          </FormLabel>
          <RadioGroup
            aria-label="Assistant options"
            name="assistants-options"
            className={classes.radioGroup}
            value={hasAssistants ? 'yes' : 'no'}
            onChange={handleHasAssistantsChange}
          >
            <FormControlLabel value="no" control={<Radio color="secondary" />} label="No" />
            <FormControlLabel value="yes" control={<Radio color="secondary" />} label="Yes" />
          </RadioGroup>
        </FormControl>
        <PeopleListEditor
          className={classNames(classes.peopleEditor, {
            [classes.peopleEditorHidden]: !hasAssistants,
          })}
          currentPersonId={currentPersonId}
          people={assistants}
          onChange={handleAssistantsChange}
          label="Start typing their name"
          inputRef={assistantsInputRef}
        />

        <div className={classes.spacer} />

        <FormControl
          // @ts-ignore
          component="fieldset"
          className={classes.formControl}
        >
          <FormLabel
            // @ts-ignore
            component="legend"
            className={classes.label}
          >
            {/*
            // @ts-ignore */}
            Are you {indefinite(pluralize(displayName, 1)).toLowerCase()} to anyone else?
          </FormLabel>
          <RadioGroup
            aria-label="Assistant of options"
            name="assistant-of-options"
            className={classes.radioGroup}
            value={hasAssistantOf ? 'yes' : 'no'}
            onChange={handleHasAssistantOfChange}
          >
            <FormControlLabel value="no" control={<Radio color="secondary" />} label="No" />
            <FormControlLabel value="yes" control={<Radio color="secondary" />} label="Yes" />
          </RadioGroup>
        </FormControl>
        <PeopleListEditor
          className={classNames(classes.peopleEditor, {
            [classes.peopleEditorHidden]: !hasAssistantOf,
          })}
          currentPersonId={currentPersonId}
          people={assistantOf}
          onChange={handleAssistantOfChange}
          label="Start typing their name"
          inputRef={assistantOfInputRef}
        />
      </div>
    </>
  );
}

export default Assistants;
