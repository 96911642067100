import { defineFetch } from 'resift';

export default defineFetch({
  displayName: 'example people',
  make: (fieldKey: string) => ({
    request: () => ({ http }) =>
      http({
        method: 'GET',
        route: '/sample-people',
        query: { requiredFieldKeys: fieldKey },
      }),
  }),
});
