import { useSelector } from 'react-redux';
import _get from 'lodash/get';

function useCustomNavLinkSettings() {
  const customNavLinkSettings = useSelector(state =>
    _get(state, 'client.settings.customNavLink', {}),
  );

  const customNavLinkEnabled = _get(customNavLinkSettings, 'enabled', false);

  const customNavLinkDisplayName = _get(customNavLinkSettings, 'displayName', null);

  const customNavLinkUrl = _get(customNavLinkSettings, 'url', null);

  return { customNavLinkEnabled, customNavLinkDisplayName, customNavLinkUrl };
}

export default useCustomNavLinkSettings;
