import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import usePrevious from '@sift/resift/usePrevious';

// Components
import Drawer from '@mui/material/Drawer';
import SupportDrawerForm from './Form';
import SupportDrawerCompletedForm from './CompletedForm';

import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';

// Component
function SupportDrawer(props) {
  const {
    supportTicket,
    clientName,
    userEmail,
    openDrawer,
    toggleSupportDrawer,
    createSupportMessage,
    updateSupportMessage,
    addAttachments,
    status,
  } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const [completedForm, setCompletedForm] = useState(false);
  const previousOpenDrawer = usePrevious(openDrawer);

  useEffect(() => {
    if (!previousOpenDrawer && openDrawer) {
      setCompletedForm(false);
    }
  }, [previousOpenDrawer, openDrawer]);

  const handleSubmit = () => {
    setCompletedForm(true);
  };

  return (
    <Drawer
      open={openDrawer}
      onClose={toggleSupportDrawer}
      anchor="right"
      style={{
        zIndex:
          theme.zIndex.max +
          1 /* this has to be displayed in all scenarios across the app, it has to be the highest zIndex allowed */,
      }}
      classes={{ paper: classes.paper }}
      ModalProps={{ disableEnforceFocus: true }}
    >
      {completedForm ? (
        <SupportDrawerCompletedForm
          clientName={clientName}
          toggleSupportDrawer={toggleSupportDrawer}
        />
      ) : (
        <SupportDrawerForm
          onSubmit={handleSubmit}
          userEmail={userEmail}
          toggleSupportDrawer={toggleSupportDrawer}
          createSupportMessage={createSupportMessage}
          updateSupportMessage={updateSupportMessage}
          addAttachments={addAttachments}
          supportTicket={supportTicket}
          status={status}
        />
      )}
    </Drawer>
  );
}

const useStyles = makeStyles()(() => {
  return {
    paper: {
      maxWidth: 400,
    },
  };
});

SupportDrawer.propTypes = {
  status: PropTypes.object.isRequired,
  supportTicket: PropTypes.object,
  openDrawer: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  clientName: PropTypes.string,
  toggleSupportDrawer: PropTypes.func.isRequired,
  createSupportMessage: PropTypes.func.isRequired,
  updateSupportMessage: PropTypes.func.isRequired,
  addAttachments: PropTypes.func.isRequired,
};

export default SupportDrawer;
