import React from 'react';

import AggregatesColumn from './AggregatesColumn';
import EntitiesColumn from './EntitiesColumn';
import PeopleColumn from './PeopleColumn';
import RecentSearchesColumn from './RecentSearchesColumn';

export default (result, props = {}) => {
  let Component = null;

  switch (result.type) {
    case 'aggregates':
      Component = AggregatesColumn;
      break;

    case 'entities':
      Component = EntitiesColumn;
      break;

    case 'people':
      Component = PeopleColumn;
      break;

    case 'recentSearches':
      Component = RecentSearchesColumn;
      break;

    default:
      return null;
  }
  if (!Component) return null;
  return <Component result={result} {...props} />;
};
