import React, { useContext, useState, useMemo } from 'react';
import { useStatus, useDispatch } from 'resift';
import useRouter from '@sift/resift/useRouter';
import useSlideInfo from 'pages/Onboarding/helpers/useSlideInfo';
import useLinkedIn from 'pages/Onboarding/helpers/useLinkedIn';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import useValidation from '@sift/runner/useValidation';
import RunnerContext from '@sift/runner/Context';
import useIsLastSlide from 'pages/Onboarding/helpers/useIsLastSlide';
import pluralize from 'pluralize';
import useWrap from 'helpers/useWrap';

import makeUpdateProfileFetch from 'pages/Profile/fetches/updateProfileFetch';
import makeUpdateCompletedSlidesFetch from 'pages/Onboarding/fetches/updateCompletedSlidesFetch';
import updateOnboardingStateFetch from 'pages/Onboarding/fetches/updateOnboardingStateFetch';

// Components
import { Redirect } from 'react-router';
import Runner from '@sift/runner/Runner';
import FieldTemplate from './FieldTemplate';

function FieldTemplateContainer() {
  const { history } = useRouter();
  const dispatch = useDispatch();
  const linkedIn = useLinkedIn();
  const contextValue = useContext(RunnerContext);
  const { field, nextSlideLink, currentSlide } = useSlideInfo();
  const [okayToNavigate, setOkayToNavigate] = useState(false);

  if (!nextSlideLink) {
    throw new Error('[FieldTemplate Container] expected `nextSlideLink` to be truthy');
  }
  if (!contextValue) {
    throw new Error('[FieldTemplate Container] could not find runner context');
  }
  if (!currentSlide) {
    throw new Error('[FieldTemplate Container] expected `currentSlide` to be truthy');
  }

  const { root } = contextValue;
  const personId = _get(root, ['id']) as string;
  const objectKey = _get(field, ['objectKey'], '');
  const displayName = _get(field, ['displayName'], '');
  const linkedInValue = _get(linkedIn, [objectKey]);

  const initialValue = useMemo(() => {
    return !_isEmpty(linkedInValue) ? linkedInValue : _get(root, [objectKey]);
  }, [linkedInValue, root, objectKey]);

  const updateProfileFetch = makeUpdateProfileFetch(personId);
  const updateCompletedSlidesFetch = makeUpdateCompletedSlidesFetch();

  const [editValue, setEditValue] = useState(initialValue);
  const status = useStatus(updateProfileFetch);

  const { isValid: isValidFromValidationLibrary } = useValidation(editValue, field);
  const isValid = isValidFromValidationLibrary && !_isEmpty(editValue);

  const isLastSlide = useIsLastSlide();
  const getHistory = useWrap(history);

  const handleContinue = async () => {
    setOkayToNavigate(true);
    await dispatch(updateProfileFetch({ [objectKey]: editValue }));
    await dispatch(updateCompletedSlidesFetch(currentSlide, objectKey));
    if (isLastSlide) {
      await dispatch(updateOnboardingStateFetch('completed'));
    }
    const history = getHistory();
    history.push(nextSlideLink);
  };

  const handleSkip = async () => {
    await dispatch(updateCompletedSlidesFetch(currentSlide));
    if (isLastSlide) {
      await dispatch(updateOnboardingStateFetch('completed'));
    }
    setOkayToNavigate(true);
    history.push(nextSlideLink);
  };

  const fromLinkedIn = !_isEmpty(linkedInValue);
  const showNavigationConfirmation = !_isEqual(editValue, initialValue);
  const showSkipConfirmation = fromLinkedIn || showNavigationConfirmation;

  const fieldDescription = _get(field, ['display', 'description'], '');
  const description = useMemo(() => {
    if (objectKey === 'skills' && fromLinkedIn) {
      const skillCount = _get(linkedInValue, ['length']);

      if (typeof skillCount !== 'number') {
        return (
          <>
            {fieldDescription}
            <br />
            We retrieved your top skills from LinkedIn.
          </>
        );
      }

      return (
        <>
          {fieldDescription}
          <br />
          We retrieved your top {skillCount} {pluralize('skills')} from LinkedIn.
        </>
      );
    }

    return fieldDescription;
  }, [fieldDescription, objectKey, fromLinkedIn, linkedInValue]);

  if (!field) {
    return <Redirect to={nextSlideLink} />;
  }

  return (
    <FieldTemplate
      displayName={displayName}
      description={description}
      isLastSlide={isLastSlide}
      fromLinkedIn={fromLinkedIn}
      input={
        <Runner
          mode="input"
          node={field}
          currentValue={editValue}
          onCurrentChange={setEditValue}
          defaultTouched
        />
      }
      isValid={isValid}
      status={status}
      onContinue={handleContinue}
      onSkip={handleSkip}
      showSkipConfirmation={showSkipConfirmation}
      showNavigationConfirmation={okayToNavigate ? false : showNavigationConfirmation}
    />
  );
}

export default FieldTemplateContainer;
