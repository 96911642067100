import delay from 'delay';

export default async function wootric(user) {
  function showSurveyCallback() {
    const date = new Date();
    window.Intercom('update', {
      last_wootric_surveyed_at: Math.round(date.getTime() / 1000),
    });
  }

  function declineSubmittedCallback() {
    const date = new Date();
    window.Intercom('update', {
      last_wootric_declined_at: Math.round(date.getTime() / 1000),
    });
  }

  function responseSubmittedCallback(score, comment) {
    window.Intercom('update', { last_wootric_score: score });
    window.Intercom('update', { last_wootric_comment: comment });
  }

  window.wootricSettings = {
    email: user.email,
    external_id: user.id,
    account_token: 'NPS-5ca1f1da', // This is your unique account token.
    survey_callback: showSurveyCallback,
    decline_callback: declineSubmittedCallback,
    response_callback: responseSubmittedCallback,
  };

  await delay(0);
  if (!window.wootric) return;
  window.wootric('run');
}
