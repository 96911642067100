import moment from 'moment-timezone';
import _sortBy from 'lodash/sortBy';
import timezoneDisplayNameMappings from 'components/TimezoneAndWorkHours/constants/timezoneMappings';

function getTimezoneDisplayNames(timezoneGuess: string) {
  const _tzNames = Object.keys(timezoneDisplayNameMappings);

  // Add timezoneGuess to the list if it's not already there
  const tzNames = _tzNames.includes(timezoneGuess) ? _tzNames : [..._tzNames, timezoneGuess];

  const timezones = tzNames.reduce((timezones: any, name) => {
    const utcOffset = moment.tz(name).format('Z');
    // @ts-ignore
    const displayName = timezoneDisplayNameMappings[name] || name;
    timezones[name] = {
      name,
      displayName,
      utcOffset,
    };
    return timezones;
  }, {});

  const orderedTimezones = _sortBy(Object.values(timezones), [
    (tzItem: any) => moment.tz(tzItem.name).utcOffset(),
    'displayName',
  ]);

  const timezoneDisplayNamesByName = orderedTimezones.reduce((displayNames: any, item: any) => {
    const { name, displayName, utcOffset } = item;
    displayNames[name] = `(UTC${utcOffset}) ${displayName}`;
    return displayNames;
  }, {} as any);

  return timezoneDisplayNamesByName as any;
}

export default getTimezoneDisplayNames;
