import _get from 'lodash/get';
import hashDate from './hashDate';

function isDate(value) {
  if (!value) return false;
  if (typeof value !== 'object') return false;
  if (value.to) return true;
  if (value.from) return true;
  return false;
}

/**
 * @param {string} camelCase
 */

export function createDisplayNameFromCamelCase(camelCase) {
  if (camelCase.length <= 1) return camelCase;

  const letters = camelCase.split('');

  const almostNormalCase = letters
    .map(letter => {
      // if uppercase letter
      if (/[A-Z]/.test(letter)) return ' ' + letter;
      return letter;
    })
    .join('');

  const firstLetter = letters[0];

  return `${firstLetter.toUpperCase()}${almostNormalCase.substring(1)}`;
}

export default function getAttributeValuesFromObject(params, fields) {
  const attributeKeyValueTuples = Object.entries(params)
    .map(([attributeKey, values]) => ({
      attributeKey,
      values: Array.isArray(values) ? values : [values],
    }))
    .map(({ attributeKey, values }) =>
      values.map(value => ({
        attributeKey,
        value,
      })),
    )
    .reduce((flattened, next) => [...flattened, ...next], [])
    .map(({ attributeKey, value }) => ({
      attributeKey,
      value: isDate(value) ? hashDate(value) : value,
    }));

  return attributeKeyValueTuples
    .filter(({ attributeKey }) => {
      if (attributeKey === 'q') return true;
      if (fields[attributeKey]) return true;
      if (attributeKey.includes('_exists')) return true;
      if (attributeKey === 'separated') return true;
      return false;
    })
    .map(attributeValue => {
      const { attributeKey } = attributeValue;
      if (attributeKey === 'q') {
        return { ...attributeValue, attributeKey: 'searched' };
      }

      return attributeValue;
    })
    .map(({ attributeKey, value }) => {
      const _value = attributeKey === 'searched' ? `"${value}"` : value;
      return {
        key: `${attributeKey}__${value}`,
        attributeKey,
        value: _value,
        title: _value === 'true' ? 'Yes' : _value === 'false' ? 'No' : undefined,
        attributeDisplayName: _get(
          fields,
          [attributeKey, 'displayName'],
          createDisplayNameFromCamelCase(attributeKey),
        ),
      };
    });
}
