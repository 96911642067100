import * as Types from 'components/TimezoneAndWorkHours/typings';

import HomeIcon from '@mui/icons-material/HomeOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FlexibleWorkIcon from '@mui/icons-material/MapsHomeWorkOutlined';

export const AM_PM_FORMAT = 'h:mm A';

export const WEEK_DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as Types.WeekDay[];

export const WEEK_DAY_ABBREVIATIONS = {
  Sunday: 'S',
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'T',
  Friday: 'F',
  Saturday: 'S',
};

export const DEFAULT_WORK_HOURS = {
  startTime: {
    hour: 9,
    minute: 0,
  }, // 9AM
  endTime: {
    hour: 17,
    minute: 0,
  }, // 5PM
  location: 'FLEXIBLE',
} as Types.WorkHours;

const DEFAULT_WORK_HOURS_AND_LOCATION_BY_DAY = {
  Sunday: null,
  Monday: {
    day: 1,
    ...DEFAULT_WORK_HOURS,
  },
  Tuesday: {
    day: 2,
    ...DEFAULT_WORK_HOURS,
  },
  Wednesday: {
    day: 3,
    ...DEFAULT_WORK_HOURS,
  },
  Thursday: {
    day: 4,
    ...DEFAULT_WORK_HOURS,
  },
  Friday: {
    day: 5,
    ...DEFAULT_WORK_HOURS,
  },
  Saturday: null,
} as Types.WorkHoursAndLocationByDay;

export default DEFAULT_WORK_HOURS_AND_LOCATION_BY_DAY;

export const INITIAL_WEEK_DAY_EDIT_ERRORS = {
  Sunday: {
    startTime: 0,
    endTime: 0,
    location: 0,
    workSchedule: 0,
  },
  Monday: {
    startTime: 0,
    endTime: 0,
    location: 0,
    workSchedule: 0,
  },
  Tuesday: {
    startTime: 0,
    endTime: 0,
    location: 0,
    workSchedule: 0,
  },
  Wednesday: {
    startTime: 0,
    endTime: 0,
    location: 0,
    workSchedule: 0,
  },
  Thursday: {
    startTime: 0,
    endTime: 0,
    location: 0,
    workSchedule: 0,
  },
  Friday: {
    startTime: 0,
    endTime: 0,
    location: 0,
    workSchedule: 0,
  },
  Saturday: {
    startTime: 0,
    endTime: 0,
    location: 0,
    workSchedule: 0,
  },
} as Types.WorkHoursEditErrors;

export const LOCATION_LOOKUP = {
  REMOTE: { icon: HomeIcon, copy: 'Working From Home' },
  OFFICE: { icon: ApartmentIcon, copy: 'In the Office' },
  FLEXIBLE: { icon: FlexibleWorkIcon, copy: 'Flexible' },
};
