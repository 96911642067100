export default {
  v: '5.3.4',
  fr: 24,
  ip: 0,
  op: 43,
  w: 500,
  h: 500,
  nm: 'Bell Animation',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Bell_Loop',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [28.355, -2.847, 0], ix: 2 },
        a: { a: 0, k: [5.16, 4.094, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-1.63, 0], [0, 2.07], [2.14, 0], [0, -2.22]],
                  o: [[1.63, 0], [0, -2.06], [-2.14, 0], [0, 2.23]],
                  v: [[3.66, 5.94], [7.74, 4.66], [3.87, 0], [0, 4.66]],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [133.333, 133.333], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 43,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Bell_Body',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 16,
              s: [0],
              e: [-8],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 19,
              s: [-8],
              e: [28],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 23,
              s: [28],
              e: [-25],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 26,
              s: [-25],
              e: [9],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 29,
              s: [9],
              e: [-5],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 32,
              s: [-5],
              e: [1],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 35,
              s: [1],
              e: [-2],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 38,
              s: [-2],
              e: [0],
            },
            { t: 40 },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: { a: 0, k: 255.5, ix: 3 },
          y: { a: 0, k: 94.939, ix: 4 },
        },
        a: { a: 0, k: [28.355, -2.82, 0], ix: 1 },
        s: { a: 0, k: [550, 550, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.34, 0],
                        [0, 0],
                        [0, 0],
                        [8.19, 0],
                        [0, -8.46],
                        [0, 0],
                        [0, 0],
                        [0, -2.42],
                        [-2.34, 0],
                        [0, 0],
                        [0, 2.41],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, -8.46],
                        [-8.19, 0],
                        [0, 0],
                        [0, 0],
                        [-2.34, 0],
                        [0, 2.41],
                        [0, 0],
                        [2.34, 0],
                        [0, -2.42],
                      ],
                      v: [
                        [38.12, 24.06],
                        [36, 24.06],
                        [36, 15.31],
                        [21.18, 0],
                        [6.35, 15.31],
                        [6.35, 24.06],
                        [4.24, 24.06],
                        [0, 28.43],
                        [4.24, 32.8],
                        [38.12, 32.8],
                        [42.36, 28.43],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 1,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [133.333, 133.333], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Page 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 43,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'MASK',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: { a: 0, k: 28.355, ix: 3 },
          y: { a: 0, k: 37.412, ix: 4 },
        },
        a: { a: 0, k: [26.067, 4.107, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[0, 0.24], [39.1, 0], [39.1, 6.16], [0.97, 5.87]],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [133.333, 133.333], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 43,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Bell_Pendulum',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 16,
                s: [28.356],
                e: [11.24],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 24,
                s: [11.24],
                e: [42.999],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 28,
                s: [42.999],
                e: [14.921],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 31,
                s: [14.921],
                e: [38.548],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 35,
                s: [38.548],
                e: [19.43],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 38,
                s: [19.43],
                e: [28.356],
              },
              { t: 42 },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 16,
                s: [42.897],
                e: [42.999],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 24,
                s: [42.999],
                e: [42.897],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 28,
                s: [42.897],
                e: [42.999],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 31,
                s: [42.999],
                e: [42.922],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: 35,
                s: [42.922],
                e: [42.945],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [1], y: [0] },
                n: ['0p667_1_1_0'],
                t: 38,
                s: [42.945],
                e: [42.897],
              },
              { t: 42 },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [9, 9], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [133.333, 133.333], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Oval 4',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 43,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
