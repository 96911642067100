import { defineFetch } from 'resift';

const makeGetTasks = defineFetch({
  displayName: 'Get Tasks',
  make: () => ({
    request: () => ({ http }) =>
      http({
        method: 'GET',
        route: '/notifications',
      }),
  }),
});

const getTasks = makeGetTasks();

export default getTasks;
