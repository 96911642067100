import React from 'react';
import classnames from 'classnames';

// Components
import ReactAutosuggest, { AutosuggestProps as ReactAutosuggestProps } from 'react-autosuggest';
import DefaultTextField from './DefaultTextField';
import DefaultSuggestion from './DefaultSuggestion';
import DefaultSuggestionsContainer from './DefaultSuggestionContainer';

// Styles
import { makeStyles } from 'tss-react/mui';
import { Theme, useTheme } from '@mui/material/styles';

// @ts-ignore
interface Props<T> extends ReactAutosuggestProps<T> {
  className?: string;
  suggestions: any[];
  inputProps: any;
  renderInputComponent?: any;
  renderSuggestion: any;
  onSuggestionsClearRequested: () => void;
}

/**
 * Skippers Autosuggest is derived from react-autosuggest. This is
 * a convenience wrapper that gives us some simple defaults.
 *
 * For more details on input / component overrides...
 * Look here https://github.com/moroshko/react-autosuggest#inputPropsProp
 */
const Autosuggest = <T extends object>(props: Props<T>) => {
  const theme = useTheme();
  const {
    className,
    inputProps = {
      value: '',
      onChange: () => {},
      placeholder: 'Enter a value',
    },
    suggestions = [],
    renderInputComponent = DefaultTextField,
    renderSuggestion = DefaultSuggestion,
    ...other
  } = props;
  const { classes } = useStyles();

  const handleSuggestionsClearRequested = () => {
    if (props.onSuggestionsClearRequested) {
      props.onSuggestionsClearRequested();
    }
  };

  const getSuggestionValue = (suggestion: T) => {
    return suggestion;
  };

  const renderSuggestionsContainer = (containerProps: any) => {
    return DefaultSuggestionsContainer(containerProps, {
      classes: {
        root: classes.suggestionsPaper,
      },
    });
  };

  return (
    <ReactAutosuggest
      // @ts-ignore
      getSuggestionValue={getSuggestionValue}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
      theme={{
        container: classnames(classes.root, className),
        suggestionsContainer: classes.suggestionsContainer,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
        ...theme,
      }}
      suggestions={suggestions}
      inputProps={inputProps}
      renderSuggestion={renderSuggestion}
      {...other}
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  suggestionsPaper: {
    position: 'absolute',
    width: '100%',
    minWidth: 200,
    zIndex: theme.zIndex.max,
  },
  suggestionsContainer: {
    width: '100%',
  },
  suggestionsContainerOpen: {},
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    display: 'block',
  },
}));

export default Autosuggest;
