import React, { useContext } from 'react';
import { Flex, Header } from '@fluentui/react-northstar';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';
import ToggleTruncateButton from 'components/msTeams/embed/atoms/ToggleTruncateButton';

interface Props {
  sectionHeader: string;
  content: React.ReactNode;
  onToggleMore?: () => void;
  truncated?: boolean;
}

function ProfileSection({ sectionHeader, content, onToggleMore, truncated }: Props) {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);

  return (
    <Flex column style={styles.root}>
      <Header as="h2" style={styles.header}>
        {sectionHeader}
      </Header>
      {content}
      {onToggleMore && (
        <Flex hAlign="end">
          <ToggleTruncateButton onClick={onToggleMore} truncated={truncated} />
        </Flex>
      )}
    </Flex>
  );
}

const useStyles = (theme: Theme) => ({
  root: {
    marginTop: theme.siteVariables.spacing.unit * 2,
  },
  header: {
    marginBottom: theme.siteVariables.spacing.unit,
  },
});

export default ProfileSection;
