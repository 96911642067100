import { createContextFetch } from 'resift';
import orgChartSettingsFetch from 'pages/OrgChart/fetches/orgChartSettingsFetch';

const {
  useContextFetch: useOrgChartSettings,
  ContextFetchProvider: OrgChartSettingsProvider,
  // @ts-ignore
} = createContextFetch(orgChartSettingsFetch);

export { OrgChartSettingsProvider };
export default useOrgChartSettings;
