import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Success = props => (
  <SvgIcon width="183px" height="80px" viewBox="0 0 183 80" {...props}>
    <title>icon</title>
    <defs>
      <path
        d="M37.96808,5.74647887 C58.9373391,5.74647887 75.9362261,4.460086 75.9362261,2.87323933 C75.9362261,1.28639333 58.9373391,0 37.96808,0 C16.998887,0 0,1.28639333 0,2.87323933 C0,4.460086 16.998887,5.74647887 37.96808,5.74647887 Z"
        id="path-1"
      />
      <path
        d="M13.2301333,0 L0.769866667,0 C0.3449,0 0,0.2235 0,0.5 C0,0.776 0.3449,1 0.769866667,1 L13.2301333,1 C13.6558733,1 14,0.776 14,0.5 C14,0.2235 13.6558733,0 13.2301333,0 Z"
        id="path-3"
      />
      <path
        d="M1.1530562,0.184914216 C0.85423121,-0.0970571063 0.411262481,-0.051528552 0.163062492,0.287157035 C-0.085843746,0.626642621 -0.0450624979,1.12987815 0.253062488,1.41184947 L3.84671232,4.81467676 C3.97819982,4.93849099 4.13780606,4.99999811 4.2967123,4.99999811 C4.49780604,4.99999811 4.69819353,4.90174815 4.83671228,4.71243394 C5.08561851,4.37294835 5.04553727,3.86971283 4.74671228,3.5877415 L1.1530562,0.184914216 Z"
        id="path-5"
      />
      <path
        d="M3.84671232,0.1853286 L0.253062488,3.58763627 C-0.0450624979,3.86962917 -0.085843746,4.37370068 0.163062492,4.71241501 C0.302281236,4.90174361 0.501962477,5.00000077 0.703056217,5.00000077 C0.86196246,5.00000077 1.02157495,4.93928648 1.1530562,4.81466503 L4.74671228,1.4123645 C5.04553727,1.1295716 5.08561851,0.626292954 4.83671228,0.286785759 C4.58850604,-0.0511285793 4.14554356,-0.0974643008 3.84671232,0.1853286 Z"
        id="path-7"
      />
      <path
        d="M0.7698,1 L13.2302,1 C13.6558667,1 14,0.7765 14,0.5 C14,0.2235 13.6558667,0 13.2302,0 L0.7698,0 C0.344866667,0 0,0.2235 0,0.5 C0,0.7765 0.344866667,1 0.7698,1 Z"
        id="path-9"
      />
      <path
        d="M3.84611929,4.8151143 L0.252749625,1.41282857 C-0.0453749327,1.1301 -0.0854373732,0.626950001 0.162749758,0.287514286 C0.301937052,0.0982357145 0.502311754,0 0.704123955,0 C0.86231122,0 1.02193598,0.0615000001 1.15337329,0.185285715 L4.74749295,3.58757858 C5.04486751,3.86950001 5.08561745,4.37345715 4.83749282,4.71288572 C4.58786819,5.0515143 4.14636885,5.09704287 3.84611929,4.8151143 Z"
        id="path-11"
      />
      <path
        d="M1.1526253,4.8151493 C0.854562721,5.09712783 0.412312607,5.05159213 0.162687542,4.71210646 C-0.0855000221,4.37341365 -0.0454375118,3.86937087 0.253375066,3.58659948 L3.846876,0.185321402 C3.97837603,0.0607071341 4.13725107,0 4.29612611,0 C4.49793866,0 4.69762622,0.0974499859 4.83750125,0.288364244 C5.08568882,0.626257052 5.04493881,1.13029984 4.74681373,1.41307122 L1.1526253,4.8151493 Z"
        id="path-13"
      />
      <path
        d="M1,0 C0.632,0 0.333333333,0.35574 0.333333333,0.79408 L0.333333333,6.20592 C0.333333333,6.64426 0.632,7 1,7 C1.368,7 1.66666667,6.64426 1.66666667,6.20592 L1.66666667,0.79408 C1.66666667,0.35574 1.368,0 1,0 Z"
        id="path-15"
      />
      <path
        d="M1,0 C0.632,0 0.333333333,0.355754 0.333333333,0.794101 L0.333333333,6.205899 C0.333333333,6.645037 0.632,7 1,7 C1.368,7 1.66666667,6.645037 1.66666667,6.205899 L1.66666667,0.794101 C1.66666667,0.355754 1.368,0 1,0 Z"
        id="path-17"
      />
      <path
        d="M1.2513125,0.215 C0.9653125,-0.0716875 0.5011875,-0.0716875 0.2145,0.215 C-0.0715,0.5016875 -0.0715,0.965125 0.2145,1.2518125 L3.748,4.7851875 C3.8916875,4.928125 4.0794375,5 4.267125,5 C4.454125,5 4.6418125,4.928125 4.7855,4.7851875 C5.0715,4.4985 5.0715,4.0350625 4.7855,3.748375 L1.2513125,0.215 Z"
        id="path-19"
      />
      <path
        d="M0.2150625,0.214487567 C-0.0716875,0.501212657 -0.0716875,0.965387802 0.2150625,1.25137539 L3.7480625,4.78514525 C3.8918125,4.92813904 4.0788125,5.00000156 4.2665,5.00000156 C4.45425,5.00000156 4.6419375,4.92813904 4.7849375,4.78514525 C5.0716875,4.49915766 5.0716875,4.03498251 4.7849375,3.74825742 L1.2519375,0.214487567 C0.9651875,-0.0714937723 0.501,-0.0714937723 0.2150625,0.214487567 Z"
        id="path-21"
      />
      <path
        d="M3.74825,0.2144875 L0.215,3.74825625 C-0.0716875,4.03498125 -0.0716875,4.49915625 0.215,4.78514375 C0.358,4.9281375 0.5456875,5 0.733375,5 C0.9210625,5 1.1080625,4.9281375 1.25175,4.78514375 L4.785,1.251375 C5.0716875,0.9653875 5.0716875,0.5012125 4.785,0.2144875 C4.4983125,-0.07149375 4.0349375,-0.07149375 3.74825,0.2144875 Z"
        id="path-23"
      />
      <path
        d="M3.7486875,0.215 L0.2145,3.748375 C-0.0715,4.0350625 -0.0715,4.4985 0.2145,4.7851875 C0.3581875,4.928125 0.545875,5 0.732875,5 C0.9205625,5 1.1083125,4.928125 1.2513125,4.7851875 L4.7855,1.2518125 C5.0715,0.965125 5.0715,0.5016875 4.7855,0.215 C4.4988125,-0.0716875 4.0346875,-0.0716875 3.7486875,0.215 Z"
        id="path-25"
      />
      <path
        d="M6.20592,0 L0.79408,0 C0.35497,0 0,0.224 0,0.5 C0,0.776 0.35497,1 0.79408,1 L6.20592,1 C6.64426,1 7,0.776 7,0.5 C7,0.224 6.64426,0 6.20592,0 Z"
        id="path-27"
      />
      <path
        d="M6.20578,0 L0.79422,0 C0.35497,0 0,0.224 0,0.5 C0,0.776 0.35497,1 0.79422,1 L6.20578,1 C6.64503,1 7,0.776 7,0.5 C7,0.224 6.64503,0 6.20578,0 Z"
        id="path-29"
      />
      <path
        d="M8.18181692,0 L0.818183077,0 C0.366542308,0 0,0.2235 0,0.5 C0,0.7765 0.366542308,1 0.818183077,1 L8.18181692,1 C8.63345769,1 9,0.7765 9,0.5 C9,0.2235 8.63345769,0 8.18181692,0 Z"
        id="path-31"
      />
      <path
        d="M4.16666643,0 L0.833333571,0 C0.373333357,0 0,0.2235 0,0.5 C0,0.77649995 0.373333357,1 0.833333571,1 L4.16666643,1 C4.62666643,1 5,0.77649995 5,0.5 C5,0.2235 4.62666643,0 4.16666643,0 Z"
        id="path-33"
      />
      <path
        d="M4.16664286,0 L0.833357143,0 C0.3725,0 0,0.2235 0,0.5 C0,0.7765 0.3725,1 0.833357143,1 L4.16664286,1 C4.6275,1 5,0.7765 5,0.5 C5,0.2235 4.6275,0 4.16664286,0 Z"
        id="path-35"
      />
      <path
        d="M5.5,9.42857235 C3.33375625,9.42857235 1.57141875,7.66621706 1.57141875,5.5 C1.57141875,3.33378294 3.33375625,1.57142765 5.5,1.57142765 C7.66624375,1.57142765 9.42858125,3.33378294 9.42858125,5.5 C9.42858125,7.66621706 7.66624375,9.42857235 5.5,9.42857235 Z M5.5,0 C2.46791875,0 0,2.46714471 0,5.5 C0,8.53285529 2.46791875,11 5.5,11 C8.53208125,11 11,8.53285529 11,5.5 C11,2.46714471 8.53208125,0 5.5,0 Z"
        id="path-37"
      />
      <path
        d="M4.5,7.36363385 C2.92172538,7.36363385 1.63636615,6.07909154 1.63636615,4.5 C1.63636615,2.92090846 2.92172538,1.63636615 4.5,1.63636615 C6.07909154,1.63636615 7.36363385,2.92090846 7.36363385,4.5 C7.36363385,6.07909154 6.07909154,7.36363385 4.5,7.36363385 Z M4.5,0 C2.01927462,0 0,2.01927462 0,4.5 C0,6.98072538 2.01927462,9 4.5,9 C6.98154231,9 9,6.98072538 9,4.5 C9,2.01927462 6.98154231,0 4.5,0 Z"
        id="path-39"
      />
      <path
        d="M8.18181692,0 L0.818183077,0 C0.366542308,0 0,0.224 0,0.5 C0,0.776 0.366542308,1 0.818183077,1 L8.18181692,1 C8.63345769,1 9,0.776 9,0.5 C9,0.224 8.63345769,0 8.18181692,0 Z"
        id="path-41"
      />
      <path
        d="M5.25,0 L0.75,0 C0.336,0 0,0.224 0,0.5 C0,0.776 0.336,1 0.75,1 L5.25,1 C5.664,1 6,0.776 6,0.5 C6,0.224 5.664,0 5.25,0 Z"
        id="path-43"
      />
      <path
        d="M8.18183077,0 L0.818169231,0 C0.365746154,0 0,0.224 0,0.5 C0,0.776 0.365746154,1 0.818169231,1 L8.18183077,1 C8.63425385,1 9,0.776 9,0.5 C9,0.224 8.63425385,0 8.18183077,0 Z"
        id="path-45"
      />
      <path
        d="M3.2,0 L0.8,0 C0.3576,0 0,0.224 0,0.5 C0,0.776 0.3576,1 0.8,1 L3.2,1 C3.6424,1 4,0.776 4,0.5 C4,0.224 3.6424,0 3.2,0 Z"
        id="path-47"
      />
      <path
        d="M37.96808,5.74647887 C58.9373391,5.74647887 75.9362261,4.460086 75.9362261,2.87323933 C75.9362261,1.28639333 58.9373391,0 37.96808,0 C16.998887,0 0,1.28639333 0,2.87323933 C0,4.460086 16.998887,5.74647887 37.96808,5.74647887 Z"
        id="path-49"
      />
      <path
        d="M13.2301333,0 L0.769866667,0 C0.3449,0 0,0.2235 0,0.5 C0,0.776 0.3449,1 0.769866667,1 L13.2301333,1 C13.6558733,1 14,0.776 14,0.5 C14,0.2235 13.6558733,0 13.2301333,0 Z"
        id="path-51"
      />
      <path
        d="M1.1530562,0.184914216 C0.85423121,-0.0970571063 0.411262481,-0.051528552 0.163062492,0.287157035 C-0.085843746,0.626642621 -0.0450624979,1.12987815 0.253062488,1.41184947 L3.84671232,4.81467676 C3.97819982,4.93849099 4.13780606,4.99999811 4.2967123,4.99999811 C4.49780604,4.99999811 4.69819353,4.90174815 4.83671228,4.71243394 C5.08561851,4.37294835 5.04553727,3.86971283 4.74671228,3.5877415 L1.1530562,0.184914216 Z"
        id="path-53"
      />
      <path
        d="M3.84671232,0.1853286 L0.253062488,3.58763627 C-0.0450624979,3.86962917 -0.085843746,4.37370068 0.163062492,4.71241501 C0.302281236,4.90174361 0.501962477,5.00000077 0.703056217,5.00000077 C0.86196246,5.00000077 1.02157495,4.93928648 1.1530562,4.81466503 L4.74671228,1.4123645 C5.04553727,1.1295716 5.08561851,0.626292954 4.83671228,0.286785759 C4.58850604,-0.0511285793 4.14554356,-0.0974643008 3.84671232,0.1853286 Z"
        id="path-55"
      />
      <path
        d="M0.7698,1 L13.2302,1 C13.6558667,1 14,0.7765 14,0.5 C14,0.2235 13.6558667,0 13.2302,0 L0.7698,0 C0.344866667,0 0,0.2235 0,0.5 C0,0.7765 0.344866667,1 0.7698,1 Z"
        id="path-57"
      />
      <path
        d="M3.84611929,4.8151143 L0.252749625,1.41282857 C-0.0453749327,1.1301 -0.0854373732,0.626950001 0.162749758,0.287514286 C0.301937052,0.0982357145 0.502311754,0 0.704123955,0 C0.86231122,0 1.02193598,0.0615000001 1.15337329,0.185285715 L4.74749295,3.58757858 C5.04486751,3.86950001 5.08561745,4.37345715 4.83749282,4.71288572 C4.58786819,5.0515143 4.14636885,5.09704287 3.84611929,4.8151143 Z"
        id="path-59"
      />
      <path
        d="M1.1526253,4.8151493 C0.854562721,5.09712783 0.412312607,5.05159213 0.162687542,4.71210646 C-0.0855000221,4.37341365 -0.0454375118,3.86937087 0.253375066,3.58659948 L3.846876,0.185321402 C3.97837603,0.0607071341 4.13725107,0 4.29612611,0 C4.49793866,0 4.69762622,0.0974499859 4.83750125,0.288364244 C5.08568882,0.626257052 5.04493881,1.13029984 4.74681373,1.41307122 L1.1526253,4.8151493 Z"
        id="path-61"
      />
      <path
        d="M1,0 C0.632,0 0.333333333,0.35574 0.333333333,0.79408 L0.333333333,6.20592 C0.333333333,6.64426 0.632,7 1,7 C1.368,7 1.66666667,6.64426 1.66666667,6.20592 L1.66666667,0.79408 C1.66666667,0.35574 1.368,0 1,0 Z"
        id="path-63"
      />
      <path
        d="M1,0 C0.632,0 0.333333333,0.355754 0.333333333,0.794101 L0.333333333,6.205899 C0.333333333,6.645037 0.632,7 1,7 C1.368,7 1.66666667,6.645037 1.66666667,6.205899 L1.66666667,0.794101 C1.66666667,0.355754 1.368,0 1,0 Z"
        id="path-65"
      />
      <path
        d="M1.2513125,0.215 C0.9653125,-0.0716875 0.5011875,-0.0716875 0.2145,0.215 C-0.0715,0.5016875 -0.0715,0.965125 0.2145,1.2518125 L3.748,4.7851875 C3.8916875,4.928125 4.0794375,5 4.267125,5 C4.454125,5 4.6418125,4.928125 4.7855,4.7851875 C5.0715,4.4985 5.0715,4.0350625 4.7855,3.748375 L1.2513125,0.215 Z"
        id="path-67"
      />
      <path
        d="M0.2150625,0.214487567 C-0.0716875,0.501212657 -0.0716875,0.965387802 0.2150625,1.25137539 L3.7480625,4.78514525 C3.8918125,4.92813904 4.0788125,5.00000156 4.2665,5.00000156 C4.45425,5.00000156 4.6419375,4.92813904 4.7849375,4.78514525 C5.0716875,4.49915766 5.0716875,4.03498251 4.7849375,3.74825742 L1.2519375,0.214487567 C0.9651875,-0.0714937723 0.501,-0.0714937723 0.2150625,0.214487567 Z"
        id="path-69"
      />
      <path
        d="M3.74825,0.2144875 L0.215,3.74825625 C-0.0716875,4.03498125 -0.0716875,4.49915625 0.215,4.78514375 C0.358,4.9281375 0.5456875,5 0.733375,5 C0.9210625,5 1.1080625,4.9281375 1.25175,4.78514375 L4.785,1.251375 C5.0716875,0.9653875 5.0716875,0.5012125 4.785,0.2144875 C4.4983125,-0.07149375 4.0349375,-0.07149375 3.74825,0.2144875 Z"
        id="path-71"
      />
      <path
        d="M3.7486875,0.215 L0.2145,3.748375 C-0.0715,4.0350625 -0.0715,4.4985 0.2145,4.7851875 C0.3581875,4.928125 0.545875,5 0.732875,5 C0.9205625,5 1.1083125,4.928125 1.2513125,4.7851875 L4.7855,1.2518125 C5.0715,0.965125 5.0715,0.5016875 4.7855,0.215 C4.4988125,-0.0716875 4.0346875,-0.0716875 3.7486875,0.215 Z"
        id="path-73"
      />
      <path
        d="M6.20592,0 L0.79408,0 C0.35497,0 0,0.224 0,0.5 C0,0.776 0.35497,1 0.79408,1 L6.20592,1 C6.64426,1 7,0.776 7,0.5 C7,0.224 6.64426,0 6.20592,0 Z"
        id="path-75"
      />
      <path
        d="M6.20578,0 L0.79422,0 C0.35497,0 0,0.224 0,0.5 C0,0.776 0.35497,1 0.79422,1 L6.20578,1 C6.64503,1 7,0.776 7,0.5 C7,0.224 6.64503,0 6.20578,0 Z"
        id="path-77"
      />
      <path
        d="M8.18181692,0 L0.818183077,0 C0.366542308,0 0,0.2235 0,0.5 C0,0.7765 0.366542308,1 0.818183077,1 L8.18181692,1 C8.63345769,1 9,0.7765 9,0.5 C9,0.2235 8.63345769,0 8.18181692,0 Z"
        id="path-79"
      />
      <path
        d="M4.16666643,0 L0.833333571,0 C0.373333357,0 0,0.2235 0,0.5 C0,0.77649995 0.373333357,1 0.833333571,1 L4.16666643,1 C4.62666643,1 5,0.77649995 5,0.5 C5,0.2235 4.62666643,0 4.16666643,0 Z"
        id="path-81"
      />
      <path
        d="M4.16664286,0 L0.833357143,0 C0.3725,0 0,0.2235 0,0.5 C0,0.7765 0.3725,1 0.833357143,1 L4.16664286,1 C4.6275,1 5,0.7765 5,0.5 C5,0.2235 4.6275,0 4.16664286,0 Z"
        id="path-83"
      />
      <path
        d="M5.5,9.42857235 C3.33375625,9.42857235 1.57141875,7.66621706 1.57141875,5.5 C1.57141875,3.33378294 3.33375625,1.57142765 5.5,1.57142765 C7.66624375,1.57142765 9.42858125,3.33378294 9.42858125,5.5 C9.42858125,7.66621706 7.66624375,9.42857235 5.5,9.42857235 Z M5.5,0 C2.46791875,0 0,2.46714471 0,5.5 C0,8.53285529 2.46791875,11 5.5,11 C8.53208125,11 11,8.53285529 11,5.5 C11,2.46714471 8.53208125,0 5.5,0 Z"
        id="path-85"
      />
      <path
        d="M4.5,7.36363385 C2.92172538,7.36363385 1.63636615,6.07909154 1.63636615,4.5 C1.63636615,2.92090846 2.92172538,1.63636615 4.5,1.63636615 C6.07909154,1.63636615 7.36363385,2.92090846 7.36363385,4.5 C7.36363385,6.07909154 6.07909154,7.36363385 4.5,7.36363385 Z M4.5,0 C2.01927462,0 0,2.01927462 0,4.5 C0,6.98072538 2.01927462,9 4.5,9 C6.98154231,9 9,6.98072538 9,4.5 C9,2.01927462 6.98154231,0 4.5,0 Z"
        id="path-87"
      />
      <path
        d="M8.18181692,0 L0.818183077,0 C0.366542308,0 0,0.224 0,0.5 C0,0.776 0.366542308,1 0.818183077,1 L8.18181692,1 C8.63345769,1 9,0.776 9,0.5 C9,0.224 8.63345769,0 8.18181692,0 Z"
        id="path-89"
      />
      <path
        d="M5.25,0 L0.75,0 C0.336,0 0,0.224 0,0.5 C0,0.776 0.336,1 0.75,1 L5.25,1 C5.664,1 6,0.776 6,0.5 C6,0.224 5.664,0 5.25,0 Z"
        id="path-91"
      />
      <path
        d="M8.18183077,0 L0.818169231,0 C0.365746154,0 0,0.224 0,0.5 C0,0.776 0.365746154,1 0.818169231,1 L8.18183077,1 C8.63425385,1 9,0.776 9,0.5 C9,0.224 8.63425385,0 8.18183077,0 Z"
        id="path-93"
      />
      <path
        d="M3.2,0 L0.8,0 C0.3576,0 0,0.224 0,0.5 C0,0.776 0.3576,1 0.8,1 L3.2,1 C3.6424,1 4,0.776 4,0.5 C4,0.224 3.6424,0 3.2,0 Z"
        id="path-95"
      />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-1122.000000, -87.000000)">
        <g id="icon" transform="translate(1122.000000, 87.000000)">
          <g id="sparkles">
            <g id="Group-3" transform="translate(56.000000, 73.000000)">
              <mask id="mask-2" fill="white">
                <use />
              </mask>
              <g id="Clip-2" />
              <polygon
                id="Fill-1"
                fill="#ECF1EE"
                mask="url(#mask-2)"
                points="-3.30434783 9.079812 79.2405739 9.079812 79.2405739 -3.33333467 -3.30434783 -3.33333467"
              />
            </g>
            <g id="Group-6" transform="translate(11.000000, 28.000000)">
              <mask id="mask-4" fill="white">
                <use />
              </mask>
              <g id="Clip-5" />
              <polygon
                id="Fill-4"
                fill="#ECF1EE"
                mask="url(#mask-4)"
                points="-3.33333333 3.5 17.3333333 3.5 17.3333333 -2.5 -3.33333333 -2.5"
              />
            </g>
            <g id="Group-9" transform="translate(20.000000, 25.000000)">
              <mask id="mask-6" fill="white">
                <use />
              </mask>
              <g id="Clip-8" />
              <polygon
                id="Fill-7"
                fill="#ECF1EE"
                mask="url(#mask-6)"
                points="-3.125 8.57142857 8.125 8.57142857 8.125 -3.57142857 -3.125 -3.57142857"
              />
            </g>
            <g id="Group-12" transform="translate(20.000000, 28.000000)">
              <mask id="mask-8" fill="white">
                <use />
              </mask>
              <g id="Clip-11" />
              <polygon
                id="Fill-10"
                fill="#ECF1EE"
                mask="url(#mask-8)"
                points="-3.125 8.57142857 8.125 8.57142857 8.125 -3.57142857 -3.125 -3.57142857"
              />
            </g>
            <g id="Group-15" transform="translate(143.000000, 58.000000)">
              <mask id="mask-10" fill="white">
                <use />
              </mask>
              <g id="Clip-14" />
              <polygon
                id="Fill-13"
                fill="#ECF1EE"
                mask="url(#mask-10)"
                points="17.3333333 3.5 -3.33333333 3.5 -3.33333333 -2.5 17.3333333 -2.5"
              />
            </g>
            <g id="Group-18" transform="translate(153.000000, 54.000000)">
              <mask id="mask-12" fill="white">
                <use />
              </mask>
              <g id="Clip-17" />
              <polygon
                id="Fill-16"
                fill="#ECF1EE"
                mask="url(#mask-12)"
                points="-3.125 8.57142857 8.125 8.57142857 8.125 -3.57142857 -3.125 -3.57142857"
              />
            </g>
            <g id="Group-21" transform="translate(153.000000, 58.000000)">
              <mask id="mask-14" fill="white">
                <use />
              </mask>
              <g id="Clip-20" />
              <polygon
                id="Fill-19"
                fill="#ECF1EE"
                mask="url(#mask-14)"
                points="-3.125 8.57142857 8.125 8.57142857 8.125 -3.57142857 -3.125 -3.57142857"
              />
            </g>
            <g id="Group-24" transform="translate(96.000000, 0.000000)">
              <mask id="mask-16" fill="white">
                <use />
              </mask>
              <g id="Clip-23" />
              <polygon
                id="Fill-22"
                fill="#ECF1EE"
                mask="url(#mask-16)"
                points="-3 10.5 5 10.5 5 -3.5 -3 -3.5"
              />
            </g>
            <g id="Group-27" transform="translate(96.000000, 15.000000)">
              <mask id="mask-18" fill="white">
                <use />
              </mask>
              <g id="Clip-26" />
              <polygon
                id="Fill-25"
                fill="#ECF1EE"
                mask="url(#mask-18)"
                points="-3 10.5 5 10.5 5 -3.5 -3 -3.5"
              />
            </g>
            <g id="Group-30" transform="translate(89.000000, 3.000000)">
              <mask id="mask-20" fill="white">
                <use />
              </mask>
              <g id="Clip-29" />
              <polygon
                id="Fill-28"
                fill="#ECF1EE"
                mask="url(#mask-20)"
                points="-3.125 8.125 8.125 8.125 8.125 -3.125 -3.125 -3.125"
              />
            </g>
            <g id="Group-33" transform="translate(99.000000, 13.000000)">
              <mask id="mask-22" fill="white">
                <use />
              </mask>
              <g id="Clip-32" />
              <polygon
                id="Fill-31"
                fill="#ECF1EE"
                mask="url(#mask-22)"
                points="-3.125 8.125 8.125 8.125 8.125 -3.125 -3.125 -3.125"
              />
            </g>
            <g id="Group-36" transform="translate(89.000000, 13.000000)">
              <mask id="mask-24" fill="white">
                <use />
              </mask>
              <g id="Clip-35" />
              <polygon
                id="Fill-34"
                fill="#ECF1EE"
                mask="url(#mask-24)"
                points="-3.125 8.125 8.125 8.125 8.125 -3.125 -3.125 -3.125"
              />
            </g>
            <g id="Group-39" transform="translate(99.000000, 3.000000)">
              <mask id="mask-26" fill="white">
                <use />
              </mask>
              <g id="Clip-38" />
              <polygon
                id="Fill-37"
                fill="#ECF1EE"
                mask="url(#mask-26)"
                points="-3.125 8.125 8.125 8.125 8.125 -3.125 -3.125 -3.125"
              />
            </g>
            <g id="Group-42" transform="translate(87.000000, 11.000000)">
              <mask id="mask-28" fill="white">
                <use />
              </mask>
              <g id="Clip-41" />
              <polygon
                id="Fill-40"
                fill="#ECF1EE"
                mask="url(#mask-28)"
                points="-3.5 3.5 10.5 3.5 10.5 -2.5 -3.5 -2.5"
              />
            </g>
            <g id="Group-45" transform="translate(100.000000, 11.000000)">
              <mask id="mask-30" fill="white">
                <use />
              </mask>
              <g id="Clip-44" />
              <polygon
                id="Fill-43"
                fill="#ECF1EE"
                mask="url(#mask-30)"
                points="-3.5 3.5 10.5 3.5 10.5 -2.5 -3.5 -2.5"
              />
            </g>
            <g id="Group-48" transform="translate(13.000000, 66.000000)">
              <mask id="mask-32" fill="white">
                <use />
              </mask>
              <g id="Clip-47" />
              <polygon
                id="Fill-46"
                fill="#ECF1EE"
                mask="url(#mask-32)"
                points="-3.46153846 3.5 12.4615385 3.5 12.4615385 -2.5 -3.46153846 -2.5"
              />
            </g>
            <g id="Group-51" transform="translate(0.000000, 78.000000)">
              <mask id="mask-34" fill="white">
                <use />
              </mask>
              <g id="Clip-50" />
              <polygon
                id="Fill-49"
                fill="#ECF1EE"
                mask="url(#mask-34)"
                points="-3.57142857 3.5 8.57142857 3.5 8.57142857 -2.5 -3.57142857 -2.5"
              />
            </g>
            <g id="Group-54" transform="translate(178.000000, 62.000000)">
              <mask id="mask-36" fill="white">
                <use />
              </mask>
              <g id="Clip-53" />
              <polygon
                id="Fill-52"
                fill="#ECF1EE"
                mask="url(#mask-36)"
                points="-3.57142857 3.5 8.57142857 3.5 8.57142857 -2.5 -3.57142857 -2.5"
              />
            </g>
            <g id="Group-57" transform="translate(155.000000, 24.000000)">
              <mask id="mask-38" fill="white">
                <use />
              </mask>
              <g id="Clip-56" />
              <polygon
                id="Fill-55"
                fill="#ECF1EE"
                mask="url(#mask-38)"
                points="-3.4375 14.2352941 14.4375 14.2352941 14.4375 -3.23529412 -3.4375 -3.23529412"
              />
            </g>
            <g id="Group-60" transform="translate(42.000000, 53.000000)">
              <mask id="mask-40" fill="white">
                <use />
              </mask>
              <g id="Clip-59" />
              <polygon
                id="Fill-58"
                fill="#ECF1EE"
                mask="url(#mask-40)"
                points="-3.46153846 12.4615385 12.4615385 12.4615385 12.4615385 -3.46153846 -3.46153846 -3.46153846"
              />
            </g>
            <g id="Group-63" transform="translate(52.000000, 17.000000)">
              <mask id="mask-42" fill="white">
                <use />
              </mask>
              <g id="Clip-62" />
              <polygon
                id="Fill-61"
                fill="#ECF1EE"
                mask="url(#mask-42)"
                points="-3.46153846 3.5 12.4615385 3.5 12.4615385 -2.5 -3.46153846 -2.5"
              />
            </g>
            <g id="Group-66" transform="translate(44.000000, 17.000000)">
              <mask id="mask-44" fill="white">
                <use />
              </mask>
              <g id="Clip-65" />
              <polygon
                id="Fill-64"
                fill="#ECF1EE"
                mask="url(#mask-44)"
                points="-3.33333333 3.5 9.33333333 3.5 9.33333333 -2.5 -3.33333333 -2.5"
              />
            </g>
            <g id="Group-69" transform="translate(121.000000, 28.000000)">
              <mask id="mask-46" fill="white">
                <use />
              </mask>
              <g id="Clip-68" />
              <polygon
                id="Fill-67"
                fill="#ECF1EE"
                mask="url(#mask-46)"
                points="-3.46153846 3.5 12.4615385 3.5 12.4615385 -2.5 -3.46153846 -2.5"
              />
            </g>
            <g id="Group-72" transform="translate(131.000000, 28.000000)">
              <mask id="mask-48" fill="white">
                <use />
              </mask>
              <g id="Clip-71" />
              <polygon
                id="Fill-70"
                fill="#ECF1EE"
                mask="url(#mask-48)"
                points="-3.33333333 3.5 7.33333333 3.5 7.33333333 -2.5 -3.33333333 -2.5"
              />
            </g>
            <g id="Group-75" transform="translate(56.000000, 73.000000)">
              <mask id="mask-50" fill="white">
                <use />
              </mask>
              <g id="Clip-74" />
              <polygon
                id="Fill-73"
                fill="#ECF1EE"
                mask="url(#mask-50)"
                points="-3.30434783 9.079812 79.2405739 9.079812 79.2405739 -3.33333467 -3.30434783 -3.33333467"
              />
            </g>
            <g id="Group-78" transform="translate(11.000000, 28.000000)">
              <mask id="mask-52" fill="white">
                <use />
              </mask>
              <g id="Clip-77" />
              <polygon
                id="Fill-76"
                fill="#ECF1EE"
                mask="url(#mask-52)"
                points="-3.33333333 3.5 17.3333333 3.5 17.3333333 -2.5 -3.33333333 -2.5"
              />
            </g>
            <g id="Group-81" transform="translate(20.000000, 25.000000)">
              <mask id="mask-54" fill="white">
                <use />
              </mask>
              <g id="Clip-80" />
              <polygon
                id="Fill-79"
                fill="#ECF1EE"
                mask="url(#mask-54)"
                points="-3.125 8.57142857 8.125 8.57142857 8.125 -3.57142857 -3.125 -3.57142857"
              />
            </g>
            <g id="Group-84" transform="translate(20.000000, 28.000000)">
              <mask id="mask-56" fill="white">
                <use />
              </mask>
              <g id="Clip-83" />
              <polygon
                id="Fill-82"
                fill="#ECF1EE"
                mask="url(#mask-56)"
                points="-3.125 8.57142857 8.125 8.57142857 8.125 -3.57142857 -3.125 -3.57142857"
              />
            </g>
            <g id="Group-87" transform="translate(143.000000, 58.000000)">
              <mask id="mask-58" fill="white">
                <use />
              </mask>
              <g id="Clip-86" />
              <polygon
                id="Fill-85"
                fill="#ECF1EE"
                mask="url(#mask-58)"
                points="17.3333333 3.5 -3.33333333 3.5 -3.33333333 -2.5 17.3333333 -2.5"
              />
            </g>
            <g id="Group-90" transform="translate(153.000000, 54.000000)">
              <mask id="mask-60" fill="white">
                <use />
              </mask>
              <g id="Clip-89" />
              <polygon
                id="Fill-88"
                fill="#ECF1EE"
                mask="url(#mask-60)"
                points="-3.125 8.57142857 8.125 8.57142857 8.125 -3.57142857 -3.125 -3.57142857"
              />
            </g>
            <g id="Group-93" transform="translate(153.000000, 58.000000)">
              <mask id="mask-62" fill="white">
                <use />
              </mask>
              <g id="Clip-92" />
              <polygon
                id="Fill-91"
                fill="#ECF1EE"
                mask="url(#mask-62)"
                points="-3.125 8.57142857 8.125 8.57142857 8.125 -3.57142857 -3.125 -3.57142857"
              />
            </g>
            <g id="Group-96" transform="translate(96.000000, 0.000000)">
              <mask id="mask-64" fill="white">
                <use />
              </mask>
              <g id="Clip-95" />
              <polygon
                id="Fill-94"
                fill="#ECF1EE"
                mask="url(#mask-64)"
                points="-3 10.5 5 10.5 5 -3.5 -3 -3.5"
              />
            </g>
            <g id="Group-99" transform="translate(96.000000, 15.000000)">
              <mask id="mask-66" fill="white">
                <use />
              </mask>
              <g id="Clip-98" />
              <polygon
                id="Fill-97"
                fill="#ECF1EE"
                mask="url(#mask-66)"
                points="-3 10.5 5 10.5 5 -3.5 -3 -3.5"
              />
            </g>
            <g id="Group-102" transform="translate(89.000000, 3.000000)">
              <mask id="mask-68" fill="white">
                <use />
              </mask>
              <g id="Clip-101" />
              <polygon
                id="Fill-100"
                fill="#ECF1EE"
                mask="url(#mask-68)"
                points="-3.125 8.125 8.125 8.125 8.125 -3.125 -3.125 -3.125"
              />
            </g>
            <g id="Group-105" transform="translate(99.000000, 13.000000)">
              <mask id="mask-70" fill="white">
                <use />
              </mask>
              <g id="Clip-104" />
              <polygon
                id="Fill-103"
                fill="#ECF1EE"
                mask="url(#mask-70)"
                points="-3.125 8.125 8.125 8.125 8.125 -3.125 -3.125 -3.125"
              />
            </g>
            <g id="Group-108" transform="translate(89.000000, 13.000000)">
              <mask id="mask-72" fill="white">
                <use />
              </mask>
              <g id="Clip-107" />
              <polygon
                id="Fill-106"
                fill="#ECF1EE"
                mask="url(#mask-72)"
                points="-3.125 8.125 8.125 8.125 8.125 -3.125 -3.125 -3.125"
              />
            </g>
            <g id="Group-111" transform="translate(99.000000, 3.000000)">
              <mask id="mask-74" fill="white">
                <use />
              </mask>
              <g id="Clip-110" />
              <polygon
                id="Fill-109"
                fill="#ECF1EE"
                mask="url(#mask-74)"
                points="-3.125 8.125 8.125 8.125 8.125 -3.125 -3.125 -3.125"
              />
            </g>
            <g id="Group-114" transform="translate(87.000000, 11.000000)">
              <mask id="mask-76" fill="white">
                <use />
              </mask>
              <g id="Clip-113" />
              <polygon
                id="Fill-112"
                fill="#ECF1EE"
                mask="url(#mask-76)"
                points="-3.5 3.5 10.5 3.5 10.5 -2.5 -3.5 -2.5"
              />
            </g>
            <g id="Group-117" transform="translate(100.000000, 11.000000)">
              <mask id="mask-78" fill="white">
                <use />
              </mask>
              <g id="Clip-116" />
              <polygon
                id="Fill-115"
                fill="#ECF1EE"
                mask="url(#mask-78)"
                points="-3.5 3.5 10.5 3.5 10.5 -2.5 -3.5 -2.5"
              />
            </g>
            <g id="Group-120" transform="translate(13.000000, 66.000000)">
              <mask id="mask-80" fill="white">
                <use />
              </mask>
              <g id="Clip-119" />
              <polygon
                id="Fill-118"
                fill="#ECF1EE"
                mask="url(#mask-80)"
                points="-3.46153846 3.5 12.4615385 3.5 12.4615385 -2.5 -3.46153846 -2.5"
              />
            </g>
            <g id="Group-123" transform="translate(0.000000, 78.000000)">
              <mask id="mask-82" fill="white">
                <use />
              </mask>
              <g id="Clip-122" />
              <polygon
                id="Fill-121"
                fill="#ECF1EE"
                mask="url(#mask-82)"
                points="-3.57142857 3.5 8.57142857 3.5 8.57142857 -2.5 -3.57142857 -2.5"
              />
            </g>
            <g id="Group-126" transform="translate(178.000000, 62.000000)">
              <mask id="mask-84" fill="white">
                <use />
              </mask>
              <g id="Clip-125" />
              <polygon
                id="Fill-124"
                fill="#ECF1EE"
                mask="url(#mask-84)"
                points="-3.57142857 3.5 8.57142857 3.5 8.57142857 -2.5 -3.57142857 -2.5"
              />
            </g>
            <g id="Group-129" transform="translate(155.000000, 24.000000)">
              <mask id="mask-86" fill="white">
                <use />
              </mask>
              <g id="Clip-128" />
              <polygon
                id="Fill-127"
                fill="#ECF1EE"
                mask="url(#mask-86)"
                points="-3.4375 14.2352941 14.4375 14.2352941 14.4375 -3.23529412 -3.4375 -3.23529412"
              />
            </g>
            <g id="Group-132" transform="translate(42.000000, 53.000000)">
              <mask id="mask-88" fill="white">
                <use />
              </mask>
              <g id="Clip-131" />
              <polygon
                id="Fill-130"
                fill="#ECF1EE"
                mask="url(#mask-88)"
                points="-3.46153846 12.4615385 12.4615385 12.4615385 12.4615385 -3.46153846 -3.46153846 -3.46153846"
              />
            </g>
            <g id="Group-135" transform="translate(52.000000, 17.000000)">
              <mask id="mask-90" fill="white">
                <use />
              </mask>
              <g id="Clip-134" />
              <polygon
                id="Fill-133"
                fill="#ECF1EE"
                mask="url(#mask-90)"
                points="-3.46153846 3.5 12.4615385 3.5 12.4615385 -2.5 -3.46153846 -2.5"
              />
            </g>
            <g id="Group-138" transform="translate(44.000000, 17.000000)">
              <mask id="mask-92" fill="white">
                <use />
              </mask>
              <g id="Clip-137" />
              <polygon
                id="Fill-136"
                fill="#ECF1EE"
                mask="url(#mask-92)"
                points="-3.33333333 3.5 9.33333333 3.5 9.33333333 -2.5 -3.33333333 -2.5"
              />
            </g>
            <g id="Group-141" transform="translate(121.000000, 28.000000)">
              <mask id="mask-94" fill="white">
                <use />
              </mask>
              <g id="Clip-140" />
              <polygon
                id="Fill-139"
                fill="#ECF1EE"
                mask="url(#mask-94)"
                points="-3.46153846 3.5 12.4615385 3.5 12.4615385 -2.5 -3.46153846 -2.5"
              />
            </g>
            <g id="Group-144" transform="translate(131.000000, 28.000000)">
              <mask id="mask-96" fill="white">
                <use />
              </mask>
              <g id="Clip-143" />
              <polygon
                id="Fill-142"
                fill="#ECF1EE"
                mask="url(#mask-96)"
                points="-3.33333333 3.5 7.33333333 3.5 7.33333333 -2.5 -3.33333333 -2.5"
              />
            </g>
          </g>
          <g
            id="inbox-letter"
            transform="translate(66.000000, 21.000000)"
            fill="#3B4340"
            fillRule="nonzero"
          >
            <path
              d="M43.3445545,30.4950495 C42.785996,30.4950495 42.3326733,30.0396568 42.3326733,29.4785479 L42.3326733,2.0330033 L17.4545624,2.0330033 L5.9049505,13.6353531 L5.9049505,29.4785479 C5.9049505,30.0396568 5.45162772,30.4950495 4.89306931,30.4950495 C4.33451089,30.4950495 3.88118812,30.0396568 3.88118812,29.4785479 L3.88118812,13.2145215 C3.88118812,12.944132 3.98844752,12.6859406 4.17665743,12.4948383 L16.3192317,0.296818482 C16.5094653,0.107749175 16.7664832,0 17.0356436,0 L43.3445545,0 C43.9031129,0 44.3564356,0.455392739 44.3564356,1.01650165 L44.3564356,29.4785479 C44.3564356,30.0396568 43.9031129,30.4950495 43.3445545,30.4950495 Z"
              id="Shape"
            />
            <path
              d="M17.2673267,14.4158416 L4.91089109,14.4158416 C4.34249505,14.4158416 3.88118812,13.9545347 3.88118812,13.3861386 C3.88118812,12.8177426 4.34249505,12.3564356 4.91089109,12.3564356 L16.2376238,12.3564356 L16.2376238,1.02970297 C16.2376238,0.461306931 16.6989307,0 17.2673267,0 C17.8357228,0 18.2970297,0.461306931 18.2970297,1.02970297 L18.2970297,13.3861386 C18.2970297,13.9545347 17.8357228,14.4158416 17.2673267,14.4158416 Z"
              id="Shape"
            />
            <g id="Group" transform="translate(0.000000, 21.069307)">
              <path
                d="M43.2128713,27.3346535 L5.02475248,27.3346535 C2.25510891,27.3346535 0,25.0684356 0,22.2851485 L0,9.12007921 C0,7.60724752 0.470316832,6.16308911 1.36070297,4.94110891 L4.17858416,0.531881188 C4.47805941,0.0632871287 5.09911881,-0.0740594059 5.56742574,0.228910891 C6.03372277,0.529861386 6.16838614,1.1539802 5.86891089,1.62459406 L3.01686139,6.08431683 C2.34555446,7.00736634 2.00990099,8.03948515 2.00990099,9.12007921 L2.00990099,22.2851485 C2.00990099,23.9555248 3.36256436,25.3148515 5.02475248,25.3148515 L43.2128713,25.3148515 C44.8750594,25.3148515 46.2277228,23.9555248 46.2277228,22.2851485 L46.2277228,8.97061386 C46.2277228,7.97081188 45.9362871,7.00130693 45.3835644,6.16914851 L42.3767525,1.63671287 C42.0692376,1.17215842 42.1938515,0.546019802 42.6561287,0.236990099 C43.116396,-0.0740594059 43.7414752,0.0531881188 44.0489901,0.517742574 L47.055802,5.05017822 C47.8296139,6.21358416 48.2376238,7.57089109 48.2376238,8.97061386 L48.2376238,22.2851485 C48.2376238,25.0684356 45.9825149,27.3346535 43.2128713,27.3346535 Z"
                id="Shape"
              />
              <path
                d="M33.1633663,17.2356436 L15.0742574,17.2356436 C12.3046139,17.2356436 10.049505,14.9694257 10.049505,12.1861386 C10.049505,10.5157624 8.69684158,9.15643564 7.03465347,9.15643564 L2.00990099,9.15643564 C1.45516832,9.15643564 1.0049505,8.704 1.0049505,8.14653465 C1.0049505,7.58906931 1.45516832,7.13663366 2.00990099,7.13663366 L7.03465347,7.13663366 C9.80429703,7.13663366 12.0594059,9.40285149 12.0594059,12.1861386 C12.0594059,13.8565149 13.4120693,15.2158416 15.0742574,15.2158416 L33.1633663,15.2158416 C34.8255545,15.2158416 36.1782178,13.8565149 36.1782178,12.1861386 C36.1782178,9.40285149 38.4333267,7.13663366 41.2029703,7.13663366 L46.2277228,7.13663366 C46.7824554,7.13663366 47.2326733,7.58906931 47.2326733,8.14653465 C47.2326733,8.704 46.7824554,9.15643564 46.2277228,9.15643564 L41.2029703,9.15643564 C39.5407822,9.15643564 38.1881188,10.5157624 38.1881188,12.1861386 C38.1881188,14.9694257 35.9330099,17.2356436 33.1633663,17.2356436 Z"
                id="Shape"
              />
            </g>
          </g>
          <g
            id="done-doc"
            transform="translate(106.475248, 63.693069)"
            fill="#7ED321"
            fillRule="nonzero"
          >
            <path
              d="M7.76237624,0 C3.48318992,0 0,3.48318992 0,7.76237624 C0,12.0415626 3.48318992,15.5247525 7.76237624,15.5247525 C12.0415626,15.5247525 15.5247525,12.0415626 15.5247525,7.76237624 C15.5247525,3.48318992 12.0415626,0 7.76237624,0 Z M12.4960144,5.43930873 L6.85064986,11.0846733 C6.71233843,11.2215734 6.53168677,11.2907291 6.3510351,11.2907291 C6.17038344,11.2907291 5.98973177,11.2215734 5.85142034,11.0846733 L3.02873807,8.261991 C2.75352655,7.98677948 2.75352655,7.53938434 3.02873807,7.26417282 C3.30394959,6.9889613 3.75134473,6.9889613 4.02655626,7.26417282 L6.34962376,9.58724032 L11.4953735,4.44149055 C11.7705851,4.16627903 12.2179802,4.16627903 12.4931917,4.44149055 C12.7684032,4.71670207 12.7712259,5.16268587 12.4960144,5.43930873 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default Success;
