import React from 'react';
import useAnalytics from 'helpers/useAnalytics';
import useCustomNavLinkSettings from 'helpers/useCustomNavLinkSettings';
import NewTabDiv from 'components/atoms/NewTabDiv';
import NavButton from '../NavButton';

function CustomNavLink() {
  const analytics = useAnalytics();
  const { customNavLinkUrl, customNavLinkDisplayName } = useCustomNavLinkSettings();

  if (!customNavLinkUrl || !customNavLinkDisplayName) {
    return null;
  }

  return (
    <a
      href={customNavLinkUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        analytics.track(`Custom nav link ${customNavLinkDisplayName} Tab Clicked`, {
          category: 'Navigation',
        });
      }}
    >
      <NavButton>
        <NewTabDiv text={customNavLinkDisplayName} />
      </NavButton>
    </a>
  );
}

export default CustomNavLink;
