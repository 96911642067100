import React, { forwardRef } from 'react';
import typography from 'helpers/typography';

import TextField, {
  OutlinedTextFieldProps as MuiOutlinedTextFieldProps,
} from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

type SelectedOutlinedTextFieldProps = Omit<MuiOutlinedTextFieldProps, 'variant'>;
export interface OutlinedTextFieldProps extends SelectedOutlinedTextFieldProps {
  description?: string;
  labelVisuallyHidden?: boolean;
}

const OutlinedTextField = forwardRef((props: OutlinedTextFieldProps, ref: React.Ref<any>) => {
  const { label, description, labelVisuallyHidden, ...restOfProps } = props;
  const { classes } = useStyles();

  if (!label && !description) {
    return <TextField variant="outlined" {...restOfProps} />;
  }

  return (
    <div>
      <label>
        <span className={classes.labelContainer}>
          {label && !labelVisuallyHidden && <span className={classes.label}>{label}</span>}
          {label && labelVisuallyHidden && (
            <span className={classes.screenReaderOnly}>{label}</span>
          )}
          {description && <span className={classes.description}>{description}</span>}
        </span>
        <TextField
          variant="outlined"
          inputProps={{ 'aria-label': `${label}-input` }}
          ref={ref}
          {...restOfProps}
        />
      </label>
    </div>
  );
});

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  label: {
    ...typography(theme, 'h6'),
    display: 'block',
    textTransform: 'capitalize',
  },
  description: {
    ...typography(theme, 'body2'),
  },
  labelContainer: {
    display: 'block',
    marginBottom: theme.spacing(),
  },
  screenReaderOnly: {
    position: 'absolute',
    left: -10000,
    top: 'auto',
    width: 1,
    height: 1,
    overflow: 'hidden',
  },
}));

export default OutlinedTextField;
