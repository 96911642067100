import React, { useEffect } from 'react';
import { useDispatch, useData, useStatus } from 'resift';

// Hooks
import useSlideInfo from 'pages/Onboarding/helpers/useSlideInfo';
import useTerminology from 'helpers/useTerminology';

// Helpers
import _get from 'lodash/get';
import _noop from 'lodash/noop';

// Components
import Runner from '@sift/runner/Runner';
import AttributeAccordion from './AttributeAccordion';

// Fetch
import makeExamplePeopleFetch from 'pages/Onboarding/fetches/examplePeopleFetch';

interface Props {
  className?: string;
}

function Container(props: Props) {
  const { fieldKey, field } = useSlideInfo();
  const dispatch = useDispatch();
  const terminology = useTerminology();

  if (!fieldKey) {
    throw new Error('[AttributeAccordion] fieldKey not found');
  }

  const examplePeopleFetch = makeExamplePeopleFetch(fieldKey);

  useEffect(() => {
    dispatch(examplePeopleFetch());
  }, [dispatch, examplePeopleFetch]);

  const response = useData(examplePeopleFetch);
  const status = useStatus(examplePeopleFetch);

  const results = _get(response, ['results'], []);
  const people = results.map((result: any) => ({
    displayName: result.person.displayName,
    pictureUrl: result.person.pictureUrl,
    content: <Runner node={field} value={result.person} onChange={_noop} disableLinks noReadMore />,
  }));
  const term = terminology.pluralize(results.length);

  return <AttributeAccordion people={people} status={status} term={term} {...props} />;
}

export default Container;
