import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';
import skipperDefaultTheme from './skipperDefaultTheme';

// removes a warning. this is just to resolve a TS thing
export const Theme = undefined;

export default function createSkipperTheme(theme = {}) {
  return createTheme(deepmerge(skipperDefaultTheme, theme));
}
