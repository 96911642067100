import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import getBackgroundColor from 'styles/getBackgroundColor';

import _get from 'lodash/get';
import getTasks from 'pages/Notifications/fetches/getTasks';
import { useData } from 'resift';
import useAnalytics from 'helpers/useAnalytics';

import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import BellIcon from 'pages/Notifications/components/BellIcon';

function NotificationBell() {
  const { classes } = useStyles();
  const analytics = useAnalytics();

  const tasks = useData(getTasks);
  const unseenActivityItems = _get(tasks, 'other.results', []).filter((item: any) => !item.seenAt);
  const numberOfUnseenActivityItems = unseenActivityItems.length;
  const numberOfTasks = _get(tasks, 'required.results.length', 0);
  const notificationCountToShow = numberOfTasks + numberOfUnseenActivityItems;

  const handleClick = () => {
    const label = notificationCountToShow > 0 ? 'with count red dot' : 'no count red dot';
    analytics.track('Notification Bell Clicked', {
      category: 'Navigation',
      label,
    });
  };

  return (
    <NavLink to="/notifications" className={classes.root}>
      <IconButton className={classes.bellContainer} onClick={handleClick} size="large">
        <BellIcon />
        {notificationCountToShow > 0 && (
          <div className={classes.badge}>{notificationCountToShow}</div>
        )}
      </IconButton>
      <div className={classes.hoverBackground} id="hover-container"></div>
    </NavLink>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(1.25),
    position: 'relative',
    '&:hover': {
      '& #hover-container': {
        visibility: 'visible',
      },
    },
  },
  bellContainer: {
    display: 'flex',
    alignItems: 'end',
    padding: theme.spacing(0.25),
    marginRight: '9px !important',
    marginTop: '8px !important',
    '& > div > svg': {
      width: '36px !important',
      height: '36px !important',
    },
    zIndex: theme.zIndex.surface,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  hoverBackground: {
    position: 'absolute',
    backgroundColor: getBackgroundColor(theme),
    width: 48,
    height: 48,
    borderRadius: '50%',
    top: 3,
    left: -theme.spacing(0.25),
    visibility: 'hidden',
    transition: 'visibility 0.1s linear',
  },
  badge: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    fontSize: 12,
    padding: '1px 4px',
    fontWeight: 'bold',
    borderRadius: '50%',
    marginLeft: -theme.spacing(1),
    zIndex: theme.zIndex.tooltip,
    top: 0,
    right: 0,
    position: 'absolute',
  },
}));

export default NotificationBell;
