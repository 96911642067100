// Libs
import { useEffect, useRef } from 'react';
import { useDispatch } from 'resift';
import { connect } from 'react-redux';
import useRouter from '@sift/resift/useRouter';
import _get from 'lodash/get';
import hocify from 'hocify';
import compose from 'recompose/compose';

// Actions
import { toggleSupportDrawer } from 'store/support';
import {
  fetchAutocomplete,
  updateQuery,
  clearQuery,
  toggleBigSearch,
  closeBigSearch,
} from 'store/bigSearch';

import {
  fetchTeam,
  fetchRecentSearches,
  updateRecentSearches,
  deleteRecentSearches,
  deleteRecentSearch,
} from 'store/search';

import { updateSettings } from 'store/settings';

// Models
import { Status } from '@sift/resift/models/Status';

import Component from './BigSearch';

/**
 * this watches the pathname and closes big search when the pathname changes
 */
const withCloseEffect = hocify(() => {
  const { location } = useRouter();
  const dispatch = useDispatch();
  const first = useRef(true);

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }

    dispatch(closeBigSearch());
  }, [dispatch, location.key]);

  return null;
});

const mapDispatchToProps = {
  toggleBigSearch,
  fetchAutocomplete,
  fetchTeam,
  updateQuery,
  clearQuery,
  fetchRecentSearches,
  updateRecentSearches,
  deleteRecentSearches,
  deleteRecentSearch,
  updateSettings,
  toggleSupportDrawer,
};

const mapStateToProps = (state, ownProps) => {
  const initialStatus = new Status(
    state.bigSearch.fetchRecentSearchesStatus.value | state.bigSearch.fetchTeamStatus.value,
  );
  const team = state.bigSearch.team;

  let results = state.bigSearch.results;
  if (state.bigSearch.query === '' && initialStatus.value === 1) {
    if (Object.keys(state.bigSearch.recentSearches).length) {
      results = [state.bigSearch.recentSearches];
    } else {
      results = [];
    }

    if (team) {
      results.push(team);
    }
  }

  return {
    recentSearches: state.bigSearch.recentSearches,
    renderToLayer: ownProps.renderToLayer || false,
    suggestions: state.bigSearch.suggestions || [],
    team: team,
    user: state.authentication.person,
    query: state.bigSearch.query,
    results,
    status: initialStatus,
    searchStatus: state.bigSearch.searchStatus,
    open: state.bigSearch.open,
    fields: state.configurations.user.fields,
    supportEnabled: _get(state.client.settings, 'support.enabled', false),
  };
};

export default compose(withCloseEffect, connect(mapStateToProps, mapDispatchToProps))(Component);
