import React, { useContext, useState, useEffect } from 'react';
import { useStatus, useDispatch } from 'resift';
import useRouter from '@sift/resift/useRouter';
import useValidation from '@sift/runner/useValidation';
import useSlideInfo from 'pages/Onboarding/helpers/useSlideInfo';
import useLinkedIn from 'pages/Onboarding/helpers/useLinkedIn';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import RunnerContext from '@sift/runner/Context';
import useIsLastSlide from 'pages/Onboarding/helpers/useIsLastSlide';
import useWrap from 'helpers/useWrap';

import makeUpdateProfileFetch from 'pages/Profile/fetches/updateProfileFetch';
import makeUpdateCompletedSlidesFetch from 'pages/Onboarding/fetches/updateCompletedSlidesFetch';
import updateOnboardingStateFetch from 'pages/Onboarding/fetches/updateOnboardingStateFetch';

// Components
import { Redirect } from 'react-router';
import Runner from '@sift/runner/Runner';

import ObjectCollectionTemplate from './ObjectCollectionTemplate';

function Container() {
  const { history } = useRouter();
  const getHistory = useWrap(history);
  const dispatch = useDispatch();
  const linkedIn = useLinkedIn();
  const contextValue = useContext(RunnerContext);
  const { field, nextSlideLink, currentSlide } = useSlideInfo();
  const [okayToNavigate, setOkayToNavigate] = useState(false);

  if (!nextSlideLink) {
    throw new Error('[FieldTemplate Container] expected `nextSlideLink` to be truthy');
  }
  if (!contextValue) {
    throw new Error('[FieldTemplate Container] could not find runner context');
  }

  if (!currentSlide) {
    throw new Error('[FieldTemplate Container] expected `currentSlide` to be truthy');
  }

  const { root } = contextValue;
  const personId = _get(root, ['id']) as string;
  const objectKey = _get(field, ['objectKey'], '');
  const displayName = _get(field, ['displayName'], '');
  const linkedInValue = objectKey && _get(linkedIn, [objectKey]);

  const updateProfileFetch = makeUpdateProfileFetch(personId);
  const updateCompletedSlidesFetch = makeUpdateCompletedSlidesFetch();

  const initialValue = !_isEmpty(linkedInValue) ? linkedInValue : _get(root, [objectKey]);

  const [editValue, setEditValue] = useState(initialValue);
  const status = useStatus(updateProfileFetch);

  useEffect(() => {
    setEditValue(initialValue);
  }, [initialValue]);

  const description = _get(field, ['display', 'description'], '');
  const { isValid: isValidFromValidationLibrary } = useValidation(editValue, field);
  const isValid = isValidFromValidationLibrary && !_isEmpty(editValue);

  const isLastSlide = useIsLastSlide();

  const handleContinue = async () => {
    setOkayToNavigate(true);
    await dispatch(updateProfileFetch({ [objectKey]: editValue }));
    await dispatch(updateCompletedSlidesFetch(currentSlide, objectKey));
    if (isLastSlide) {
      await dispatch(updateOnboardingStateFetch('completed'));
    }
    const history = getHistory();
    history.push(nextSlideLink);
  };

  const handleSkip = async () => {
    await dispatch(updateCompletedSlidesFetch(currentSlide));
    if (isLastSlide) {
      await dispatch(updateOnboardingStateFetch('completed'));
    }
    const history = getHistory();
    setOkayToNavigate(true);
    history.push(nextSlideLink);
  };

  const fromLinkedIn = !_isEmpty(linkedInValue);
  const showNavigationConfirmation = !_isEqual(editValue, initialValue);
  const showSkipConfirmation = fromLinkedIn || showNavigationConfirmation;

  if (!field) {
    return <Redirect to={nextSlideLink} />;
  }

  return (
    <ObjectCollectionTemplate
      displayName={displayName}
      description={description}
      fromLinkedIn={fromLinkedIn}
      isValid={isValid}
      status={status}
      onContinue={handleContinue}
      onSkip={handleSkip}
      showSkipConfirmation={showSkipConfirmation}
      content={<Runner node={field} currentValue={editValue} onCurrentChange={setEditValue} />}
      isLastSlide={isLastSlide}
      showNavigationConfirmation={okayToNavigate ? false : showNavigationConfirmation}
    />
  );
}

export default Container;
