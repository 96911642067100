import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import SearchOverlayChildren from './SearchOverlayChildren';

function SearchOverlay(props) {
  const theme = useTheme();
  const originalTheme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: theme.palette.originalPrimary,
      secondary: theme.palette.originalSecondary,
    },
  };

  /**
   * (notes - pearl) All components within router (components/Router)
   * uses CoreLayout when not in MSTeams.
   * CoreLayout uses ReadableThemedComponent to ensure our components look good against white background,
   * because most of our pages have a white background. Except:
   * BigSearch needs original palettes.
   * There are two places where the BigSearch (components/BigSearch) is being used.
   * One in pages/Landing, the other is here in SearchOverlay.
   * The BigSearch component itself uses ThemeOverrideComponent to force the original theme to be used. This works great in pages/Landing. But here in search overlay, the ThemeOverrideComponent in BigSearch is being overriden by ReadableThemedComponent.
   * I think this has something to do with our memo/component tree, which I can look further into. For now, I am adding a ThemeProvider with original palettes for SearchOverlay.
   * This might not be the most elegant solution, but it only impacts this component and won't cause other ripple effects.
   */
  return (
    <ThemeProvider theme={originalTheme}>
      <SearchOverlayChildren {...props} />
    </ThemeProvider>
  );
}

SearchOverlay.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default SearchOverlay;
