import React, { useState } from 'react';
import typography from 'helpers/typography';

// Components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SkipTranslate from 'components/atoms/SkipTranslate';

// Helpers
import classNames from 'classnames';
import useAnalytics from 'helpers/useAnalytics';
import analyticsOptions from 'helpers/analyticsOptions';

// Styles
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
  },
  name: {
    ...typography(theme, 'body1'),
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: theme.spacing(4),

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  expanded: {
    color: theme.palette.primary.main,
  },
  panelDetails: {
    '& > *': {
      maxWidth: '100%',
    },
  },
  // don't delete
  AccordionSummary: {},
}));

interface Props {
  person: {
    pictureUrl: string;
    displayName: string;
    content: React.ReactNode;
  };
  autoOpen: boolean;
}

function AccordionItem({ person, autoOpen }: Props) {
  const { classes } = useStyles();
  const analytics = useAnalytics();
  const [expanded, setExpanded] = useState(autoOpen);

  function handleClick(e: any, expanded: boolean) {
    if (expanded) {
      analytics.track(analyticsOptions.action.CLICKED, {
        feature: analyticsOptions.feature.USER_ONBOARDING,
        action: 'Example user data viewed',
      });
    }

    setExpanded(expanded);
  }

  return (
    <Accordion className={classes.root} expanded={expanded} onChange={handleClick}>
      <AccordionSummary
        className="expansion-panel-summary"
        expandIcon={<ExpandMoreIcon />}
        classes={{
          content: classes.content,
        }}
      >
        <Avatar src={person.pictureUrl} />
        <SkipTranslate
          className={classNames({ [classes.name]: true, [classes.expanded]: expanded })}
        >
          {person.displayName}
        </SkipTranslate>
      </AccordionSummary>
      <AccordionDetails className={classes.panelDetails}>{person.content}</AccordionDetails>
    </Accordion>
  );
}

export default AccordionItem;
