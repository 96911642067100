import React from 'react';
import readableColorForWhite from 'styles/skipper/readableColorForWhite';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import Search from '@mui/icons-material/Search';
import SubdomainConditional from 'components/SubdomainConditional';

interface Props {
  searchBarClass: string;
  query?: string;
  onClick: () => void;
  defaultPlaceholderText: string;
}

function NavSearchInput({ searchBarClass, query, onClick, defaultPlaceholderText }: Props) {
  const { classes } = useStyles();

  return (
    <div className={classes.searchBarContainer}>
      <div className={searchBarClass} onClick={onClick}>
        <Search className={classes.searchIcon} />
        <div className={classes.placeholderText}>
          <SubdomainConditional
            subdomainMap={{
              default: query ? query : defaultPlaceholderText,
              meridian: query || 'Search by name, job title or team',
            }}
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => {
  const searchTextColor = readableColorForWhite(theme.palette.primary.main);

  return {
    root: {},
    searchBarContainer: {
      flex: '1 1 auto',
      minWidth: 0,
    },
    searchIcon: {
      position: 'absolute',
      left: 11,
      color: searchTextColor,
    },
    topNavIcon: {
      marginRight: theme.spacing(1),
    },
    placeholderText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});

export default NavSearchInput;
