/**
 * Notes (Pearl): This unfortunately has to exist because svg clip path is not working in material
 * ui drawer in appBar/Drawer component in chrome.
 */

import React, { useRef, useState, useEffect } from 'react';
import bowser from 'bowser';
import urlParse from 'url-parse';
import queryString from 'query-string';
import useInterval from 'helpers/useInterval';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    root: {
      flex: '0 0 auto',
      width: 40,
      height: 40,
      borderRadius: 99999,
      overflow: 'hidden',
      '@media print': {
        WebkitMaskImage: '-webkit-radial-gradient(white, black)',
      },
      ...(bowser.safari
        ? {
            overflow: 'hidden',
          }
        : undefined),
    },
    img: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      // Handle non-square image. The property isn't supported by IE 11.
      objectFit: 'cover',
      // IE fix
      ...(bowser.msie
        ? {
            height: 'auto',
            top: '50%',
            transform: 'translateY(-50%)',
            position: 'relative',
          }
        : undefined),
    },
  };
});

interface Props {
  className?: string;
  src: string;
  alt: string;
  ignoreOfficialPhotos?: boolean;
}

function AvatarOld({ className, src: _src, alt }: Props) {
  const { classes } = useStyles();
  // ensures it's always a string
  const src = _src || '';

  const rootRef = useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [hadError, setHadError] = useState(false);

  const updateSizing = () => {
    const rootEl = rootRef.current;
    if (!rootEl) {
      return;
    }

    const rect = rootEl.getBoundingClientRect();
    if (rect.width > width && rect.height > height) {
      setWidth(rect.width);
      setHeight(rect.height);
    }
  };

  useInterval(updateSizing, 1000);
  useEffect(updateSizing, []);

  const sizedSrc = (() => {
    if (!src.includes('people-media') && !src.includes('profileImages')) {
      return src;
    }

    if (hadError) {
      return src;
    }

    if (!width || !height) {
      return '';
    }

    const url = urlParse(src);
    url.set(
      'query',
      queryString.stringify({
        // @ts-ignore
        ...queryString.parse(url.query),
        width: width * 2,
        height: height * 2,
      }),
    );

    return url.toString();
  })();

  const handleError = () => {
    setHadError(true);
  };

  return (
    <div
      className={classNames(classes.root, className)}
      key={sizedSrc}
      role="img"
      aria-label={alt}
      ref={rootRef}
    >
      {sizedSrc && (
        <img
          className={classes.img}
          src={sizedSrc}
          onError={handleError}
          // alt is from above
          alt=""
        />
      )}
    </div>
  );
}

export default AvatarOld;
