import React, { useContext } from 'react';
import SiftLogo from 'components/msTeams/embed/icons/SiftLogo';

import MsTeamsMainTheme, { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';
import { Flex } from '@fluentui/react-northstar';

interface Props {
  children: React.ReactNode;
}

function InfoPageLayout({ children }: Props) {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);

  return (
    <MsTeamsMainTheme>
      <Flex
        as="main"
        column
        hAlign="center"
        // @ts-ignore
        style={styles.root}
      >
        <SiftLogo style={styles.section} />
        <Flex style={styles.section} column>
          {children}
        </Flex>
      </Flex>
    </MsTeamsMainTheme>
  );
}

const useStyles = (theme: Theme) => ({
  root: {
    textAlign: 'center',
    maxWidth: 500,
  },
  section: {
    marginTop: theme.siteVariables.spacing.unit * 4,
    marginBottom: theme.siteVariables.spacing.unit * 4,
  },
});

export default InfoPageLayout;
