import React, { useState } from 'react';
import { useStatus, useDispatch } from 'resift';
import _get from 'lodash/get';
import useRouter from '@sift/resift/useRouter';
import makeUpdateLinkedInFetch from 'pages/Onboarding/fetches/updateLinkedInFetch';
import useSlideInfo from 'pages/Onboarding/helpers/useSlideInfo';
import useAnalytics from 'helpers/useAnalytics';
import analyticsOptions from 'helpers/analyticsOptions';

import LinkedInUploader from './LinkedInUploader';

function Container() {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { history } = useRouter();
  const [file, setFile] = useState<File | null>(null);

  const { nextSlideLink } = useSlideInfo();
  const updateLinkedInFetch = makeUpdateLinkedInFetch();
  const status = useStatus(updateLinkedInFetch);

  const handleFile = (file: File) => {
    setFile(file);
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const handleContinue = async () => {
    if (!file) {
      return;
    }

    await dispatch(updateLinkedInFetch(file));

    if (!nextSlideLink) {
      return;
    }

    analytics.track(analyticsOptions.action.CLICKED, {
      feature: analyticsOptions.feature.USER_ONBOARDING,
      action: 'User uploaded LinkedIn profile',
    });

    history.push(nextSlideLink);
  };

  const fileName = _get(file, ['name'], '');

  return (
    <LinkedInUploader
      status={status}
      onAcceptFile={handleFile}
      fileName={fileName}
      onDeleteFile={handleDeleteFile}
      onContinue={handleContinue}
    />
  );
}

export default Container;
