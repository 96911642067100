import createReducer from '@sift/resift/createReducer';
import * as Types from 'pages/OrgChart/typings';

// ------------------------------------
// Constants
// ------------------------------------
export const SET_PREV_HIGHLIGHT_COLORS_LOOKUP_ARRAY = 'SET_PREV_HIGHLIGHT_COLORS_LOOKUP_ARRAY';
export const SET_EXCLUDED_NODE_IDS = 'SET_EXCLUDED_NODE_IDS';

// ------------------------------------
// Actions
// ------------------------------------
export const setPrevHighlightColorsLookupArray = (
  prevHighlightColorsLookupArray: Types.NodeGroupWithColor[],
) => {
  return {
    type: SET_PREV_HIGHLIGHT_COLORS_LOOKUP_ARRAY,
    identifier: 'orgChart',
    payload: {
      prevHighlightColorsLookupArray,
    },
  };
};

export const setExcludedNodeIds = (excludedNodeIds: string[]) => {
  return {
    type: SET_EXCLUDED_NODE_IDS,
    identifier: 'orgChart',
    payload: {
      excludedNodeIds,
    },
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_PREV_HIGHLIGHT_COLORS_LOOKUP_ARRAY]: (state: any, action: any) => {
    return {
      ...state,
      prevHighlightColorsLookupArray: action.payload.prevHighlightColorsLookupArray,
    };
  },
  [SET_EXCLUDED_NODE_IDS]: (state: any, action: any) => {
    return {
      ...state,
      excludedNodeIds: action.payload.excludedNodeIds,
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  prevHighlightColorsLookupArray: null,
  excludedNodeIds: [],
};

export default createReducer(initialState, ACTION_HANDLERS);
