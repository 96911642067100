import { defineFetch } from 'resift';
import _pickBy from 'lodash/pickBy';

export default defineFetch({
  displayName: 'update profile fetch',
  share: {
    namespace: 'person',
    merge: (previous, current) => {
      if (!previous) {
        return current;
      }

      const additionalProperties = _pickBy(previous, (v, k) => k.startsWith('_'));

      return { ...current, ...additionalProperties };
    },
  },
  make: (personId: string) => ({
    request: (partialPerson: any) => ({ http }: any) =>
      http({
        method: 'PUT',
        route: `/people/${personId}`,
        data: partialPerson,
      }),
  }),
});
