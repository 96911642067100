import createReducer from '@sift/resift/createReducer';
import moment from 'moment-timezone';
import getTimezoneDisplayNames from 'components/TimezoneAndWorkHours/helpers/getTimezoneDisplayNames';

const PROFILE_SET_PICTURE_INDEX = 'PROFILE_SET_PICTURE_INDEX';
const PROFILE_SET_EDITING_PHOTOS = 'PROFILE_SET_EDITING_PHOTOS';
const TOGGLE_GENERAL_SETTINGS_MODAL = 'TOGGLE_GENERAL_SETTINGS_MODAL';
const TOGGLE_OPEN_VIEW_PROFILE_PICTURES_MODAL = 'TOGGLE_OPEN_VIEW_PROFILE_PICTURES_MODAL';
const TOGGLE_AUDIO_EDITOR_MODAL = 'TOGGLE_AUDIO_EDITOR_MODAL';
const EDIT_AUDIO_EDITOR = 'EDIT_AUDIO_EDITOR';

interface AudioEditor {
  base64: any | 'DELETE' | undefined;
  url?: string;
}

export interface ProfileState {
  pictureUrlIndex: number;
  editingPhotos: boolean; // this is for BOTH the people profile and entity profiles
  generalSettingsModalOpen: boolean;
  viewProfilePicturesModal: boolean;
  audioEditorModalOpen: boolean;
  timezoneDisplayNames: any;
  audioEditor?: AudioEditor;
}

export function setPictureUrlIndex(pictureUrlIndex: number) {
  return {
    type: PROFILE_SET_PICTURE_INDEX,
    payload: pictureUrlIndex,
  };
}

export function setEditingPhotos(editingPhotos: boolean) {
  return {
    type: PROFILE_SET_EDITING_PHOTOS,
    payload: editingPhotos,
  };
}

export const toggleOpenGeneralSettingsModal = () => {
  return {
    type: TOGGLE_GENERAL_SETTINGS_MODAL,
  };
};

export const toggleOpenViewProfilePicturesModal = () => {
  return {
    type: TOGGLE_OPEN_VIEW_PROFILE_PICTURES_MODAL,
  };
};

export const toggleAudioEditorModal = () => {
  return {
    type: TOGGLE_AUDIO_EDITOR_MODAL,
  };
};

export const editAudioEditor = (audioEditor?: AudioEditor) => {
  return {
    type: EDIT_AUDIO_EDITOR,
    payload: {
      audioEditor,
    },
  };
};

const actionHandlers = {
  [PROFILE_SET_PICTURE_INDEX]: (
    state: ProfileState,
    action: ReturnType<typeof setPictureUrlIndex>,
  ) => ({
    ...state,
    pictureUrlIndex: action.payload,
  }),
  [PROFILE_SET_EDITING_PHOTOS]: (
    state: ProfileState,
    action: ReturnType<typeof setEditingPhotos>,
  ) => ({
    ...state,
    editingPhotos: action.payload,
  }),
  [TOGGLE_GENERAL_SETTINGS_MODAL]: (state: any) => {
    return {
      ...state,
      generalSettingsModalOpen: !state.generalSettingsModalOpen,
    };
  },
  [TOGGLE_OPEN_VIEW_PROFILE_PICTURES_MODAL]: (state: any) => {
    return {
      ...state,
      viewProfilePicturesModal: !state.viewProfilePicturesModal,
    };
  },
  [TOGGLE_AUDIO_EDITOR_MODAL]: (state: any) => {
    return {
      ...state,
      audioEditorModalOpen: !state.audioEditorModalOpen,
    };
  },
  [EDIT_AUDIO_EDITOR]: (state: any, action: any) => {
    return {
      ...state,
      audioEditor: action.payload.audioEditor,
    };
  },
};

const initialState: ProfileState = {
  pictureUrlIndex: 0,
  editingPhotos: false,
  generalSettingsModalOpen: false,
  viewProfilePicturesModal: false,
  audioEditorModalOpen: false,
  audioEditor: undefined,
  timezoneDisplayNames: getTimezoneDisplayNames(moment.tz.guess()),
};

export default createReducer(initialState, actionHandlers);
