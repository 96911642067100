import PropTypes from 'prop-types';
import getSubdomain from 'helpers/getSubdomain';

// the subdomain map values can either be a function or a value. this will evaluate it if it's a
// function
function evaluateSubdomainMapValue(element: () => React.ReactNode | React.ReactNode) {
  if (typeof element === 'function') {
    return element();
  }

  return element;
}

function getTestSubdomain() {
  const subdomain = getSubdomain();
  if (!subdomain) {
    return undefined;
  }

  const match = /test-(.*)/i.exec(subdomain);
  if (!match) {
    return undefined;
  }

  return match[1].toLowerCase();
}

interface Props {
  subdomainMap: {
    [key: string]: React.ReactNode | (() => React.ReactNode);
  };
}

/**
 * very simple component that handles subdomain conditionals with components
 */
function SubdomainConditional({ subdomainMap }: Props): React.ReactElement | null {
  const subdomain = getSubdomain();
  const subdomainOnTest = getTestSubdomain();

  if (process.env.NODE_ENV !== 'production') {
    if (subdomainMap.default === undefined) {
      throw new Error('subdomainMap must have a `default` key');
    }
  }

  const maybeElement = (() => {
    const real = subdomainMap[subdomain as any];
    const test = subdomainMap[subdomainOnTest as any];
    if (real !== undefined) return real;
    return test;
  })();

  // the sub-domain conditional could be intentionally set to `null`
  // so we'll explicitly check if `undefined`
  if (maybeElement === undefined) {
    return evaluateSubdomainMapValue(subdomainMap.default as any) as React.ReactElement | null;
  }

  return evaluateSubdomainMapValue(maybeElement as any) as React.ReactElement | null;
}

SubdomainConditional.propTypes = {
  subdomainMap: PropTypes.object.isRequired,
};

export default SubdomainConditional;
