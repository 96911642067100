import React from 'react';
import pluralize from 'pluralize';
import stringifyLocationProps from 'helpers/stringifyLocationProps';
import useLocationProps from 'helpers/useLocationProps';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import useRouter from '@sift/resift/useRouter';

// UI
import Item from './Item';
import ItemInfo from './ItemInfo';
import { Link } from 'react-router-dom';
import Avatar from 'components/atoms/Avatar';
import AddressBookIcon from 'components/Icons/AddressBook';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  value: { name: string; id: string; pictureUrl: string; peopleCount: number };
  field: string;
  query: string;
  analyticsLabel: string;
}

function EntityItem({ value, field, query, analyticsLabel }: Props) {
  const { classes } = useStyles();
  const locationProps = useLocationProps('user');
  const { history } = useRouter();
  const isFieldKeyQuery = field === 'q' || field === 'query';
  const fieldKey = `${field}.id`;

  const handleViewMembersInDirectory = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const directoryPathname = isFieldKeyQuery
      ? `/search?q=${value.name}`
      : `/search?${stringifyLocationProps({
          ...locationProps,
          filters: [{ fieldKey, value: value.id }],
        })}`;
    history.push(directoryPathname);
  };

  return (
    <Link to={`/entities/${value.id}`} className={classes.linkRoot}>
      <Item
        rows={2}
        recentSearchObject={{
          id: value.id,
          displayName: value.name,
          type: 'entity',
        }}
        iconRecentSearchObject={{
          displayName: value.name,
          query: {
            [fieldKey]: value.id,
            page: 1,
          },
          type: 'search',
        }}
        analyticsCardObject={{
          event: 'Page Opened in Big Search',
          properties: {
            category: 'Big Search',
            label: analyticsLabel,
          },
        }}
        analyticsIconObject={{
          event: 'Page Directory Opened',
          properties: {
            category: 'Big Search',
            label: analyticsLabel,
          },
        }}
        icon={
          field && value.peopleCount > 0
            ? (onClick: () => void) => (
                <div onClick={onClick}>
                  <Tooltip title="View Members" placement="top">
                    <IconButton
                      aria-label="ViewMembers"
                      onClick={handleViewMembersInDirectory}
                      size="large"
                    >
                      <AddressBookIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                </div>
              )
            : null
        }
      >
        <Avatar
          pictureUrl={value.pictureUrl}
          alt={`${value.name}-avatar`}
          size={56}
          isEntity={true}
        />
        <ItemInfo
          title={value.name}
          subtitle={field ? `${value.peopleCount} ${pluralize('person', value.peopleCount)}` : null}
          query={query}
        />
      </Item>
    </Link>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  linkRoot: {
    width: '100%',
  },
  icon: {
    fill: theme.palette.primary.contrastText,
  },
}));

export default EntityItem;
