import React, { useEffect } from 'react';
import typography from 'helpers/typography';
import { isLoading } from 'resift';
import { useDropzone } from 'react-dropzone';
import usePageTrackingEffect from 'components/Page/usePageTrackingEffect';

// Components
import ContrastButton from 'pages/Onboarding/components/ContrastButton';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import AppBarPortal from 'pages/Onboarding/components/AppBarPortal';
import Paper from '@mui/material/Paper';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CheckMarkIcon from 'components/Icons/CheckMarkIcon';
import { useSnackbar } from 'notistack';

// Helpers
import classNames from 'classnames';
import { transparentize } from 'polished';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import brandColors from 'styles/skipper/brandColors';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flex: '1 1 auto',
      width: 1280,
      maxWidth: '100%',
      marginTop: theme.spacing(10),
      marginBottom: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(0, 4),

      alignSelf: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6),
        justifyContent: 'center',
      },
    },
    copyContainer: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(8, 0),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(4),
        width: 600,
        maxWidth: '100%',
      },
      '& > *': {
        flex: '0 0 auto',
      },
    },
    title: {
      ...typography(theme, 'h4'),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    subtitle: {
      ...typography(theme, 'body1'),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    paper: {
      width: '100%',
      minHeight: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(8),
    },
    paperAccept: {
      backgroundColor: transparentize(0.7, brandColors.tertiaryPalette.lime),
    },
    paperReject: {
      backgroundColor: transparentize(0.7, theme.palette.error.main),
    },
    dropCopy: {
      ...typography(theme, 'body1'),
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(2),
    },
    or: {
      ...typography(theme, 'body1'),
      marginBottom: theme.spacing(2),
    },
    fileUploadDisplay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    fileDisplay: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(0, 4),
    },
    fileDisplayContinue: {
      marginTop: theme.spacing(2),
    },
    iconAndName: {
      display: 'flex',
      alignItems: 'center',
    },
    fileName: {
      ...typography(theme, 'body1'),
      fontWeight: theme.typography.fontWeightBold,
      paddingLeft: theme.spacing(1),
    },
    imageContainer: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        // TODO: we should probably still show the illustration
        display: 'none',
      },
    },
    waitButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    continueButton: {
      marginLeft: theme.spacing(2),
    },
    checkMarkIcon: {
      width: 42,
      height: 42,
    },
    snackbarRoot: {
      border: '1px solid red',
    },
    buttonDisabled: {
      color: `${theme.palette.grey['300']} !important`,
      backgroundColor: `${theme.palette.grey['600']} !important`,
    },
  };
});

interface Props {
  onAcceptFile: (file: File) => void;
  onDeleteFile: () => void;
  fileName: string;
  status: number;
  onContinue: () => void;
}

function LinkedInUploader({ onAcceptFile, fileName, status, onContinue, onDeleteFile }: Props) {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  usePageTrackingEffect('Upload LinkedIn Resume', 'New User Onboarding');

  useEffect(() => {
    if (!fileName) {
      return;
    }

    enqueueSnackbar('Great! Your resume was successfully imported.', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      style: {
        marginTop: 80, // 80 is the size of the app bar
        width: '100%',
      },
    });
  }, [fileName, enqueueSnackbar]);

  const handleDrop = (acceptFiles: File[]) => {
    const firstFile = acceptFiles[0];

    if (!firstFile) {
      return;
    }

    onAcceptFile(firstFile);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop: handleDrop,
    accept: 'application/pdf',
  });

  const { ref, ...rootProps } = getRootProps();

  return (
    <>
      <AppBarPortal>
        {/*
      // @ts-ignore */}
        <ContrastButton component={Link} to="/onboarding/linkedin">
          Back
        </ContrastButton>
        <Button
          disabled={!fileName.length || isLoading(status)}
          onClick={onContinue}
          color="secondary"
          variant="contained"
          className={classes.continueButton}
          classes={{
            disabled: classes.buttonDisabled,
          }}
        >
          Continue
        </Button>
      </AppBarPortal>
      <div className={classes.root}>
        <div className={classes.copyContainer}>
          <div className={classes.title}>Let’s get your LinkedIn info in</div>
          <div className={classes.subtitle}>
            <p>
              To download your resume, go to your LinkedIn profile and click
              <br />
              <strong>"More…"</strong>, then click <strong>"Save to PDF"</strong>.
            </p>
            <p>After doing so, upload your resume file below!</p>
          </div>
          <Paper
            ref={ref}
            elevation={2}
            {...rootProps}
            className={classNames({
              [classes.paper]: true,
              [classes.paperAccept]: isDragAccept,
              [classes.paperReject]: isDragReject,
            })}
          >
            {!fileName.length && <input {...getInputProps()} />}
            {fileName.length ? (
              <div className={classes.fileUploadDisplay}>
                <div className={classes.fileDisplay}>
                  <div className={classes.iconAndName}>
                    <CheckMarkIcon className={classes.checkMarkIcon} />
                    <div className={classes.fileName}>{fileName}</div>
                  </div>
                  <IconButton aria-label="Delete" onClick={onDeleteFile} size="large">
                    <Delete />
                  </IconButton>
                </div>
                <div className={classes.fileDisplayContinue}>
                  <Button
                    disabled={!fileName.length || isLoading(status)}
                    onClick={onContinue}
                    color="secondary"
                    variant="contained"
                    className={classes.continueButton}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className={classes.dropCopy}>Drop LinkedIn Resume</div>
                <div className={classes.or}>or</div>
                <Button color="secondary" variant="contained">
                  Upload
                </Button>
              </>
            )}
          </Paper>
        </div>
        <div
          className={classes.imageContainer}
          style={{
            background:
              'url(https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/onboarding_3.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
          }}
        />
      </div>
    </>
  );
}

export default LinkedInUploader;
