import { Theme } from '@mui/material/styles';
import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';

export default function ensurePictureUrl(src: string, theme: Theme) {
  if (!src || src.includes('/placeholders')) {
    return theme.pictureUrl || createPictureUrlFromColor(theme.palette.primary.dark);
  }

  return src;
}
