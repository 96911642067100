import React from 'react';
import typography from 'helpers/typography';

// Components
import AppBarPortal from 'pages/Onboarding/components/AppBarPortal';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import CheckMarkIcon from 'components/Icons/CheckMarkIcon';
import WarningIcon from 'components/Icons/WarningIcon';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: 1280,
    maxWidth: '100%',
    marginTop: theme.spacing(10),
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 4),
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(6),
    },
  },
  content: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  backContainer: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  copyContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  title: {
    ...typography(theme, 'h4'),
  },
  subtitle: {
    ...typography(theme, 'body1'),
    marginBottom: theme.spacing(4),
  },
  paper: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  linkedinField: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 0),
  },
  fieldTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconAndField: {
    display: 'flex',
  },
  field: {
    ...typography(theme, 'body1'),
    lineHeight: theme.spacing(3),
    paddingLeft: theme.spacing(1),
  },
  warning: {
    ...typography(theme, 'caption'),
    color: theme.palette.grey[700],
  },
  imageContainer: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

interface Props {
  info: Array<{ displayName: string; status: 'success' | 'warning' }>;
  onContinue: () => void;
}

function LinkedInResults({ info, onContinue }: Props) {
  const { classes } = useStyles();

  return (
    <>
      <AppBarPortal>
        <Button color="secondary" variant="contained" onClick={onContinue}>
          Continue
        </Button>
      </AppBarPortal>
      <div className={classes.root}>
        <div className={classes.backContainer}>
          <Button
            // @ts-ignore
            component={Link}
            to="/onboarding/linkedin-uploader"
            color="secondary"
          >
            Back
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.copyContainer}>
            <div className={classes.title}>Great we added your LinkedIn info</div>
            <div className={classes.subtitle}>
              You have the opportunity to make changes and add additional info in the next steps.
            </div>
            <Paper elevation={2} className={classes.paper}>
              {info.map(item => (
                <div className={classes.linkedinField}>
                  <div className={classes.iconAndField}>
                    {item.status === 'success' ? <CheckMarkIcon /> : <WarningIcon />}
                    <div className={classes.fieldTextContainer}>
                      <div className={classes.field}>
                        {item.displayName}
                        {item.status === 'warning' && (
                          <div className={classes.warning}>Need additional info</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Paper>
          </div>

          <div
            className={classes.imageContainer}
            style={{
              background:
                'url(https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/onboarding_4.png)',
              backgroundSize: 'contain',
              backgroundPosition: 'center top',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </div>
      </div>
    </>
  );
}

export default LinkedInResults;
