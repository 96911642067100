import React from 'react';
import { useTheme, Theme } from '@mui/material';
import createTheme from './createSkipperTheme';
import memoizeLast from '@sift/resift/memoizeLast';
import createReadablePalette from 'styles/skipper/createReadablePalette';

import { ThemeProvider } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
  color: string;
}

function ReadableThemedComponent({ children, color }: Props) {
  const theme = useTheme();

  const _getOrCalculateFixedTheme = memoizeLast((theme: Theme) => {
    return createTheme({ ...theme, palette: createReadablePalette(theme.palette, color) });
  });

  const fixedTheme = _getOrCalculateFixedTheme(theme);

  return <ThemeProvider theme={fixedTheme}>{children}</ThemeProvider>;
}

export default ReadableThemedComponent;
