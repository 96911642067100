import { useSelector } from 'react-redux';
import useExploreEnabled from 'helpers/useExploreEnabled';
import useSupportEnabled from 'helpers/useSupportEnabled';
import useSettingsEnabled from 'helpers/useSettingsEnabled';
import useCustomNavLinkSettings from 'helpers/useCustomNavLinkSettings';
import useIsAdmin from 'helpers/useIsAdmin';
import getHostDomain from 'helpers/getHostDomain';
import { SEARCH_PATH, ENTITY_TYPE_USER } from 'components/search/consts';
import * as Types from 'components/Navigation/typings';

interface Args {
  personId: string;
  orgChartEnabled: boolean;
  hasProfile: boolean;
  onToggleSupportDrawer: () => void;
}

function useNavDropdownRoutes({
  personId,
  orgChartEnabled,
  onToggleSupportDrawer,
}: Args): {
  adminRoute?: Types.Route;
  navBarRoutes: Types.Routes;
  accountRoutes: Types.Routes;
  additionalRoutes: Types.Routes;
  msTeamsRoutes: Types.Routes;
} {
  const hostDomain = getHostDomain();
  const exploreEnabled = useExploreEnabled();
  const supportEnabled = useSupportEnabled();
  const settingsEnabled = useSettingsEnabled();
  const {
    customNavLinkEnabled,
    customNavLinkDisplayName,
    customNavLinkUrl,
  } = useCustomNavLinkSettings();
  const isAdmin = useIsAdmin();
  const client = useSelector((state: any) => state.client);
  const additionalRouteItems: Array<{ displayName: string; url: string }> =
    client.settings.secondaryNavigationItems;

  const adminRoute = isAdmin && {
    name: 'Admin Dashboard',
    key: 'admin-dashboard',
    externalUrl: `http://${client.subdomain}.${hostDomain}.com/admin`,
  };

  const settingsRoute = settingsEnabled
    ? {
        name: 'Settings',
        key: 'settings-page',
        url: '/settings',
      }
    : null;

  const supportRoute = supportEnabled
    ? {
        name: 'Support',
        key: 'support',
        onClick: () => {
          onToggleSupportDrawer();
        },
      }
    : null;

  const directorySearchRoute = {
    name: 'Directory',
    key: 'company-directory',
    url: `${SEARCH_PATH}/${ENTITY_TYPE_USER}/`,
  };

  const exploreRoute = exploreEnabled
    ? {
        name: 'Analytics',
        key: 'analytics',
        url: `/search/${ENTITY_TYPE_USER}/explore`,
      }
    : null;

  const listRoute = {
    name: 'Lists',
    key: 'lists',
    url: '/lists',
  };

  const orgChartRoute = orgChartEnabled
    ? {
        name: 'Org Chart',
        key: 'org-chart',
        url: '/orgchart',
      }
    : null;

  const customNavRoute = customNavLinkEnabled
    ? {
        name: customNavLinkDisplayName,
        key: 'client-custom-nav-link',
        externalUrl: customNavLinkUrl,
      }
    : null;

  const navBarRoutes = [
    directorySearchRoute,
    exploreRoute,
    listRoute,
    orgChartRoute,
    customNavRoute,
  ].filter(route => !!route) as Types.Routes;

  const accountRoutes = [settingsRoute, supportRoute].filter(route => !!route) as Types.Routes;

  const additionalRoutes = additionalRouteItems.map(item => {
    const { displayName, url } = item;
    const routeProps = {
      name: displayName,
      key: displayName
        .toLowerCase()
        .replace(/[^a-z ]/g, '')
        .split(' ')
        .join('-'),
    };

    if (url.includes('http')) {
      return {
        ...routeProps,
        externalUrl: url,
      };
    }

    return {
      ...routeProps,
      url,
    };
  });

  const msTeamsRoutes = [customNavRoute, settingsRoute, supportRoute].filter(
    route => !!route,
  ) as Types.Routes;

  return {
    adminRoute,
    navBarRoutes,
    accountRoutes,
    additionalRoutes,
    msTeamsRoutes,
  };
}

export default useNavDropdownRoutes;
