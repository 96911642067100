import React from 'react';
import PropTypes from 'prop-types';

// UI
import { Link } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import classnames from 'classnames';

/**
 * Need to link to an Entity thing? Use m3!
 *
 * This component is wrapping `react-router-dom`s `Link` component. Checkout
 * its API for additional props that are available.
 */
function EntityLink({ to, entity, classes: classesFromProps, className, children, ...otherProps }) {
  const { classes } = useStyles(undefined, { props: { classes: classesFromProps } });

  if (!to) {
    to = `/entities/${entity.id}`;
  }

  return (
    <Link to={to} className={classnames(classes.root, className)} {...otherProps}>
      {children}
    </Link>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      color: theme.palette.textColor,
      cursor: 'pointer',
      textDecoration: 'none !important',
      '&:hover': {
        color: theme.palette.accent1Color,
      },
    },
  };
});

EntityLink.propTypes = {
  entity: PropTypes.any.isRequired,
  classes: PropTypes.object,
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default EntityLink;
