import { useEffect } from 'react';

interface Params {
  loaded: boolean;
}

export default function useRemoveLoaderEffect({ loaded }: Params) {
  useEffect(() => {
    if (!loaded) {
      return;
    }

    const loadingContainer = document.querySelector('.loading_container');
    if (!loadingContainer) {
      return;
    }

    loadingContainer.classList.add('loaded');
    setTimeout(() => {
      if (!loadingContainer.parentNode) {
        return;
      }

      loadingContainer.parentNode.removeChild(loadingContainer);
    }, 200);
  }, [loaded]);
}
