import * as Sentry from '@sentry/browser';
import { createDataService, createHttpService } from 'resift';
import { unauthorizedHandler } from 'store/dataServiceActionHandlers';
import urlParse from 'url-parse';
import cookie from 'js-cookie';
import config from '../config';

function getAuthToken(prefix) {
  const { hostname } = urlParse(prefix || window.location.href);
  const subdomain = hostname.split('.')[0];
  const token = cookie.get(`auth_token_${subdomain}`) || cookie.get('auth_token');

  return token;
}

const http = createHttpService({
  getHeaders: () => ({
    Authorization: getAuthToken() ? `Bearer ${getAuthToken()}` : undefined,
    'X-Sift-App': `Directory-Web/${config.APP_VERSION} Browser`,
  }),
  prefix: `/api`,
});

export default createDataService({
  services: { http },
  onError: error => {
    if (error.status === 401) {
      unauthorizedHandler();
    } else {
      if (process.env.NODE_ENV !== 'production') {
        throw error;
      } else {
        Sentry.captureException(error);
      }
    }
  },
});
