// Component
import Support from './Support';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import {
  toggleSupportDrawer,
  createSupportMessage,
  updateSupportMessage,
  addAttachments,
} from 'store/support';

const mapDispatchToProps = {
  toggleSupportDrawer,
  createSupportMessage,
  updateSupportMessage,
  addAttachments,
};

const mapStateToProps = (state, ownProps) => {
  return {
    status: state.support.status,
    supportTicket: state.support.supportTicket,
    clientName: state.client.displayName,
    userEmail: state.authentication.person.email,
    openDrawer: state.support.supportDrawerIsOpen,
    supportEnabled: _get(state.client.settings, 'support.enabled', false),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Support);
