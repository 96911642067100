import { useEffect } from 'react';
import getSubdomain from 'helpers/getSubdomain';
import queryString from 'query-string';

interface Params {
  products: any;
}

// TODO: delete this in 2020 when wellcare's contract is up
export default function useRedirectEffect({ products }: Params) {
  useEffect(() => {
    const subdomain = getSubdomain();
    const apps = ['pulse', 'retain', 'admin'];

    // wellcare hack
    if (subdomain === 'test-wellcare' || subdomain === 'wellcare') {
      const { acfwc } = queryString.parse(window.location.search);

      if (acfwc !== 'hippo' && !(window as any).__hippo) {
        window.location.replace(`https://${subdomain}.justsift.com/understand/diversity`);
        return;
      }

      (window as any).__hippo = true;
    }

    // skip this effect until the products become defined
    if (!products) {
      return;
    }

    // if `directory` is a product then, no need to redirect
    if (products.directory) {
      return;
    }

    // otherwise redirect to the other app
    for (const app of apps) {
      if (products[app]) {
        window.location.replace(`https://${subdomain}.justsift.com/${app}`);
        return;
      }
    }

    // if no apps available
    window.location.replace(`https://login.justsift.com`);
  }, [products]);
}
