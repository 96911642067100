import React from 'react';
import { Provider } from 'react-redux';
import config from 'config';
import wootric from 'helpers/wootricCustomAnalyticsIntegration';
import createStore from 'store/createStore';

// Components
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AnalyticsProvider from 'components/AnalyticsProvider';
import ThemeProvider from 'components/ThemeProvider';
import PortalProvider from 'components/skipper/PortalProvider';
import SnackbarProvider from 'components/skipper/SnackbarProvider';
import { OrgChartSettingsProvider } from 'pages/OrgChart/helpers/useOrgChartSettingsFetch';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

const store = createStore() as any;
const customIntegrations = { wootric };
const isMobileView = window.location.href.includes('mobileView=true');

interface Props {
  children: React.ReactNode;
}

function AppProvider({ children }: Props) {
  return (
    <BrowserRouter forceRefresh={isMobileView}>
      <Provider store={store}>
        <AnalyticsProvider config={config} customIntegrations={customIntegrations}>
          <OrgChartSettingsProvider>
            <ThemeProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider
                  maxSnack={3}
                  style={{
                    minWidth: '50%',
                    marginTop: 64, // 64 is the size of the app bar
                  }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <PortalProvider>
                    <LastLocationProvider>{children}</LastLocationProvider>
                  </PortalProvider>
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </OrgChartSettingsProvider>
        </AnalyticsProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default AppProvider;
