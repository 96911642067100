// React
import typography from 'helpers/typography';
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

// Components
import Grid from '@mui/material/Grid';
import columnForResult from './Columns/columnForResult';
import Layout from './LayoutContext';

import createLayout from './createLayout';
import { makeStyles } from 'tss-react/mui';
import useWidth from 'helpers/useWidth';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      minHeight: 476,
      marginBottom: theme.spacing(3),
    },
    buttonRowIcon: {
      borderRadius: '50%',
      background: theme.palette.accent1Color,
      padding: '2px 4px',
      marginRight: '10px',
    },
    buttonRowContent: {
      ...typography(theme, 'h6'),
    },
    defaultColumn: {
      padding: theme.spacing(2),
    },
    marginBottomSpace: {},
    removeGridMargin: {
      marginBottom: 0,
      marginTop: 0,
    },
  };
});

/**
 * Results renderer for Big Search.
 *
 * This component is eventually composed of Columns and Items. Each `Item`
 * is able to determine its own height based upon its size. We want to
 * keep a uniform height for all `Items`, see `./createLayout.js` for more info.
 */
function Results({ query, results }) {
  const { classes, theme } = useStyles();
  const width = useWidth();
  const layout = createLayout(theme);

  /**
   * Begins rendering our result columns.
   *
   * Note that we currently only support 3 result columns
   * and we constrain the view to a standard 12 column flex grid.
   *
   * Each result column will receive a column size per the number
   * of results.
   */
  const renderColumns = (results, query) => {
    const widthCalc = result => {
      if (width === 'xs') return 1;
      return layout.numberOfColumnsInResult(result);
    };

    return (
      <Grid spacing={layout.itemPadding} container className={classes.removeGridMargin}>
        {results.map((result, index) => (
          <Grid
            item
            md={widthCalc(result) * 4}
            xs={12}
            key={`${result.type}-${index}`}
            className={classes.defaultColumn}
          >
            {columnForResult(result, {
              query: query,
              columns: widthCalc(result),
            })}
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderView =
    _get(results, '[0].type') === 'people' && !_get(results, '[0].totalLength')
      ? null
      : renderColumns(results, query);

  return (
    <div className={classes.root}>
      <Layout.Provider value={layout}>{renderView}</Layout.Provider>
    </div>
  );
}

Results.propTypes = {
  query: PropTypes.string,
  results: PropTypes.array,
};

export default Results;
