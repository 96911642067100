import { defineFetch } from 'resift';

const makeGetClearAuthToken = defineFetch({
  displayName: 'Get Clear Auth Token',
  make: () => ({
    request: () => ({ http }) =>
      http({
        method: 'GET', 
        route: '/auth/logout?setCookies=true',
      }),
  }),
});

const getClearAuthToken = makeGetClearAuthToken();

export default getClearAuthToken;