import { LOADING } from 'resift';
import { LegacyStatus } from '@sift/resift/models/Status';

function getLoading(status: number | LegacyStatus) {
  if (typeof status === 'number') return (status & LOADING) !== 0;
  return status.isLoading();
}

export default function isLoading(...statuses: (number | LegacyStatus)[]) {
  return statuses.some(getLoading);
}
