import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { fetchSiftBot } from 'store/siftBot';

interface Params {
  clientSettings: any;
  person: any;
  shouldFetchSiftBot: boolean;
  updateKey: string;
}

export default function useFetchSiftBotEffect({
  clientSettings,
  person,
  updateKey,
  shouldFetchSiftBot,
}: Params) {
  const dispatch = useDispatch();

  // siftbot fetch
  useEffect(() => {
    if (!clientSettings) {
      return;
    }

    if (!person) {
      return;
    }

    if (!_get(person, 'accountOnly') && _get(clientSettings, 'siftBot.enabled')) {
      dispatch(fetchSiftBot(person.id));
    }
  }, [updateKey, shouldFetchSiftBot, person, clientSettings, dispatch]);
}
