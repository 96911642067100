import hasBadContrast from './hasBadContrast';
import ensureColors from './ensureColors';

/**
 * this function requires it be ran through `ensureLightDark`
 */
export default function createReadablePalette(palette, onColor) {
  const { originalPrimary, originalSecondary, defaultAccentColor } = ensureColors(palette);

  return {
    ...palette,
    primary:
      hasBadContrast(originalPrimary.main, onColor) &&
      hasBadContrast(originalSecondary.main, onColor)
        ? defaultAccentColor
        : hasBadContrast(originalPrimary.main, onColor)
        ? originalSecondary
        : originalPrimary,
    secondary:
      hasBadContrast(originalPrimary.main, onColor) &&
      hasBadContrast(originalSecondary.main, onColor)
        ? defaultAccentColor
        : hasBadContrast(originalSecondary.main, onColor)
        ? originalPrimary
        : originalSecondary,
    originalPrimary: palette.originalPrimary || palette.primary,
    originalSecondary: palette.originalSecondary || palette.secondary,
  };
}
