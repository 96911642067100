import React from 'react';
import * as Types from 'components/Navigation/typings';
import getClearAuthToken from 'fetches/getClearAuthToken';
import { useDispatch } from 'resift';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import Menu from '@mui/material/Menu';
import MenuItems from 'components/skipper/appBar/MenuItems';

interface Props {
  open: boolean;
  routes: Types.Routes;
  person: any;
  anchorEl: HTMLElement | null;
  onCloseMenu: () => void;
}

function MsTeamsNavMenu({ open, routes, person, anchorEl, onCloseMenu }: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleMsTeamsLogout = () => {
    dispatch(getClearAuthToken());
    window.location.reload();
  };

  return (
    <Menu
      classes={{ paper: classes.menuPaper }}
      open={open}
      anchorEl={anchorEl}
      onClose={onCloseMenu}
    >
      <MenuItems
        msTeamsRoutes={routes}
        person={person}
        classes={{
          menuItemLink: classes.menuItemLink,
        }}
        onClose={onCloseMenu}
        onMsTeamsLogout={handleMsTeamsLogout}
      />
    </Menu>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  menuPaper: {
    minWidth: 200,
    top: '65px !important',
  },
  menuItemLink: {
    color: theme.palette.text.primary,
  },
}));

export default MsTeamsNavMenu;
