export default function getHostDomain() {
  if (process.env.REACT_APP_SUBDOMAIN_MOCK) {
    return process.env.REACT_APP_SUBDOMAIN_MOCK;
  }

  const hostname = window.location.hostname;
  if (!hostname) {
    return 'siftstaging';
  }

  const hostDomain = (hostname.split('.')[1] || '').trim().toLowerCase();
  if (!hostDomain) {
    return 'siftstaging';
  }

  return hostDomain;
}
