import readableColor from 'styles/skipper/readableColor';
import { oneLine } from 'common-tags';

export default function createEntityPictureUrlFromColor(primaryColor) {
  const lineColor = readableColor(primaryColor);

  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(oneLine`
  <?xml version="1.0" encoding="UTF-8"?>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Group</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group" fill-rule="nonzero">
              <circle id="Oval" fill="${primaryColor}" cx="100" cy="100" r="100"></circle>
              <path d="M71.4285714,42.8571429 L128.571429,42.8571429 C132.36,42.8571429 135.993571,44.3622143 138.672857,47.0413571 C141.352143,49.7204286 142.857143,53.3540714 142.857143,57.1428571 L142.857143,142.857143 C142.857143,146.645714 141.352143,150.279286 138.672857,152.958571 C135.993571,155.637857 132.36,157.142857 128.571429,157.142857 L71.4285714,157.142857 C67.6397857,157.142857 64.0061429,155.637857 61.3270714,152.958571 C58.6479286,150.279286 57.1428571,146.645714 57.1428571,142.857143 L57.1428571,57.1428571 C57.1428571,53.3540714 58.6479286,49.7204286 61.3270714,47.0413571 C64.0061429,44.3622143 67.6397857,42.8571429 71.4285714,42.8571429 Z M71.4285714,50 C69.5341429,50 67.7173571,50.7525714 66.3777857,52.0920714 C65.0382857,53.4316429 64.2857143,55.2484286 64.2857143,57.1428571 L64.2857143,142.857143 C64.2857143,144.751429 65.0382857,146.568571 66.3777857,147.907857 C67.7173571,149.247143 69.5341429,150 71.4285714,150 L128.571429,150 C130.465714,150 132.282857,149.247143 133.622143,147.907857 C134.961429,146.568571 135.714286,144.751429 135.714286,142.857143 L135.714286,57.1428571 C135.714286,55.2484286 134.961429,53.4316429 133.622143,52.0920714 C132.282857,50.7525714 130.465714,50 128.571429,50 L71.4285714,50 Z" id="Shape" fill="${lineColor}"></path>
              <path d="M121.435001,75 C121.435714,75.8357143 121.142143,76.6457143 120.607143,77.2878571 C120.072143,77.93 119.328571,78.3635714 118.506429,78.5142857 L117.863571,78.5714332 L82.1492857,78.5714332 C81.2571429,78.5728571 80.3964286,78.2407143 79.7364286,77.6392857 C79.0771429,77.0378571 78.6664286,76.2114286 78.5857143,75.3228571 C78.5057143,74.4335714 78.7607143,73.5471429 79.3007143,72.8371429 C79.8414286,72.1264286 80.6285714,71.645 81.5064286,71.4857143 L82.1492857,71.4285714 L117.863571,71.4285714 C118.810714,71.4285714 119.719286,71.805 120.389286,72.4742857 C121.058571,73.1442857 121.435001,74.0528571 121.435001,75 Z M121.435001,100 C121.435714,100.835714 121.142143,101.645714 120.607143,102.287857 C120.072143,102.93 119.328571,103.363571 118.506429,103.514286 L117.863571,103.571433 L82.1492857,103.571433 C81.2571429,103.572857 80.3964286,103.240714 79.7364286,102.639286 C79.0771429,102.037857 78.6664286,101.211429 78.5857143,100.322857 C78.5057143,99.4335714 78.7607143,98.5471429 79.3007143,97.8371429 C79.8414286,97.1264286 80.6285714,96.645 81.5064286,96.4857143 L82.1492857,96.4285714 L117.863571,96.4285714 C118.810714,96.4285714 119.719286,96.805 120.389286,97.4742857 C121.058571,98.1442857 121.435001,99.0528571 121.435001,100 Z M121.435001,125 C121.435714,125.835714 121.142143,126.645714 120.607143,127.287857 C120.072143,127.93 119.328571,128.363571 118.506429,128.514286 L117.863571,128.571433 L82.1492857,128.571433 C81.2571429,128.572857 80.3964286,128.240714 79.7364286,127.639286 C79.0771429,127.037857 78.6664286,126.211429 78.5857143,125.322857 C78.5057143,124.433571 78.7607143,123.547143 79.3007143,122.837143 C79.8414286,122.126429 80.6285714,121.645 81.5064286,121.485714 L82.1492857,121.428571 L117.863571,121.428571 C118.810714,121.428571 119.719286,121.805 120.389286,122.474286 C121.058571,123.144286 121.435001,124.052857 121.435001,125 Z" id="Shape" fill="${lineColor}"></path>
          </g>
      </g>
  </svg>
  `)}`;
}
