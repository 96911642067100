export default function getSubdomain() {
  if (process.env.REACT_APP_SUBDOMAIN_MOCK) {
    return process.env.REACT_APP_SUBDOMAIN_MOCK;
  }

  const hostname = window.location.hostname;
  if (!hostname) {
    return undefined;
  }

  const subdomain = (hostname.split('.')[0] || '').trim().toLowerCase();
  if (!subdomain) {
    return undefined;
  }

  return subdomain;
}
