/*
 * `storage` exists because of a bug with IE in certain environments. specifically, IE would throw
 * an error and crash our app if `localStorage` was accessed. it would give error messages like:
 * "Access is denied" or "The file cannot be accessed by the system."
 */
export default {
  getItem: (key: string) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return undefined;
    }
  },
  setItem: (key: string, value: string) => {
    try {
      return localStorage.setItem(key, value);
    } catch (e) {
      // eslint wanted something here
    }
  },
  removeItem: (key: string) => {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      // eslint wanted something here
    }
  },
};
