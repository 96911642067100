import { useRef, useLayoutEffect, useCallback } from 'react';

function useWrap<T>(t: T) {
  const ref = useRef(t);

  useLayoutEffect(() => {
    ref.current = t;
  }, [t]);

  return useCallback(() => ref.current, []);
}

export default useWrap;
