import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function EmailIcon(props) {
  return (
    <SvgIcon width={18} height={18} fill="none" viewBox="0 0 18 18" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path d="M18 3.497l-7.042 5.876c-1.166.98-2.751.98-3.917 0L0 3.497v11.238c0 .327.28.56.56.56h16.88a.54.54 0 0 0 .56-.56V3.497H18z" />
        <path d="M.513 2.705l7.135 5.969c.792.652 1.912.652 2.751 0l7.089-5.97H.512z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EmailIcon;
