import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import getBackgroundColor from 'styles/getBackgroundColor';
import { alpha } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  toPath?: string;
  notToPath?: string;
}

export default function NavButton({
  children,
  onClick,
  toPath = 'randomToPath',
  notToPath = 'randomNotToPath',
}: Props) {
  const { classes } = useStyles();
  const location = useLocation();
  const routeActive = location.pathname.includes(toPath) && !location.pathname.includes(notToPath);

  return (
    <button
      className={classNames(classes.root, { [classes.active]: routeActive })}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      marginLeft: theme.spacing(1.25),
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.25, 2),
      backgroundColor: 'inherit',
      borderRadius: '16px',
      border: 'none',
      color: alpha(theme.palette.getContrastText(theme.palette.primary.main), 0.7),
      cursor: 'pointer',
      textTransform: 'uppercase',
      minWidth: 64,
      boxSizing: 'border-box',
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 700,
      lineHeight: 1.75,
      letterSpacing: '1.25px',
      transition: 'all .25s',
      '&:hover': {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: getBackgroundColor(theme),
      },
    },
    active: {
      backgroundColor: getBackgroundColor(theme),
    },
  };
});
