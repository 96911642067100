import React, { useEffect, createContext } from 'react';
import { useData, useDispatch, useStatus, isLoading } from 'resift';
import useInterval from 'helpers/useInterval';
import { Loader } from '@fluentui/react-northstar';
import getIsInMsTeamsEmbed from 'helpers/getIsInMsTeamsEmbed';
import * as microsoftTeams from '@microsoft/teams-js';
import makeGetMeetingAttendeesFetch from 'components/msTeams/fetches/makeGetMeetingAttendeesFetch';
import MsTeamsMainTheme from 'components/msTeams/MsTeamsMainTheme';
import MsTeamsMain from './MsTeamsMain';
import InsufficientPermission from 'components/msTeams/embed/InsufficientPermission';
import { Person, meetingAttendee } from 'components/msTeams/embed/typing';

const attendeeData: Array<Person> = [];

export const AttendeesDataContext = createContext(attendeeData);

function Container() {
  const isInMsTeamsEmbed = getIsInMsTeamsEmbed();
  const meetingAttendeesFetch = makeGetMeetingAttendeesFetch();
  const dispatch = useDispatch();
  const attendeesFetchData = useData(meetingAttendeesFetch);
  const oneMinute = 60000;
  const fetchStatus = useStatus(meetingAttendeesFetch);

  useEffect(() => {
    if (isInMsTeamsEmbed) {
      microsoftTeams.initialize();

      microsoftTeams.getContext(context => {
        dispatch(meetingAttendeesFetch(context.chatId!));
      });
    }
  }, [dispatch, isInMsTeamsEmbed, meetingAttendeesFetch]);

  useInterval(() => {
    if (isInMsTeamsEmbed) {
      microsoftTeams.getContext(context => {
        dispatch(meetingAttendeesFetch(context.chatId!));
      });
    }
  }, oneMinute);

  const handleRefresh = () => {
    microsoftTeams.getContext(context => {
      dispatch(meetingAttendeesFetch(context.chatId!));
    });
  };

  if (!isInMsTeamsEmbed) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: 20,
        }}
      >
        This page is only available in Microsoft Teams panel.
      </div>
    );
  }

  if (!attendeesFetchData) {
    return (
      <MsTeamsMainTheme>
        <Loader />
      </MsTeamsMainTheme>
    );
  }

  const { participantFetchStatus } = attendeesFetchData;

  if (participantFetchStatus !== 'SUCCESS') {
    return <InsufficientPermission onRefresh={handleRefresh} isFetching={isLoading(fetchStatus)} />;
  }

  const attendeeList = attendeesFetchData.results.map(
    (attendee: meetingAttendee) => attendee.profile,
  );

  return (
    <MsTeamsMainTheme>
      <AttendeesDataContext.Provider value={attendeeList}>
        <MsTeamsMain />
      </AttendeesDataContext.Provider>
    </MsTeamsMainTheme>
  );
}

export default Container;
