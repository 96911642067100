import _get from 'lodash/get';
import { getContrast } from 'polished';
import skipperDefaultPalette from './skipperDefaultPalette';
import createPaletteColor from 'styles/createPaletteColor';
import isValidColor from 'styles/isValidColor';
import normalizeColor from 'styles/normalizeColor';
import brandColors from 'styles/skipper/brandColors';

const defaultPalette = ensureLightMainDark(skipperDefaultPalette);

function ensureLightMainDark(palette) {
  if (!palette) throw new Error(`palette was ${palette}`);
  return Object.entries(palette)
    .map(([key, value]) => {
      // if `value.main` is present, it will tack on the props `light`, `dark`, and `contrastText`
      if (value.main !== undefined) {
        const defaultPaletteMain = _get(skipperDefaultPalette, [key, 'main']);

        const main = isValidColor(value.main)
          ? normalizeColor(value.main)
          : isValidColor(defaultPaletteMain)
          ? normalizeColor(defaultPaletteMain)
          : skipperDefaultPalette.defaultAccentColor.main;

        const derivedLightDark = createPaletteColor(key, main);

        return {
          key,
          value: {
            light: isValidColor(value.light) ? normalizeColor(value.light) : derivedLightDark.light,
            main,
            dark: isValidColor(value.dark) ? normalizeColor(value.dark) : derivedLightDark.dark,
            contrastText:
              getContrast(main, brandColors.common.white) >
              getContrast(main, brandColors.common.black)
                ? brandColors.common.white
                : brandColors.common.black,
          },
        };
      }

      return { key, value };
    })
    .reduce((palette, { key, value }) => {
      palette[key] = value;
      return palette;
    }, {});
}

function ensureRequiredPaletteKeys(palette) {
  return {
    ...palette,
    primary: palette.primary || defaultPalette.primary,
    secondary: palette.secondary || defaultPalette.secondary,
    matched: palette.matched || defaultPalette.matched,
    originalPrimary: palette.originalPrimary || palette.primary || defaultPalette.primary,
    originalSecondary: palette.originalSecondary || palette.secondary || defaultPalette.secondary,
    defaultAccentColor: palette.defaultAccentColor || defaultPalette.defaultAccentColor,
  };
}

export default function ensureColors(palette = {}) {
  const ensuredColors = ensureLightMainDark(palette);
  return ensureRequiredPaletteKeys(ensuredColors);
}
