import React from 'react';
import typography from 'helpers/typography';
import usePageTrackingEffect from 'components/Page/usePageTrackingEffect';
import useLinkedInImportEnabled from 'helpers/useLinkedInImportEnabled';

// Components
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flex: '1 1 auto',
      width: 1280,
      maxWidth: '100%',
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(0, 4),
      alignSelf: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    copyContainer: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(4),
        width: 600,
        maxWidth: '100%',
      },
    },
    copy: {
      width: '90%',
      flex: '0 0 auto',
    },
    title: {
      ...typography(theme, 'h4'),
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(4),
    },
    subtitleBold: {
      ...typography(theme, 'h6'),
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitle: {
      ...typography(theme, 'h6'),
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(4),
    },
    startButton: {
      alignSelf: 'flex-start',
    },
    imageContainer: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  };
});

interface Props {
  companyName: string;
}

const StartPage = ({ companyName }: Props) => {
  const { classes } = useStyles();

  usePageTrackingEffect('Welcome to Sift', 'New User Onboarding');
  const linkedInImportEnabled = useLinkedInImportEnabled();

  return (
    <div className={classes.root}>
      <div className={classes.copyContainer}>
        <div className={classes.copy}>
          <div className={classes.title}>Take a few moments to complete your profile.</div>
          <div className={classes.subtitleBold}>
            Find the right people in the moment you need them.
          </div>
          <div className={classes.subtitle}>
            The next time you need to find someone at {companyName}, easily seek them out with
            simple search by job title, location, or even skillset!
          </div>
          <div className={classes.subtitleBold}>Grow your career.</div>
          <div className={classes.subtitle}>
            Showcase your skills and unique interests to advance your career with exposure to more
            teams.
          </div>
        </div>
        <Button
          // @ts-ignore
          component={Link}
          to={linkedInImportEnabled ? '/onboarding/linkedin' : '/onboarding/photos'}
          className={classes.startButton}
          color="secondary"
          variant="contained"
        >
          Get Started
        </Button>
      </div>
      <div
        className={classes.imageContainer}
        style={{
          background:
            'url(https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/onboarding_1.svg)',
          backgroundSize: 'contain',
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </div>
  );
};

export default StartPage;
