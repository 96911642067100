import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import SeparatedCopy from './Copy';

import { makeStyles } from 'tss-react/mui';

function SeparatedIcon({ className, person }) {
  const { classes } = useStyles();

  return (
    <Tooltip className={className} title={<SeparatedCopy person={person} />}>
      <ErrorOutline className={classes.icon} />
    </Tooltip>
  );
}

const useStyles = makeStyles()(() => {
  return {
    icon: {
      // TODO: use theme
      color: '#ffd436',
    },
  };
});

SeparatedIcon.propTypes = {
  person: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default SeparatedIcon;
