import React, { useState, memo } from 'react';
import typography from 'helpers/typography';
import bowser from 'bowser';

// Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';

// Styles
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import brandColors from 'styles/skipper/brandColors';
import readableColor from 'styles/skipper/readableColor';

const useStyles = makeStyles<{ useSecondaryColor: boolean }>()(
  (theme: Theme, { useSecondaryColor }) => ({
    root: {
      backgroundColor: useSecondaryColor
        ? theme.palette.secondary.main
        : brandColors.logomarkDotColors.gold,
      color: useSecondaryColor
        ? readableColor(theme.palette.secondary.main)
        : readableColor(brandColors.logomarkDotColors.gold),
      borderRadius: 0,
      display: 'flex',
      padding: theme.spacing(1),
    },
    content: {
      margin: '0 auto',
      ...(bowser.msie ? { alignSelf: 'center' } : null),
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...typography(theme, 'body2'),
    },
    message: {
      marginRight: theme.spacing(0.5),
    },
    link: {
      cursor: 'pointer',
      color: useSecondaryColor
        ? readableColor(theme.palette.secondary.main)
        : brandColors.secondaryPalette.cobalt,
      textDecorationColor: useSecondaryColor
        ? readableColor(theme.palette.secondary.main)
        : brandColors.secondaryPalette.cobalt,
    },
    contactLink: {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
    dialogContent: {
      '& p': {
        marginTop: 0,
      },
    },
    browsers: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    browser: {
      display: 'flex',
      flexDirection: 'column',
    },
    browserImage: {
      width: 128,
      height: 128,
      marginBottom: theme.spacing(2),
    },
    browserLabel: {
      ...typography(theme, 'body1'),
      fontWeight: theme.typography.fontWeightSemiBold,
      textAlign: 'center',
    },
  }),
);

interface Props {
  content?: React.ReactNode;
  useSecondaryColor?: boolean;
}

function IeSucksBanner({ content, useSecondaryColor = false }: Props) {
  const { classes } = useStyles({ useSecondaryColor });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDismiss = () => {
    setDismissed(true);
  };

  if (!bowser.msie || dismissed) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          {content ? (
            content
          ) : (
            <div className={classes.message}>Experiencing performance issues?</div>
          )}
          <Link onClick={handleOpen} className={classes.link} underline="always">
            Try using a different browser.
          </Link>
          <Button size="small" onClick={handleDismiss}>
            Dismiss
          </Button>
        </div>
      </div>

      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Experiencing Issues?</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <p>
            The Org Chart works best on modern browsers. If you’re experiencing issues, try using
            one of the web browsers listed below.
          </p>
          <p>
            <strong>Look for these on your computer:</strong>
          </p>
          <div className={classes.browsers}>
            <div className={classes.browser}>
              <img
                className={classes.browserImage}
                alt="Chrome"
                src="https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/chrome-logo.png"
              />
              <div className={classes.browserLabel}>Chrome</div>
            </div>
            <div>OR</div>
            <div className={classes.browser}>
              <img
                className={classes.browserImage}
                alt="Firefox"
                src="https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/firefox-logo.png"
              />
              <div className={classes.browserLabel}>Firefox</div>
            </div>
            <div>OR</div>
            <div className={classes.browser}>
              <img
                className={classes.browserImage}
                alt="Edge"
                src="https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/edge-logo.png"
              />
              <div className={classes.browserLabel}>Edge</div>
            </div>
          </div>
          <p>
            Ask your IT professionals for help finding or installing any of these browsers. Contact
            us at{' '}
            <a
              className={classes.contactLink}
              href={`mailto:support@justsift.com?subject=${encodeURIComponent(
                'Advanced Org Chart',
              )}`}
            >
              support@justsift.com
            </a>{' '}
            if your issue persists.
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(IeSucksBanner);
