import queryString from 'query-string';
import removeFalsyKeys from 'helpers/removeFalsyKeys';
import { LocationProps } from 'helpers/useLocationProps';
import { pageSizeOptions } from 'store/searchResults';

export default function stringifyLocationProps({
  q,
  page,
  pageSize,
  sortBy,
  sortDirection,
  advancedSearch,
  filters,
  graphType,
}: LocationProps) {
  return queryString.stringify(
    removeFalsyKeys({
      q,
      page: page === 1 ? undefined : page,
      pageSize: pageSize && pageSize === pageSizeOptions[0] ? undefined : pageSize,
      sortBy,
      sortDirection,
      advancedSearch: advancedSearch ? 'true' : undefined,
      graphType,
      ...filters.reduce(
        (acc, { value, fieldKey }) => {
          const values = acc[fieldKey] || [];
          values.push(value);
          acc[fieldKey] = values;

          return acc;
        },
        {} as { [key: string]: string[] },
      ),
    }),
  );
}
