import getSubdomain from 'helpers/getSubdomain';

export default function subdomainIs(inputSubdomain: string) {
  const subdomain = getSubdomain();
  const normalized = inputSubdomain.trim().toLowerCase();

  if (subdomain === normalized) {
    return true;
  }
  if (subdomain === `test-${normalized}`) {
    return true;
  }
  if (normalized === 'foc') {
    return true;
  }
  return false;
}
