import React from 'react';
import PropTypes from 'prop-types';

// Components
import Paper, { PaperProps } from '@mui/material/Paper';

const DefaultSuggestionsContainer = ({ containerProps, children }: any, paperProps: PaperProps) => {
  return (
    <Paper elevation={2} {...paperProps}>
      <div {...containerProps}>{children}</div>
    </Paper>
  );
};

DefaultSuggestionsContainer.propTypes = {
  containerProps: PropTypes.object,
  children: PropTypes.node,
};

export default DefaultSuggestionsContainer;
