import { defineFetch } from 'resift';
import removeKeys from 'helpers/removeKeys';
import _get from 'lodash/get';
import _merge from 'lodash/merge';

export default defineFetch({
  displayName: 'update linkedin',
  share: { namespace: 'settings' },
  make: () => ({
    request: (linkedInFile: File) => async ({ http, getState }: any) => {
      const state = getState();
      const personId = _get(state, ['authentication', 'person', 'id']) as string | undefined;

      if (!personId) {
        throw new Error('[updateLinkedInFetch] could not find person id');
      }

      const formData = new FormData();
      formData.append('linkedin-pdf', linkedInFile);

      // step 1: send linkedin PDF to parser on backend
      const linkedInData = await http({
        method: 'POST',
        route: '/people/parse-linkedin-pdf',
        data: formData,
      });

      // step 2: get fetch settings
      const settings = await http({
        method: 'GET',
        route: `/people/${personId}/settings`,
      });

      const userOnboarding = _get(settings, ['directory', 'userOnboarding']);

      // step 3: merge results from linkedin parser to fresh settings
      const settingsWithLinkedIn = _merge(
        {},
        {
          directory: { userOnboarding: removeKeys(userOnboarding, ['linkedInData']) },
        },
        {
          directory: { userOnboarding: { linkedInData } },
        },
      );

      // step 4: post to settings endpoint
      return http({
        method: 'PUT',
        route: `/people/${personId}/settings`,
        data: settingsWithLinkedIn,
      });
    },
  }),
});
