import React, { createContext } from 'react';
import { deepmerge } from '@mui/utils';
import {
  Provider as ThemeProvider,
  teamsDarkTheme,
  ThemePrepared,
} from '@fluentui/react-northstar';

interface Props {
  children: React.ReactNode;
}

export interface Theme extends Omit<ThemePrepared, 'siteVariables'> {
  siteVariables: {
    spacing: {
      unit: number;
    };
    colorScheme: {
      brand: {
        [key: string]: string;
      };
      sift: {
        [key: string]: string;
      };
      [key: string]: any;
    };
    [key: string]: any;
  };
}

const customTheme = {
  siteVariables: {
    spacing: {
      unit: 8,
    },
    colors: {
      // Based on color palette: https://fluentsite.z22.web.core.windows.net/0.62.0/color-palette
      grey: {
        270: '#BDBDBD',
      },
    },
    colorScheme: {
      sift: {
        backgroundPurple: '#6264A7',
        labelGrey: 'rgba(255, 255, 255, 0.6)',
      },
    },
  },
};

// @ts-ignore
export const theme: Theme = deepmerge(teamsDarkTheme, customTheme);

export const TeamsThemeContext = createContext(theme);

function MsTeamsMainTheme({ children }: Props) {
  const styles = useStyles(theme);

  return (
    <ThemeProvider
      theme={theme}
      // @ts-ignore
      style={styles.root}
    >
      <TeamsThemeContext.Provider value={theme}>{children}</TeamsThemeContext.Provider>
    </ThemeProvider>
  );
}

const useStyles = (theme: Theme) => ({
  root: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.siteVariables.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default MsTeamsMainTheme;
