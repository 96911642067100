import { Status, States } from '@sift/resift/models/Status';

// Models

export const APP_SMS_SEND = 'APP_SMS_SEND';
export const APP_SMS_SEND_SUCCESS = 'APP_SMS_SEND_SUCCESS';
export const APP_SMS_SEND_ERROR = 'APP_SMS_SEND_ERROR';
export const APP_SMS_RESET = 'APP_SMS_RESET';

export const sendSmsApp = (phoneNumber, operatingSystem) => {
  return {
    type: APP_SMS_SEND,
    payload: {
      data: {
        phoneNumber,
        operatingSystem,
      },
    },
  };
};

export const resetSms = () => {
  return {
    type: APP_SMS_RESET,
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [APP_SMS_SEND]: (state, action) => {
    return {
      ...state,
      status: new Status(States.LOADING),
    };
  },
  [APP_SMS_SEND_ERROR]: (state, action) => {
    return {
      ...state,
      status: new Status(States.ERROR),
    };
  },
  [APP_SMS_SEND_SUCCESS]: (state, action) => {
    return {
      ...state,
      status: new Status(States.NORMAL),
    };
  },
  [APP_SMS_RESET]: (state, action) => {
    return {
      ...state,
      status: new Status(States.NORMAL),
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  status: new Status(States.NORMAL),
};

export default function settingsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
