import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AdvancedSearchIcon = props => (
  <SvgIcon viewBox="0 0 36 32" {...props}>
    <path
      fillRule="nonzero"
      d="M21.781 23.833l-7.496 7.497a1.493 1.493 0 0 1-2.117-.005 1.5 1.5 0 0 1-.005-2.117l7.498-7.497a9 9 0 1 1 2.12 2.123zm1.166-2.909a6 6 0 1 0-.378-.379 1.53 1.53 0 0 1 .378.38zM0 9a1.5 1.5 0 0 1 1.493-1.5h12.014C14.332 7.5 15 8.166 15 9a1.5 1.5 0 0 1-1.493 1.5H1.493A1.493 1.493 0 0 1 0 9zm0 7.5c0-.828.673-1.5 1.504-1.5h7.492c.83 0 1.504.666 1.504 1.5 0 .828-.673 1.5-1.504 1.5H1.504C.674 18 0 17.334 0 16.5zM0 24c0-.828.673-1.5 1.504-1.5h7.492c.83 0 1.504.666 1.504 1.5 0 .828-.673 1.5-1.504 1.5H1.504C.674 25.5 0 24.834 0 24zM0 1.5C0 .672.674 0 1.504 0h29.992C32.326 0 33 .666 33 1.5c0 .828-.674 1.5-1.504 1.5H1.504C.674 3 0 2.334 0 1.5z"
    />
  </SvgIcon>
);

export default AdvancedSearchIcon;
