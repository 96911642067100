import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import classNames from 'classnames';
import { transparentize } from 'polished';
import useExploreEnabled from 'helpers/useExploreEnabled';
import useCustomNavLinkSettings from 'helpers/useCustomNavLinkSettings';
import useWidth from 'helpers/useWidth';
import useAnalytics from 'helpers/useAnalytics';
import * as Types from 'components/Navigation/typings';

import { Link } from 'react-router-dom';
import { Route } from 'react-router';
import SiftLogo from 'components/skipper/SiftLogo';
import NavSearchInput from 'components/Navigation/NavigationSearchInput';
import NavPersonMenu from 'components/Navigation/NavPersonMenu';
import NavHamburgerMenu from 'components/Navigation/NavHamburgerMenu';
import NavBackAndForwardButtons from 'components/Navigation/NavBackAndForwardButtons';
import NavigationLink from 'components/Navigation/NavigationLink';
import NavButton from 'components/Navigation/NavButton';
import CustomNavLink from 'components/Navigation/CustomNavLink';
import NotificationBell from 'components/Navigation/NotificationBell';
import AdminNavLink from 'components/Navigation/AdminNavLink';
import { SEARCH_PATH, EXPLORE_PATH, ENTITY_TYPE_USER } from 'components/search/consts';

interface Props {
  person: any;
  pictureUrl?: string;
  logoUrl?: string;
  query?: string;
  dropdownRoutes: {
    adminRoute?: Types.Route;
    navBarRoutes: Types.Routes;
    accountRoutes: Types.Routes;
    additionalRoutes: Types.Routes;
    msTeamsRoutes: Types.Routes;
  };
  searchIcon: React.ReactElement | null;
  searchBarClass: string;
  responsiveClasses: {
    tabletContainer: string;
    routeContainer: string;
  };
  orgChartEnabled: boolean;
  onClickSearch: () => void;
}

function DefaultNavigation({
  person,
  pictureUrl,
  logoUrl,
  query,
  dropdownRoutes,
  searchIcon,
  searchBarClass,
  responsiveClasses,
  orgChartEnabled,
  onClickSearch,
}: Props) {
  const { classes } = useStyles();
  const width = useWidth();
  const exploreEnabled = useExploreEnabled();
  const { customNavLinkEnabled } = useCustomNavLinkSettings();
  const analytics = useAnalytics();
  // @ts-ignore
  const isOnDesktopApp = !!window.bridge;

  const handleProfileClick = () => {
    analytics.track('User Own Profile Clicked', {
      category: 'Navigation',
    });
  };

  return (
    <>
      <NavHamburgerMenu
        person={person}
        pictureUrl={pictureUrl}
        adminRoute={dropdownRoutes.adminRoute}
        navBarRoutes={dropdownRoutes.navBarRoutes}
        accountRoutes={dropdownRoutes.accountRoutes}
        additionalRoutes={dropdownRoutes.additionalRoutes}
      />
      <Link to="/" className={classes.logo} aria-label="Logo">
        {logoUrl ? (
          <img src={logoUrl} alt="Logo" />
        ) : (
          <SiftLogo height={24} backgroundColor="primary" />
        )}
      </Link>
      {isOnDesktopApp && <NavBackAndForwardButtons />}
      <NavSearchInput
        searchBarClass={searchBarClass}
        query={query}
        onClick={onClickSearch}
        defaultPlaceholderText={
          isOnDesktopApp && width === 'md'
            ? 'Search...'
            : 'Search by name, job title, team, skill or anything'
        }
      />
      <div className={classes.tabletDownOnlyIconsContainer}>{searchIcon}</div>
      <div className={responsiveClasses.tabletContainer}>
        <NotificationBell />
      </div>

      <div className={responsiveClasses.routeContainer}>
        <NavButton
          onClick={() => {
            window.location.assign(`${SEARCH_PATH}/${ENTITY_TYPE_USER}/`);
          }}
          toPath={`${SEARCH_PATH}/${ENTITY_TYPE_USER}/`}
          notToPath={EXPLORE_PATH}
        >
          Directory
        </NavButton>
        {exploreEnabled && (
          <NavButton
            onClick={() => {
              window.location.assign(`${SEARCH_PATH}/${ENTITY_TYPE_USER}${EXPLORE_PATH}`);
            }}
            toPath={`${SEARCH_PATH}/${ENTITY_TYPE_USER}${EXPLORE_PATH}`}
          >
            Analytics
          </NavButton>
        )}
        <NavigationLink toPath="/lists" displayName="Lists" />
        {orgChartEnabled && (
          <NavigationLink toPath={'/orgchart'} displayName="Org Chart" exact={false} />
        )}
        {customNavLinkEnabled && <CustomNavLink />}
        <NotificationBell />
        <AdminNavLink />

        <Route path="/profile">
          {({ match }) => (
            <NavPersonMenu
              person={person}
              pictureUrl={pictureUrl}
              className={classes.personMenuRootClasses}
              accountRoutes={dropdownRoutes.accountRoutes}
              additionalRoutes={dropdownRoutes.additionalRoutes}
              color="primary"
              classes={{
                name: classNames(classes.personMenuName, {
                  [classes.routeActive]: !!match,
                }),
              }}
              onProfileClick={handleProfileClick}
            />
          )}
        </Route>
      </div>
    </>
  );
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {},
    logo: {
      padding: theme.spacing(0, 2),
      marginRight: theme.spacing(1),
      height: 32,
      '& *': {
        height: '100%',
        width: 'auto',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    tabletDownOnlyIconsContainer: {
      display: 'none',
      alignItems: 'flex-end',
    },
    // Skipper's Dropdown Menu / Drawer
    personMenuRootClasses: {
      flex: '0 0 auto',
      marginLeft: `${theme.spacing(2)} !important`,
      color: transparentize(
        theme.palette.transparentizeAmount.navItem,
        theme.palette.primary.contrastText,
      ),
      borderColor: transparentize(
        theme.palette.transparentizeAmount.navItem,
        theme.palette.primary.contrastText,
      ),
    },
    personMenuName: {
      color: transparentize(
        theme.palette.transparentizeAmount.navItem,
        theme.palette.primary.contrastText,
      ),
      '&:hover': {
        color: theme.palette.primary.contrastText,
      },
    },
    routeActive: {
      color: `${theme.palette.primary.contrastText} !important`,
      '& *': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
      },
    },
  };
});

export default DefaultNavigation;
