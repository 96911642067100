import { getLuminance } from 'polished';

export default function hasBadContrast(colorA: string, colorB: string) {
  const luminanceA = getLuminance(colorA);
  const luminanceB = getLuminance(colorB);

  const pad = 0.05;

  if (luminanceA > luminanceB) {
    return (luminanceA + pad) / (luminanceB + pad) < 1.5;
  }

  return (luminanceB + pad) / (luminanceA + pad) < 1.5;
}
