import React from 'react';
import PropTypes from 'prop-types';
import getDerivedLocationFromProps from 'pages/SearchResults/_helpers/getDerivedLocationFromProps';

// Utils
import { Link } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import classnames from 'classnames';

/**
 * Want to search? Use this link!
 *
 * This component is wrapping `react-router-dom`s `Link` component. Checkout
 * its API for additional props that are available.
 */
function SearchLink(props) {
  const { value, fieldKey, classes: classesFromProps, className, children, ...otherProps } = props;
  const { classes } = useStyles(undefined, { props: { classes: classesFromProps } });

  if (typeof value === 'object') {
    return null;
  }

  const isFieldKeyQuery = fieldKey === 'q' || fieldKey === 'query';

  const to = isFieldKeyQuery
    ? `/search?q=${value}`
    : getDerivedLocationFromProps({
        selectedAttributeValues: [
          {
            attributeDisplayName: fieldKey,
            attributeKey: fieldKey,
            key: fieldKey + value,
            value: value,
          },
        ],
      });

  return (
    <Link to={to} className={classnames(classes.root, className)} {...otherProps}>
      {children}
    </Link>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

SearchLink.propTypes = {
  value: PropTypes.any,
  fieldKey: PropTypes.string.isRequired,
  classes: PropTypes.object,
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SearchLink;
