import createReducer from '@sift/resift/createReducer';

const ON_TYPE_LIST_SEARCH = 'ON_TYPE_LIST_SEARCH';
const SELECT_LIST_SEARCH_TYPE = 'SELECT_LIST_SEARCH_TYPE';
const SELECT_MENU_ITEM = 'SELECT_MENU_ITEM';

interface State {
  searchValue: string;
  currentSearchOption: string;
  selectedItems: any[];
}

const initialState = {
  searchValue: '',
  currentSearchOption: 'List Name',
  selectedItems: [],
} as State;

export function handleListSearchType(value: any) {
  return {
    type: ON_TYPE_LIST_SEARCH,
    payload: {
      value,
    },
  };
}

export function handleSelectSearchType(value: string) {
  return {
    type: SELECT_LIST_SEARCH_TYPE,
    payload: {
      value,
    },
  };
}

export function handleSelectMenuItem(value: any) {
  return {
    type: SELECT_MENU_ITEM,
    payload: {
      value,
    },
  };
}

const actionHandlers = {
  [ON_TYPE_LIST_SEARCH]: (state: any, action: any) => {
    return {
      ...state,
      searchValue: action.payload.value,
    };
  },
  [SELECT_LIST_SEARCH_TYPE]: (state: any, action: any) => {
    return {
      ...state,
      currentSearchOption: action.payload.value,
      searchValue: '',
      selectedItems: [],
    };
  },
  [SELECT_MENU_ITEM]: (state: any, action: any) => {
    return {
      ...state,
      searchValue: '',
      selectedItems: action.payload.value,
    };
  },
};

export default createReducer(initialState, actionHandlers);
