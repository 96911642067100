import React, { useContext } from 'react';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';

interface Props extends React.ComponentProps<'div'> {
  content: string;
  style?: any;
}

const Chip = (props: Props) => {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);
  const { content, style, ...restOfProps } = props;

  return (
    <div style={{ ...styles.root, ...style }} {...restOfProps}>
      <span style={styles.content}>{content}</span>
    </div>
  );
};

const useStyles = (theme: Theme) => ({
  root: {
    maxWidth: 200,
    height: 32,
    display: 'inline-flex',
    backgroundColor: theme.siteVariables.colorScheme.sift.backgroundPurple,
    padding: theme.siteVariables.spacing.unit * 0.5,
    paddingLeft: theme.siteVariables.spacing.unit,
    paddingRight: theme.siteVariables.spacing.unit,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 99,
    fontWeight: theme.siteVariables.fontWeightSemibold,
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default Chip;
