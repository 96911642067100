import { useContext } from 'react';
import AnalyticsContext from 'components/AnalyticsProvider/AnalyticsContext';
import _has from 'lodash/get';
import _noop from 'lodash/noop';

interface AnalyticsObject {
  identify: () => void;
  track: (event: string, properties?: any) => void;
  page: (name: string, properties?: any) => void;
}

export default function useAnalytics(): AnalyticsObject {
  if (_has(window, ['appState'])) {
    return {
      identify: _noop,
      track: _noop,
      page: _noop,
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const contextValue = useContext(AnalyticsContext);

  if (!contextValue) {
    throw new Error(
      '[useAnalytics] could not find analytics context. is this component wrapped in the AnalyticsProvider?',
    );
  }

  return contextValue;
}
