import React, { useContext, useEffect, useRef } from 'react';
import { DivProps } from 'helpers/typings';
import PortalContext from 'components/skipper/PortalProvider/context';

export default function usePortalPlaceholder(Portal: { __portalId: string }) {
  const portalId = Portal.__portalId;

  function PortalPlaceholder(props: DivProps) {
    const rootRef = useRef<HTMLElement | null>(null);
    const portalContext = useContext(PortalContext);

    if (!portalContext) {
      throw new Error('[usePortalPlaceholder] could not find portal context');
    }

    const { setContentEl, removeContentEl } = portalContext;

    useEffect(() => {
      const rootEl = rootRef.current;

      if (!rootEl) {
        throw new Error('[usePortalPlaceholder] rootRef was falsy');
      }

      setContentEl(portalId, rootEl);

      return () => removeContentEl(portalId);
    }, [setContentEl, removeContentEl]);

    return <div ref={rootRef as any} {...props} />;
  }

  return PortalPlaceholder;
}
