import { useContext, useEffect } from 'react';

import AnalyticsContext from 'components/AnalyticsProvider/AnalyticsContext';
import useRouter from '@sift/resift/useRouter';
import usePreserveReference from 'use-preserve-reference';

function usePageTrackingEffect(title, name, _properties, skipTranslation) {
  const { location } = useRouter();
  const analytics = useContext(AnalyticsContext);

  const properties = usePreserveReference(_properties);

  useEffect(() => {
    if (!title) return;

    document.title = title;

    analytics.page(name || title, properties);

    skipTranslation && document.querySelector('title').setAttribute('translate', 'no');
  }, [location, title, name, analytics, properties, skipTranslation]);
}

export default usePageTrackingEffect;
