import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import usePassiveRedux from '@sift/resift/usePassiveRedux';
import useAction from '@sift/resift/useAction';
import useRouter from '@sift/resift/useRouter';

import { fetchFieldAutocomplete } from 'store/advancedSearch';
import getDerivedLocationFromProps from 'pages/SearchResults/_helpers/getDerivedLocationFromProps';
import getAttributeValuesFromObject from 'pages/SearchResults/_helpers/getAttributeValuesFromObject';

// Components
import AdvancedSearch from './AdvancedSearch';

const Container = memo(props => {
  const { history } = useRouter();
  const autocompleteResults = useSelector(state => state.advancedSearch.objects);
  const fields = usePassiveRedux(state => state.configurations.user.fields);

  const handleSearch = useCallback(
    obj => {
      const selectedAttributeValues = getAttributeValuesFromObject(obj, fields);
      history.push(
        getDerivedLocationFromProps({
          selectedAttributeValues,
          advancedSearch: true,
        }),
      );
    },
    [fields, history],
  );

  return (
    <AdvancedSearch
      fetchFieldAutocomplete={useAction(fetchFieldAutocomplete)}
      autocompleteResults={autocompleteResults}
      onSearch={handleSearch}
      {...props}
    />
  );
});

export default Container;
