// React
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Column from './Column';
import EntityItem from '../Items/EntityItem';

class EntitiesColumn extends PureComponent {
  static propTypes = {
    result: PropTypes.object,
    query: PropTypes.string,
    onItemSelect: PropTypes.func,
  };

  render() {
    const { result, query, ...otherProps } = this.props;

    return (
      <Column title={result.display.header} {...otherProps}>
        {result.items.map((item, index) => (
          <EntityItem
            value={item}
            field={result.field}
            analyticsLabel={result.display.header}
            query={query}
            key={item.id + index}
          />
        ))}
      </Column>
    );
  }
}

export default EntitiesColumn;
