import apps from './apps';

const directoryApp = apps.find(app => app.key === 'directory')!;
if (!directoryApp) throw new Error('could not find directory key in apps in skipper');

export default function getAppFromLinkPathname(pathname: string) {
  const base = pathname
    .toLowerCase()
    .split('/')[1]
    .trim();

  const app = apps.find(app => app.to.split('/')[1].toLowerCase() === base);

  if (app) {
    return app;
  }

  return directoryApp;
}
