import React from 'react';
import * as Sentry from '@sentry/browser';

// Components
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ErrorView from 'components/skipper/views/Error';
import Box from '@mui/material/Box';

// Styles
import { withStyles } from 'tss-react/mui';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * used to create an error code: https://github.com/darkskyapp/string-hash/blob/master/index.js
 */
function hash(str: string) {
  let hash = 5381;
  let i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  return hash >>> 0;
}

interface Props {
  className?: string;
  fullScreen: boolean;
  onError?: (error: Error) => void;
  children: React.ReactNode;
}

interface State {
  hadError: boolean;
  errorCode: undefined | number;
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hadError: false,
    errorCode: undefined,
  };

  componentDidCatch(error: Error) {
    const errorCode = (error.message && hash(error.message)) || undefined;

    Sentry.withScope(scope => {
      errorCode && scope.setTag('errorCode', errorCode.toString());
      Sentry.captureException(error);
    });

    this.setState({ hadError: true, errorCode });

    const { onError } = this.props;
    if (onError) {
      onError(error);
    }
  }

  onRefresh = () => {
    window.location.reload(); // true enables force-get from server
  };

  handleClose = () => {
    this.setState({ hadError: false });
  };

  render() {
    const { className, children, fullScreen } = this.props;
    const { hadError } = this.state;

    if (hadError) {
      const errorView = (
        <ErrorView
          className={className}
          subtitle={
            <>
              <>
                Something went wrong. Try refreshing the page and if this error persists, contact
                support.
              </>
              <br />
              <br />
              <a href="mailto:support@justsift.com">Support@justsift.com</a>{' '}
              {this.state.errorCode && <>| Error code: {this.state.errorCode}</>}
              <br />
              <Button
                sx={{ marginTop: 16 }}
                color="secondary"
                variant="contained"
                onClick={this.onRefresh}
              >
                Refresh Page
              </Button>
            </>
          }
        />
      );

      return fullScreen ? (
        <Dialog open fullScreen TransitionComponent={Transition}>
          <Box
            sx={{
              flex: '0 0 auto',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 16,
            }}
          >
            <IconButton onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {errorView}
        </Dialog>
      ) : (
        <>
          <Box
            sx={{
              flex: '0 0 auto',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 16,
            }}
          >
            <IconButton onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {errorView}
        </>
      );
    }

    return children;
  }
}

export default withStyles(ErrorBoundary, () => ({
  root: {
    flex: '1 1 auto',
  },
}));
