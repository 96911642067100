import React, { forwardRef } from 'react';

/**
 * (notes - Pearl)
 * This is a wrapper component for elements we would like to skip browser translation.
 *
 * Usage example references:
 *  ** Apply SkipTranslate to div elements - see `profile/StickyHeader`
 *  ** Apply SkipTranslate to non-div elements - see `ProfileInformation`
 *  ** Apply SkipTranslate to broken down elements - see `search/PersonCard`
 *  ** Apply SkipTranslate to MUI5+ styled-components - see `NameColumn`
 */
interface Props {
  Component: any;
  [key: string]: any;
}

const SkipTranslate = forwardRef((props: Props, ref: React.Ref<any>) => {
  const { Component = 'div', ...restOfProps } = props;

  return <Component ref={ref} translate="no" {...restOfProps} />;
});

export default SkipTranslate;
