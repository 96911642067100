// material-ui's popper was buggy and didn't update when elements moved so this Popper was made
import React, { useRef, useEffect, useState } from 'react';
import { DivProps } from 'helpers/typings';
import { createPortal } from 'react-dom';
import useInterval from 'helpers/useInterval';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles()(() => {
  return {
    root: {
      visibility: 'hidden',
    },
  };
});

interface Props extends DivProps {
  className?: string;
  children: React.ReactNode;
  rootRef?: any;
}

function Popper({ className, children, rootRef: externalRootRef, ...restOfProps }: Props) {
  const theme = useTheme();
  const { classes } = useStyles();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const popperEl = document.createElement('div');
    document.body.appendChild(popperEl);
    setPopperEl(popperEl);

    return () => {
      document.body.removeChild(popperEl);
    };
  }, []);

  const handleInterval = () => {
    const rootEl = rootRef.current;

    if (!rootEl) {
      return;
    }

    const { top, left, width, height } = rootEl.getBoundingClientRect();
    setTop(top);
    setLeft(left);
    setWidth(width);
    setHeight(height);
  };

  useInterval(handleInterval, 200);

  useEffect(handleInterval, []);

  useEffect(() => {
    if (!popperEl) {
      return;
    }

    popperEl.style.position = 'fixed';
    popperEl.style.top = `${top.toString()}px`;
    popperEl.style.left = `${left.toString()}px`;
    popperEl.style.width = `${width.toString()}px`;
    popperEl.style.height = `${height.toString()}px`;
    popperEl.style.zIndex = (theme.zIndex.modal + 10).toString();
  }, [top, left, width, height, popperEl, theme]);

  const handleRef = (ref: any) => {
    rootRef.current = ref;

    if (typeof externalRootRef === 'function') {
      externalRootRef(ref);
    } else if (typeof externalRootRef === 'object') {
      externalRootRef.current = ref;
    }
  };

  return (
    <>
      <div className={classNames(classes.root, className)} ref={handleRef} {...restOfProps}>
        {children}
      </div>
      {popperEl && createPortal(children, popperEl)}
    </>
  );
}

export default Popper;
