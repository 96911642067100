import 'core-js';
import 'react-app-polyfill/ie11';
import 'fullscreen-api-polyfill';
import './prependPolyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import config from './config';
import { init, captureException } from '@sentry/browser';

// Styles
import 'styles/core.css';
import 'styles/bodyFullscreen.css';

// Components
import App from 'components/App';
import MsTeamsLogin from 'components/msTeams/MsTeamsLogin';
import MsTeamsLoginSuccess from 'components/msTeams/MsTeamsLoginSuccess';
import TeamsLogin from 'components/msTeams/embed/TeamsLogin';
import {
  MS_TEAMS_LOGIN_URL,
  MS_TEAMS_MEETING_LOGIN_URL,
  MS_TEAMS_LOGIN_DONE_URL,
} from 'components/msTeams/consts';

(window as any)._captureException = captureException;

if (config.ENV === 'production') {
  // Hotjar Tracking Code for https://foc.justsift.com/
  (function(h: any, o: any, t: any, j: any, a?: any, r?: any) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 2029747, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

async function main() {
  if (window.location.href.includes(MS_TEAMS_LOGIN_URL)) {
    const loadingContainer = document.querySelector('.loading_container');
    loadingContainer?.parentNode?.removeChild(loadingContainer);
    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(<MsTeamsLogin />);
    return;
  }

  if (window.location.href.includes(MS_TEAMS_MEETING_LOGIN_URL)) {
    const loadingContainer = document.querySelector('.loading_container');
    loadingContainer?.parentNode?.removeChild(loadingContainer);
    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(<TeamsLogin />);
    return;
  }

  if (window.location.href.includes(MS_TEAMS_LOGIN_DONE_URL)) {
    const loadingContainer = document.querySelector('.loading_container');
    loadingContainer?.parentNode?.removeChild(loadingContainer);
    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(<MsTeamsLoginSuccess />);
    window.close();
    return;
  }

  if (config.SENTRY) {
    init({
      dsn: config.SENTRY,
      environment: config.ENV,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
  }
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<App />);
}

main().catch(e => {
  return captureException(e);
});
