import { ERROR } from 'resift';
import { LegacyStatus } from '@sift/resift/models/Status';

function getError(status: number | LegacyStatus) {
  if (typeof status === 'number') return (status & ERROR) !== 0;
  return status.hasError();
}

export default function isError(...statuses: (number | LegacyStatus)[]) {
  return statuses.some(getError);
}
