// React
import React from 'react';
import PropTypes from 'prop-types';
import typography from 'helpers/typography';

// Components
import MagnifyingGlass from 'components/Icons/MagnifyingGlass';
import Button from '@mui/material/Button';

import { makeStyles } from 'tss-react/mui';
import readableColor from 'styles/skipper/readableColor';

function NoResults({ query, onShowAdvancedSearch, onClearSearch }) {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <MagnifyingGlass className={classes.icon} />
      <div className={classes.header}>
        <div> {`We couldn't find anything for `} </div>
        <div className={classes.showEllipsis}>{`"${query}"`}</div>
      </div>
      <div className={classes.subheader}> Try our advanced search to specify your search </div>
      <Button variant="contained" color="secondary" onClick={onShowAdvancedSearch}>
        Advanced Search
      </Button>
      <Button variant="contained" onClick={onClearSearch} className={classes.clearSearchButton}>
        Clear Search
      </Button>
    </div>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 100,
      '& > button': {
        margin: theme.spacing(1),
      },
    },
    icon: {
      width: '50px',
      height: '50px',
      fill: readableColor(theme.palette.primary.main),
    },
    showEllipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '300px',
      marginLeft: theme.spacing(0.5),
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    header: {
      ...typography(theme, 'h6'),
      color: theme.palette.primary.contrastText,
      marginTop: '20px',
      marginBottom: '12px',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    subheader: {
      ...typography(theme, 'subtitle1'),
      color: theme.palette.primary.contrastText,
      marginBottom: '32px',
    },
    clearSearchButton: {
      color: theme.palette.common.black,
    },
  };
});

NoResults.propTypes = {
  query: PropTypes.string,
  onClearSearch: PropTypes.func,
  onShowAdvancedSearch: PropTypes.func,
};

export default NoResults;
