import React from 'react';
import TextField from '@mui/material/TextField';

/**
 * react-autosuggest/whatever assumes that `inputProps` will be applied
 * to a standard input element. We need to pick off and apply certain props
 * to the root `TextField` component and pass the rest down through the
 * `InputProps` of the `TextField`.
 *
 * To make sure we remain in control of the props available on `TextField`
 * use the `textField` key when creating `inputProps`.
 */
export default function renderDefaultTextInput(inputProps: any) {
  const { value, placeholder, autoFocus, textFieldProps, ...otherInputProps } = inputProps;

  return (
    <TextField
      value={value}
      autoFocus={autoFocus}
      placeholder={placeholder}
      inputProps={{
        ...otherInputProps,
      }}
      {...textFieldProps}
    />
  );
}
