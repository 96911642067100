import readableColor from 'styles/skipper/readableColor';
import { oneLine } from 'common-tags';

export default function createEntityPictureUrlFromColor(primaryColor) {
  const lineColor = readableColor(primaryColor);

  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(oneLine`
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="57px" height="57px" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="57" height="57" rx="28.5" fill="${primaryColor}"/>
      <path d="M26.3333 27.6667C29.28 27.6667 31.6667 25.28 31.6667 22.3333C31.6667 19.3867 29.28 17 26.3333 17C23.3867 17 21 19.3867 21 22.3333C21 25.28 23.3867 27.6667 26.3333 27.6667ZM26.3333 19.6667C27.8 19.6667 29 20.8667 29 22.3333C29 23.8 27.8 25 26.3333 25C24.8667 25 23.6667 23.8 23.6667 22.3333C23.6667 20.8667 24.8667 19.6667 26.3333 19.6667Z" fill="${lineColor}"/>
      <path d="M18.3333 35.6667C18.6267 34.7067 22.7467 33 26.3333 33C26.3333 32.0667 26.5067 31.1733 26.8 30.3467C23.16 30.2133 15.6667 32.0267 15.6667 35.6667V38.3333H28.3867C27.6933 37.56 27.1467 36.6667 26.8 35.6667H18.3333Z" fill="${lineColor}"/>
      <path d="M38.9067 35.6933C39.3867 34.9067 39.6667 33.9867 39.6667 33C39.6667 30.0533 37.28 27.6667 34.3333 27.6667C31.3867 27.6667 29 30.0533 29 33C29 35.9467 31.3867 38.3333 34.3333 38.3333C35.32 38.3333 36.24 38.04 37.0267 37.5733C38.2667 38.8133 39.1867 39.7333 40.4533 41L42.3333 39.12C40.3333 37.12 41.28 38.0533 38.9067 35.6933ZM34.3333 35.6667C32.8667 35.6667 31.6667 34.4667 31.6667 33C31.6667 31.5333 32.8667 30.3333 34.3333 30.3333C35.8 30.3333 37 31.5333 37 33C37 34.4667 35.8 35.6667 34.3333 35.6667Z" fill="${lineColor}"/>
    </svg>
  `)}`;
}
