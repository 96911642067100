import React from 'react';
import moment from 'moment-timezone';
import ProfileHeaderItem from 'components/msTeams/embed/molecules/ProfileHeaderItem';
import { ShiftActivityIcon } from '@fluentui/react-icons-northstar';
import { AM_PM_FORMAT } from 'components/TimezoneAndWorkHours/constants';
import { ProfileComponentProps } from 'components/msTeams/embed/typing';

function WorkSchedule({ value }: ProfileComponentProps) {
  const { timezone } = value;

  if (!timezone) {
    return null;
  }

  const currentTime = moment()
    .tz(timezone)
    .format(AM_PM_FORMAT);

  return (
    <ProfileHeaderItem icon={<ShiftActivityIcon />} content={`${currentTime} (${timezone} time)`} />
  );
}

export default WorkSchedule;
