import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { makeStyles } from 'tss-react/mui';
import classnames from 'classnames';
import { useTheme } from '@mui/material/styles';
import getPictureUrl from 'helpers/getPictureUrl';
import createOpenRolePictureUrlFromColor from 'helpers/createOpenRolePictureUrlFromColor';
import { OPEN_ROLE_ENTITY_KEY } from 'pages/OrgChart/consts';

import MuiAvatar from '@mui/material/Avatar';

/**
 * A Persons active avatar. In some configurations an official photo
 * and a custom photo are available to people. This component will
 * be connected to the store to make sure the proper setting is respected
 * and the correct photo is shown.
 */
function Avatar({ size, person, showBorder, className, ...other }) {
  const { classes } = useStyles();
  const { root, border, background, ...avatarClasses } = classes;
  const theme = useTheme();
  const preferOfficialPhotos = useSelector(state =>
    _get(state, 'settings.directory.preferOfficialPhotos', false),
  );

  if (person.entityTypeKey === OPEN_ROLE_ENTITY_KEY) {
    const pictureUrl =
      person.pictureUrl ?? createOpenRolePictureUrlFromColor(theme.palette.primary.dark);

    return (
      <div className={classnames(className, classes.root)}>
        <MuiAvatar
          style={{ width: size, height: size }}
          className={classnames({
            [classes.border]: showBorder,
            [classes.background]: true,
          })}
          classes={avatarClasses}
          {...other}
          src={pictureUrl}
          alt={person.name || ''}
        />
      </div>
    );
  }

  const officialPictureUrl = person.officialPictureUrl;
  const _pictureUrl = getPictureUrl(
    person.pictureUrl,
    officialPictureUrl,
    preferOfficialPhotos,
    theme,
  );

  const name =
    person.firstName && person.lastName
      ? `${person.firstName[0]} ${person.lastName[0]}`
      : person.displayName[0];

  return (
    <div className={classnames(className, classes.root)}>
      <MuiAvatar
        style={{ width: size, height: size }}
        className={classnames({
          [classes.border]: showBorder,
          [classes.background]: true,
        })}
        classes={avatarClasses}
        {...other}
        src={_pictureUrl}
        alt={name}
      />
    </div>
  );
}

Avatar.propTypes = {
  /**
   * Person to display photo for.
   */
  person: PropTypes.object.isRequired,
  /**
   * Photo size. We prefere a size prop over size styling to
   * have control over the size of the image that is requested
   * from the API.
   */
  size: PropTypes.number.isRequired,
  /**
   * In most cases we display a white border around the avatar. But
   * in some cases the border will not show up so we allow it to be
   * disabled. Defaults to `true`.
   */
  showBorder: PropTypes.bool,
  /**
   * Defaults to `false` .
   */
  className: PropTypes.string,
};

const useStyles = makeStyles()(theme => {
  return {
    root: {
      position: 'relative',
    },
    background: {
      backgroundColor: theme.palette.primary.main,
    },
    border: {
      border: `2px solid ${theme.palette.common.white}`,
    },
  };
});

export default Avatar;
