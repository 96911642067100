import { defineFetch } from 'resift';
import _get from 'lodash/get';

export default defineFetch({
  displayName: 'settings fetch',
  share: { namespace: 'settings' },
  make: () => ({
    request: () => ({ http, getState }: any) => {
      const state = getState();
      const personId = _get(state, ['authentication', 'person', 'id']) as string | undefined;

      if (!personId) {
        throw new Error('[settingsFetch] could not find personId');
      }

      return http({
        method: 'GET',
        route: `/people/${personId}/settings`,
      });
    },
  }),
});
