import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@fluentui/react-northstar';

interface Props extends ButtonProps {
  truncated?: boolean;
}

const ToggleTruncateButton = forwardRef((props: Props, ref: React.Ref<any>) => {
  const { truncated, text, content, ...restOfProps } = props;

  return (
    <Button
      text
      ref={ref}
      primary
      content={truncated ? 'Show all' : 'Show less'}
      {...restOfProps}
    />
  );
});

export default ToggleTruncateButton;
