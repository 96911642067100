import React from 'react';
import Analytics from './AnalyticsContext';
import { captureException, withScope } from '@sentry/browser';

interface Props {
  children: React.ReactNode;
  userId: string;
  user: any;
  clientId: string;
  clientName: string;
  clientSubdomain: string;
}

/**
 * A provider for accessing Segment's analytics object from the window.
 *
 *
 * Segments Docs
 * https://segment.com/docs/sources/website/analytics.js
 *
 * Segments React Setup
 * https://github.com/segmentio/analytics-react
 *
 */
class AnalyticsProvider extends React.Component<Props> {
  componentDidMount() {
    this.identify();
  }

  componentDidUpdate(previousProps: Props) {
    if (previousProps.user !== this.props.user || previousProps.clientId !== this.props.clientId) {
      this.identify();
    }
  }

  identify = () => {
    // @ts-ignore
    const analytics = window.analytics;

    const { userId, user, clientId, clientName, clientSubdomain } = this.props;

    if (!user || !clientId) {
      return;
    }

    try {
      analytics.identify(userId, {
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
        title: user.title,
        company: {
          id: clientSubdomain,
          company_id: clientSubdomain,
          name: clientName,
          subdomain: clientSubdomain,
        },
      });
    } catch (e) {
      withScope(scope => {
        scope.setTag('Non-fatal', 'true');
        captureException(e);
      });
    }
  };

  track = (event: any, properties: any) => {
    const { clientId, clientName, clientSubdomain } = this.props;

    try {
      // @ts-ignore
      window.analytics.track(event, {
        ...properties,
        company: {
          id: clientId,
          company_id: clientId,
          name: clientName,
          subdomain: clientSubdomain,
        },
      });
    } catch (e) {
      withScope(scope => {
        scope.setTag('Non-fatal', 'true');
        captureException(e);
      });
    }
  };

  page = (name: any, properties: any) => {
    const { clientId, clientName, clientSubdomain } = this.props;

    try {
      // @ts-ignore
      window.analytics.page(name, {
        ...properties,
        company: {
          id: clientId,
          company_id: clientId,
          name: clientName,
          subdomain: clientSubdomain,
        },
      });
    } catch (e) {
      withScope(scope => {
        scope.setTag('Non-fatal', 'true');
        captureException(e);
      });
    }
  };

  render() {
    // @ts-ignore
    return <Analytics.Provider value={this}>{this.props.children}</Analytics.Provider>;
  }
}

export default AnalyticsProvider;
