import React, { useState } from 'react';
import urlParse from 'url-parse';
import queryString from 'query-string';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

export type AvatarSize = 196 | 140 | 120 | 72 | 64 | 56 | 50 | 40 | 32 | 24;

interface Props {
  src: string;
  alt: string;
  className?: string;
  size?: AvatarSize;
  whiteStroke?: boolean;
  shortIdForClipPath: string;
}

function Avatar({ className, src: _src, alt, size = 40, whiteStroke, shortIdForClipPath }: Props) {
  const { classes } = useStyles({ whiteStroke });
  const strokeWidth = size === 196 || size === 140 || size === 120 ? 2 : size === 24 ? 0 : 1;
  const src = _src || '';
  const [hadError, setHadError] = useState(false);
  const clipMaskId = `avatarMask-size-${size}-${shortIdForClipPath}`;

  const sizedSrc = (() => {
    if (!src.includes('people-media') && !src.includes('profileImages')) {
      return src;
    }

    if (hadError) {
      return src;
    }

    const url = urlParse(src);
    url.set(
      'query',
      queryString.stringify({
        // @ts-ignore
        ...queryString.parse(url.query),
        width: size * 2,
        height: size * 2,
      }),
    );

    return url.toString();
  })();

  const handleError = () => {
    setHadError(true);
  };

  return (
    <svg
      width={size}
      height={size}
      role="img"
      aria-label={alt}
      className={classNames(classes.root, className)}
    >
      <defs>
        <clipPath id={clipMaskId}>
          <circle cx={size / 2} cy={size / 2} r={size / 2} />
        </clipPath>
      </defs>
      <g>
        <image
          width={size}
          height={size}
          preserveAspectRatio="xMidYMid slice"
          clipPath={`url(#${clipMaskId}`}
          xlinkHref={sizedSrc}
          onError={handleError}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - strokeWidth / 2}
          strokeWidth={strokeWidth}
          className={classes.avatarStroke}
        />
      </g>
    </svg>
  );
}

const useStyles = makeStyles<{ whiteStroke?: boolean }>()((theme: Theme, { whiteStroke }) => ({
  root: {
    overflow: 'visible !important',
  },
  avatarStroke: {
    stroke: whiteStroke ? theme.palette.common.white : theme.palette.common.black,
    strokeOpacity: whiteStroke
      ? theme.palette.opacity.thirtyEightPercent
      : theme.palette.opacity.eightPercent,
    fill: 'none',
  },
}));

export default Avatar;
