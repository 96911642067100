import { useData } from 'resift';
import * as Sift from '@sift/types';
import usePassiveRedux from '@sift/resift/usePassiveRedux';
import useEntityTypeTypeKeyFromUrl from 'helpers/useEntityTypeTypeKeyFromUrl';
import makeGetConfiguration from 'fetches/makeGetConfiguration';

function useEntityTypeConfig(typeKey?: string): Sift.Configuration {
  const typeKeyFromUrl = useEntityTypeTypeKeyFromUrl();
  const entityTypeTypeKey = typeKey || typeKeyFromUrl;
  const getConfiguration = makeGetConfiguration(entityTypeTypeKey);
  const config = useData(getConfiguration);
  // (notes - Pearl) user config is needed for Big Search
  const userConfig = usePassiveRedux((state: any) => state.configurations.user) as any;

  return typeKey === 'user' ? userConfig : config;
}

export default useEntityTypeConfig;
