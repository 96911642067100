import { defineFetch } from 'resift';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import removeKeys from 'helpers/removeKeys';

export default defineFetch({
  displayName: 'update completed slides',
  share: { namespace: 'settings' },
  make: () => ({
    request: (newSlide: string, linkedInFieldToRemove = '') => async ({ http, getState }) => {
      const state = getState();
      const personId = _get(state, ['authentication', 'person', 'id']) as string | undefined;
      if (!personId) {
        throw new Error('[updateCompletedSlidesFetch] could not find personId');
      }

      const settings = await http({
        method: 'GET',
        route: `/people/${personId}/settings`,
      });
      const userOnboarding = _get(settings, ['directory', 'userOnboarding']);

      const completedSlides = _get(
        settings,
        ['directory', 'userOnboarding', 'completedSlides'],
        [],
      ) as string[];

      const linkedInData = _get(settings, ['directory', 'userOnboarding', 'linkedInData'], {});
      const settingsWithCompletedSlides = _merge(
        {},
        {
          directory: {
            userOnboarding: removeKeys(userOnboarding, ['completedSlides', 'linkedInData']),
          },
        },
        {
          directory: {
            userOnboarding: {
              completedSlides: [...completedSlides.filter(slide => slide !== newSlide), newSlide],
              linkedInData: removeKeys(linkedInData, [linkedInFieldToRemove]),
            },
          },
        },
      );

      return http({
        method: 'PUT',
        route: `/people/${personId}/settings`,
        data: settingsWithCompletedSlides,
      });
    },
  }),
});
