import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckMarkIcon = props => (
  <SvgIcon width={24} height={24} viewBox="0 0 48 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.724 33.931c7.999 0 14.483-6.33 14.483-14.138S29.723 5.655 21.724 5.655c-7.999 0-14.483 6.33-14.483 14.138s6.484 14.138 14.483 14.138z"
      fill="#70D44B"
    />
    <path
      d="M18.927 23.125l-3.059-2.986a.87.87 0 0 0-.611-.25.87.87 0 0 0-.612.25.825.825 0 0 0 0 1.194l3.662 3.575a.885.885 0 0 0 1.232 0l9.264-9.035a.825.825 0 0 0 0-1.194.87.87 0 0 0-1.224 0l-8.652 8.446z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={1.5}
    />
    <mask id="prefix__a" maskUnits="userSpaceOnUse" x={13} y={13} width={17} height={13}>
      <path
        d="M18.927 23.125l-3.059-2.986a.87.87 0 0 0-.611-.25.87.87 0 0 0-.612.25.825.825 0 0 0 0 1.194l3.662 3.575a.885.885 0 0 0 1.232 0l9.264-9.035a.825.825 0 0 0 0-1.194.87.87 0 0 0-1.224 0l-8.652 8.446z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={1.5}
      />
    </mask>
  </SvgIcon>
);

export default CheckMarkIcon;
