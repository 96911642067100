import React from 'react';
import { useSelector } from 'react-redux';
import useIsAdmin from 'helpers/useIsAdmin';
import getHostDomain from 'helpers/getHostDomain';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import getBackgroundColor from 'styles/getBackgroundColor';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AdminIcon from 'components/Icons/Admin';

function AdminNavLink() {
  const { classes } = useStyles();
  const isAdmin = useIsAdmin();
  const client = useSelector((state: any) => state.client);
  const hostDomain = getHostDomain();

  if (!isAdmin) {
    return null;
  }

  return (
    <Tooltip title="Admin Dashboard" placement="top" aria-label="admin dashboard">
      <IconButton
        href={`http://${client.subdomain}.${hostDomain}.com/admin`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.iconButton}
        size="large"
      >
        <AdminIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  icon: {
    color: theme.palette.primary.contrastText,
  },
  iconButton: {
    '&:hover': {
      backgroundColor: getBackgroundColor(theme),
    },
  },
}));

export default AdminNavLink;
