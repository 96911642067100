// Libs
import { connect } from 'react-redux';

// Actions
import { deleteRecentSearches, deleteRecentSearch } from 'store/search';

import Component from './RecentSearchesColumn';

const mapDispatchToProps = {
  deleteRecentSearch: deleteRecentSearch,
  deleteRecentSearches: deleteRecentSearches,
};

export default connect(
  null,
  mapDispatchToProps,
)(Component);
