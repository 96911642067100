import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import IconButton from '@mui/material/IconButton';

import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateAfter from '@mui/icons-material/NavigateNext';

import { makeStyles } from 'tss-react/mui';

function NavBackAndForwardButtons() {
  const { classes } = useStyles();
  const history = useHistory();
  const [canGoForward, setCanGoForward] = useState(false);
  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(() => {
    if (window.bridge) {
      window.bridge.setNavigationChangedHandler(changedHandler);
    }
  }, []);

  const changedHandler = (canGoBack, canGoForward) => {
    setCanGoForward(canGoForward);
    setCanGoBack(canGoBack);
  };

  const goBack = () => {
    history.goBack();
  };

  const goForward = () => {
    history.goForward();
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={goBack} size="large">
        <NavigateBefore style={{ opacity: canGoBack ? 1 : 0.4 }} className={classes.icon} />
      </IconButton>
      <IconButton onClick={goForward} size="large">
        <NavigateAfter
          style={{
            opacity: canGoForward ? 1 : 0.4,
          }}
          className={classes.icon}
        />
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    // TODO: Transfer this up to the parent in MUI Next
    marginRight: '16px',
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
}));

export default NavBackAndForwardButtons;
