import _get from 'lodash/get';
import usePassiveRedux from '@sift/resift/usePassiveRedux';
import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';
import { Theme } from '@mui/material/styles';

interface Person {
  pictureUrl: string;
  officialPictureUrl: string;
  customPictureUrl?: string;
}

export default function usePictureUrlFromPerson(person: Person | undefined | null, theme: Theme) {
  const preferOfficialPhotos = usePassiveRedux(state =>
    _get(state, ['settings', 'directory', 'preferOfficialPhotos'], false),
  );

  if (!person) {
    return '';
  }

  const { pictureUrl, officialPictureUrl = '', customPictureUrl } = person;
  const specialPictureUrl = officialPictureUrl.includes('/placeholders')
    ? theme.pictureUrl ||
      createPictureUrlFromColor(theme.palette.primary.dark) ||
      officialPictureUrl
    : officialPictureUrl || theme.pictureUrl;

  if (preferOfficialPhotos) {
    return specialPictureUrl || pictureUrl;
  }

  // if customPictureUrl exists, then user has uploaded their own picture and it should default to pictureUrl
  // if not, pictureUrl is not defined and themePictureUrl needs to have priority

  return customPictureUrl || specialPictureUrl || pictureUrl;
}
