import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * NOTE: there is a custom hook `helpers/useTerminology`.
 * Check it out before using this component
 */
class Terminology extends Component {
  static propTypes = {
    terminology: PropTypes.object.isRequired,
    term: PropTypes.string.isRequired,
    className: PropTypes.string,
    casing: PropTypes.string.isRequired,
    withSpace: PropTypes.bool,
  };

  render() {
    const { terminology, casing, term, className, withSpace } = this.props;

    if (casing && casing !== 'capitalize' && casing !== 'lowercase' && casing !== 'uppercase') {
      console.error('Error: Terminology does not compute casing prop.'); // eslint-disable-line
    }

    return (
      <span className={className} style={{ textTransform: casing, marginLeft: withSpace ? 4 : 0 }}>
        {terminology[term]}
      </span>
    );
  }
}

export default Terminology;
