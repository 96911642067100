import React from 'react';
import typography from 'helpers/typography';

// Components
import Confetti from 'react-confetti';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

// Helpers
import useWindowSize from 'react-use/lib/useWindowSize';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto',
    padding: theme.spacing(0, 4),
  },
  confetti: {
    top: '60px !important',
    pointerEvents: 'none',
  },
  copyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: 800,
    maxWidth: '100%',
  },
  title: {
    ...typography(theme, 'h4'),
    marginBottom: theme.spacing(6),
    maxWidth: '100%',
  },
  description: {
    ...typography(theme, 'body1'),
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: theme.spacing(6),
    maxWidth: '100%',
  },
}));

function ConfettiPage() {
  const { classes } = useStyles();
  const { width, height } = useWindowSize();

  return (
    <div className={classes.root}>
      {/*
      // @ts-ignore */}
      <Confetti width={width} height={height} className={classes.confetti} />
      <div className={classes.copyContainer}>
        <div className={classes.title}>Thank you for filling out your profile!</div>
        <div className={classes.description}>
          To make any changes, you can click on your name in the top right corner to edit and change
          this information at any time.
        </div>
        <Button
          // @ts-ignore
          component={Link}
          to="/"
          color="secondary"
          variant="contained"
        >
          Go to Sift
        </Button>
      </div>
    </div>
  );
}

export default ConfettiPage;
