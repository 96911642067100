import React from 'react';
import { RemoveProps } from 'helpers/typings';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';

// Components
import { default as MuiChip, ChipProps } from '@mui/material/Chip';
import { ChipColor } from 'components/fields/input/ChipsInput/ChipsInput';

interface Props extends RemoveProps<ChipProps, 'color'> {
  color?: string;
  textColor?: string;
  component?: React.ElementType<any>;
  onClick?: () => void;
  clickable?: boolean;
  onDelete?: () => void;
  to?: string;
}

function Chip(props: Props) {
  const { color, className, ...restOfProps } = props;
  const { classes } = useStyles();

  return (
    <MuiChip
      className={classNames(classes.root, className)}
      color={(color as ChipColor) || ('default' as ChipColor)}
      {...restOfProps}
    />
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '@media print': {
      backgroundColor: theme.palette.common.white,
      border: `solid 1px ${theme.palette.common.black}`,
      color: theme.palette.common.black,
    },
  },
}));

export default Chip;
