// Libs
import React from 'react';
import PropTypes from 'prop-types';

// UI
import DatePicker from './DatePicker';
import FormLabel from '@mui/material/FormLabel';

// Style
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';

function DateRangePicker({ label, value, fromProps, fieldKey, toProps, className, onChange }) {
  const { classes } = useStyles();

  const onFieldChange = (key, newValue) => {
    onChange(fieldKey, {
      ...value,
      [key]: newValue,
    });
  };

  value = value || {};

  return (
    <div className={classNames(classes.root, className)}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <div className={classes.container}>
        <DatePicker
          value={value.from || null}
          onChange={value => onFieldChange('from', value)}
          label="Start Date"
          maxDate={moment(value.to)}
          {...fromProps}
        />
        <DatePicker
          value={value.to || null}
          onChange={value => onFieldChange('to', value)}
          disableFuture
          label="End Date"
          {...toProps}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    color: `${theme.palette.originalPrimary.contrastText} !important`,
  },
}));

DateRangePicker.propTypes = {
  value: PropTypes.shape({
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
  }),
  fieldKey: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  fromProps: PropTypes.object,
  toProps: PropTypes.object,
  className: PropTypes.string,
};

export default DateRangePicker;
