import React from 'react';
import _random from 'lodash/random';

interface Props {
  oneOf: React.ReactNode[];
}

class Random extends React.PureComponent<Props> {
  randomIndex: number;

  constructor(props: Props) {
    super(props);
    this.randomIndex = _random(props.oneOf.length - 1);
  }

  render() {
    return this.props.oneOf[this.randomIndex];
  }
}

export default Random;
