import { connect } from 'react-redux';
import ThemeSnackbar from './ThemeSnackbar';
import { removePreviewTheme } from 'store/theme';

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(removePreviewTheme());
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  },
});

const mapStateToProps = state => {
  return {
    open: !!state.theme.previewThemeId,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeSnackbar);
