import useValidSlides from './useValidSlides';
import useSlideInfo from './useSlideInfo';

export default function useIsLastSlide() {
  const validSlides = useValidSlides();

  const { currentSlide: currentSlidePath } = useSlideInfo();

  const currentSlide = validSlides.find((slide: any) => slide.path === currentSlidePath);

  if (!currentSlide) {
    return false;
  }

  const currentSlideIndex = validSlides.findIndex((slide: any) => slide === currentSlide);

  const nextSlide = validSlides[currentSlideIndex + 1];

  if (!nextSlide) {
    return true;
  }

  return nextSlide.path === '/done';
}
