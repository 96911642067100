import moment from 'moment';

export default function parseDate(maybeDateAttributeValue) {
  if (typeof maybeDateAttributeValue !== 'string') {
    return {
      from: undefined,
      to: undefined,
    };
  }

  const match = /^(\d+\/\d+\/\d+)*-(\d+\/\d+\/\d+)*$/.exec(maybeDateAttributeValue);
  if (!match) return undefined;

  const fromString = match[1];
  const toString = match[2];

  const from = fromString && moment(fromString, 'MM/DD/YYYY').toDate();
  const to = toString && moment(toString, 'MM/DD/YYYY').toDate();

  return { from, to };
}
