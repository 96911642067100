import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import PersonProfile from './PersonProfile';
import { TeamsThemeContext } from 'components/msTeams/MsTeamsMainTheme';
import { AttendeesDataContext } from 'components/msTeams/embed/MsTeamsMain';

function Container() {
  const themeContext = useContext(TeamsThemeContext);
  const attendeeList = useContext(AttendeesDataContext);
  const location = useLocation();
  const personId = location.pathname.split('/').pop();
  const person = attendeeList.find(attendee => attendee.id === personId);

  if (!person) {
    return <div>Person with id {personId} not found.</div>;
  }

  return <PersonProfile person={person} theme={themeContext} />;
}

export default Container;
