import { defineFetch } from 'resift';
import _get from 'lodash/get';

export default defineFetch({
  displayName: 'assistants fetch',
  share: {
    namespace: 'person',
    merge: (previous, next) => ({
      ...previous,
      _entityRelationships: {
        ..._get(previous, ['_entityRelationships']),
        ...next,
      },
    }),
  },
  make: (personId: string) => ({
    request: (assistantIds: string[]) => ({ http }: any) =>
      http({
        method: 'POST',
        route: `/people/${personId}/assistants`,
        data: assistantIds.map(id => ({ person: id })),
      }),
  }),
});
