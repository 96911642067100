import { useLocation } from 'react-router';
import { ATTRIBUTE_PATH } from 'helpers/consts';
import { SEARCH_PATH } from 'components/search/consts';

function useEntityTypeTypeKeyFromUrl() {
  const location = useLocation();
  const path = location.pathname;

  if (!path.includes(SEARCH_PATH) && !path.includes(ATTRIBUTE_PATH)) {
    return null;
  }

  const regex = path.includes(ATTRIBUTE_PATH)
    ? new RegExp(`${ATTRIBUTE_PATH}/.*/`)
    : new RegExp(`${SEARCH_PATH}/.*/`);
  const match = path.match(regex);

  if (!match) {
    return null;
  }

  return match[0].split('/')[2];
}

export default useEntityTypeTypeKeyFromUrl;
