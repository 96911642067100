// TODO: (rico) this file probably isn't used so it should be audited to be deleted

// Libs
import { Status, States } from '@sift/resift/models/Status';
import { RequestResolve } from '@sift/resift/redux/DataService';

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_SEARCH = 'FETCH_SEARCH';
export const FETCH_SEARCH_ERROR = 'FETCH_SEARCH_ERROR';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';

export const FETCH_TEAM = 'FETCH_TEAM';
export const FETCH_TEAM_ERROR = 'FETCH_TEAM_ERROR';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';

export const FETCH_RECENT_SEARCHES = 'FETCH_RECENT_SEARCHES';
export const FETCH_RECENT_SEARCHES_ERROR = 'FETCH_RECENT_SEARCHES_ERROR';
export const FETCH_RECENT_SEARCHES_SUCCESS = 'FETCH_RECENT_SEARCHES_SUCCESS';

export const UPDATE_RECENT_SEARCHES = 'UPDATE_RECENT_SEARCHES';
export const UPDATE_RECENT_SEARCHES_ERROR = 'UPDATE_RECENT_SEARCHES_ERROR';
export const UPDATE_RECENT_SEARCHES_SUCCESS = 'UPDATE_RECENT_SEARCHES_SUCCESS';

export const DELETE_RECENT_SEARCHES = 'DELETE_RECENT_SEARCHES';
export const DELETE_RECENT_SEARCHES_ERROR = 'DELETE_RECENT_SEARCHES_ERROR';
export const DELETE_RECENT_SEARCHES_SUCCESS = 'DELETE_RECENT_SEARCHES_SUCCESS';

export const DELETE_RECENT_SEARCH = 'DELETE_RECENT_SEARCH';
export const DELETE_RECENT_SEARCH_ERROR = 'DELETE_RECENT_SEARCH_ERROR';
export const DELETE_RECENT_SEARCH_SUCCESS = 'DELETE_RECENT_SEARCH_SUCCESS';

export const FIELD_AUTOSUGGEST_FETCH = 'FIELD_AUTOSUGGEST_FETCH';
export const FIELD_AUTOSUGGEST_FETCH_SUCCESS = 'FIELD_AUTOSUGGEST_FETCH_SUCCESS';
export const FIELD_AUTOSUGGEST_FETCH_ERROR = 'FIELD_AUTOSUGGEST_FETCH_ERROR';

export const OPEN_FILTER_DRAWER = 'OPEN_FILTER_DRAWER';

export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

export const FIELD_AUTOSUGGEST_ITEM_FETCH = 'FIELD_AUTOSUGGEST_ITEM_FETCH';
export const FIELD_AUTOSUGGEST_ITEM_FETCH_SUCCESS = 'FIELD_AUTOSUGGEST_ITEM_FETCH_SUCCESS';
export const FIELD_AUTOSUGGEST_ITEM_FETCH_ERROR = 'FIELD_AUTOSUGGEST_ITEM_FETCH_ERROR';

// ------------------------------------
// Actions
// ------------------------------------

/**
 * Fetch a search query
 *
 * @param {object} params The params of the search query.
 *  {
 *    q: string,
 *    ...filters,
 *    ...pagination
 *  }
 *
 */
export const fetchSearch = (query, identifier) => {
  return {
    type: FETCH_SEARCH,
    identifier: identifier || 'search/people',
    payload: {
      query: query,
      requestResolve: RequestResolve.CANCEL,
    },
  };
};

/**
 * Get Team
 *
 */
export const fetchTeam = () => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch({
      type: FETCH_TEAM,
      identifier: 'search',
      payload: {
        id: state.authentication.person.id,
      },
    });
  };
};

/**
 * Fetch recent searches
 *
 */
export const fetchRecentSearches = () => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch({
      type: FETCH_RECENT_SEARCHES,
      identifier: 'search',
      payload: {
        id: state.authentication.person.id,
      },
    });
  };
};

/**
 * Fetch recent searches
 *
 */
export const updateRecentSearches = search => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch({
      type: UPDATE_RECENT_SEARCHES,
      identifier: 'search',
      payload: {
        id: state.authentication.person.id,
        data: search,
        requestResolve: null,
      },
    });
  };
};

/**
 * Delete recent searches
 *
 */
export const deleteRecentSearches = () => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch({
      type: DELETE_RECENT_SEARCHES,
      identifier: 'search',
      payload: {
        id: state.authentication.person.id,
      },
    });
  };
};

/**
 * Delete recent search
 *
 */
export const deleteRecentSearch = index => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch({
      type: DELETE_RECENT_SEARCH,
      identifier: 'search',
      payload: {
        id: state.authentication.person.id,
        index: index,
      },
    });
  };
};

/**
 * Open Filter Drawer
 */
export const openFilterDrawer = open => {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_FILTER_DRAWER,
      identifier: 'search',
      payload: {
        open,
      },
    });
  };
};

/**
 * Search Skills
 */
export const fetchTags = (query, field) => {
  return {
    type: FETCH_TAGS,
    identifier: 'search/tags',
    payload: {
      query,
      field,
      requestResolve: RequestResolve.CANCEL,
    },
  };
};

/**
 * Search Autosuggest
 */
export const fetchSuggestions = (field, searchTerm) => {
  return {
    type: FIELD_AUTOSUGGEST_FETCH,
    identifier: field.objectKey,
    payload: {
      searchTerm,
      fieldId: field.id,
      requestResolve: RequestResolve.CANCEL,
    },
  };
};

/**
 * Fetch Autosuggest suggestion to item schema
 */
export const fetchMappingsForSuggestionItem = (fieldId, suggestionItem) => {
  return {
    type: FIELD_AUTOSUGGEST_ITEM_FETCH,
    identifier: fieldId,
    payload: {
      targetId: suggestionItem.id,
      name: suggestionItem.name,
      fieldId,
    },
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FETCH_SEARCH]: (state, action) => {
    return {
      ...state,
      searches: {
        ...state.searches,
        [action.identifier]: {
          ...state.searches[action.identifier],
          status: new Status(States.LOADING),
        },
      },
    };
  },
  [FETCH_SEARCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      searches: {
        ...state.searches,
        [action.identifier]: {
          result: action.payload.data,
          status: new Status(States.NORMAL),
        },
      },
    };
  },
  [FETCH_TAGS]: (state, action) => {
    return {
      ...state,
    };
  },
  [FETCH_TAGS_SUCCESS]: (state, action) => {
    return {
      ...state,
      lastTagSearch: action.payload.data.results,
    };
  },
  [FIELD_AUTOSUGGEST_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      suggestions: {
        ...state.suggestions,
        [action.identifier]: action.payload.data.results,
      },
    };
  },
  [FIELD_AUTOSUGGEST_FETCH_ERROR]: (state, action) => {
    return {
      ...state,
      suggestions: {
        ...state.suggestions,
        [action.identifier]: [],
      },
    };
  },
  [FIELD_AUTOSUGGEST_ITEM_FETCH_SUCCESS]: (state, action) => {
    // This payload.data is returned as an array to mimic the search endpoint
    // There will only be one item returned each time. If there isn't an acItem,
    // then the suggestion item is created by the user.

    const acItem = action.payload.data.results[0];
    const targetId = action.originalPayload.targetId;
    const name = action.originalPayload.name;

    return {
      ...state,
      suggestionMappings: {
        ...state.suggestionMappings,
        [targetId]: acItem || {
          title: name,
          targetId,
        },
      },
    };
  },
  [FIELD_AUTOSUGGEST_ITEM_FETCH_ERROR]: (state, action) => {
    // TODO: This is currenlty not being used.
    const targetId = action.originalPayload.targetId;
    return {
      ...state,
      suggestionMappings: {
        ...state.suggestionMappings,
        [targetId]: {
          hasError: true,
        },
      },
    };
  },
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  searches: {},
  suggestions: {},
  suggestionMappings: {},
};

export default function searchReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
