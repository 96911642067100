import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import getIsInMicrosoft from 'helpers/getIsInMicrosoft';
import Link, { LinkProps } from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';

interface Props extends LinkProps {
  href: string;
  children?: React.ReactNode;
  className?: string;
}

const NewTabLink = forwardRef((props: Props, ref: React.Ref<any>) => {
  const isInMicrosoft = getIsInMicrosoft();
  const { children, className, ...restOfProps } = props;
  const { classes } = useStyles();

  return (
    <Link
      {...restOfProps}
      target="_blank"
      rel="noopener noreferrer"
      ref={ref}
      className={classNames(classes.root, className)}
    >
      {children}
      {isInMicrosoft && <LaunchIcon fontSize="inherit" />}
    </Link>
  );
});

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'inline-flex',
  },
}));

export default NewTabLink;
