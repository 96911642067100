import React from 'react';
// @ts-ignore
import { findAll } from 'highlight-words-core';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {},
    highlight: {
      color: theme.palette.matched.main,
    },
  };
});

interface Props {
  className?: string;
  find: string[];
  children: React.ReactNode;
  element?: any;
  highlightClassName?: string;
}

/**
 * Highlights all occurrences of child text.
 *
 * see https://github.com/bvaughn/react-highlight-words for
 * original implementation.
 */
function Highlighter({
  className,
  find,
  children,
  element = 'span',
  highlightClassName = '',
}: Props) {
  const { classes } = useStyles();
  if (!children) return null;
  if (find.length === 0) return <span>{children}</span>;

  const searchText = Array.isArray(children) ? children.join(' ') : children.toString();

  const chunks = findAll({
    autoEscape: true,
    searchWords: find,
    textToHighlight: searchText,
  });

  const Element = element;

  const elements = chunks.map((chunk: any, index: number) => {
    const text = searchText.substr(chunk.start, chunk.end - chunk.start);

    if (chunk.highlight) {
      return (
        <Element
          className={classNames(highlightClassName, classes.highlight, 'highlight')}
          key={index}
        >
          {text}
        </Element>
      );
    }

    return <span key={index}>{text}</span>;
  });

  return <span className={classNames(classes.root, className)}>{elements}</span>;
}

export default Highlighter;
