import React, { useState } from 'react';
import delay from 'delay';

// Components
import PersonAutocomplete from 'components/skipper/PersonAutocomplete';
import PersonList from 'components/skipper/PersonList';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const danGilbert = '7LGLly9h5O5C5PHlqWO20b';
const carolDanvers = '4CLmP5TLcGWQzyV1eWslbk';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  autocomplete: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  personList: {
    flex: '1 1 auto',
    height: 400,
  },
}));

interface Person {
  id: string;
  displayName: string;
  title: string;
  pictureUrl: string;
}

interface Props {
  className?: string;
  people: Person[];
  currentPersonId: string;
  onChange: (people: Person[]) => void;
  label: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

function PeopleListEditor({
  className,
  people,
  currentPersonId,
  onChange,
  label,
  inputRef,
}: Props) {
  const { classes } = useStyles();
  const [personValue, setPersonValue] = useState('');
  const [errorHelperText, setErrorHelperText] = useState('');

  const handleAutocompleteChange = (_: any, { newValue }: { newValue: string }) => {
    setPersonValue(newValue);
  };
  const handleSelectPerson = async (person: Person) => {
    if (person.id === currentPersonId) {
      setErrorHelperText('You cannot add yourself to this list.');
      // TODO: use durations from material-ui theme when we refactor to use the hooks
      // version of getting the theme
      await delay(3000);
      setErrorHelperText('');
      return;
    }

    if (person.id === danGilbert || person.id === carolDanvers) {
      setErrorHelperText('You cannot add that person to this list.');
      // same comment as above
      await delay(3000);
      setErrorHelperText('');
      return;
    }

    onChange([...people.filter(p => p.id !== person.id), person]);
    setPersonValue('');
  };

  return (
    <div className={classNames(classes.root, className)}>
      <PersonAutocomplete
        className={classes.autocomplete}
        value={personValue}
        onChange={handleAutocompleteChange}
        onSelectPerson={handleSelectPerson}
        variant="outlined"
        fullWidth
        label={label}
        helperText={errorHelperText}
        error={!!errorHelperText}
        inputRef={inputRef}
      />
      <PersonList className={classes.personList} people={people} onChange={onChange} />
    </div>
  );
}

export default PeopleListEditor;
