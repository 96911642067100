// Libs
import { connect } from 'react-redux';

// Actions
import { toggleBigSearch } from 'store/bigSearch';

// Component
import SearchOverlay from './SearchOverlay';

const mapDispatchToProps = {
  toggle: toggleBigSearch,
};

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.bigSearch.open || false,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchOverlay);
