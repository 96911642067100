import React from 'react';
import { CalendarIcon } from '@fluentui/react-icons-northstar';
import ProfileHeaderItem from 'components/msTeams/embed/molecules/ProfileHeaderItem';
import getAnniversaryDateContent from 'pages/Profile/components/Header/helpers/getAnniversaryDateContent';

interface Props {
  value: string;
}

function Tenure({ value: anniversaryDate }: Props) {
  const tenure = getAnniversaryDateContent(anniversaryDate)?.tenure;
  return <ProfileHeaderItem icon={<CalendarIcon />} content={`Worked here for ${tenure}`} />;
}

export default Tenure;
