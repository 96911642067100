// Libs
import { RequestResolve } from '@sift/resift/redux/DataService';
import Fetch from '@sift/resift/models/Fetch';
import { Status, States } from '@sift/resift/models/Status';
import createActionHandlers from '@sift/resift/redux/FetchUtils/createActionHandlers';

// Models

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_FIELD_AUTOCOMPLETE = 'FETCH_FIELD_AUTOCOMPLETE';
export const FETCH_FIELD_AUTOCOMPLETE_SUCCESS = 'FETCH_FIELD_AUTOCOMPLETE_SUCCESS';
export const FETCH_FIELD_AUTOCOMPLETE_ERROR = 'FETCH_FIELD_AUTOCOMPLETE_ERROR';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFieldAutocomplete = (fieldId, value) => {
  return {
    type: FETCH_FIELD_AUTOCOMPLETE,
    identifier: fieldId,
    payload: {
      requestResolve: RequestResolve.CANCEL,
      fieldId: fieldId,
      query: {
        aggSearchTerm: value,
        sortBy: 'popularity',
      },
    },
  };
};
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  ...createActionHandlers(FETCH_FIELD_AUTOCOMPLETE),
  [FETCH_FIELD_AUTOCOMPLETE_SUCCESS]: (state, action) => {
    return {
      ...state,
      objects: {
        [action.identifier]: action.payload.data.results.map(i => i.value).slice(0, 5),
      },
      fetches: {
        ...state.fetches,
        [action.identifier]: new Fetch(
          action.identifier,
          [action.identifier],
          [],
          action.payload.data,
          new Status(States.NORMAL),
        ),
      },
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  objects: {},
  fetches: {},
};

export default function landingReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
