import Tenure from './Tenure';
import Pronouns from './Pronouns';
import WorkSchedule from './WorkSchedule';
import Bio from './Bio';
import Interests from './Interests';
import Skills from './Skills';

const componentRegistry = {
  anniversaryDate: Tenure,
  inclusivePronouns: Pronouns,
  timezoneAndWorkHours: WorkSchedule,
  bio: Bio,
  interests: Interests,
  skills: Skills,
};

export default componentRegistry;
