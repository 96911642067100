export default function removeFalsyKeys<T>(obj: T): { [P in keyof T]: NonNullable<T[P]> } {
  return Object.entries(obj)
    .filter(([_, value]) => !!value || _ === 'mergeAdditionalFilters')
    .reduce(
      (ret, [key, value]) => {
        ret[key] = value;
        return ret;
      },
      {} as any,
    );
}
