import { useMemo } from 'react';
import createTheme from './createSkipperTheme';
import usePassiveRedux from '@sift/resift/usePassiveRedux';
import { getThemeToUse } from 'store/theme';

export default function useOriginalTheme() {
  const theme = usePassiveRedux((state: any) => getThemeToUse(state.theme));

  const fixedTheme = useMemo(() => {
    return createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: theme.palette.originalPrimary,
        secondary: theme.palette.originalSecondary,
      },
    });
  }, [theme]);

  return fixedTheme;
}
