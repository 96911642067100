import React, { useCallback } from 'react';
import { RemoveProps, MakeOptional } from 'helpers/typings';
import { HttpParams } from 'resift';
import Autosuggest from 'react-autosuggest';
import { TextFieldProps } from '@mui/material/TextField';
import PersonAutocomplete from './PersonAutocomplete';
import PersonSuggestion from './PersonSuggestion';
import _get from 'lodash/get';
import _flatten from 'lodash/flatten';

interface Props
  extends MakeOptional<
    RemoveProps<TextFieldProps, 'value' | 'onChange' | 'inputProps' | 'rows'>,
    'classes' | 'className' | 'defaultValue' | 'style' | 'InputProps' | 'inputRef'
  > {
  value: string;
  pageSize?: number;
  renderSuggestion?: React.ComponentType<React.PropsWithChildren<any>>;
  onChange: (event: React.FormEvent<any>, params: Autosuggest.ChangeEvent) => void;
  onSelectPerson: (person: any) => void;
  filterSuggestions?: (suggestions: any[]) => any[];
  getEndpoint?: (value: string) => HttpParams;
  labelVisuallyHidden?: boolean;
}

function getPeopleFromResults(peopleResult: any) {
  const results = _get(peopleResult, ['results'], []);
  return _flatten(results.map((result: any) => _get(result, ['items'], [])));
}

function Container({
  onSelectPerson,
  pageSize,
  renderSuggestion: renderSuggestionFromProps,
  onChange,
  getEndpoint: getEndpointFromProps,
  ...restOfProps
}: Props) {
  const getEndpoint = useCallback(
    (value: string) => ({
      method: 'GET' as 'GET',
      route: '/search/people',
      query: {
        q: value,
        pageSize: pageSize ? pageSize.toString() : '5',
        separated: 'false',
      },
    }),
    [pageSize],
  );

  const handleChange = (e: any, params: Autosuggest.ChangeEvent) => {
    onChange(e, params);
  };

  const handleSuggestionSelected = (_: any, data: any) => {
    const person = data.suggestion;
    onSelectPerson(person);
  };

  return (
    <PersonAutocomplete
      getEndpoint={getEndpointFromProps || getEndpoint}
      renderSuggestion={renderSuggestionFromProps || PersonSuggestion}
      getSuggestionsFromResult={getPeopleFromResults}
      getSuggestionValue={person => person.displayName}
      onChange={handleChange}
      AutosuggestProps={{
        onSuggestionSelected: handleSuggestionSelected,
        highlightFirstSuggestion: true,
        ..._get(restOfProps, ['AutosuggestProps']),
      }}
      {...restOfProps}
    />
  );
}

export default Container;
