import { Theme } from '@mui/material/styles';
import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';
import createEntityPictureUrlFromColor from 'helpers/createEntityPictureUrlFromColor';

export default function ensurePictureUrl(src: string, theme: Theme, isEntity?: boolean) {
  if (!src || src.includes('/placeholders')) {
    const pictureUrlFromColor = isEntity
      ? createEntityPictureUrlFromColor(theme.palette.primary.dark)
      : createPictureUrlFromColor(theme.palette.primary.dark);
    return theme.pictureUrl || pictureUrlFromColor;
  }

  return src;
}
