import createReducer from '@sift/resift/redux/createReducer';
import memoizeLast from '@sift/resift/memoizeLast';
import observableStore from '@sift/resift/observableStore';
import { getFetch, mergeLoading, mergeSuccess, mergeError } from '@sift/resift/redux/FetchUtils';
import { Status, States } from '@sift/resift/models/Status';
import { CLIENT_FETCH_SUCCESS } from '@sift/resift/redux/reducers/client';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { deepmerge } from '@mui/utils';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import storage from 'helpers/storage';

import defaultTheme from 'styles/theme';
import createPaletteColor from 'styles/createPaletteColor';
import createSkipperTheme from 'styles/skipper/createSkipperTheme';
import hasBadContrast from 'styles/skipper/hasBadContrast';
import ensureColors from 'styles/skipper/ensureColors';

function createSkillsInterestsColors(palette) {
  const { primary, secondary, defaultAccentColor } = palette;
  const hasBadContrastWithWhite = color => hasBadContrast(color, '#fff');

  const mainColor = !hasBadContrastWithWhite(primary.main)
    ? primary.main
    : !hasBadContrastWithWhite(primary.dark)
    ? primary.dark
    : !hasBadContrastWithWhite(secondary.main)
    ? secondary.main
    : defaultAccentColor.main;

  return {
    skills: createPaletteColor('skills', mainColor),
    interests: createPaletteColor('interests', mainColor),
  };
}

function createTheme(theme = {}) {
  if (theme.shadows) {
    delete theme.shadows;
  }

  return createSkipperTheme(deepmerge(defaultTheme, theme));
}

function createUserThemeFromStorage() {
  const primary = storage.getItem('THEME_COLOR_PRIMARY') || vanillaTheme.palette.primary.main;
  const secondary = storage.getItem('THEME_COLOR_SECONDARY') || vanillaTheme.palette.secondary.main;
  const logoUrl = storage.getItem('THEME_LOGO_URL') || null;
  return createThemeFromPrimarySecondary(primary, secondary, logoUrl);
}

function createThemeFromPrimarySecondary(primary, secondary, logoUrl) {
  const palette = {
    ...vanillaTheme.palette,
    ...ensureColors({
      primary: { main: primary },
      secondary: { main: secondary },
    }),
  };

  return createTheme({
    ...vanillaTheme,
    logoUrl: logoUrl || null,
    palette: {
      ...palette,
      ...createSkillsInterestsColors(palette),
    },
    components: {
      ...vanillaTheme.components,
    },
  });
}

function createThemeFromApi(apiResponse) {
  if (!apiResponse) return undefined;

  const theme = apiResponse.settings;
  const logoUrl = apiResponse.logoUrl;
  const backgroundUrl = apiResponse.backgroundUrl;
  const pictureUrl = apiResponse.pictureUrl;
  if (!theme) return undefined;
  const palette = ensureColors(deepmerge(theme.palette, {}));

  return createTheme({
    ...theme,
    logoUrl,
    backgroundUrl,
    pictureUrl,
    palette: {
      ...palette,
      ...createSkillsInterestsColors(palette),
    },
  });
}

/**
 * tests whether the backgroundUrl is a placeholder
 * @param {string | undefined} backgroundUrl
 * @return {string | undefined}
 */
export function backgroundUrlOrUndefined(backgroundUrl) {
  if (!backgroundUrl) return undefined;
  if (/backgroundImages.*placeholders/i.test(backgroundUrl)) return undefined;
  return backgroundUrl;
}

/**
 * tests whether a pictureUrl is a placeholder
 * @param {string | undefined} pictureUrl
 * @return {string | undefined}
 */
export function pictureUrlOrUndefined(pictureUrl) {
  if (!pictureUrl) return undefined;
  if (/profile-images.*placeholders/i.test(pictureUrl)) return undefined;
  return pictureUrl;
}

// ------------------------------------
// Constants
// ------------------------------------
const vanillaTheme = createTheme();
const vanillaPrimary = vanillaTheme.palette.primary.main;
const vanillaSecondary = vanillaTheme.palette.secondary.main;

export const THEME_ACTIVATE_USER_THEME = 'THEME_ACTIVATE_USER_THEME';
export const THEME_REMOVE_USER_THEME = 'THEME_REMOVE_USER_THEME';
export const THEME_REMOVE_PREVIEW_THEME = 'THEME_REMOVE_PREVIEW_THEME';
export const THEME_SET_THEME_ID = 'THEME_SET_THEME_ID';
// local storage keys
export const THEME_USER_THEME_ACTIVE = 'THEME_USER_THEME_ACTIVE';
export const THEME_PREVIEW_THEME_ID = 'THEME_PREVIEW_THEME_ID';
export const THEME_COLOR_PRIMARY = 'THEME_COLOR_PRIMARY';
export const THEME_COLOR_SECONDARY = 'THEME_COLOR_SECONDARY';
export const THEME_COLOR_CONTRAST_TEXT = 'THEME_COLOR_CONTRAST_TEXT';
export const THEME_LOGO_URL = 'THEME_LOGO_URL';
// fetches (for previewing themes only, the main theme is fetched from the client fetch)
export const THEME_IDENTIFIER = 'THEME_IDENTIFIER';
export const THEME_FETCH = 'THEME_FETCH';
export const THEME_FETCH_SUCCESS = 'THEME_FETCH_SUCCESS';
export const THEME_FETCH_ERROR = 'THEME_FETCH_ERROR';

// ------------------------------------
// Action Creators
// ------------------------------------
export function activeUserTheme(primary, secondary, logoUrl) {
  return {
    type: THEME_ACTIVATE_USER_THEME,
    payload: {
      primary,
      secondary,
      logoUrl,
    },
  };
}
export function removeUserTheme() {
  return { type: THEME_REMOVE_USER_THEME };
}

/**
 * @param {string} themeId
 */
export function fetchTheme() {
  return (dispatch, getState) => {
    let themeId = getState().theme.previewThemeId;

    const pathParts = window.location.pathname.split('/');
    if (pathParts.length > 2 && pathParts[1] === 'preview-theme' && pathParts[2]) {
      themeId = pathParts[2];
    }

    if (!themeId) return;

    dispatch({
      type: THEME_FETCH,
      identifier: THEME_IDENTIFIER,
      payload: {
        themeId,
      },
    });
  };
}

export function setTheme(themeId) {
  return {
    type: THEME_SET_THEME_ID,
    payload: { themeId },
  };
}

export function removePreviewTheme() {
  return { type: THEME_REMOVE_PREVIEW_THEME };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [THEME_SET_THEME_ID]: (state, action) => {
    return {
      ...state,
      previewThemeId: action.payload.themeId,
    };
  },
  [THEME_REMOVE_USER_THEME]: state => {
    return {
      ...state,
      userThemeActive: false,
    };
  },
  [THEME_ACTIVATE_USER_THEME]: (state, action) => {
    const { payload } = action;
    const primary = payload.primary || vanillaTheme.primary.main;
    const secondary = payload.secondary || vanillaTheme.secondary.main;
    const logoUrl = payload.logoUrl || null;

    return {
      ...state,
      userThemeActive: true,
      user: createThemeFromPrimarySecondary(primary, secondary, logoUrl),
    };
  },
  [THEME_REMOVE_PREVIEW_THEME]: state => {
    return {
      ...state,
      previewThemeId: undefined,
    };
  },
};

const DATA_SERVICE_ACTION_HANDLERS = {
  [CLIENT_FETCH_SUCCESS]: (state, action) => {
    const clientTheme = createThemeFromApi(_get(action, 'payload.data.theme'));
    if (!clientTheme) return state;

    return {
      ...state,
      client: clientTheme,
    };
  },
  [THEME_FETCH]: mergeLoading,
  [THEME_FETCH_SUCCESS]: (state, action) => {
    const afterMergeSuccess = mergeSuccess(state, action);
    const fetchedTheme = getFetchedTheme(afterMergeSuccess);
    const preview = createThemeFromApi(fetchedTheme.data);

    return {
      ...afterMergeSuccess,
      preview,
    };
  },
  [THEME_FETCH_ERROR]: (state, action) => {
    const afterMergeError = mergeError(state, action);

    return {
      ...afterMergeError,
      previewThemeId: undefined,
    };
  },
};

// ------------------------------------
// Side effects
// ------------------------------------
export function mapThemeChangesToStorageSaves(store) {
  const state$ = observableStore(store);

  const themeChanges$ = state$.pipe(
    map(state => {
      const theme = getThemeToUse(state.theme);
      const primary = _get(theme, 'palette.primary.main', vanillaPrimary);
      const contrastText = theme.palette.getContrastText(primary);

      return {
        userThemeActive: state.theme.userThemeActive,
        previewThemeId: state.theme.previewThemeId,
        primary,
        secondary: _get(theme, 'palette.secondary.main', vanillaSecondary),
        contrastText,
        logoUrl: _get(theme, 'logoUrl', null),
      };
    }),
    distinctUntilChanged(_isEqual),
  );
  themeChanges$.subscribe(
    ({ userThemeActive, previewThemeId, primary, secondary, contrastText, logoUrl }) => {
      if (userThemeActive) {
        storage.setItem(THEME_USER_THEME_ACTIVE, 'true');
      } else {
        storage.removeItem(THEME_USER_THEME_ACTIVE);
      }

      if (previewThemeId) {
        storage.setItem(THEME_PREVIEW_THEME_ID, previewThemeId);
      } else {
        storage.removeItem(THEME_PREVIEW_THEME_ID);
      }

      storage.setItem(THEME_COLOR_PRIMARY, primary);
      storage.setItem(THEME_COLOR_SECONDARY, secondary);
      storage.setItem(THEME_COLOR_CONTRAST_TEXT, contrastText);

      if (logoUrl) {
        storage.setItem(THEME_LOGO_URL, logoUrl);
      } else {
        storage.removeItem(THEME_LOGO_URL);
      }
    },
  );

  return store;
}

// ------------------------------------
// Accessors
// ------------------------------------
export const getFetchedTheme = memoizeLast(state => {
  const resiftFetch = getFetch(THEME_IDENTIFIER, state);

  const data = resiftFetch.object();
  const status = resiftFetch.status;
  if (status.isUnknown()) return { data, status: new Status(States.LOADING) };
  return { data, status };
});

export function getThemeToUse(state) {
  if (state.previewThemeId) return state.preview || vanillaTheme;
  if (state.userThemeActive) return state.user;
  return state.client || vanillaTheme;
}

function getInitialPreviewThemeId() {
  if (window.location.pathname.includes('preview-theme')) return undefined;
  return storage.getItem(THEME_PREVIEW_THEME_ID);
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  previewThemeId: getInitialPreviewThemeId(),
  userThemeActive: !!storage.getItem(THEME_USER_THEME_ACTIVE),
  user: createUserThemeFromStorage(),
  preview: undefined,
  client: undefined,
  fetches: {},
  objects: {},
};

export default createReducer(initialState, {
  ...ACTION_HANDLERS,
  ...DATA_SERVICE_ACTION_HANDLERS,
});
