// Libs
import { HTTPVerb } from '@sift/resift/redux/DataService';
import dataServiceActionHandlers from '@sift/resift/redux/reducers/dataServiceActionHandlers';
import config from 'config';
import getSubdomain from 'helpers/getSubdomain';

// Actions
import { AUTH_PERSON_FETCH as FETCH_ME } from '@sift/resift/redux/reducers/authentication';

import { FETCH_CONFIG } from 'store/configurations';
import { UPDATE_SETTINGS } from 'store/settings';
import { FETCH_AUTOCOMPLETE } from 'store/bigSearch';
import { FETCH_FIELD_AUTOCOMPLETE } from 'store/advancedSearch';
import { THEME_FETCH } from 'store/theme';

import {
  FETCH_SEARCH,
  FETCH_TAGS,
  FETCH_TEAM,
  FETCH_RECENT_SEARCHES,
  UPDATE_RECENT_SEARCHES,
  DELETE_RECENT_SEARCHES,
  DELETE_RECENT_SEARCH,
  FIELD_AUTOSUGGEST_FETCH,
  FIELD_AUTOSUGGEST_ITEM_FETCH,
} from 'store/search';

import {
  SUPPORT_MESSAGE_CREATE,
  SUPPORT_MESSAGE_UPDATE,
  SUPPORT_MESSAGE_ATTACHMENTS_ADD,
} from 'store/support';

// Routes
import { APP_SMS_SEND } from 'store/smsApp';

import {
  FETCH_ENTITY_TYPES,
  FETCH_ENTITY,
  UPDATE_ENTITY,
  UPDATE_ENTITY_RELATIONSHIP,
} from 'store/entities';

import { SIFT_BOT_FETCH, SIFT_BOT_READ, SIFT_BOT_OPT_OUT } from 'store/siftBot';
import { MS_TEAMS_LOGIN_URL, MS_TEAMS_MEETING_LOGIN_URL } from 'components/msTeams/consts';

import getIsInMsTeamsEmbed from 'helpers/getIsInMsTeamsEmbed';
import getIsInMicrosoft from 'helpers/getIsInMicrosoft';

export const unauthorizedHandler = () => {
  const isInMsTeamsEmbed = getIsInMsTeamsEmbed();
  const isInMicrosoft = getIsInMicrosoft();
  const url = window.location.pathname + window.location.search;

  if (process.env.NODE_ENV === 'development') {
    if (isInMsTeamsEmbed) {
      window.location.href = MS_TEAMS_MEETING_LOGIN_URL;
      return true;
    }

    if (isInMicrosoft) {
      window.location.href = `${MS_TEAMS_LOGIN_URL}?redirectTo=${encodeURIComponent(url)}`;
      return true;
    }

    return false;
  }

  if (isInMsTeamsEmbed) {
    window.location.href = MS_TEAMS_MEETING_LOGIN_URL;
    return true;
  }

  if (isInMicrosoft) {
    window.location.href = `${MS_TEAMS_LOGIN_URL}?redirectTo=${encodeURIComponent(url)}`;
    return true;
  }

  const subdomain = getSubdomain();

  if (getQueryVariable('samlRedirect') === 'true') {
    window.location.href = `${
      config.LOGIN
    }/api/login/${subdomain}/saml?redirectTo=${encodeURIComponent(url)}`;
  } else {
    window.location.href = `${config.LOGIN}/login?redirectTo=${encodeURIComponent(url)}`;
  }

  return true;
};

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export const DataServiceActionHandlers = {
  ...dataServiceActionHandlers,
  [FETCH_ME]: {
    httpMethod: HTTPVerb.GET,
    route: 'people/me',
  },
  [FETCH_CONFIG]: {
    httpMethod: HTTPVerb.GET,
    route: (authentication, action) => {
      return `configurations/${action.payload.configType}`;
    },
  },
  [FETCH_SEARCH]: {
    httpMethod: HTTPVerb.GET,
    route: 'search/people',
  },
  [FETCH_TEAM]: {
    httpMethod: HTTPVerb.GET,
    route: (state, action) => {
      return `people/${action.payload.id}/org/team`;
    },
  },
  [FETCH_AUTOCOMPLETE]: {
    httpMethod: HTTPVerb.GET,
    route: 'search',
  },
  [UPDATE_SETTINGS]: {
    httpMethod: HTTPVerb.PUT,
    route: (state, action) => {
      return `people/${state.person.id}/settings`;
    },
  },
  [FETCH_FIELD_AUTOCOMPLETE]: {
    httpMethod: HTTPVerb.GET,
    route: (state, action) => {
      return `metadata/${action.payload.fieldId}`;
    },
  },
  [FETCH_RECENT_SEARCHES]: {
    httpMethod: HTTPVerb.GET,
    route: (state, action) => {
      return `people/${action.payload.id}/searches`;
    },
  },
  [UPDATE_RECENT_SEARCHES]: {
    httpMethod: HTTPVerb.POST,
    route: (state, action) => {
      return `people/${action.payload.id}/searches`;
    },
  },
  [DELETE_RECENT_SEARCHES]: {
    httpMethod: HTTPVerb.DELETE,
    route: (state, action) => {
      return `people/${action.payload.id}/searches`;
    },
  },
  [DELETE_RECENT_SEARCH]: {
    httpMethod: HTTPVerb.DELETE,
    route: (state, action) => {
      return `people/${action.payload.id}/searches?index=${action.payload.index}`;
    },
  },
  [FETCH_ENTITY]: {
    httpMethod: HTTPVerb.GET,
    route: (state, action) => {
      return `entities/${action.payload.entityId}`;
    },
  },
  [FETCH_ENTITY_TYPES]: {
    httpMethod: HTTPVerb.GET,
    route: 'entity-types',
  },
  [FETCH_TAGS]: {
    httpMethod: HTTPVerb.GET,
    route: (state, action) => {
      const { query, field } = action.payload;
      return `itemcollections/${field.objectKey}?q=${query.value}&n=5`;
    },
  },
  [UPDATE_ENTITY]: {
    httpMethod: HTTPVerb.PUT,
    route: (state, action) => {
      return `entities/${action.payload.data.id}`;
    },
  },
  [UPDATE_ENTITY_RELATIONSHIP]: {
    httpMethod: HTTPVerb.POST,
    route: (state, action) => {
      return `entities/${action.identifier}/${action.payload.relationship}`;
    },
  },
  [APP_SMS_SEND]: {
    httpMethod: HTTPVerb.POST,
    route: (state, action) => {
      return `communications/sms/mobile-app-link`;
    },
  },
  [SUPPORT_MESSAGE_CREATE]: {
    httpMethod: HTTPVerb.POST,
    route: (state, action) => {
      return `communications/email/support-messages${action.payload.query || ''}`;
    },
  },
  [SUPPORT_MESSAGE_UPDATE]: {
    httpMethod: HTTPVerb.PUT,
    route: (state, action) => {
      return `communications/email/support-messages/${action.payload.messageId}${action.payload
        .query || ''}`;
    },
  },
  [SUPPORT_MESSAGE_ATTACHMENTS_ADD]: {
    httpMethod: HTTPVerb.POST,
    upload: 'attachments',
    route: (state, action) => {
      return `communications/email/support-messages/${action.payload.messageId}/attachments`;
    },
  },
  [FIELD_AUTOSUGGEST_ITEM_FETCH]: {
    httpMethod: HTTPVerb.GET,
    route: (state, action) => {
      return `fields/${action.payload.fieldId}/autocomplete?targetId=${action.payload.targetId}`;
    },
  },
  [FIELD_AUTOSUGGEST_FETCH]: {
    httpMethod: HTTPVerb.GET,
    route: (state, action) => {
      return `fields/${action.payload.fieldId}/autocomplete?q=${action.payload.searchTerm}`;
    },
  },
  [SIFT_BOT_FETCH]: {
    httpMethod: HTTPVerb.GET,
    route: (state, action) => {
      return `sift-bot/${action.payload.userId}`;
    },
  },
  [SIFT_BOT_READ]: {
    httpMethod: HTTPVerb.POST,
    route: (state, action) => {
      return `sift-bot/${action.payload.userId}/read`;
    },
  },
  [SIFT_BOT_OPT_OUT]: {
    httpMethod: HTTPVerb.POST,
    route: (state, action) => {
      return `sift-bot/${action.payload.userId}/opt-out`;
    },
  },
  [THEME_FETCH]: {
    httpMethod: HTTPVerb.GET,
    route: (_, action) => {
      return `themes/${action.payload.themeId}`;
    },
  },
};

export default DataServiceActionHandlers;
