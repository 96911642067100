import moment from 'moment';
import parseDate from 'pages/SearchResults/_helpers/parseDate';

export default function transformToQueryParams(selectedAttributeValues = []) {
  return selectedAttributeValues.reduce((acc, { attributeKey, value }) => {
    const date = parseDate(value);

    if (date) {
      const upperKey = `${attributeKey.substring(0, 1).toUpperCase()}${attributeKey.substring(1)}`;

      if (date.from) {
        acc[`from${upperKey}`] = moment(date.from).format('MM/DD/YYYY');
      }

      if (date.to) {
        acc[`to${upperKey}`] = moment(date.to).format('MM/DD/YYYY');
      }

      return acc;
    }

    const arr = acc[attributeKey] || [];
    arr.push(value);
    acc[attributeKey] = arr;
    return acc;
  }, {});
}
