import React, { useEffect, useContext } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { Text, Button, Flex } from '@fluentui/react-northstar';
import { OpenOutsideIcon } from '@fluentui/react-icons-northstar';
import config from 'config';
import { SIFT_MARKETING_SITE } from 'helpers/consts';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';
import InfoPageLayout from 'components/msTeams/embed/molecules/InfoPageLayout';
import { MS_TEAMS_MEETING_MAIN_URL, MS_TEAMS_LOGIN_DONE_URL } from 'components/msTeams/consts';

function TeamsLogin() {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);

  useEffect(() => {
    microsoftTeams.initialize();
  }, []);

  const handleSignIn = () => {
    microsoftTeams.authentication.authenticate({
      successCallback: () => {
        // (pearl) Note: Console log showed that this never fires, but putting it here in case it is needed.
        const path = MS_TEAMS_MEETING_MAIN_URL;
        window.location.assign(path);
      },
      failureCallback: () => {
        /** This failure callback fires when the login window is closed.
         * We don't have a way of calling the successCallback because we're not following the
         * microsoft teams login flow. Instead, we have our own cookie authentication system.
         * Therefore, we always load the MS_TEAMS_MEETING_MAIN_URL, and auth checking is always handled in `dataServiceActionHandlers`
         */
        const path = MS_TEAMS_MEETING_MAIN_URL;
        window.location.assign(path);
      },
      height: 700,
      width: 400,
      url: `${config.LOGIN}?initAzureADLogin=true&inTeams=true&redirectTo=${encodeURIComponent(
        MS_TEAMS_LOGIN_DONE_URL,
      )}`,
    });
  };

  return (
    <InfoPageLayout>
      <Text style={styles.intro} size="large">
        Sift is the fastest way to find and connect with anyone in your company.
      </Text>
      <Flex gap="gap.medium" hAlign="center">
        <Button primary onClick={handleSignIn}>
          Sign In
        </Button>
        <Button
          as="a"
          href={SIFT_MARKETING_SITE}
          content="Learn More"
          target="_blank"
          rel="noopener noreferrer"
          icon={<OpenOutsideIcon outline />}
          iconPosition="before"
          data-testid="learn-more-button"
        />
      </Flex>
      <Text size="small" style={styles.footer}>
        <strong>Note:</strong> A Sift account is required. To get your company started with Sift,{' '}
        <Text
          as="a"
          style={styles.link}
          href={SIFT_MARKETING_SITE}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="learn more about getting Sift for your organization"
          data-testid="learn-more-link"
        >
          learn more
          <OpenOutsideIcon outline />
        </Text>{' '}
        or try our 30-day trial through the store page.
        <Text style={styles.consent}>
          Consent to access meeting information through your Microsoft account is required. If you
          have trouble granting these permissions during sign-in, please contact your administrator.
        </Text>
      </Text>
    </InfoPageLayout>
  );
}

const useStyles = (theme: Theme) => ({
  root: {},
  intro: {
    marginBottom: theme.siteVariables.spacing.unit * 4,
  },
  footer: {
    marginTop: theme.siteVariables.spacing.unit * 4,
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    display: 'inline-flex',
  },
  consent: {
    display: 'block',
    marginTop: theme.siteVariables.spacing.unit * 2,
  },
});

export default TeamsLogin;
