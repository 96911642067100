import React, { useContext } from 'react';
import _get from 'lodash/get';
import { Layout, Flex, Header, Text } from '@fluentui/react-northstar';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';
import { Person } from '@sift/types';
import NamePronunciation from 'components/msTeams/embed/profile/NamePronunciation';
import SkipTranslate from 'components/atoms/SkipTranslate';

interface Props {
  person: Person;
}

function Headings({ person }: Props) {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);
  const { id, displayName, hasNamePronunciation, _alternateFieldValues } = person;

  const altDisplayName = _get(_alternateFieldValues, 'displayName');

  return (
    <Layout style={styles.root}>
      <Flex gap="gap.small" vAlign="center">
        <SkipTranslate>
          <Header style={styles.h1}>{displayName}</Header>
        </SkipTranslate>
        {hasNamePronunciation && <NamePronunciation id={id} />}
      </Flex>
      {altDisplayName && <SkipTranslate Component={Text}>({altDisplayName})</SkipTranslate>}
    </Layout>
  );
}

const useStyles = (theme: Theme) => ({
  root: {
    marginBottom: theme.siteVariables.spacing.unit * 2,
  },
  h1: {
    margin: 0,
  },
});

export default Headings;
