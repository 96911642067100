import React, { useEffect, useMemo } from 'react';
import { RemoveProps } from 'helpers/typings';
import { HttpParams, useData, useClearFetch, useDispatch } from 'resift';
import shortId from 'shortid';
import makeAutocompleteFetch from './fetches/autocompleteFetch';
import _get from 'lodash/get';

// Components
import Autosuggest from 'react-autosuggest'; // 👈 the community typings for this thing are awesome
import OutlinedTextField, { OutlinedTextFieldProps } from 'components/molecules/OutlinedTextField';
import Paper from '@mui/material/Paper';
import Popper from './Popper';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {},
    popper: {
      position: 'relative',
      height: 1,
    },
    suggestionsPaper: {
      position: 'absolute',
      width: '100%',
      minWidth: 200,
      zIndex: theme.zIndex.max,
    },
    suggestionsContainer: {
      width: '100%',
      maxWidth: 350,
    },
    suggestionsContainerOpen: {},
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    suggestion: {
      display: 'block',
    },
  };
});

// couldn't use an interface due to typescript limitation
type Props = RemoveProps<OutlinedTextFieldProps, 'value' | 'onChange'> & {
  className?: string;
  fetchDefaultSuggestions?: boolean;
  value: string;
  onChange: (event: React.FormEvent<any>, params: Autosuggest.ChangeEvent) => void;
  getEndpoint: (value: string) => HttpParams;
  getSuggestionsFromResult?: (result: any) => any[];
  getSuggestionValue: (t: any) => string;
  renderSuggestion: React.ComponentType<React.PropsWithChildren<any>>;
  /**
   * it's possible to override default behavior so don't add the props `onChange`
   * `getSuggestionValue`, or `renderSuggestion`
   */
  // @ts-ignore
  AutosuggestProps?: Partial<Autosuggest.AutosuggestProps<any>>;
  postSuggestions?: React.ReactNode;
  onSuggestionSelected?: Autosuggest.OnSuggestionSelected<any>;
  filterSuggestions?: (suggestions: any[]) => any[];
  description?: string;
  labelVisuallyHidden?: boolean;
};

const identity = (x: any) => x;

function Autocomplete({
  className,
  fetchDefaultSuggestions = false,
  value,
  onChange,
  renderSuggestion: Suggestion,
  getSuggestionsFromResult = identity,
  getEndpoint,
  getSuggestionValue,
  onFocus,
  AutosuggestProps,
  postSuggestions,
  onSuggestionSelected,
  inputProps,
  filterSuggestions,
  description,
  labelVisuallyHidden,
  ...textFieldProps
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const clear = useClearFetch();

  const instanceId = useMemo(shortId, []);
  const autocompleteFetch = makeAutocompleteFetch(instanceId);
  const result = useData(autocompleteFetch);
  const _suggestions = getSuggestionsFromResult(result) || [];
  const suggestionsFilter = filterSuggestions ? filterSuggestions(_suggestions) : _suggestions;
  const suggestions = [...suggestionsFilter];

  if (postSuggestions) {
    suggestions.push('POST_SUGGESTION');
  }

  const handleClear = () => {
    clear(autocompleteFetch);
  };

  const handleFetch = (e: Autosuggest.SuggestionsFetchRequestedParams) => {
    dispatch(autocompleteFetch(getEndpoint(e.value)));
  };

  useEffect(() => {
    if (fetchDefaultSuggestions) {
      dispatch(autocompleteFetch(getEndpoint('')));
    }
  }, [fetchDefaultSuggestions, dispatch, autocompleteFetch, getEndpoint]);

  const handleSuggestionSelection = (
    e: any,
    data: Autosuggest.SuggestionSelectedEventData<any>,
  ) => {
    if (data.suggestion === 'POST_SUGGESTION') {
      return;
    }

    const autosuggestOnSuggestionSelected = _get(AutosuggestProps, ['onSuggestionSelected']);

    if (autosuggestOnSuggestionSelected) {
      autosuggestOnSuggestionSelected(e, data);
      return;
    }

    if (onSuggestionSelected) {
      onSuggestionSelected(e, data);
    }
  };

  const shimmedGetSuggestionValue = (suggestion: any) => {
    if (suggestion === 'POST_SUGGESTION') {
      return value;
    }

    return getSuggestionValue(suggestion);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      shouldRenderSuggestions={() => fetchDefaultSuggestions || (value || '').trim().length > 0}
      inputProps={{
        value: value || '',
        onChange,
        onFocus,
      }}
      getSuggestionValue={shimmedGetSuggestionValue}
      renderInputComponent={({
        value,
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        ...restOfInputProps
      }) => (
        <OutlinedTextField
          value={value || ''}
          onChange={e => onChange(e, { method: 'type', newValue: e.currentTarget.value })}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          description={description}
          labelVisuallyHidden={labelVisuallyHidden}
          inputProps={{
            'aria-label': 'autocomplete-input',
            ...restOfInputProps,
            ...inputProps,
          }}
          {...(textFieldProps as any)}
        />
      )}
      onSuggestionsFetchRequested={handleFetch}
      onSuggestionsClearRequested={handleClear}
      renderSuggestion={(autocompleteResult, autosuggestParams) => {
        if (autocompleteResult === 'POST_SUGGESTION') {
          return postSuggestions;
        }

        return <Suggestion {...autocompleteResult} {...autosuggestParams} />;
      }}
      renderSuggestionsContainer={({ containerProps, children }) => {
        const { ref, ...restOfContainerProps } = containerProps;

        return (
          <div className={classes.popper} ref={ref}>
            <Popper {...restOfContainerProps}>
              <Paper elevation={2}>{children}</Paper>
            </Popper>
          </div>
        );
      }}
      theme={{
        container: classNames(classes.root, className),
        suggestionsContainer: classes.suggestionsContainer,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      {...AutosuggestProps}
      onSuggestionSelected={handleSuggestionSelection}
    />
  );
}

export default Autocomplete;
