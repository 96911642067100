import React, { useContext, useState } from 'react';
import { Flex } from '@fluentui/react-northstar';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';
import Chip from 'components/msTeams/embed/atoms/Chip';
import ProfileSection from 'components/msTeams/embed/molecules/ProfileSection';
import { MAX_LEN_INTERESTS } from 'components/msTeams/consts';

interface Props {
  value: string[];
  field: any;
}

function Interests({ value: interestItems, field }: Props) {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);
  const needTruncation = interestItems.length > MAX_LEN_INTERESTS;
  const [truncated, setTruncated] = useState(needTruncation);

  if (!interestItems.length) {
    return null;
  }

  const handleToggleMore = () => {
    setTruncated(!truncated);
  };

  const displayInterestItems = !needTruncation
    ? interestItems
    : truncated
    ? interestItems.slice(0, MAX_LEN_INTERESTS)
    : interestItems;

  return (
    <ProfileSection
      sectionHeader={field?.displayName}
      content={
        <Flex wrap gap="gap.smaller">
          {displayInterestItems.map((interest, i) => (
            <Chip
              content={interest}
              style={styles.chip}
              key={`interest-${i}`}
              data-testid="interest-chip"
            />
          ))}
        </Flex>
      }
      onToggleMore={needTruncation ? handleToggleMore : undefined}
      truncated={truncated}
    />
  );
}

const useStyles = (theme: Theme) => ({
  root: {},
  chip: {
    marginBottom: theme.siteVariables.spacing.unit,
    '& > div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

export default Interests;
