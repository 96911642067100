import React from 'react';
import ensurePictureUrl from './helpers/ensurePictureUrl';
import usePictureUrlFromPerson from './helpers/usePictureUrlFromPerson';
import _get from 'lodash/get';
import shortId from 'shortid';
import { useTheme } from '@mui/material/styles';
import * as Sift from '@sift/types';
import * as EntityTypes from 'pages/Entity/typings';
import Avatar, { AvatarSize } from './Avatar';

// prettier-ignore
type Props = {
  className?: string;
  isEntity?: boolean; 
  size?: AvatarSize;
  whiteStroke?: boolean;
} & (
  | { person: Sift.Person | EntityTypes.Entity; }
  | { pictureUrl?: string; alt: string; }
);

function Container({
  className,
  isEntity = false,
  size,
  whiteStroke,
  person,
  pictureUrl: pictureUrlFromProps,
  alt,
}: any) {
  const theme = useTheme();
  const displayName = _get(person, ['displayName']) as string | undefined;

  const pictureUrlFromPerson = usePictureUrlFromPerson(person, theme);

  const _pictureUrl = pictureUrlFromProps || pictureUrlFromPerson;
  const pictureUrl = ensurePictureUrl(_pictureUrl, theme, isEntity);
  const shortIdForClipPath = shortId.generate();

  return (
    <Avatar
      className={className}
      src={pictureUrl}
      alt={alt || displayName || 'Person'}
      size={size}
      whiteStroke={whiteStroke}
      shortIdForClipPath={shortIdForClipPath}
    />
  );
}

// @ts-ignore
export default Container as React.ComponentType<React.PropsWithChildren<Props>>;
