/**
 * Layout
 *
 * Results are laid out with `Columns` and `Items`. The results are generally
 * constrained to a specific height. In order to take advantage of Flex layouts
 * we need to constrain the height of our containers and the height of our
 * individual items.
 *
 * Columns are already constrained to grid widths.
 *
 * This object helps us dynamically calculate this.
 */
export default function createLayout(theme) {
  const NUMBER_OF_ITEMS = 10;

  const props = {
    baseItemHeight: 32,
    interItemSpacing: 2,
    itemPadding: 4,
    maxRows: NUMBER_OF_ITEMS,
  };

  function heightForRows(size) {
    return this.baseItemHeight * size + this.interItemSpacing * (size - 1);
  }

  function maxColumnHeight(size = 3) {
    // (notes - pearl) calculating column height by heightForRows * items per column
    return (this.baseItemHeight * size + this.interItemSpacing * size) * (NUMBER_OF_ITEMS / 2);
  }

  function numberOfColumnsInResult(result) {
    let columns = 1;

    switch (result.type) {
      case 'aggregates':
        columns = 1;
        break;
      case 'entities':
        columns = 1;
        break;
      case 'recentSearch':
        columns = 1;
        break;
      case 'people':
        columns = result.items.length > 1 ? 2 : 1;
        break;
      default:
        break;
    }

    return columns;
  }

  return {
    heightForRows,
    maxColumnHeight,
    numberOfColumnsInResult,
    ...props,
  };
}
