import React, { useContext } from 'react';
import PortalContext from 'components/skipper/PortalProvider/context';
import ReactDOM from 'react-dom';
import shortId from 'shortid';

interface PortalProps {
  children: React.ReactNode;
}

export default function createPortal(name: string) {
  const portalId = shortId();

  function Portal({ children }: PortalProps) {
    const portalContext = useContext(PortalContext);

    if (!portalContext) {
      throw new Error(`[${name}Portal] could not find portal context`);
    }

    const { contentEls } = portalContext;

    const contentEl = contentEls[portalId];

    if (!contentEl) {
      return null;
    }

    return ReactDOM.createPortal(children, contentEl);
  }

  Portal.displayName = `${name}Portal`;
  Object.defineProperty(Portal, '__portalId', {
    get: () => portalId,
  });

  const PortalFixedTypings = Portal as typeof Portal & { __portalId: string };

  return PortalFixedTypings;
}
