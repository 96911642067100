import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import pluralize from 'pluralize';

// View
import Terminology from './Terminology';

// Selectors
const teamLeaderSelector = createSelector(
  state => state.config.person.fields.teamLeader.displayName,
  teamLeader => teamLeader,
);

// mapToProps
const mapDispatchToProps = {};

const mapStateToProps = (state, ownProps) => {
  const teamLeader = _get(state, 'config.person.fields.teamLeader.displayName')
    ? teamLeaderSelector(state)
    : 'Leader';
  const terminology = {
    ...state.client.terminology,
    teamLeader,
    teamLeader_plural: pluralize(teamLeader),
  };

  return {
    terminology,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Terminology);
