import React, { useContext } from 'react';
import { Flex, Button, Text, Loader } from '@fluentui/react-northstar';
import { TeamsThemeContext, Theme } from 'components/msTeams/MsTeamsMainTheme';

import InfoPageLayout from 'components/msTeams/embed/molecules/InfoPageLayout';

interface Props {
  onRefresh: () => void;
  isFetching: boolean;
}

function InsufficientPermission({ onRefresh, isFetching }: Props) {
  const themeContext = useContext(TeamsThemeContext);
  const styles = useStyles(themeContext);

  return (
    <InfoPageLayout>
      <Text style={styles.info} size="large">
        Sift does not have sufficient permissions to retrieve the list of meeting participants.
        Please ask the organizer of the meeting to sign into Sift, then refresh to try again.
      </Text>
      <Flex hAlign="center">
        <Button primary onClick={onRefresh} disabled={isFetching}>
          {isFetching ? <Loader size="small" /> : 'Refresh'}
        </Button>
      </Flex>
    </InfoPageLayout>
  );
}

const useStyles = (theme: Theme) => ({
  root: {},
  info: {
    marginBottom: theme.siteVariables.spacing.unit * 4,
  },
});

export default InsufficientPermission;
