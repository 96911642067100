// https://www.lingoapp.com/space/86663/k/B2DE4BDF-3C89-44EF-9D84-E833AEB7FE02/DE9D9C25-B9C4-41BA-A7E4-C14B64C64EA8/?tkn=covARoOwR4m_6_U8zowW36a_qrMaql0bmEcfIpfVgwI
export default {
  logomarkDotColors: {
    bubblegum: '#F25497',
    salmon: '#FF7276',
    tiger: '#F88D2B',
    gold: '#F5A800',
  },
  primaryPalette: {
    violet: '#60269E',
    amethyst: '#773DBD',
    eggplant: '#431A6E',
    lavender: '#D8C4E5',
  },
  secondaryPalette: {
    cobalt: '#2962FF',
    sky: '#6EA8FF',
    navy: '#001E70',
    artic: '#B1E4FF',
  },
  tertiaryPalette: {
    moss: '#4A7F1F',
    parakeet: '#61A60E',
    pine: '#00482F',
    lime: '#70D44B',
  },
  quaternaryPalette: {
    watermelon: '#D50057',
    bubblegum: '#F45197',
    merlot: '#960048',
    rose: '#F89ABA',
  },
  indicatorColors: {
    fire: '#FF342C',
    tiger: '#F88D2B',
    gold: '#F5A800',
    banana: '#FFDE03',
    parakeet: '#61A60E',
  },
  grayScale: {
    iron: '#212121',
    gray1: '#666666',
    gray2: '#979797',
    gray3: '#BDBDBD',
    appCanvasColor: '#FAFAFA',
    paperCardsColor: '#FFFFFF',
    cloud: '#F3F5F9',
  },
  // (pearl) note: `common` colors were added in addition to lingo colors
  common: {
    white: '#fff',
    black: '#212121',
  },
};
