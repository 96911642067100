import * as Types from 'components/Skills/typings';

export default {
  '100': {
    name: 'Beginner',
    descriptions: {
      you: "You've started to learn this skill, or have some initial experience.",
      thirdPerson: ' has started to learn this skill, or has some initial experience.',
    },
    value: 100 as Types.SkillLevel,
  },
  '200': {
    name: 'Intermediate',
    descriptions: {
      you: 'You have a working knowledge, but refer to materials to back you up.',
      thirdPerson: ' has a working knowledge, but refers to materials to back them up.',
    },
    value: 200 as Types.SkillLevel,
  },
  '300': {
    name: 'Advanced',
    descriptions: {
      you: "You've developed a depth of understanding, and frequently use it to a high standard.",
      thirdPerson:
        ' has developed a depth of understanding, and frequently uses it to a high standard.',
    },
    value: 300 as Types.SkillLevel,
  },
  '400': {
    name: 'Expert',
    descriptions: {
      you: 'You have authoritative knowledge on the subject, and can act as a reference to others.',
      thirdPerson:
        ' has authoritative knowledge on the subject, and can act as a reference to others.',
    },
    value: 400 as Types.SkillLevel,
  },
};

export const levelNameLookup = {
  '100': 'Beginner',
  '200': 'Intermediate',
  '300': 'Advanced',
  '400': 'Expert',
};
