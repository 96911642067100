import { defineFetch } from 'resift';

export default defineFetch({
  displayName: 'profile fetch',
  share: {
    namespace: 'person',
    merge: (previous, next) => ({
      ...previous,
      ...next,
    }),
  },
  make: (personId: string) => ({
    request: () => ({ http }: any) =>
      http({
        method: 'GET',
        route: `/people/${personId}`,
        query: {
          relationships: true,
          configuration: true,
          pageSize: 1,
        },
      }),
  }),
});
