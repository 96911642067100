import typography from './typography';
import { Shadows } from '@mui/material/styles/shadows';

export default {
  typography,
  shadows: [
    'none', // MUI default [0]
    '0px 2px 4px rgba(33, 33, 33, 0.07)', // [1]
    '0px 2px 2px rgba(33, 33, 33, 0.05), 0px 0px 2px rgba(33, 33, 33, 0.2)', // [2]
    '0px 4px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2)', // [3]
    '0px 6px 12px -6px rgba(33, 33, 33, 0.08), 0px 8px 24px -4px rgba(33, 33, 33, 0.08)', // [4]
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)', // MUI default [5]
    '0px 6px 14px -6px rgba(33, 33, 33, 0.12), 0px 10px 32px -4px rgba(33, 33, 33, 0.1)', // [6]
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)', // MUI default [7]
    '0px 8px 18px -6px rgba(33, 33, 33, 0.12), 0px 12px 42px -4px rgba(33, 33, 33, 0.12)', // [8]
    '0px 8px 18px -6px rgba(33, 33, 33, 0.12), 0px 12px 42px -4px rgba(33, 33, 33, 0.12)', // [9]
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)', // MUI default [10]
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)', // MUI default [11]
    '0px 8px 22px -6px rgba(33, 33, 33, 0.12), 0px 14px 64px -4px rgba(33, 33, 33, 0.12)', // [12]
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)', // MUI default [13]
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)', // MUI default [14]
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)', // MUI default [15]
    '0px 8px 22px -6px rgba(33, 33, 33, 0.12), 0px 14px 64px -4px rgba(33, 33, 33, 0.12)', // [16]
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)', // MUI default [17]
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)', // MUI default [18]
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)', // MUI default [19]
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)', // MUI default [20]
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)', // MUI default [21]
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)', // MUI default [22]
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)', // MUI default [23]
    '0px 8px 28px -6px rgba(33, 33, 33, 0.12), 0px 18px 88px -4px rgba(33, 33, 33, 0.14)', // [24]
  ] as Shadows,
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          height: 34,
        },
        avatar: {
          marginLeft: 1,
          marginTop: 2,
          marginBottom: 2,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 8, // the default material ui spacing unit
          margin: 0,
        },
      },
    },
  },
  zIndex: {
    surface: 1,
    loader: 1150,
    max: 1600,
  },
};
