/**
 * Typography
 */

import React from 'react';

const fontWeight = {
  fontWeightExtraLight: 200,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightBlack: 900,
};

export default {
  ...fontWeight,
  fontFamily: "'Source Sans Pro', sans-serif",
  h1: {
    fontWeight: fontWeight.fontWeightLight,
    fontSize: 96,
    letterSpacing: -1.5,
    lineHeight: '112px',
  },
  h2: {
    fontWeight: fontWeight.fontWeightLight,
    size: 60,
    letterSpacing: -0.5,
    lineHeight: '72px',
  },
  h3: {
    fontWeight: fontWeight.fontWeightRegular,
    fontSize: 48,
    lineHeight: '56px',
  },
  h4: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: 34,
    letterSpacing: 0.25,
    lineHeight: '42px',
  },
  h5: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: 20,
    lineHeight: '27px',
  },
  h6: {
    fontWeight: fontWeight.fontWeightSemiBold,
    fontSize: 18,
    letterSpacing: 0.15,
    lineHeight: '29px',
  },
  body1: {
    fontWeight: fontWeight.fontWeightRegular,
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: '24px',
  },
  body2: {
    fontWeight: fontWeight.fontWeightRegular,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: '20px',
  },
  subtitle1: {
    fontWeight: fontWeight.fontWeightRegular,
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: '28px',
  },
  subtitle2: {
    fontWeight: fontWeight.fontWeightSemiBold,
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: '22px',
  },
  caption: {
    fontWeight: fontWeight.fontWeightRegular,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: '20px',
  },
  overline: {
    fontWeight: fontWeight.fontWeightRegular,
    fontSize: 12,
    letterSpacing: 1,
    lineHeight: '32px',
    textTransform: 'uppercase' as React.CSSProperties['textTransform'],
  },
  button: {
    fontWeight: fontWeight.fontWeightBold,
    fontSize: 14,
    letterSpacing: 0.4,
    lineHeight: '24px',
    textTransform: 'uppercase' as React.CSSProperties['textTransform'],
  },
  special: {
    h4InBar: {
      fontWeight: fontWeight.fontWeightBold,
      fontSize: 24,
      letterSpacing: 0.25,
      lineHeight: '30px',
    },
    h5SemiBold: {
      fontWeight: fontWeight.fontWeightSemiBold,
      fontSize: 20,
      lineHeight: '27px',
    },
    body1Bold: {
      fontWeight: fontWeight.fontWeightBold,
      fontSize: 16,
      letterSpacing: 0.15,
      lineHeight: '24px',
    },
    body2Bold: {
      fontWeight: fontWeight.fontWeightBold,
      fontSize: 14,
      letterSpacing: 0.15,
      lineHeight: '20px',
    },
  },
};
