// copied and pasted from:
// https://github.com/JustSift/Directory.WebReact/blob/17780b174e36b08ee42311215def62421b4671c8/src/components/Profile/Header/EditPhoto/AvatarCropper/AvatarCropper.js
// React
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Slider from 'react-rangeslider';
import AvatarEditor from 'react-avatar-editor';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    dialogContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  };
});

// Component
function AvatarCropper({ pictureUrl, open, onClose, onCrop }) {
  const { classes } = useStyles();
  const [scale, setScale] = useState(1.2);
  let cropperElement = useRef(null);

  const scaleMin = 1;
  const scaleMax = 5;

  const handleScaler = value => {
    setScale(value);
  };

  const handleCrop = () => {
    if (cropperElement && cropperElement.getImage()) {
      onCrop(cropperElement.getImage().toDataURL('image/jpeg'));
      onClose();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  if (!pictureUrl) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Crop your photo</DialogTitle>
      <DialogContent>
        <div className={classes.dialogContent}>
          <AvatarEditor
            image={pictureUrl}
            width={300}
            height={300}
            borderRadius={150}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={scale}
            rotate={0}
            ref={el => {
              cropperElement = el;
            }}
          />
          <Slider
            min={scaleMin}
            max={scaleMax}
            step={0.01}
            value={scale}
            onChange={handleScaler}
            style={{ width: 300 }}
            tooltip={false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleCrop} color="secondary" variant="contained">
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AvatarCropper.propTypes = {
  open: PropTypes.bool.isRequired,
  pictureUrl: PropTypes.string.isRequired,
  onCrop: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AvatarCropper;
