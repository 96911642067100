import React from 'react';
import PropTypes from 'prop-types';

// Components
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

// Styles
import ThemeOverrideComponent from 'styles/skipper/ThemeOverrideComponent';
import createPaletteColor from 'styles/createPaletteColor';

export default class ThemeSnackbar extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { open, onClose } = this.props;
    const primary = createPaletteColor('primary', '#F5A800');

    return (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message="You're currently previewing a theme."
        action={
          <ThemeOverrideComponent
            theme={{
              palette: { primary },
            }}
          >
            <Button onClick={onClose}>Remove</Button>
          </ThemeOverrideComponent>
        }
      />
    );
  }
}
