import React, { useContext } from 'react';
import { useDispatch, useStatus } from 'resift';
import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';
import { useTheme } from '@mui/material/styles';
import RunnerContext from '@sift/runner/Context';
import useRouter from '@sift/resift/useRouter';
import usePassiveRedux from '@sift/resift/usePassiveRedux';
import _get from 'lodash/get';
import useSlideInfo from 'pages/Onboarding/helpers/useSlideInfo';

import makeUploadPictureFetch from 'pages/Profile/fetches/uploadPictureFetch';
import makeUpdateCompletedSlidesFetch from 'pages/Onboarding/fetches/updateCompletedSlidesFetch';

import { Redirect } from 'react-router';
import PhotoUploader from './PhotoUploader';

function Container() {
  const theme = useTheme();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const slideInfo = useSlideInfo();
  const { nextSlideLink, currentSlide } = slideInfo;

  if (!nextSlideLink) {
    throw new Error('[PhotoUploader Container] expected nextSlideLink to be truthy');
  }
  if (!currentSlide) {
    throw new Error('[PhotoUploader Container] expected currentSlide to be truthy');
  }

  const contextValue = useContext(RunnerContext);

  if (!contextValue) {
    throw new Error('[PhotoUploader Container] could not find runner context');
  }

  const { root } = contextValue;

  const personId = _get(root, ['id']) as string | undefined;
  if (!personId) {
    throw new Error('[PhotoUploader Container] could not find person id');
  }

  const uploadPictureFetch = makeUploadPictureFetch(personId);
  const updateCompletedSlidesFetch = makeUpdateCompletedSlidesFetch();

  const status = useStatus(uploadPictureFetch);

  const handleContinue = async (dataUri: string) => {
    if (dataUri.startsWith('data')) {
      await dispatch(uploadPictureFetch(dataUri));
    }
    await dispatch(updateCompletedSlidesFetch(currentSlide));
    history.push(nextSlideLink);
  };

  const handleSkip = async () => {
    await dispatch(updateCompletedSlidesFetch(currentSlide));
    history.push(nextSlideLink);
  };

  const clientDisplayName = usePassiveRedux(
    state => _get(state, ['client', 'displayName']) as string,
  );

  const customPictureUrl = _get(root, ['customPictureUrl']) as string | undefined;
  const officialPictureUrl = _get(
    root,
    ['officialPictureUrl'],
    theme.pictureUrl || createPictureUrlFromColor(theme.palette.primary.dark),
  ) as string;

  const socialPhotosEnabled = _get(root, ['_directorySettings', 'socialPhotos', 'enabled'], true);

  if (!socialPhotosEnabled) {
    return <Redirect to={nextSlideLink} />;
  }

  return (
    <PhotoUploader
      clientDisplayName={clientDisplayName}
      onContinue={handleContinue}
      customPictureUrl={customPictureUrl}
      officialPictureUrl={officialPictureUrl}
      status={status}
      onSkip={handleSkip}
    />
  );
}

export default Container;
