import { connect } from 'react-redux';
import AdvancedSearchForm from './AdvancedSearchForm';

const mapStateToProps = state => ({
  config: state.configurations,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvancedSearchForm);
