import React from 'react';

// Components
import { SnackbarProvider as NotistackSnackbarProvider, SnackbarProviderProps } from 'notistack';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import CheckedIcon from 'components/Icons/Checked';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      minWidth: 'auto !important',
    },
    variantSuccess: {
      backgroundColor: `${theme.palette.common.black} !important`,
    },
    iconVariantSuccess: {
      marginRight: 10,
    },
  };
});

function SnackbarProvider(props: SnackbarProviderProps) {
  const { classes } = useStyles();

  return (
    <NotistackSnackbarProvider
      classes={{
        root: classes.root,
        variantSuccess: classes.variantSuccess,
      }}
      iconVariant={{
        success: <CheckedIcon className={classes.iconVariantSuccess} />,
      }}
      {...props}
    />
  );
}

export default SnackbarProvider;
