// React
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OPEN_ROLE_ENTITY_KEY } from 'pages/OrgChart/consts';
// Components
import Column from './Column';
import PersonItem from '../Items/PersonItem';
import PersonCardItem from '../Items/PersonCardItem';
import LayoutContext from 'components/BigSearch/Results/LayoutContext';

import { makeStyles } from 'tss-react/mui';

function PeopleColumn(props) {
  const { result, query, columns, width, ...otherProps } = props;
  const { classes } = useStyles();

  // NOTE: If the height of PersonItem changes, the number of people displayed will likely need
  // to update
  const peopleToDisplay = result.items.slice(0, 14);
  const layout = useContext(LayoutContext);
  const isTopMatch = result.items.length === 1 && columns === 1;
  const topMatchItem = isTopMatch && result.items[0];
  const topMatchIsOpenRole = topMatchItem && topMatchItem.entityTypeKey === OPEN_ROLE_ENTITY_KEY;
  const personItemWidth =
    result.items.length <= 5 && window.innerWidth < 959
      ? '100%'
      : `calc(100% / ${columns} - ${columns === 1 ? 0 : layout.itemPadding * 8}px)`;
  const showMargin =
    (window.innerWidth < 959 && result.items.length <= 5) || window.innerWidth < 600;

  const nextClasses = { ...classes };
  if (!isTopMatch) {
    delete nextClasses.items;
  }

  return (
    <Column
      title={result.display.header}
      classes={nextClasses}
      isTopMatch={isTopMatch}
      itemsStyle={{ margin: showMargin ? 0 : `0 -${layout.itemPadding * 4}px` }}
      style={{ height: isTopMatch ? 'inherit' : undefined }}
      {...otherProps}
    >
      {isTopMatch ? (
        <>
          {topMatchIsOpenRole ? (
            <PersonItem
              value={topMatchItem}
              query={query}
              style={{
                width: personItemWidth,
              }}
            />
          ) : (
            <PersonCardItem value={topMatchItem} query={query} />
          )}
        </>
      ) : (
        peopleToDisplay.map((item, index) => (
          <PersonItem
            value={item}
            query={query}
            style={{
              width: personItemWidth,
              margin: showMargin ? 0 : `0 ${layout.itemPadding * 4}px`,
            }}
            key={item.id + index}
          />
        ))
      )}
    </Column>
  );
}

const useStyles = makeStyles()(() => {
  return {
    items: {
      maxHeight: 'none',
      height: 'inherit',
    },
  };
});

PeopleColumn.propTypes = {
  result: PropTypes.object,
  query: PropTypes.string,
  width: PropTypes.number,
  onItemSelect: PropTypes.func,
  columns: PropTypes.number.isRequired,
};

export default PeopleColumn;
