import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import SupportDrawer from './Drawer';

// Component
class Support extends PureComponent {
  static propTypes = {
    status: PropTypes.object.isRequired,
    supportTicket: PropTypes.object,
    supportEnabled: PropTypes.bool.isRequired,
    openDrawer: PropTypes.bool.isRequired,
    clientName: PropTypes.string,
    userEmail: PropTypes.string.isRequired,
    toggleSupportDrawer: PropTypes.func.isRequired,
    createSupportMessage: PropTypes.func.isRequired,
    updateSupportMessage: PropTypes.func.isRequired,
    addAttachments: PropTypes.func.isRequired,
  };

  render() {
    const {
      supportTicket,
      supportEnabled,
      clientName,
      userEmail,
      openDrawer,
      toggleSupportDrawer,
      createSupportMessage,
      updateSupportMessage,
      addAttachments,
      status,
    } = this.props;

    if (!supportEnabled) {
      return null;
    }

    return (
      <div>
        <SupportDrawer
          clientName={clientName}
          userEmail={userEmail}
          openDrawer={openDrawer}
          toggleSupportDrawer={toggleSupportDrawer}
          createSupportMessage={createSupportMessage}
          updateSupportMessage={updateSupportMessage}
          addAttachments={addAttachments}
          supportTicket={supportTicket}
          status={status}
        />
      </div>
    );
  }
}

export default Support;
