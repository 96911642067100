/**
 * use the following array to configure the apps in the app links. this
 * configuration is used in the `MenuItems` component to map `person.products`
 * to their titles and paths
 *
 * `key` is *must* be the same key in `person.products` from `/api/people/me`
 */
export default [
  { key: 'directory', to: '/', title: 'Connect' },
  { key: 'pulse', to: '/pulse', title: 'Pulse' },
  { key: 'insights', to: '/insights', title: 'Insights' },
  { key: 'admin', to: '/admin', title: 'Admin' },
];
