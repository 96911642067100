// MUI official suggestion of replacement of `withWidth`
// https://material-ui.com/components/use-media-query/#migrating-from-withwidth

import { Theme, useTheme, Breakpoint, useMediaQuery } from '@mui/material';

function useWidth() {
  const theme: Theme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

export default useWidth;
