import React from 'react';
import useAnalytics from 'helpers/useAnalytics';

import { NavLink } from 'react-router-dom';
import NavButton from '../NavButton';

interface Props {
  toPath: string;
  displayName: string;
  exact?: boolean;
}

function NavigationLink({ toPath, displayName, exact = true }: Props) {
  const analytics = useAnalytics();

  return (
    <NavLink
      to={toPath}
      exact={exact}
      onClick={() => {
        analytics.track(`${displayName} Tab Clicked`, { category: 'Navigation' });
      }}
    >
      <NavButton toPath={toPath}>{displayName}</NavButton>
    </NavLink>
  );
}

export default NavigationLink;
