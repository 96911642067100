import React from 'react';
import PropTypes from 'prop-types';
import SkipTranslate from 'components/atoms/SkipTranslate';

class SeparatedCopy extends React.PureComponent {
  static propTypes = {
    person: PropTypes.object.isRequired,
    variant: PropTypes.string,
  };

  render() {
    const { variant, person } = this.props;

    const separationText = person.separationImplicit ? 'was removed on: ' : 'was separated on: ';

    if (variant === 'long') {
      return (
        <>
          <SkipTranslate Component="span">{person.firstName}</SkipTranslate> {separationText}{' '}
          {person.separationDate}
        </>
      );
    }

    if (person.separationImplicit) {
      return `Removed from Sift on : ${person.separationDate}`;
    } else {
      return `Separated on : ${person.separationDate}`;
    }
  }
}

export default SeparatedCopy;
