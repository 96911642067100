/**
 * takes in a file and returns a base 64 string
 */
export default function getBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.addEventListener('load', () => {
      resolve(reader.result as string);
    });
    reader.addEventListener('error', err => {
      reject(err);
    });
  });
}
