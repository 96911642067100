import { useEffect } from 'react';
import { configureScope } from '@sentry/browser';

interface Params {
  person: any;
}

export default function useConfigureSentryEffect({ person }: Params) {
  useEffect(() => {
    if (!person) {
      return;
    }

    configureScope(scope => {
      scope.setUser({
        id: person.id,
        email: person.email,
      });
    });
  }, [person]);
}
