import Start from 'pages/Onboarding/pages/Start';
import LinkedInFork from 'pages/Onboarding/pages/LinkedInFork';
import LinkedInUploader from 'pages/Onboarding/pages/LinkedInUploader';
import LinkedInResults from 'pages/Onboarding/pages/LinkedInResults';
import FieldTemplate from 'pages/Onboarding/pages/FieldTemplate';
import ObjectCollectionTemplate from 'pages/Onboarding/pages/ObjectCollectionTemplate';
import PhotoUploader from 'pages/Onboarding/pages/PhotoUploader';
import Assistants from 'pages/Onboarding/pages/Assistants';
import ConfettiPage from 'pages/Onboarding/pages/ConfettiPage';

interface Slide {
  /**
   * this displayName isn't required for some paths. this is used in the nav
   */
  displayName?: string;
  path: string;
  type: 'fullscreen' | 'nav';
  component: React.ComponentType<React.PropsWithChildren<React.PropsWithChildren<any>>>;
}

// NOTE: there are some hardcoded links in the app e.g. the LinkedInFork and the FieldTemplate
// when updating this slides array, please ensure that those pages are checked
const slides: Slide[] = [
  { path: '/start', type: 'fullscreen', component: Start },
  { path: '/linkedin', type: 'fullscreen', component: LinkedInFork },
  { path: '/linkedin-uploader', type: 'fullscreen', component: LinkedInUploader },
  { path: '/linkedin-results', type: 'fullscreen', component: LinkedInResults },
  { path: '/photos', type: 'nav', component: PhotoUploader, displayName: 'Photo Uploader' },
  { path: '/about-me', type: 'nav', component: FieldTemplate },
  { path: '/what-i-do-here', type: 'nav', component: FieldTemplate },
  { path: '/skills', type: 'nav', component: ObjectCollectionTemplate },
  { path: '/education', type: 'nav', component: ObjectCollectionTemplate },
  { path: '/experience', type: 'nav', component: ObjectCollectionTemplate },
  { path: '/languages', type: 'nav', component: ObjectCollectionTemplate },
  { path: '/assistants', type: 'nav', component: Assistants },
  { path: '/interests', type: 'nav', component: FieldTemplate },
  { path: '/done', type: 'fullscreen', component: ConfettiPage },
];

export default slides;
