import React from 'react';
import { getLuminance } from 'polished';

// Components
import SvgIcon from '@mui/material/SvgIcon';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import hasBadContrast from 'styles/skipper/hasBadContrast';
import brandColors from 'styles/skipper/brandColors';

const aspectRatio = 308 / 93;

const bubbleGum = '#F25497';
const salmon = '#FF7276';
const tiger = '#F88D2B';
const gold = '#F5A800';

interface Props {
  className?: string;
  backgroundColor?: string;
  color?: string;
  height: number;
}

function Sift({
  className,
  height,
  backgroundColor: _backgroundColor,
  color,
  ...restOfProps
}: Props) {
  const { classes } = useStyles({ backgroundColor: _backgroundColor || '#ffffff' });

  return (
    <SvgIcon
      className={classNames(classes.root, className)}
      viewBox="0 0 308 93"
      style={{ height, width: height * aspectRatio }}
      {...restOfProps}
    >
      <g
        className={classes.bubbleGumDot}
        style={{ fill: color }}
        transform="translate(0.000000, 25.000000)"
      >
        <circle cx="14.89" cy="14.32" r="14.03" />
      </g>
      <g
        className={classes.salmonDot}
        style={{ fill: color }}
        transform="translate(39.000000, 25.000000)"
      >
        <circle cx="14.77" cy="14.32" r="14.03" />
      </g>
      <g
        className={classes.tigerDot}
        style={{ fill: color }}
        transform="translate(78.000000, 25.000000)"
      >
        <circle cx="14.59" cy="14.32" r="14.03" />
      </g>
      <g
        className={classes.goldDot}
        style={{ fill: color }}
        transform="translate(39.000000, 63.000000)"
      >
        <circle cx="14.77" cy="14.28" r="14.03" />
      </g>
      <path
        className={classes.whiteOrBlack}
        d="M120.05,70.38 L133.57,62.47 C133.784656,62.348022 134.041382,62.324182 134.274828,62.4045485 C134.508273,62.4849151 134.69592,62.6617358 134.79,62.89 C138.07,70.63 144.24,75.58 154.89,75.58 C165.54,75.58 169.13,71.13 169.13,66.17 C169.13,59.56 163.13,57.02 149.93,53.17 C136.33,49.23 123.11,43.51 123.11,26.6 C123.11,9.69 137.11,0.15 152.35,0.15 C166.69,0.15 177.72,7.37 183.78,19.39 C183.97847,19.7977143 183.8305,20.289498 183.44,20.52 L170.18,28.18 C169.974314,28.2947567 169.730564,28.3201316 169.505652,28.2502011 C169.28074,28.1802705 169.094352,28.0211549 168.99,27.81 C165.8,21.52 160.99,17.19 152.35,17.19 C144.98,17.19 140.65,21.01 140.65,26.09 C140.65,31.56 144.09,34.48 157.56,38.55 C171.68,43 186.68,47.71 186.68,65.89 C186.68,82.54 173.33,92.71 154.38,92.71 C136.52,92.71 124.67,84.24 119.68,71.4 C119.550239,71.0153168 119.703786,70.5920253 120.05,70.38 Z"
        style={{ fill: color }}
      />
      <path
        className={classes.whiteOrBlack}
        d="M195.8,3.75 C195.849259,3.6921833 195.9065,3.64167624 195.97,3.6 C204.03,-2.02 212.68,4.05 212.68,11.6 C212.58721,17.1502355 208.061011,21.6007756 202.51,21.6 C194.51,21.62 188.41,11.8 195.8,3.75 Z M195.23,29.24 L209.92,29.24 C210.392709,29.2454029 210.774597,29.6272912 210.78,30.1 L210.78,90.1 C210.774522,90.571055 210.391087,90.9500318 209.92,90.95 L195.23,90.95 C194.758913,90.9500318 194.375478,90.571055 194.37,90.1 L194.37,30.1 C194.375403,29.6272912 194.757291,29.2454029 195.23,29.24 Z"
        style={{ fill: color }}
      />
      <path
        className={classes.whiteOrBlack}
        d="M245.13,26.76 L245.13,29.24 L257.76,29.24 C258.231055,29.2454778 258.610032,29.6289131 258.61,30.1 L258.61,42.17 C258.61,42.639442 258.229442,43.02 257.76,43.02 L246,43.02 C245.527291,43.0254029 245.145403,43.4072912 245.14,43.88 L245.14,90.11 C245.14,90.579442 244.759442,90.96 244.29,90.96 L229.59,90.96 C229.362838,90.9626727 229.14406,90.8743006 228.982484,90.7146041 C228.820909,90.5549076 228.729984,90.3371774 228.73,90.11 L228.73,43.88 C228.730032,43.4089131 228.351055,43.0254778 227.88,43.02 L220.43,43.02 C219.960558,43.02 219.58,42.639442 219.58,42.17 L219.58,30.1 C219.579968,29.6289131 219.958945,29.2454778 220.43,29.24 L228.73,29.24 L228.73,26.76 C228.73,9.45 238.13,-0.81 257.79,0.14 C258.249275,0.161384511 258.610498,0.54022749 258.61,1 L258.61,15 C258.610589,15.2362254 258.512848,15.4620414 258.340216,15.6232904 C258.167585,15.7845394 257.935637,15.8666755 257.7,15.85 C249.74,15.52 245.13,18.42 245.13,26.76 Z"
        style={{ fill: color }}
      />
      <path
        className={classes.whiteOrBlack}
        d="M293.59,43 C293.118945,43.0054778 292.739968,43.3889131 292.74,43.86 L292.74,69.6 C292.74,76.25 297.38,76.6 306.2,76.14 C306.434891,76.1263087 306.665135,76.209455 306.837082,76.370065 C307.009029,76.530675 307.107663,76.7547215 307.11,76.99 L307.11,90.19 C307.10728,90.6336024 306.771232,91.0041176 306.33,91.05 C283.92,93.35 276.33,86.58 276.33,69.6 L276.33,43.88 C276.330032,43.4089131 275.951055,43.0254778 275.48,43.02 L266.12,43.02 C265.650558,43.02 265.27,42.639442 265.27,42.17 L265.27,30.1 C265.269968,29.6289131 265.648945,29.2454778 266.12,29.24 L275.48,29.24 C275.949442,29.24 276.33,28.859442 276.33,28.39 L276.33,17.39 C276.328314,17.0292741 276.551929,16.705832 276.89,16.58 L291.59,11.25 C291.852394,11.1547364 292.144838,11.1943832 292.372398,11.3560701 C292.599957,11.517757 292.733621,11.7808714 292.73,12.06 L292.73,28.39 C292.73,28.859442 293.110558,29.24 293.58,29.24 L306.24,29.24 C306.714965,29.24 307.1,29.6250351 307.1,30.1 L307.1,42.17 C307.100016,42.3971774 307.009091,42.6149076 306.847516,42.7746041 C306.68594,42.9343006 306.467162,43.0226727 306.24,43.02 L293.59,43 Z"
        style={{ fill: color }}
      />
    </SvgIcon>
  );
}

const useStyles = makeStyles<{ backgroundColor: string }>()((theme: any, { backgroundColor }) => {
  const { common, primary, secondary } = theme.palette;

  const bgColor: string =
    backgroundColor === 'primary'
      ? primary.main
      : backgroundColor === 'secondary'
      ? secondary.main
      : '#ffffff';

  const shouldUseColoredDots = () => {
    if (
      theme.palette.primary.main.toLowerCase() === brandColors.primaryPalette.violet.toLowerCase()
    )
      return true;

    if (hasBadContrast(bgColor, bubbleGum)) return false;
    if (hasBadContrast(bgColor, salmon)) return false;
    if (hasBadContrast(bgColor, tiger)) return false;
    if (hasBadContrast(bgColor, gold)) return false;
    return true;
  };

  const shouldUseBlack = getLuminance(bgColor) > 0.3;

  const returnColor = (color: string) => {
    if (shouldUseColoredDots()) {
      return color;
    } else if (!shouldUseColoredDots() && shouldUseBlack) {
      return common.black;
    } else {
      return common.white;
    }
  };

  return {
    root: {},
    bubbleGumDot: { fill: returnColor(bubbleGum) },
    salmonDot: {
      fill: returnColor(salmon),
    },
    tigerDot: {
      fill: returnColor(tiger),
    },
    goldDot: {
      fill: returnColor(gold),
    },
    whiteOrBlack: {
      fill: shouldUseBlack ? common.black : common.white,
    },
  };
});

export default Sift;
