import React from 'react';
import PropTypes from 'prop-types';

import Chip from 'components/skipper/Chip';
import Avatar from '@mui/material/Avatar';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import SeparatedCopy from './Copy';
import SeparatedDetailDialog from './DetailDialog';

import { makeStyles } from 'tss-react/mui';
import { separatedBackground } from './styles';

function SeparatedChip(props) {
  const { person } = props;
  const { classes } = useStyles();

  return (
    <SeparatedDetailDialog person={person}>
      <Chip
        className={classes.chip}
        avatar={
          <Avatar>
            <ErrorOutline className={classes.icon} />
          </Avatar>
        }
        label={<SeparatedCopy person={person} />}
      />
    </SeparatedDetailDialog>
  );
}

const useStyles = makeStyles()(() => {
  return {
    root: {
      cursor: 'pointer',
    },
    avatar: {
      backgroundColor: 'transparent',
    },
    icon: { backgroundColor: separatedBackground.backgroundColor },
    chip: {
      backgroundColor: separatedBackground.backgroundColor,
    },
  };
});

SeparatedChip.propTypes = {
  person: PropTypes.object.isRequired,
};

export default SeparatedChip;
