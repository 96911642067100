import { Status, States } from '@sift/resift/models/Status';

// ------------------------------------
// Constants
// ------------------------------------
export const SUPPORT_DRAWER_TOGGLE = 'SUPPORT_DRAWER_TOGGLE';

export const SUPPORT_MESSAGE_CREATE = 'SUPPORT_MESSAGE_CREATE';
export const SUPPORT_MESSAGE_CREATE_SUCCESS = 'SUPPORT_MESSAGE_CREATE_SUCCESS';
export const SUPPORT_MESSAGE_CREATE_ERROR = 'SUPPORT_MESSAGE_CREATE_ERROR';

export const SUPPORT_MESSAGE_UPDATE = 'SUPPORT_MESSAGE_UPDATE';
export const SUPPORT_MESSAGE_UPDATE_SUCCESS = 'SUPPORT_MESSAGE_UPDATE_SUCCESS';
export const SUPPORT_MESSAGE_UPDATE_ERROR = 'SUPPORT_MESSAGE_UPDATE_ERROR';

export const SUPPORT_MESSAGE_ATTACHMENTS_ADD = 'SUPPORT_MESSAGE_ATTACHMENTS_ADD';
export const SUPPORT_MESSAGE_ATTACHMENTS_ADD_SUCCESS = 'SUPPORT_MESSAGE_ATTACHMENTS_ADD_SUCCESS';
export const SUPPORT_MESSAGE_ATTACHMENTS_ADD_ERROR = 'SUPPORT_MESSAGE_ATTACHMENTS_ADD_ERROR';

// ------------------------------------
// Actions
// ------------------------------------
export const toggleSupportDrawer = () => ({
  type: SUPPORT_DRAWER_TOGGLE,
});

export const createSupportMessage = (body, query) => {
  return {
    type: SUPPORT_MESSAGE_CREATE,
    identifier: 'create-support-message',
    payload: {
      query,
      data: body,
    },
  };
};

export const updateSupportMessage = (body, messageId, query) => {
  return {
    type: SUPPORT_MESSAGE_UPDATE,
    identifier: 'update-support-message',
    payload: {
      data: body,
      messageId,
      query,
    },
  };
};

export const addAttachments = (body, messageId) => {
  return {
    type: SUPPORT_MESSAGE_ATTACHMENTS_ADD,
    identifier: body.name,
    payload: {
      data: body,
      messageId,
    },
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SUPPORT_DRAWER_TOGGLE]: (state, action) => {
    return {
      ...state,
      supportDrawerIsOpen: !state.supportDrawerIsOpen,
    };
  },

  [SUPPORT_MESSAGE_CREATE_SUCCESS]: (state, action) => {
    // if supportTicket is undefined, then the state will refresh
    const supportTicket = action.payload.data.sent ? undefined : { ...action.payload.data };
    return {
      ...state,
      supportTicket,
    };
  },
  [SUPPORT_MESSAGE_CREATE_ERROR]: (state, action) => {
    return {
      ...state,
      status: new Status(States.ERROR),
    };
  },

  [SUPPORT_MESSAGE_UPDATE]: (state, action) => {
    return {
      ...state,
      status: new Status(States.LOADING),
    };
  },
  [SUPPORT_MESSAGE_UPDATE_SUCCESS]: (state, action) => {
    let supportTicket = {
      ...state.supportTicket,
      ...action.payload.data,
    };

    if (action.payload.data.sent) {
      supportTicket = undefined;
    }

    return {
      ...state,
      supportTicket,
      status: new Status(States.NORMAL),
    };
  },
  [SUPPORT_MESSAGE_UPDATE_ERROR]: (state, action) => {
    return {
      ...state,
      status: new Status(States.ERROR),
    };
  },

  [SUPPORT_MESSAGE_ATTACHMENTS_ADD]: (state, action) => {
    return {
      ...state,
      status: new Status(States.LOADING),
    };
  },
  [SUPPORT_MESSAGE_ATTACHMENTS_ADD_SUCCESS]: (state, action) => {
    return {
      ...state,
      supportTicket: {
        ...state.supportTicket,
        ...action.payload.data,
      },
      status: new Status(States.NORMAL),
    };
  },
  [SUPPORT_MESSAGE_ATTACHMENTS_ADD_ERROR]: (state, action) => {
    return {
      ...state,
      status: new Status(States.ERROR),
    };
  },
};

const initialState = {
  status: new Status(States.NORMAL),
  supportDrawerIsOpen: false,
  supportTicket: undefined,
};

export default function supportDrawerReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
