import React from 'react';
import PropTypes from 'prop-types';
import _last from 'lodash/last';
import _get from 'lodash/get';
import typography from 'helpers/typography';

// UI
import CloseIcon from '@mui/icons-material/Close';
import Item from './Item';
import EntityLink from 'components/Link/EntityLink';
import PersonLink from 'components/Link/PersonLink';
import SearchLink from 'components/Link/SearchLink';
import SkipTranslate from 'components/atoms/SkipTranslate';

import { makeStyles } from 'tss-react/mui';
import readableColor from 'styles/skipper/readableColor';

/**
 * Recent searches differ from other items used in big search results in that
 * we expect a value but no field key. The value has a type property which will
 * include a partial object of that type.
 */
function RecentSearchItem({ value, index, deleteSearch }) {
  const { classes } = useStyles();

  let LinkElem = null;
  let linkText = null;
  let linkProps = {};

  const handleDeleteSearch = e => {
    e.preventDefault();
    deleteSearch(index);
  };

  // Person
  if (value.type === 'person') {
    LinkElem = PersonLink;
    linkProps = { person: value };
    linkText = <SkipTranslate Component="span">{value.displayName}</SkipTranslate>;
  }
  // Entity
  else if (value.type === 'entity') {
    LinkElem = EntityLink;
    linkProps = { entity: value };
    linkText = value.displayName;
  }
  // Aggregate (mayyyybe)
  else if (value.type === 'search') {
    let _value = { ...value.query };
    delete _value.page;
    const key = _last(Object.keys(_value));
    const displayName = _get(value, 'displayName');

    if (!key) {
      console.warn('Recent Search "search" value invalid.'); // eslint-disable-line
      return null;
    }

    if (!_value[key]) {
      return null;
    }

    LinkElem = SearchLink;
    linkProps = { value: _value[key], fieldKey: key };
    linkText = displayName ?? _value[key];
  }

  const LinkTextComponent = !value.query?.q ? 'div' : SkipTranslate;

  return (
    <LinkElem {...linkProps} classes={{ root: classes.linkRoot }}>
      <Item
        rows={1}
        analyticsCardObject={{
          event: 'Searched by Search History',
          properties: {
            category: 'Big Search',
            label: linkText,
          },
        }}
        icon={onClick => (
          <div onClick={onClick}>
            <CloseIcon className={classes.icon} onClick={handleDeleteSearch} />
          </div>
        )}
        classes={{ root: classes.root }}
      >
        <LinkTextComponent className={classes.text}>{linkText}</LinkTextComponent>
      </Item>
    </LinkElem>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      textTransform: 'capitalize',
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
      borderRadius: 4,
      '&:hover': {
        color: readableColor(theme.palette.primary.dark),
      },
    },
    linkRoot: {
      width: '100%',
    },
    text: {
      ...typography(theme, 'special', 'body1Bold'),
      color: 'inherit',
      flex: '1 1 auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
    },
    icon: {
      flex: '0 0 auto',
      justifyContent: 'center',
      marginRight: '5px',
    },
  };
});

RecentSearchItem.propTypes = {
  value: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  index: PropTypes.number,
  deleteSearch: PropTypes.func,
};

export default RecentSearchItem;
