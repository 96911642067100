import React from 'react';
import typography from 'helpers/typography';
import usePageTrackingEffect from 'components/Page/usePageTrackingEffect';

// Components
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flex: '1 0 auto',
      width: 1280,
      maxWidth: '100%',
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(0, 4),
      alignSelf: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    copyContainer: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(4),
        width: 600,
        maxWidth: '100%',
      },
      '& > *': {
        flex: '0 0 auto',
      },
    },
    title: {
      ...typography(theme, 'h4'),
      marginBottom: theme.spacing(4),
    },
    subtitle: {
      ...typography(theme, 'body1'),
      marginBottom: theme.spacing(4),
    },
    outerButtonsContainer: {
      display: 'flex',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    linkedinButton: {
      marginBottom: theme.spacing(1),
    },
    buttonText: {
      ...typography(theme, 'subtitle2'),
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(4),
    },
    or: {
      ...typography(theme, 'body1'),
      marginBottom: theme.spacing(4),
    },
    imageContainer: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      margin: theme.spacing(0, 2),
      marginTop: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  };
});

const LinkedInFork = () => {
  const { classes } = useStyles();
  usePageTrackingEffect('Upload LinkedIn resume', 'New User Onboarding');

  return (
    <div className={classes.root}>
      <div className={classes.copyContainer}>
        <div className={classes.title}>Want to reduce the steps?</div>
        <div className={classes.subtitle}>
          We recommend uploading your LinkedIn resume to make this process easier. You’ll have full
          control over what information you add and can always change it later.
        </div>
        <div className={classes.outerButtonsContainer}>
          <div className={classes.buttonsContainer}>
            <Button
              component={Link}
              to="/onboarding/linkedin-uploader"
              color="secondary"
              variant="contained"
              className={classes.linkedinButton}
            >
              Upload LinkedIn resume
            </Button>
            <div className={classes.buttonText}>We'll show you how in the next step</div>
            <div className={classes.or}>or</div>
            <Button component={Link} to="/onboarding/photos" variant="contained" color="secondary">
              I’ll manually fill out my profile
            </Button>
          </div>
        </div>
      </div>
      <div
        className={classes.imageContainer}
        style={{
          background:
            'url(https://siftprodcoredata.z13.web.core.windows.net/siftgeneral/onboarding_2.png)',
          backgroundSize: 'contain',
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
    </div>
  );
};

export default LinkedInFork;
