import { useContext, useMemo } from 'react';
import _get from 'lodash/get';
import RunnerContext from '@sift/runner/Context';
import usePassiveRedux from '@sift/resift/usePassiveRedux';
import slides from './slides';
import fieldMap from './fieldMap';

export default function useValidSlides(): any {
  const contextValue = useContext(RunnerContext);
  if (!contextValue) {
    throw new Error('[useValidSlides] could not find runner context');
  }

  const { root, config } = contextValue;

  const assistantsEnabled = usePassiveRedux((state: any) =>
    _get(state, ['client', 'features', 'connect', 'assistants'], false),
  ) as boolean;

  const socialPhotosEnabled = _get(root, ['_directorySettings', 'socialPhotos', 'enabled'], true);

  return useMemo(() => {
    const consideredSlides = slides
      .filter(slide => slide.type === 'nav')
      .filter(slide => {
        if (slide.path !== '/photos') {
          return true;
        }

        return socialPhotosEnabled;
      })
      // filter out the assistants tab if assistants is not abled
      .filter(slide => {
        if (slide.path !== '/assistants') {
          return true;
        }

        return assistantsEnabled;
      })
      .filter(slide => {
        const fieldKey = fieldMap[slide.path.substring(1)];

        // @ts-ignore
        const field = _get(config, ['fields', fieldKey]) as Sift.Field | undefined;

        // if there is a fieldKey but no field that means that the field is not available in the
        // instance
        if (fieldKey && !field) {
          return false;
        }
        return true;
      });

    return consideredSlides;
  }, [socialPhotosEnabled, assistantsEnabled, config]);
}
