// React
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '@mui/material/Button';

import Column from '../Column';
import RecentSearchItem from '../../Items/RecentSearchItem';
import { makeStyles } from 'tss-react/mui';
import bigSearchStyles from '../../../styles';

function RecentSearchesColumn(props) {
  const { result, deleteRecentSearch, deleteRecentSearches, ...otherProps } = props;
  const { classes } = useStyles();

  return (
    <Column
      title={result.display.header}
      action={
        <Button
          variant="outlined"
          color="inherit"
          className={classes.button}
          size="small"
          onClick={deleteRecentSearches}
        >
          Clear All
        </Button>
      }
      {...otherProps}
    >
      {result.items.map((item, index) => (
        <RecentSearchItem
          index={index}
          value={item}
          deleteSearch={deleteRecentSearch}
          key={index}
        />
      ))}
    </Column>
  );
}

const useStyles = makeStyles()(theme => {
  const bigSearch = bigSearchStyles(theme);
  return {
    button: {
      color: bigSearch.components.card.color,
      marginTop: -theme.spacing(2),
    },
  };
});

RecentSearchesColumn.propTypes = {
  result: PropTypes.object,
  deleteRecentSearch: PropTypes.func.isRequired,
  deleteRecentSearches: PropTypes.func.isRequired,
};

export default RecentSearchesColumn;
