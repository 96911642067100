import { useEffect } from 'react';
import useRouter from '@sift/resift/useRouter';
import { useData } from 'resift';
import getAppVersionStatus from 'fetches/getAppVersionStatus';

export default function useUpdateApp() {
  const { history } = useRouter();
  const { isCompatible, recommendUpdate } = useData(getAppVersionStatus) || {};

  useEffect(() => {
    history.listen(({ pathname }) => {
      if (isCompatible === false || recommendUpdate) {
        window.location.reload(true);
      }
    });
  }, [history, isCompatible, recommendUpdate]);
}
