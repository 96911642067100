import React, { useState } from 'react';
import ProfileSection from 'components/msTeams/embed/molecules/ProfileSection';
import { MAX_LEN_BIO } from 'components/msTeams/consts';
import { ProfileComponentProps } from 'components/msTeams/embed/typing';

function Bio({ value, field }: ProfileComponentProps) {
  const needTruncation = value.length > MAX_LEN_BIO;
  const [truncated, setTruncated] = useState(needTruncation);

  const handleToggleMore = () => {
    setTruncated(!truncated);
  };

  const displayValue = !needTruncation
    ? value
    : truncated
    ? `${value.substring(0, MAX_LEN_BIO)}...`
    : value;

  return (
    <ProfileSection
      sectionHeader={field?.displayName}
      content={displayValue}
      onToggleMore={needTruncation ? handleToggleMore : undefined}
      truncated={truncated}
    />
  );
}

export default Bio;
