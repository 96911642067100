export default {
  'Africa/Cairo': 'Cairo',
  'Africa/Casablanca': 'Casablanca',
  'Africa/Harare': 'Harare, Pretoria',
  'Africa/Lagos': 'West Central Africa',
  'Africa/Nairobi': 'Nairobi',
  'Africa/Tripoli': 'Tripoli',
  'Africa/Windhoek': 'Windhoek',
  'America/Argentina/Buenos_Aires': 'City of Buenos Aires',
  'America/Asuncion': 'Asuncion',
  'America/Cancun': 'Chetumal',
  'America/Caracas': 'Caracas',
  'America/Chicago': 'Central Time (US and Canada)',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Cuiaba': 'Cuiaba',
  'America/Denver': 'Mountain Time (US and Canada), Navajo Nation',
  'America/Fortaleza': 'Cayenne, Fortaleza',
  'America/Halifax': 'Atlantic Time (Canada)',
  'America/Havana': 'Havana',
  'America/Indiana/Indianapolis': 'Indiana (East)',
  'America/Lima': 'Bogota, Lima, Quito',
  'America/Los_Angeles': 'Pacific Time (US and Canada)',
  'America/Manaus': 'Georgetown, La Paz, Manaus, San Juan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Miquelon': 'Saint Pierre and Miquelon',
  'America/Montevideo': 'Montevideo',
  'America/New_York': 'Eastern Time (US and Canada)',
  'America/Noronha': 'Fernando de Noronha',
  'America/Phoenix': 'Arizona',
  'America/Regina': 'Saskatchewan',
  'America/Santiago': 'Santiago',
  'America/Sao_Paulo': 'Brasilia',
  'America/St_Johns': 'Newfoundland',
  'Asia/Almaty': 'Astana/Nur-Sultan',
  'Asia/Anadyr': 'Anadyr, Petropavlovsk-Kamchatsky',
  'Asia/Ashgabat': 'Tashkent, Ashgabat',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Baku': 'Baku',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Barnaul': 'Barnaul, Gorno-Altaysk',
  'Asia/Beirut': 'Beirut',
  'Asia/Chita': 'Chita',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Damascus': 'Damascus',
  'Asia/Dhaka': 'Dhaka',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Gaza': 'Gaza, Hebron',
  'Asia/Hong_Kong': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Hovd': 'Hovd',
  'Asia/Irkutsk': 'Irkutsk',
  'Asia/Istanbul': 'Istanbul',
  'Asia/Jerusalem': 'Jerusalem',
  'Asia/Kabul': 'Kabul',
  'Asia/Karachi': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Asia/Magadan': 'Magadan',
  'Asia/Novosibirsk': 'Novosibirsk',
  'Asia/Pyongyang': 'Pyongyang',
  'Asia/Seoul': 'Seoul',
  'Asia/Taipei': 'Taipei',
  'Asia/Tbilisi': 'Tbilisi',
  'Asia/Tehran': 'Tehran',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Tomsk': 'Tomsk',
  'Asia/Ulaanbaatar': 'Ulaanbaatar',
  'Asia/Vladivostok': 'Vladivostok',
  'Asia/Yakutsk': 'Yakutsk',
  'Asia/Yangon': 'Yangon (Rangoon)',
  'Asia/Yekaterinburg': 'Islamabad, Karachi',
  'Asia/Yerevan': 'Yerevan',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cabo Verde Islands',
  'Atlantic/Reykjavik': 'Monrovia, Reykjavik',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Darwin': 'Darwin',
  'Australia/Eucla': 'Eucla',
  'Australia/Hobart': 'Hobart',
  'Australia/Lord_Howe': 'Lord Howe Island',
  'Australia/Perth': 'Perth',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Bucharest': 'Athens, Bucharest',
  'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Kaliningrad': 'Kaliningrad',
  'Europe/London': 'Dublin, Edinburgh, Lisbon, London',
  'Europe/Minsk': 'Minsk',
  'Europe/Moscow': 'Moscow, St. Petersburg',
  'Europe/Prague': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Samara': 'Izhevsk, Samara',
  'Europe/Volgograd': 'Astrakhan, Ulyanovsk, Volgograd',
  'Europe/Warsaw': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Indian/Mauritius': 'Port Louis',
  'Mexico/BajaNorte': 'Baja California',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Bougainville': 'Bougainville Island',
  'Pacific/Chatham': 'Chatham Islands',
  'Pacific/Easter': 'Easter Island',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Pacific/Honolulu': 'Hawaii',
  'Pacific/Kiritimati': 'Kiritimati Island',
  'Pacific/Marquesas': 'Marquesas Islands',
  'Pacific/Midway': 'Midway Island, American Samoa',
  'Pacific/Norfolk': 'Norfolk Island',
  'Pacific/Pago_Pago': 'Samoa',
  'US/Alaska': 'Alaska',
  'US/Aleutian': 'Aleutian Islands',
};
