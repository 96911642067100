import React from 'react';
import { useHistory } from 'react-router';
import _intersection from 'lodash/intersection';
import _get from 'lodash/get';
import { Layout, Avatar, Flex, Button } from '@fluentui/react-northstar';
import { ChevronStartIcon, OpenOutsideIcon } from '@fluentui/react-icons-northstar';
import makeMsTeamsDeepLinkUrl from 'helpers/makeMsTeamsDeepLinkUrl';
import { Theme } from 'components/msTeams/MsTeamsMainTheme';

import usePassiveRedux from '@sift/resift/usePassiveRedux';
import {
  PROFILE_HEADER_LAYOUT_KEYS,
  PROFILE_CONTENT_LAYOUT_KEYS,
  MS_TEAMS_MEETING_MAIN_URL,
  MS_TEAMS_PARTICIPANTS_URL,
} from 'components/msTeams/consts';
import componentRegistry from 'components/msTeams/embed/profile/componentRegistry';
import Headings from 'components/msTeams/embed/profile/Headings';
import { Person } from '@sift/types';

interface Props {
  person: Person;
  theme: Theme;
}

function PersonProfile({ person, theme }: Props) {
  const styles = useStyles(theme);
  const history = useHistory();
  const fields = usePassiveRedux((state: any) => _get(state, 'configurations.user.fields'));
  const fieldKeys = Object.keys(fields);
  const headerKeys = _intersection(PROFILE_HEADER_LAYOUT_KEYS, fieldKeys);
  const contentKeys = _intersection(PROFILE_CONTENT_LAYOUT_KEYS, fieldKeys);

  const handleBackToList = () => {
    history.push(`${MS_TEAMS_MEETING_MAIN_URL}${MS_TEAMS_PARTICIPANTS_URL}`);
  };

  const fullProfileUrl = makeMsTeamsDeepLinkUrl('search', `/profile/${person.id}`);

  return (
    <Layout vertical>
      <Flex space="between" vAlign="center" style={styles.nav}>
        <Button
          onClick={handleBackToList}
          icon={<ChevronStartIcon />}
          title="Back to participants list"
          iconOnly
          style={styles.backArrow}
        />
        <Button
          icon={<OpenOutsideIcon outline />}
          content="Full Profile"
          aria-label="full profile"
          size="small"
          as="a"
          href={fullProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
        />
      </Flex>
      <Avatar image={person.pictureUrl} size="larger" name="avatar" />
      <Headings person={person} />
      {headerKeys.map((key: any) => {
        const Component = componentRegistry[key as keyof typeof componentRegistry];
        const value = person[key];
        return value ? (
          <Component value={person[key]} key={`header-items-${key}`} field={fields[key]} />
        ) : null;
      })}
      {contentKeys.map((key: any) => {
        const Component = componentRegistry[key as keyof typeof componentRegistry];
        const value = person[key];
        return value ? <Component value={person[key]} key={key} field={fields[key]} /> : null;
      })}
    </Layout>
  );
}

const useStyles = (theme: Theme) => ({
  root: {},
  nav: {
    marginBottom: theme.siteVariables.spacing.unit * 3,
  },
  backArrow: {
    border: 'none',
    minWidth: 0,
  },
});

export default PersonProfile;
