// https://www.lingoapp.com/space/86663/k/B2DE4BDF-3C89-44EF-9D84-E833AEB7FE02/DE9D9C25-B9C4-41BA-A7E4-C14B64C64EA8/?tkn=covARoOwR4m_6_U8zowW36a_qrMaql0bmEcfIpfVgwI
import brandColors from './brandColors';
import { grey } from '@mui/material/colors';
import { darken } from 'polished';
import createPaletteColor from 'styles/createPaletteColor';

export default {
  primary: {
    main: brandColors.primaryPalette.violet,
  },
  secondary: {
    main: brandColors.secondaryPalette.cobalt,
  },
  matched: createPaletteColor('matched', brandColors.logomarkDotColors.gold),
  defaultAccentColor: createPaletteColor('defaultAccentColor', brandColors.secondaryPalette.cobalt),
  text: {
    primary: brandColors.grayScale.iron,
    secondary: brandColors.secondaryPalette.cobalt,
    gray1: brandColors.grayScale.gray1,
    gray2: brandColors.grayScale.gray2,
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  background: {
    canvas: brandColors.grayScale.appCanvasColor,
    lightBlueCanvas: '#F9FBFD',
  },
  borderColor: grey[300],
  common: {
    black: brandColors.grayScale.iron,
  },
  action: {
    infoHover: brandColors.grayScale.cloud,
    infoActive: darken(0.04, brandColors.grayScale.cloud),
  },
  skills: createPaletteColor('skills', brandColors.logomarkDotColors.tiger),
  interests: createPaletteColor('interests', brandColors.quaternaryPalette.watermelon),
};
