import React, { useState } from 'react';
import { isLoading } from 'resift';

// Components
import Button from '@mui/material/Button';
import AppBarPortal from 'pages/Onboarding/components/AppBarPortal';
import Header from 'pages/Onboarding/components/Header';
import SkipDialog from 'pages/Onboarding/components/SkipDialog';
import ContrastButton from 'pages/Onboarding/components/ContrastButton';
import { Prompt } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  skipButton: {
    marginRight: theme.spacing(2),
  },
  content: {},
  buttonDisabled: {
    color: `${theme.palette.grey['300']} !important`,
    backgroundColor: `${theme.palette.grey['600']} !important`,
  },
}));

interface Props {
  displayName: string;
  description: string;
  fromLinkedIn: boolean;
  content: React.ReactNode;
  status: number;
  onContinue: () => void;
  onSkip: () => void;
  isValid: boolean;
  showSkipConfirmation: boolean;
  isLastSlide: boolean;
  showNavigationConfirmation: boolean;
}

function ObjectCollectionTemplate({
  isValid,
  status,
  content,
  onContinue,
  displayName,
  description,
  onSkip,
  fromLinkedIn,
  showSkipConfirmation,
  isLastSlide,
  showNavigationConfirmation,
}: Props) {
  const { classes } = useStyles();
  const [skipDialogOpen, setSkipDialogOpen] = useState(false);

  const handleSkip = () => {
    if (showSkipConfirmation) {
      setSkipDialogOpen(true);
    } else {
      onSkip();
    }
  };

  const handleSkipDialogClose = () => {
    setSkipDialogOpen(false);
  };

  return (
    <>
      <AppBarPortal>
        <ContrastButton className={classes.skipButton} onClick={handleSkip}>
          Skip
        </ContrastButton>
        <Button
          variant="contained"
          color="secondary"
          disabled={!isValid || isLoading(status)}
          onClick={onContinue}
          classes={{
            disabled: classes.buttonDisabled,
          }}
        >
          {isLastSlide ? 'Finish' : 'Continue'}
        </Button>
      </AppBarPortal>
      <Prompt
        when={showNavigationConfirmation && !skipDialogOpen}
        message="Are you sure you'd like to leave? Your changes have not been saved yet."
      />
      <SkipDialog open={skipDialogOpen} onClose={handleSkipDialogClose} onSkip={onSkip} />
      <div className={classes.root}>
        <Header displayName={displayName} description={description} fromLinkedIn={fromLinkedIn} />
        <div className={classes.content}>{content}</div>
      </div>
    </>
  );
}

export default ObjectCollectionTemplate;
