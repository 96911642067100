import createReducer from '@sift/resift/createReducer';
import { defineFetch } from 'resift';

import removeFalsyKeys from 'helpers/removeFalsyKeys';
import transformToQueryParams from 'store/searchResults/_helpers/transformToQueryParams';

// ------------------------------------
// Constants
// ------------------------------------
export const pageSizeOptions = [30, 50, 100];

// filters
const SEARCH_RESULTS_OPEN_FILTERS = 'SEARCH_RESULTS_OPEN_FILTERS';
const SEARCH_RESULTS_CLOSE_FILTERS = 'SEARCH_RESULTS_CLOSE_FILTERS';
// group email
const SEARCH_RESULTS_OPEN_GROUP_EMAIL = 'SEARCH_RESULTS_OPEN_GROUP_EMAIL';
const SEARCH_RESULTS_CLOSE_GROUP_EMAIL = 'SEARCH_RESULTS_CLOSE_GROUP_EMAIL';
// group message
const SEARCH_RESULTS_OPEN_GROUP_MESSAGE = 'SEARCH_RESULTS_OPEN_GROUP_MESSAGE';
const SEARCH_RESULTS_CLOSE_GROUP_MESSAGE = 'SEARCH_RESULTS_CLOSE_GROUP_MESSAGE';
// export
const SEARCH_RESULTS_OPEN_EXPORT = 'SEARCH_RESULT_OPEN_EXPORT';
const SEARCH_RESULTS_CLOSE_EXPORT = 'SEARCH_RESULTS_CLOSE_EXPORT';

const searchExploreAction = entityTypeTypeKey => ({
  clear,
  q,
  page,
  pageSize,
  selectedAttributeValues,
  sortBy,
  advancedSearch,
  mergeAdditionalFilters,
  sortDirection,
}) => async ({ http }) => {
  // if `clear` then return a dummy empty object to replace the previously cached value
  if (clear) {
    return {
      filterCounts: {},
      filters: {},
      results: [],
      totalLength: 0,
    };
  }

  return http({
    method: 'GET',
    route: `/search/${entityTypeTypeKey}`,
    query: removeFalsyKeys({
      page,
      q,
      pageSize,
      sortBy,
      sortDirection,
      mergeAdditionalFilters,
      advancedSearch: advancedSearch ? 'true' : undefined,
      ...transformToQueryParams(selectedAttributeValues),
    }),
  });
};

const searchResultsByEntityTypeAction = entityTypeTypeKey => ({
  clear,
  q,
  page,
  pageSize,
  selectedAttributeValues,
  sortBy,
  advancedSearch,
  mergeAdditionalFilters,
  sortDirection,
}) => async ({ http }) => {
  // if `clear` then return a dummy empty object to replace the previously cached value
  if (clear) {
    return {
      filterCounts: {},
      filters: {},
      results: [],
      totalLength: 0,
    };
  }

  return http({
    method: 'GET',
    route: `/search/${entityTypeTypeKey}`,
    query: removeFalsyKeys({
      page,
      q,
      pageSize,
      sortBy,
      sortDirection,
      mergeAdditionalFilters,
      advancedSearch: advancedSearch ? 'true' : undefined,
      ...transformToQueryParams(selectedAttributeValues),
    }),
  });
};
// ------------------------------------
// Actions
// ------------------------------------

// TODO: these fetches could be combine into a single fetch taking in an extra parameter like `page`
// and then the `key` would be a function of that page
export const makeSearchResultsFetch = defineFetch({
  displayName: 'fetch search results',
  make: key => ({
    key: [key],
    request: searchExploreAction,
  }),
});

export const makeSearchExploreFetch = defineFetch({
  displayName: 'fetch search explore',
  make: entityTypeTypeKey => ({
    key: [entityTypeTypeKey],
    request: searchExploreAction(entityTypeTypeKey),
  }),
});

export const makeSearchResultsByEntityTypeFetch = defineFetch({
  displayName: 'fetch search results by entity type',
  make: entityTypeTypeKey => ({
    key: [entityTypeTypeKey],
    request: searchResultsByEntityTypeAction(entityTypeTypeKey),
  }),
});

export const makeGroupMessageFetch = defineFetch({
  displayName: 'fetch group message',
  make: () => ({
    request: searchResultsByEntityTypeAction('user'),
  }),
});

// filters
export const openFilters = () => ({
  type: SEARCH_RESULTS_OPEN_FILTERS,
});
export const closeFilters = () => ({
  type: SEARCH_RESULTS_CLOSE_FILTERS,
});
// group email
export const openGroupEmail = () => ({
  type: SEARCH_RESULTS_OPEN_GROUP_EMAIL,
});
export const closeGroupEmail = () => ({
  type: SEARCH_RESULTS_CLOSE_GROUP_EMAIL,
});
// group message
export const openGroupMessage = () => ({
  type: SEARCH_RESULTS_OPEN_GROUP_MESSAGE,
});
export const closeGroupMessage = () => ({
  type: SEARCH_RESULTS_CLOSE_GROUP_MESSAGE,
});
// export
export const openExportDialog = () => ({
  type: SEARCH_RESULTS_OPEN_EXPORT,
});
export const closeExportDialog = () => ({
  type: SEARCH_RESULTS_CLOSE_EXPORT,
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const actionHandlers = {
  [SEARCH_RESULTS_OPEN_FILTERS]: state => ({
    ...state,
    filtersOpen: true,
  }),
  [SEARCH_RESULTS_CLOSE_FILTERS]: state => ({
    ...state,
    filtersOpen: false,
  }),
  [SEARCH_RESULTS_OPEN_GROUP_EMAIL]: state => ({
    ...state,
    groupEmailOpen: true,
  }),
  [SEARCH_RESULTS_CLOSE_GROUP_EMAIL]: state => ({
    ...state,
    groupEmailOpen: false,
  }),
  [SEARCH_RESULTS_OPEN_GROUP_MESSAGE]: state => ({
    ...state,
    groupMessageOpen: true,
  }),
  [SEARCH_RESULTS_CLOSE_GROUP_MESSAGE]: state => ({
    ...state,
    groupMessageOpen: false,
  }),
  [SEARCH_RESULTS_OPEN_EXPORT]: state => ({
    ...state,
    exportOpen: true,
  }),
  [SEARCH_RESULTS_CLOSE_EXPORT]: state => ({
    ...state,
    exportOpen: false,
  }),
};

const initialState = {
  filtersOpen: false,
  groupEmailOpen: false,
  groupMessageOpen: false,
  exportOpen: false,
};

export default createReducer(initialState, actionHandlers);
