import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _throttle from 'lodash/throttle';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useData } from 'resift';
import getAppVersionStatus from 'fetches/getAppVersionStatus';
import typography from 'helpers/typography';

export const VERSION_UPDATE_QUERY = 'versionUpdate';

function UpdateBanner() {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const { isCompatible, recommendUpdate } = useData(getAppVersionStatus) || {};
  const [inactiveTime, setInactiveTime] = useState(moment());

  const resetTimer = useCallback(
    () =>
      _throttle(function() {
        setInactiveTime(moment());
      }, 5000),
    [],
  );

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer, false);
    document.addEventListener('keypress', resetTimer, false);
    document.addEventListener('touchmove', resetTimer, false);

    if (queryParams.versionUpdate) {
      const _queryParams = { ...queryParams } as any;
      delete _queryParams[VERSION_UPDATE_QUERY];

      history.push({
        search: queryString.stringify(_queryParams),
      });

      enqueueSnackbar(`You've updated to the latest version of Sift!`, {
        variant: 'success',
      });
    }

    return () => {
      document.removeEventListener('mousemove', resetTimer, false);
      document.removeEventListener('keypress', resetTimer, false);
      document.removeEventListener('touchmove', resetTimer, false);
    };
  }, [resetTimer]); // eslint-disable-line
  // Jules: to prevent the use effect from re-running, history, location, enqueueSnackbar and queryParams are not passed into this useEffect

  useEffect(() => {
    const secondsFromLastReset = moment().diff(inactiveTime, 'seconds');
    if (isCompatible === false && secondsFromLastReset >= 30) {
      handleRefresh(history);
    }
  }, [isCompatible, inactiveTime, history]);

  return (
    <Paper
      elevation={2}
      className={classes.root}
      style={{ height: recommendUpdate ? undefined : 0 }}
      square
    >
      <span role="img" aria-label="celebrate">
        🎉
      </span>
      There is a new version of Sift available!
      <Button
        className={classes.button}
        onClick={() => handleRefresh(history)}
        variant="outlined"
        classes={{ root: classes.rootButton }}
        size="small"
      >
        Click to refresh
      </Button>
      <span role="img" aria-label="celebrate">
        🎉
      </span>
    </Paper>
  );
}

function handleRefresh(history: any) {
  history.push({
    search: `?${VERSION_UPDATE_QUERY}=true`,
  });
  window.location.reload();
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      background: theme.palette.originalSecondary.main,
      color: theme.palette.originalSecondary.contrastText,
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 18px',
      flex: '0 0 auto',
      overflow: 'hidden',
      transition: 'height .4s',
      ...typography(theme, 'h6'),
      '& > span': {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.down('md')]: {
        ...typography(theme, 'subtitle2'),
        height: 70,
        '& > span': {
          display: 'none',
        },
      },
    },
    button: {
      marginLeft: theme.spacing(3),
    },
    rootButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
  };
});

export default UpdateBanner;
