import { Status, States } from '@sift/resift/models/Status';
import createReducer from '@sift/resift/createReducer';

// Auth Actions to Watch For
import { AUTH_PERSON_FETCH_SUCCESS } from '@sift/resift/redux/reducers/authentication';

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_ERROR = 'UPDATE_SETTINGS_ERROR';

// ------------------------------------
// Actions
// ------------------------------------
export const updateSettings = (objectNotation, status) => {
  return {
    type: UPDATE_SETTINGS,
    payload: {
      data: {
        directory: {
          [objectNotation]: status,
        },
      },
    },
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [AUTH_PERSON_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload.data.settings,
    };
  },
  [UPDATE_SETTINGS]: (state, action) => {
    return {
      ...state,
      status: new Status(States.LOADING),
    };
  },
  [UPDATE_SETTINGS_SUCCESS]: (state, action) => {
    return {
      ...state,
      directory: action.payload.data.directory,
      status: new Status(States.NORMAL),
    };
  },
  [UPDATE_SETTINGS_ERROR]: (state, action) => {
    return {
      ...state,
      status: new Status(States.ERROR),
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  directory: {
    tour: {},
    preferOfficialPhotos: true,
    customColumns: ['team', 'subTeam'],
  },
  status: new Status(States.NORMAL),
};

export default createReducer(initialState, ACTION_HANDLERS);
