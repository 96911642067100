import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getThemeToUse } from 'store/theme';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

// Components
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface DefaultTheme extends Theme {}
}

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

interface Props {
  children: React.ReactNode;
}

function ThemeProvider({ children }: Props) {
  const themeState = useSelector((state: any) => state.theme);
  const themeToUse = useMemo(() => {
    return getThemeToUse(themeState);
  }, [themeState]);

  return (
    <CacheProvider value={muiCache}>
      <MuiThemeProvider theme={themeToUse}>{children}</MuiThemeProvider>
    </CacheProvider>
  );
}

export default ThemeProvider;
