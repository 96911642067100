import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import typography from 'helpers/typography';
import Portal from '@mui/material/Portal';
import BigSearch from 'components/BigSearch';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';

import { makeStyles } from 'tss-react/mui';
import { transparentize } from 'polished';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: transparentize(0.02, theme.palette.primary.main),
      zIndex: theme.zIndex.modal,
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'center',
      overflowY: 'auto',
      opacity: '0',
    },
    button: {
      ...typography(theme, 'h6'),
      position: 'absolute',
      top: 10,
      right: 10,
      fontSize: 30,
      cursor: 'pointer',
      color: theme.palette.primary.contrastText,
    },
  };
});

function SearchOverlay(props) {
  const { open, toggle } = props;
  const { classes } = useStyles();

  const escFunction = useCallback(
    event => {
      if (event.key === 'Escape') {
        toggle();
      }
    },
    [toggle],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <Portal>
      <Fade in={open} unmountOnExit>
        <div className={classes.root}>
          <BigSearch />
          <CloseIcon onClick={toggle} className={classes.button} />
        </div>
      </Fade>
    </Portal>
  );
}

SearchOverlay.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default SearchOverlay;
