import React, { useEffect, useState } from 'react';
import { isNormal } from 'resift';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import useAnalytics from 'helpers/useAnalytics';
import bowser from 'bowser';
import { SIFT_MARKETING_SITE } from 'helpers/consts';
import typography from 'helpers/typography';

// Components
import Loader from 'components/skipper/Loader';
import AdvancedSearch from 'components/AdvancedSearch';
import PoweredBySift from 'components/PoweredBySift';
import Results from './Results';
import NoResults from './NoResults';
import SearchRow from './SearchRow';
import NewTabLink from 'components/atoms/NewTabLink';

// Styles
import { makeStyles } from 'tss-react/mui';
import { decomposeColor } from '@mui/material/styles';
import classNames from 'classnames';
import ThemeOverrideComponent from 'styles/skipper/ThemeOverrideComponent';
import useOriginalTheme from 'styles/skipper/useOriginalTheme';

const useStyles = makeStyles()(theme => {
  const { primary, common } = theme.palette;
  const isIE = bowser.name === 'Internet Explorer';

  return {
    root: {
      color: common.white,
      padding: theme.spacing(0, 4),
      minWidth: 0, // IMPORTANT: This allows for items to truncate properly
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'min-content',
      width: theme.page.pageMaxWidth,
      [theme.breakpoints.down('md')]: {
        height: 'min-content',
        padding: theme.spacing(0, 2),
      },
    },
    statusRoot: {
      display: isIE ? 'block' : 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100vh',
    },
    statusContent: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    searchStatusRoot: {
      minHeight: 420,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    loaderEmpty: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    loaderOverlaytransparent: {
      backgroundColor: `rgba(${decomposeColor(primary.main).values.join(',')}, 0.6)`,
    },
    footer: {
      ...typography(theme, 'body2'),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 'auto',
      width: 400,
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        width: 'initial',
        flexDirection: 'column-reverse',
        marginTop: theme.spacing(8),
      },
    },
    whiteLinkCopy: {
      color: primary.contrastText,
    },
    link: {
      color: primary.contrastText,
      opacity: 0.5,
      cursor: 'pointer',
      transition: 'opacity .2s',
      display: 'block',
      textDecoration: 'none !important',
      '&:hover': {
        opacity: 1,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(0.5),
        fontSize: 12,
      },
    },
    poweredBySift: {
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
    footerLine: {
      marginTop: theme.spacing(0.25),
      height: 2,
      width: '100%',
      backgroundColor: primary.contrastText,
      opacity: 0.5,
      cursor: 'pointer',
    },
  };
});

// Component
function BigSearch({
  fields,
  team,
  recentSearches,
  suggestions,
  user,
  query,
  results,
  supportEnabled,
  // actions
  deleteRecentSearches,
  deleteRecentSearch,
  fetchRecentSearches,
  updateRecentSearches,
  fetchAutocomplete,
  fetchTeam,
  updateQuery,
  clearQuery,
  toggleSupportDrawer,
  // Loading States
  status,
  searchStatus,
}) {
  const originalTheme = useOriginalTheme();
  const { classes } = useStyles();
  const analytics = useAnalytics();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/') {
      clearQuery();
    }

    if (recentSearches === undefined) {
      fetchRecentSearches(user.id);
    }

    if (!_get(user, 'accountOnly') && team === undefined) {
      fetchTeam(user.id);
    }
  }, [
    clearQuery,
    fetchRecentSearches,
    fetchTeam,
    fields.teamLeader,
    fields.teamLeaderEmail,
    recentSearches,
    team,
    user,
  ]);

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);

    if (showAdvancedSearch === false) {
      analytics.track('Opened Advanced Search', {
        category: 'Big Search',
      });
    } else {
      analytics.track('Clicked BigSearch Back Button', {
        category: 'Big Search',
      });
    }
  };

  return (
    <ThemeOverrideComponent theme={originalTheme}>
      <div className={classes.root}>
        {showAdvancedSearch ? (
          <AdvancedSearch onClose={toggleAdvancedSearch} />
        ) : (
          <Loader
            status={status}
            classes={{
              root: classes.statusRoot,
              overlayTransparent: classes.loaderOverlaytransparent,
            }}
          >
            <>
              <div className={classes.statusContent}>
                <SearchRow
                  query={query}
                  updateRecentSearches={updateRecentSearches}
                  onShowAdvancedSearch={toggleAdvancedSearch}
                  fetchAutocomplete={fetchAutocomplete}
                  updateQuery={updateQuery}
                  clearQuery={clearQuery}
                  suggestions={suggestions}
                />
                <Loader
                  color="secondary"
                  classes={{
                    root: classes.searchStatusRoot,
                    overlayTransparent: classes.loaderOverlaytransparent,
                    overlay: classNames({
                      [classes.loaderEmpty]: searchStatus.isEmpty(),
                    }),
                  }}
                  status={searchStatus}
                  isEmptyView={
                    <NoResults
                      query={query}
                      onClearSearch={clearQuery}
                      onShowAdvancedSearch={toggleAdvancedSearch}
                    />
                  }
                >
                  <Results
                    results={results}
                    updateRecentSearches={updateRecentSearches}
                    deleteRecentSearches={deleteRecentSearches}
                    deleteRecentSearch={deleteRecentSearch}
                    updateQuery={updateQuery}
                    query={query}
                  />
                </Loader>
              </div>
              {isNormal(status.value) && (
                <div className={classes.footer}>
                  <PoweredBySift className={classes.poweredBySift} backgroundColor="primary" />

                  <NewTabLink
                    href={`${SIFT_MARKETING_SITE}privacy-policy/`}
                    className={classes.link}
                  >
                    <div>
                      Privacy & Data Policy
                      <div className={classes.footerLine} />
                    </div>
                  </NewTabLink>
                  {supportEnabled ? (
                    <div onClick={toggleSupportDrawer} className={classes.link}>
                      Get Support
                      <div className={classes.footerLine} />
                    </div>
                  ) : (
                    <a href="mailto:support@justsift.com" className={classes.link}>
                      Get Support
                      <div className={classes.footerLine} />
                    </a>
                  )}
                </div>
              )}
            </>
          </Loader>
        )}
      </div>
    </ThemeOverrideComponent>
  );
}

BigSearch.propTypes = {
  fields: PropTypes.object.isRequired,
  team: PropTypes.object,
  recentSearches: PropTypes.object,
  suggestions: PropTypes.array,
  user: PropTypes.object,
  query: PropTypes.string,
  results: PropTypes.array,
  supportEnabled: PropTypes.bool.isRequired,
  // actions
  deleteRecentSearches: PropTypes.func,
  deleteRecentSearch: PropTypes.func,
  fetchRecentSearches: PropTypes.func,
  updateRecentSearches: PropTypes.func,
  fetchAutocomplete: PropTypes.func.isRequired,
  fetchTeam: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  clearQuery: PropTypes.func.isRequired,
  toggleSupportDrawer: PropTypes.func.isRequired,
  // Loading States
  status: PropTypes.object,
  searchStatus: PropTypes.object,
};

export default BigSearch;
