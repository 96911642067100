import React, { useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { LOADING } from 'resift';
import useRouter from '@sift/resift/useRouter';
import useAnalytics from 'helpers/useAnalytics';
import routes from './helpers/routes';
import useOnboardingRedirectEffect from 'helpers/useOnboardingRedirectEffect';
import Loader from 'components/skipper/Loader';

// Components
import CoreLayout from 'components/CoreLayout';
import SearchOverlay from 'components/BigSearch/SearchOverlay';
import MsTeamsMain from 'components/msTeams/embed/MsTeamsMain';
import { Route, Switch, Redirect } from 'react-router';
import * as microsoftTeams from '@microsoft/teams-js';
import getIsInMicrosoft from 'helpers/getIsInMicrosoft';

import { MS_TEAMS_MEETING_MAIN_URL } from 'components/msTeams/consts';

function SiftRouter() {
  const client = useSelector(state => state.client);
  const user = useSelector(state => state.authentication.person);
  const analytics = useAnalytics();
  const { history } = useRouter();
  const isInMicrosoft = getIsInMicrosoft();

  useEffect(() => {
    history.listen(({ pathname }) => {
      // TODO: this causes the analytics/segment to have the wrong page title
      // because the `document.title` is usually set after the router history event
      analytics.page(pathname);
    });

    if (isInMicrosoft) {
      microsoftTeams.initialize();
      microsoftTeams.getContext(context => {
        /** This is for deep linking in ms teams apps from the url that has subEntityId encoded
         * e.g. https://teams.microsoft.com/l/entity/19cdebca-d354-45a6-a701-a7cde03457a8/search?context=%7B%22subEntityId%22%3A%22%2Fprofile%2FxM0uI0Dh5xRr%22%7D
         * The above url gets interpreted by getContext and returns
         * context.subEntityId = "/profile/xM0uI0Dh5xRr"
         */
        if (context.subEntityId) {
          history.push(context.subEntityId);
        }
      });
    }
  }, [analytics, history, isInMicrosoft]);

  useOnboardingRedirectEffect();

  const WrapperComponent = window.location.href.includes(MS_TEAMS_MEETING_MAIN_URL)
    ? React.Fragment
    : CoreLayout;

  return (
    <WrapperComponent>
      <Suspense fallback={<Loader status={LOADING} />}>
        <Switch>
          {routes(client, user).map((route, index) => (
            <Route {...route} key={index} />
          ))}
          <Route path={MS_TEAMS_MEETING_MAIN_URL} component={MsTeamsMain} />
          <Redirect to="/404" />
        </Switch>
      </Suspense>
      <SearchOverlay />
    </WrapperComponent>
  );
}

export default SiftRouter;
