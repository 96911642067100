import React from 'react';
import { Button } from '@fluentui/react-northstar';
import { VolumeUpIcon } from '@fluentui/react-icons-northstar';

interface Props {
  id: string;
}

function NamePronunciation({ id }: Props) {
  const audio = new Audio(`/api/people/${id}/media/name-pronunciation`);
  const handleClick = () => {
    audio.play();
  };

  return (
    <Button onClick={handleClick} icon={<VolumeUpIcon />} title="Play pronunciation" iconOnly />
  );
}

export default NamePronunciation;
