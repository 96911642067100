/**
 * this maps the `path` from `slides.ts` to a field name from the config.
 */
export default {
  'about-me': 'bio',
  'what-i-do-here': 'whatIDoHere',
  skills: 'skills',
  education: 'education',
  experience: 'experience',
  languages: 'languages',
  interests: 'interests',
  assistants: 'assistants',
} as { [key: string]: string | undefined };
