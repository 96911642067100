import React from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { useTheme } from '@mui/material/styles';
import getPictureUrl from 'helpers/getPictureUrl';
import * as Types from 'components/Navigation/typings';
import * as Sift from '@sift/types';

import PersonMenu from 'components/skipper/appBar/PersonMenu';

interface Props {
  className?: string;
  classes?: any;
  avatar?: React.ReactNode;
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  navBarRoutes?: Types.Routes;
  accountRoutes?: Types.Routes;
  additionalRoutes?: Types.Routes;
  msTeamsRoutes?: Types.Routes;
  person: Sift.Person;
  pictureUrl?: string;
  onProfileClick?: () => void;
}

function NavPersonMenu({
  accountRoutes,
  additionalRoutes,
  className,
  classes,
  person,
  pictureUrl: _pictureUrl,
}: Props) {
  const theme = useTheme();

  const preferOfficialPhotos = useSelector(state =>
    _get(state, 'settings.directory.preferOfficialPhotos', false),
  );
  const officialPictureUrl = person.officialPictureUrl;
  const pictureUrl = getPictureUrl(
    _pictureUrl || person.pictureUrl,
    officialPictureUrl,
    preferOfficialPhotos,
    theme,
  );

  return (
    <PersonMenu
      accountRoutes={accountRoutes}
      additionalRoutes={additionalRoutes}
      className={className}
      classes={classes}
      person={person}
      pictureUrl={pictureUrl}
    />
  );
}

export default NavPersonMenu;
