import React from 'react';

// UI
import Loader from 'components/skipper/Loader';
import ErrorView from './ErrorView';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    position: 'relative',
    minHeight: '100px',
  },
  overlay: {},
  overlayTransparent: {},
}));

export default ({ ...props }) => {
  const { classes } = useStyles();

  return (
    <Loader
      classes={classes}
      isLoadingView={<CircularProgress />}
      isErrorView={<ErrorView />}
      {...props}
    />
  );
};
