import React, { memo, useContext } from 'react';
import LayoutContext from 'components/BigSearch/Results/LayoutContext';
import PropTypes from 'prop-types';
import useAnalytics from 'helpers/useAnalytics';

// Action
import { updateRecentSearches } from 'store/search';
import { useDispatch } from 'react-redux';

// Styles
import { makeStyles } from 'tss-react/mui';
import classnames from 'classnames';
import bigSearchStyles from '../../styles';

/**
 * Basic Big Search Item.
 *
 * All items should be passed through this component
 * so that styles can be shared, analytics tracked and
 * recent searches update.
 */
function Item({
  rows = 1,
  analyticsCardObject,
  analyticsIconObject,
  recentSearchObject,
  className,
  style,
  children,
  iconRecentSearchObject,
  icon,
}) {
  const { classes } = useStyles();
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const layout = useContext(LayoutContext);

  const onClick = () => {
    if (recentSearchObject) {
      dispatch(updateRecentSearches(recentSearchObject));
    }
    if (analyticsCardObject)
      analytics.track(analyticsCardObject.event, analyticsCardObject.properties);
  };

  const onIconClick = () => {
    if (iconRecentSearchObject) {
      dispatch(updateRecentSearches(iconRecentSearchObject));
    }
    if (analyticsIconObject)
      analytics.track(analyticsIconObject.event, analyticsIconObject.properties);
  };

  let itemStyle = {
    height: layout.heightForRows(rows),
    ...style,
  };

  return icon ? (
    <div
      data-testid="search-results-item"
      className={classnames(classes.root, className)}
      style={itemStyle}
    >
      <div
        data-testid="search-results-item-children"
        onClick={onClick}
        className={classes.container}
      >
        {children}
      </div>
      {icon && icon(onIconClick)}
    </div>
  ) : (
    <div
      data-testid="search-results-item"
      className={classnames(classes.root, className)}
      onClick={onClick}
      style={itemStyle}
    >
      {children}
    </div>
  );
}

const useStyles = makeStyles()(theme => {
  const bigSearch = bigSearchStyles(theme);

  return {
    root: {
      ...bigSearch.components.card,
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      borderRadius: '5px',
      display: 'flex',
      marginBottom: '2px',
      padding: theme.spacing(1),
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});

Item.propTypes = {
  rows: PropTypes.number,
  analyticsCardObject: PropTypes.object,
  analyticsIconObject: PropTypes.object,
  recentSearchObject: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  iconRecentSearchObject: PropTypes.object,
  icon: PropTypes.func,
};

export default memo(Item);
