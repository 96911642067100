import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const OfficePhone = props => (
  <SvgIcon {...props} viewBox="0 0 19 26">
    <g fillRule="nonzero">
      <path d="M17.371 5.088H5.944a1.799 1.799 0 0 0-1.493-.804H1.873A1.778 1.778 0 0 0 .08 6.052V20.94c0 .99.815 1.767 1.792 1.767h.706a2.9 2.9 0 0 0 2.904 2.838h7.871c1.602 0 2.905-1.285 2.905-2.865v-.776h.732c1.059 0 1.928-.857 1.928-1.902V7.016c.027-1.072-.842-1.928-1.548-1.928zM1.873 21.582a.61.61 0 0 1-.624-.616V6.078a.61.61 0 0 1 .624-.615H4.45a.61.61 0 0 1 .625.615v14.888a.61.61 0 0 1-.625.616H1.873zm13.218 1.125c0 .937-.787 1.714-1.737 1.714H5.483c-.95 0-1.737-.75-1.737-1.687h.705c.625 0 1.168-.322 1.493-.804h9.147v.777zm2.688-2.678a.782.782 0 0 1-.788.777H6.243V6.239h11.101c.109.054.435.375.435.777v13.013z" />
      <path d="M15.363 8.06H8.604a.57.57 0 0 0-.57.562v2.223a.57.57 0 0 0 .57.562h6.759a.57.57 0 0 0 .57-.562V8.622a.553.553 0 0 0-.57-.562z" />
      <ellipse cx="9.391" cy="13.496" rx="1" ry="1" />
      <ellipse cx="11.97" cy="13.496" rx="1" ry="1" />
      <ellipse cx="14.576" cy="13.496" rx="1" ry="1" />
      <ellipse cx="9.391" cy="15.959" rx="1" ry="1" />
      <ellipse cx="11.97" cy="15.959" rx="1" ry="1" />
      <ellipse cx="14.576" cy="15.959" rx="1" ry="1" />
      <ellipse cx="9.391" cy="18.449" rx="1" ry="1" />
      <ellipse cx="11.97" cy="18.449" rx="1" ry="1" />
      <ellipse cx="14.576" cy="18.449" rx="1" ry="1" />
      <path d="M11.97 2.33c-.543 0-1.086.16-1.52.428-.326.187-.38.67-.081.91.19.161.461.188.678.054.272-.16.57-.241.923-.241.353 0 .651.08.923.241a.578.578 0 0 0 .678-.054c.299-.24.245-.723-.081-.91a2.876 2.876 0 0 0-1.52-.428z" />
      <path d="M9.391 2.196a4.002 4.002 0 0 1 2.579-.937c.977 0 1.873.348 2.579.937.217.187.542.16.76 0 .271-.241.271-.643 0-.857C14.386.589 13.246.134 11.97.134a5.242 5.242 0 0 0-3.311 1.178.553.553 0 0 0 0 .857c.217.214.542.214.732.027z" />
    </g>
  </SvgIcon>
);

export default OfficePhone;
