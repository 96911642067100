import { useSelector } from 'react-redux';
import _get from 'lodash/get';

export default function useExploreEnabled() {
  const exploreIsEnabled = useSelector(state =>
    _get(state, 'client.settings.explore.enabled', false),
  );

  const personIsAdmin = useSelector(state =>
    _get(state, 'authentication.person.account.isAdmin', false),
  );

  return exploreIsEnabled || personIsAdmin;
}
