import config from 'config';
import moment from 'moment';

const APP_INTERVAL = 'APP_INTERVAL';

export const initialState = {
  name: config.APP_NAME,
  version: config.APP_VERSION,
  platform: 'browser',
  nextFetchInterval: moment().add(10, 'minutes'),
};

export const setFetchInterval = () => {
  return {
    type: APP_INTERVAL,
  };
};

const ACTION_HANDLERS = {
  [APP_INTERVAL]: state => {
    return {
      ...state,
      nextFetchInterval: moment().add(10, 'minutes'),
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function appReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
