export const SEARCH_PATH = '/search';
export const ENTITIES_PATH = '/entities';
export const MEMBERS_PATH = '/members';
export const LIST_PATH = '/list';
export const TABLE_PATH = '/table';
export const EXPLORE_PATH = '/explore';

export const MATCHED_FIELDS_IN_HEADER = ['title'];

export const ENTITY_TYPE_USER = 'user';
export const ENTITY_TYPE_PERSON = 'people';

export const PERSON_TABLE_START_COLUMNS = ['title', 'email'];
export const PAGE_TABLE_START_COLUMNS = ['memberCount'];
export const PERSON_TABLE_NAME_COLUMN = 'displayName';
export const PAGE_TABLE_NAME_COLUMN = 'name';
export const COLUMN_WIDTH = 200;
export const NAME_COLUMN_WIDTH = 300;

export const MAX_NUM_OF_GROUP_MSG_RECIPIENTS = 100;
