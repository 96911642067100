import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';
import { Theme } from '@mui/material/styles';

export default function getPictureUrl(
  pictureUrl: string,
  officialPictureUrl: string,
  preferOfficialPhotos: boolean,
  theme: Theme,
) {
  const personPictureUrl = preferOfficialPhotos ? officialPictureUrl : pictureUrl;

  if (personPictureUrl && !personPictureUrl.includes('profile-images/placeholders')) {
    return personPictureUrl;
  }

  if (theme.pictureUrl) return theme.pictureUrl;

  return createPictureUrlFromColor(theme.palette.primary.dark);
}
