import React from 'react';
import PropTypes from 'prop-types';
import typography from 'helpers/typography';

// Components
import Button from '@mui/material/Button';
import Success from 'components/Icons/Success';

import { makeStyles } from 'tss-react/mui';

// Component
function CompletedForm(props) {
  const { clientName, toggleSupportDrawer } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.drawerSection}>
      <Success style={{ marginBottom: 32, width: 183, height: 80 }} />
      <div className={classes.title}>
        The <i>{clientName}</i>
        <br />
        system administrator is on the case!
      </div>
      <div className={classes.text}>
        This message has been sent to your {`company's`} support team.
      </div>
      <div className={classes.text}>
        Someone will be in touch shortly regarding your submission.
      </div>
      <Button onClick={toggleSupportDrawer} className={classes.button}>
        CLOSE
      </Button>
    </div>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    drawerSection: {
      marginTop: 50,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    title: {
      ...typography(theme, 'h6'),
      marginBottom: 16,
      maxWidth: 350,
    },
    text: {
      ...typography(theme, 'subtitle1'),
      marginBottom: 32,
      maxWidth: 350,
    },
    button: {
      marginTop: 32,
    },
  };
});

CompletedForm.propTypes = {
  clientName: PropTypes.string,
  toggleSupportDrawer: PropTypes.func.isRequired,
};

export default CompletedForm;
