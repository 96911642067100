// Models
import Fetch from '@sift/resift/models/Fetch';
import { mergeLoading, mergeError } from '@sift/resift/redux/FetchUtils';
import { Status, States } from '@sift/resift/models/Status';

const initialState = {
  objects: {},
  fetches: {},
  types: {},
};

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_ENTITY = 'FETCH_ENTITY';
export const FETCH_ENTITY_SUCCESS = 'FETCH_ENTITY_SUCCESS';
export const FETCH_ENTITY_ERROR = 'FETCH_ENTITY_ERROR';

export const FETCH_ENTITY_TYPES = 'FETCH_ENTITY_TYPES';
export const FETCH_ENTITY_TYPES_SUCCESS = 'FETCH_ENTITY_TYPES_SUCCESS';
export const FETCH_ENTITY_TYPES_ERROR = 'FETCH_ENTITY_TYPES_SUCCESS_ERROR';

export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_ERROR = 'UPDATE_ENTITY_ERROR';

export const UPDATE_ENTITY_RELATIONSHIP = 'UPDATE_ENTITY_RELATIONSHIP';
export const UPDATE_ENTITY_RELATIONSHIP_SUCCESS = 'UPDATE_ENTITY_RELATIONSHIP_SUCCESS';
export const UPDATE_ENTITY_RELATIONSHIP_ERROR = 'UPDATE_ENTITY_RELATIONSHIP_ERROR';

export const PERSON_ENTITY_TYPE_KEY = 'user';

// ------------------------------------
// Actions
// ------------------------------------

/**
 * Get a set of entity types
 *
 * @param {string} status
 */
export const fetchEntityTypes = () => {
  return {
    type: FETCH_ENTITY_TYPES,
    identifier: 'entityTypes',
    payload: {},
  };
};

export const fetchEntity = entityId => {
  return {
    type: FETCH_ENTITY,
    identifier: 'entity',
    payload: {
      entityId,
    },
  };
};

export const updateEntity = (entity, fields, { retainDialogState }) => {
  return {
    type: UPDATE_ENTITY,
    identifier: 'entity',
    payload: {
      retainDialogState,
      data: {
        id: entity.id,
        entityField: entity.entityField,
        ...fields,
      },
    },
  };
};

export const updateEntityRelationship = (
  entity,
  relationships,
  { relationship, retainDialogState },
) => {
  return {
    type: UPDATE_ENTITY_RELATIONSHIP,
    identifier: entity.id,
    payload: {
      data: relationships,
      relationship,
      retainDialogState,
    },
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FETCH_ENTITY_TYPES]: mergeLoading,
  [FETCH_ENTITY_TYPES_SUCCESS]: (state, action) => {
    const identifier = action.identifier;
    const objectsAndIds = action.payload.data.results.reduce(
      (acc, cur, index) => {
        const id = cur.id;
        acc.objects[id] = cur;
        acc.ids.push(id);
        return acc;
      },
      {
        objects: {},
        ids: [],
      },
    );
    return {
      ...state,
      types: {
        ...state.types,
        ...objectsAndIds.objects,
      },
      fetches: {
        ...state.fetches,
        [identifier]: new Fetch(
          identifier,
          objectsAndIds.ids,
          [],
          action.payload.data,
          new Status(States.NORMAL),
        ),
      },
    };
  },
  [FETCH_ENTITY_TYPES_ERROR]: mergeError,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function EntityReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
