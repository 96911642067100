// React
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Column from '../Column';
import AggregateItem from '../../Items/AggregateItem';

class AggregatesColumn extends PureComponent {
  static propTypes = {
    result: PropTypes.object,
    query: PropTypes.string,
    onItemSelect: PropTypes.func,
    config: PropTypes.object.isRequired,
  };

  render() {
    const { result, query, config, ...otherProps } = this.props;

    const field = config.user.fields[result.field];

    if (!field) {
      return null;
    }

    return (
      <Column title={field.displayName} total={result.totalLength} {...otherProps}>
        {result.items.map(item => (
          <AggregateItem value={item} fieldKey={field.objectKey} query={query} key={item.value} />
        ))}
      </Column>
    );
  }
}

export default AggregatesColumn;
