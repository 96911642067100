import React from 'react';
import typography from 'helpers/typography';
import _get from 'lodash/get';

// Components
import Loader from 'components/skipper/Loader';
import AccordionItem from 'pages/Onboarding/components/AccordionItem';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& > *:not(:last-child) .expansion-panel-summary': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  exampleCopy: {
    ...typography(theme, 'body1'),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  className?: string;
  status: number;
  term: string;
  people: Array<{
    pictureUrl: string;
    displayName: string;
    content: React.ReactNode;
  }>;
}

function AttributeAccordion({ className, status, people, term }: Props) {
  const { classes } = useStyles();

  return (
    <Loader className={classNames(classes.root, className)} status={status}>
      <div className={classes.exampleCopy}>See examples from your {term}</div>
      {people.map((person, index) => (
        <AccordionItem autoOpen={index === 0} key={_get(person, ['id'], index)} person={person} />
      ))}
    </Loader>
  );
}

export default AttributeAccordion;
