import _get from 'lodash/get';
import createReducer from '@sift/resift/createReducer';

// ------------------------------------
// Constants
// ------------------------------------
export const SIFT_BOT_DISPLAY = 'SIFT_BOT_DISPLAY';

export const SIFT_BOT_FETCH = 'SIFT_BOT_FETCH';
export const SIFT_BOT_FETCH_SUCCESS = 'SIFT_BOT_FETCH_SUCCESS';
export const SIFT_BOT_FETCH_ERROR = 'SIFT_BOT_FETCH_ERROR';

export const SIFT_BOT_READ = 'SIFT_BOT_READ';
export const SIFT_BOT_READ_SUCCESS = 'SIFT_BOT_READ_SUCCESS';
export const SIFT_BOT_READ_ERROR = 'SIFT_BOT_READ_ERROR';

export const SIFT_BOT_OPT_OUT = 'SIFT_BOT_OPT_OUT';
export const SIFT_BOT_OPT_OUT_SUCCESS = 'SIFT_BOT_OPT_OUT_SUCCESS';
export const SIFT_BOT_OPT_OUT_ERROR = 'SIFT_BOT_OPT_OUT_ERROR';

const SIFT_BOT_SET_EDIT_FIELD = 'SIFT_BOT_SET_EDIT_FIELD';

// ------------------------------------
// Actions
// ------------------------------------
export function fetchSiftBot(userId) {
  return {
    type: SIFT_BOT_FETCH,
    payload: {
      userId,
    },
  };
}

export function readSiftBot(userId) {
  return {
    type: SIFT_BOT_READ,
    payload: {
      userId,
    },
  };
}

export function optOutSiftBot(userId, objectKey, reason) {
  return {
    type: SIFT_BOT_OPT_OUT,
    payload: {
      userId,
      data: {
        fields: [{ objectKey, reason }],
      },
    },
  };
}

export function toggleSiftBotDisplay() {
  return {
    type: SIFT_BOT_DISPLAY,
  };
}

/**
 * @param {string | undefined} objectKey
 */
export function setEditField(objectKey) {
  return {
    type: SIFT_BOT_SET_EDIT_FIELD,
    payload: objectKey,
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const actionHandlers = {
  [SIFT_BOT_FETCH_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload.data,
      readAt: state.readAt ? state.readAt : _get(action.payload.data, 'readAt'),
      open: _get(action.payload.data, 'autoOpen') ? true : !!state.open,
    };
  },
  [SIFT_BOT_READ_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload.data,
      readAt: state.readAt,
      open: _get(action.payload.data, 'autoOpen') ? true : !!state.open,
    };
  },
  [SIFT_BOT_OPT_OUT_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload.data,
      readAt: state.readAt,
      open: _get(action.payload.data, 'autoOpen') ? true : !!state.open,
    };
  },
  [SIFT_BOT_DISPLAY]: state => {
    return {
      ...state,
      open: !state.open,
    };
  },
  [SIFT_BOT_SET_EDIT_FIELD]: (state, action) => {
    return {
      ...state,
      editFieldObjectKey: action.payload,
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  fieldsNotCompleted: [],
  fieldsCompleted: [],
  editFieldObjectKey: undefined,
};

export default createReducer(initialState, actionHandlers);
