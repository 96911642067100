import { defineFetch } from 'resift';
import _get from 'lodash/get';
import * as Types from 'pages/Onboarding/typings';

const makeOnboardingStateFetch = defineFetch({
  displayName: 'update onboarding state',
  share: { namespace: 'settings' },
  make: () => ({
    request: (onboardingState: Types.OnboardingState) => async ({ http, getState }) => {
      const state = getState();
      const personId = _get(state, ['authentication', 'person', 'id']) as string | undefined;
      if (!personId) {
        throw new Error('[completedOnboardingFetch] could not find personId');
      }

      const settings = await http({
        method: 'GET',
        route: `/people/${personId}/settings`,
      });

      const completedSlides = _get(
        settings,
        ['directory', 'userOnboarding', 'completedSlides'],
        [],
      );

      return http({
        method: 'PUT',
        route: `/people/${personId}/settings`,
        data: {
          directory: {
            userOnboarding: {
              state: onboardingState,
              linkedInData: {},
              completedSlides,
            },
          },
        },
      });
    },
  }),
});

const updateOnboardingStateFetch = makeOnboardingStateFetch();

export default updateOnboardingStateFetch;
